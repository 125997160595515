<div *ngIf = '!isLoggedIn' class="page-background">
  <app-header></app-header>

  <p-toast key="loginError" position="center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [modal]="true" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
      <div style="text-align: center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
        <h3>{{message.summary}}</h3>
        <p>{{message.detail}}</p>
      </div>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 login-bck-btn" style="text-align: center">
          <button type="button" pButton label="Close" (click)="hideLoginError()" class="ui-button-success custom-button-danger">
            Close
          </button>
        </div>
      </div>
    </ng-template>
  </p-toast>
  <div class="row content-div d-flex margin-top-10">
    <div *ngIf="!loading" class="col-xl-9 col-lg-9 col-md-9 d-none d-md-block padding-10">
      <div *ngIf="!errorMessage && !isAuthenticated && enableTerms" class="login-left-wrapper img-fluid"></div>
    </div>

    <div *ngIf="loading" class=" inline-flex login-center">
      <app-loading-spinner [SigningIn]='true'></app-loading-spinner>
    </div>
    <div *ngIf="!loading && !errorMessage" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ml-0 margin-line-height">
      <div class="form-div">
        <!-- <h2 class="login-header text-center">Sign In to your account </h2> -->
        
        <div id="okta-login-container"></div>
      
        <div *ngIf="!isAuthenticated && enableTerms" class="terms">
          <div class="checkbox inline-flex">
            <h6 class="label-style">
              By logging into this application, you agree to the <a [href]="privacyLink" target="_blank" id='privacyPolicy'>
              Halliburton Privacy Policy</a>, which contains information about how your personal data is processed and accept the <a href="javascript:void(0);" id='T-and-c' (click)='openModal($event.target.id)'>
              Terms and Conditions</a> of the application.   
            </h6>
          </div>
        </div>
        
        <!-- <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for='username'>Username</label>
            <input type="text" formControlName="username" [(ngModel)]="username" ngDefaultControl
             class="padding-5 form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for='password'>Password</label>
            <input type="password"  formControlName="password" [(ngModel)]="password" ngDefaultControl
             class="padding-5 form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="checkbox inline-flex">
              <mat-checkbox name="Terms and Conditions" binary="isChecked" [(ngModel)]="isChecked" ngDefaultControl type="checkbox"
                [ngModelOptions]="{standalone: true}" value=""></mat-checkbox>
              <h6 class="label-style">
                By selecting this box, I acknowledge and accept the <a href="javascript:void(0);" id='T-and-c' (click)='openModal($event.target.id)'>
                  Terms and Conditions</a>
              </h6>
            </div>

          </div>
          <div class="form-group">
            <button [disabled]="!isChecked" class="login-button float-left">Sign In</button>

            <div *ngIf="loading" class=" inline-flex loading-spinner"></div>
            <div class="checkbox inline-flex move-checkbox" *ngIf="loading">
              <mat-checkbox name="Remember Me"  binary="remember"  [(ngModel)] ="remember" ngDefaultControl [ngModelOptions]="{standalone: true}" value="" ></mat-checkbox>
              <h6 class="label-style">
                Remember Me
              </h6>
            </div>

            <div class="checkbox inline-flex mt-1" *ngIf="!loading">
                <mat-checkbox name="Remember Me" [(ngModel)] ="remember" ngDefaultControl [ngModelOptions]="{standalone: true}" value="" binary="remember"></mat-checkbox>
                <h6 class="label-style">
                  Remember Me
                </h6>
              </div>
          </div>

          <hr class="horizontalRuler"/>
          <div *ngIf="!isLocal" class="forget-reset-password">
          <a class="forgotPassword" href={{passwordResetLink}} target="_blank">I forgot my password
         </a>
          </div>
          <br/>
         <label *ngIf="!isLocal" class="employee-label-style">**Employees sign in with network ID</label>

        </form> -->
        <div class="">
            <div class='vof-css-decorom'>
                <div class="label-style">
                    <span><img src="assets/images/Mail.png " class="img"/></span>
                    <span class="label-style ms-margin-left">
                      <a href="mailto:FDFWTSW@halliburton.com">FDFWTSW@halliburton.com</a>
                    </span>
                  </div>
                  <!-- <div class="label-style">
                    <span><img src="assets/images/Call.png " class="img"/></span>
                    <span class='font-weight-bold padding-15 ms-margin-left'>RTO Support:</span>
                    <span class="label-style padding-15">
                      <a href='tel:+1 (713) 839-4315'>+1.713.839.4315</a>
                    </span>
                  </div>
                  <div class="label-style">
                    <span><img src="assets/images/Call.png " class="img"/></span>
                    <span class='font-weight-bold padding-15 ms-margin-left'>Toll Free:</span>
                    <span class="label-style padding-15">
                      <a href='tel:+1 (877) 263-6071'>+1.877.263.6071</a>
                    </span>
                  </div> -->

              </div>
        </div>

      </div>

    </div>
  </div>

   <!-- Shows network errors -->
   <p-toast position="center" key="ToastMessageKeyNetwork" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"  [modal]="true" [baseZIndex]="12000">
    <ng-template let-message pTemplate="message">
      <div style="text-align: center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
        <h3>{{message.summary}}</h3>
        <p>{{message.detail}}</p>
      </div>
    </ng-template>
  </p-toast>

  <footer class='row footer-info'>
    <div class='col-8'>
      <span class='section-lower'>
        © Copyright {{copyRightYear}} Halliburton |
        <a target="_blank" rel="noopener noreferrer" href='https://www.halliburton.com/'>Halliburton.com </a>
      </span>
    </div>
    <div class='col-4'>
      <span class="float-right">Clariti&trade; {{version}}</span>
    </div>
  </footer>
  <div *ngIf='modal.open && modal.type === "T-and-c"'>
    <app-terms-conditions (close)='closeModal($event)' (termsNCondition)='checkValue($event)'></app-terms-conditions>
  </div>
</div>
