import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { ERR_MESSAGES } from 'src/app/shared/constants/error-messages.constants';
import configClariti from '../../../assets/data/config.json';
import { AuthenticationService } from '../../services/authentication.service';
import { HeaderService } from '../../services/header.service';
import { fileStatus, ViewOptions } from '../../shared/constants/master-page.constants';
import { State } from '../../state/container-states/app.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

// Need to clean up this component as we moved stuff to new side menu, There are usused methods/variables in this component we will do it soon
export class HeaderComponent implements OnInit, OnDestroy {
  public showControls = false;
  public showLoading= false;
  public selectedTab = ViewOptions.presTemp;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  displayName = '';
  unsubscribe$ = new Subject();
  viewOptions = ViewOptions;
  fileStatus = fileStatus;
  jobName: string;
  fileId: string;
  isLocal = false;
  highlightAlert = false;
  exportState: { [key: string]: boolean } = {
    started: false,
    ready: false,
    cancelled: false,
    failed: false
  };
  exportStatus: string;
  checkStatusInterval;
  statusList: Array<{ [key: string]: string }> = [];

  @Input()
  loggedIn = false;

  @Input()
  notificationStatus = false;

  @Input()
  notificationLength = '';

  monthTab = false;

  /* tslint:disable-next-line:no-output-rename */
  @Output('showDialog')
  showDialogEmitter = new EventEmitter();

  /* tslint:disable-next-line:no-output-rename */
  @Output('openSettings')
  openSettingsEmitter = new EventEmitter();

  /* tslint:disable-next-line:no-output-rename */
  @Output('toggleAlertPanel')
  toggleAlertPanelEmitter = new EventEmitter();

  @Output() clearAllLiveStreams = new EventEmitter();

  showControlsClick($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.showControls = !this.showControls;
    this.headerService.showSettingsMenu.next(this.showControls);
  }

  constructor(
    private loginService: AuthenticationService,
    private headerService: HeaderService,
    private store: Store<State>,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit() {

    if (configClariti.login.authentication.Method === 'local') {
      this.isLocal = true;
    }
    if (this.headerService && this.headerService.showSettingsMenu) {
      this.headerService.showSettingsMenu
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((showSettingsMenu) => {
          this.showControls = showSettingsMenu;
        });
    }
    if (this.currentUser) {
      //const userEmail: string = this.currentUser['email'];
      // const userEmail: string = this.currentUser['email'];
      // this.displayName = getDisplayNameFromEmail(userEmail);
      this.displayName = this.currentUser['displayName'];
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showDialog() {
    this.showDialogEmitter.emit();
  }

  scrollToDialog(){
    window.scrollTo({left: 0,
                     top: 100
    });
  }
  public toggleAlertPanel() {
    this.highlightAlert = !this.highlightAlert;
    this.toggleAlertPanelEmitter.emit();
  }

  public logoutUser(): void {
    if (navigator.onLine) { // logout only when internet is avilable
      this.showLoading =true;
      this.clearAllLiveStreams.emit();
      this.loginService.logoutCompletelyRedirectToLogin();
      // this.router.navigate(['login']).then(() => {
      //   this.showLoading =false;
      //   this.store.dispatch(new Logout()); // wait for navigating to login page before clearing the store
      // });
    } else { // else show error message
      const error = new Error();
      error.message = ERR_MESSAGES.NO_CONNECTIVITY;
      this.messageService.clear('ToastMessageKey');
      this.showDataRetrievalError(error);
    }
  }

  showDataRetrievalError(error) {
    this.messageService.clear('ToastMessageKey');
    this.messageService.add({
      key: 'ToastMessageKey',
      severity: 'error',
      summary: error.message,
      closable: false,
      detail: '',
      sticky: true
    });
  }

  hideSessionExpiredMsg(){
    this.messageService.clear();
  }

  openHelpDocument() {
    window.open('../../../assets/Clariti User Manual.pdf', '_blank');
  }

  public openSettings() {
    this.openSettingsEmitter.emit();
  }
}
