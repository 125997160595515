<div class="ui-g master-page-container">
</div>
<app-header [loggedIn]="true" (openSettings)="openSettings()" (showDialog)="showDialog()" (toggleTab)="toggleTab($event)"
  (toggleAlertPanel)="toggleAlertPanel()" [notificationStatus]="notificationStatus" [notificationLength]="notificationLength">
</app-header>

<app-side-nav #menu [loggedIn]="true" (openSettings)="openSettings()" (showDialog)="showDialog()" (toggleTab)="toggleTab($event)"
 (toggleAlertPanel)="toggleAlertPanel()" [notificationStatus]="notificationStatus"></app-side-nav>

<div class='ui-g page-background {{ selectedTab === viewOptions.export ? "scroll" : "" }}'>

  <!-- Global Error Section: Start -->
  <!-- Shows 201 data retrieval errors -->
  <p-toast position="center" key="ToastMessageKey" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"  [modal]="true" [baseZIndex]="12000">
      <ng-template let-message pTemplate="message">
        <div style="text-align: center">
          <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
          <h3>{{message.summary}}</h3>
          <p>{{message.detail}}</p>
        </div>
        <div class="ui-g ui-fluid">
          <div class="ui-g-12 login-bck-btn" style="text-align: center">
            <button type="button" pButton (click)="hideSessionExpiredMsg()" label="Close" class="ui-button-success custom-button-danger">
              Close
            </button>
          </div>
        </div>
      </ng-template>
    </p-toast>

  <!-- Shows network errors -->
  <p-toast position="center" key="ToastMessageKeyNetwork" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"  [modal]="true" [baseZIndex]="12000">
    <ng-template let-message pTemplate="message">
      <div style="text-align: center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
        <h3>{{message.summary}}</h3>
        <p>{{message.detail}}</p>
      </div>
    </ng-template>
  </p-toast>

  <!-- Shows 401 authorization errors -->
  <p-toast position="center" key="sessionExpired" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [modal]="true" [baseZIndex]="12000">
    <ng-template let-message pTemplate="message">
      <div style="text-align: center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
        <h3>{{message.summary}}</h3>
        <p>{{message.detail}}</p>
      </div>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 login-bck-btn" style="text-align: center">
          <button type="button" pButton (click)="hideDataSyncError()" label="Sign In Again" class="ui-button-success custom-button-danger">
            Ok
          </button>
        </div>
      </div>
    </ng-template>
  </p-toast>
  <!-- Global Error Section: End -->

  <div class='container-fluid export-section' *ngIf='selectedTab === viewOptions.export'>
    <app-export></app-export>
  </div>

  <div class='map-section' *ngIf='selectedTab === viewOptions.map'>
    <!-- <app-map [globalFilter]='globalFilter' [menu]='menu' [acknowledgedItems]= 'acknowledgedItems'></app-map> -->
    <app-azure-map [globalFilter]='globalFilter' [menu]='menu' ></app-azure-map>
  </div>

  <!-- main-section -->
  <div class='container-fluid export-section' *ngIf='selectedTab === viewOptions.newSchematic'>
    <app-schematics></app-schematics>
  </div>

  <div class="container-fluid export-section" [ngClass]="{ hide: hideMainSection(),  historicalview : isHistoricalView() }">
    <div  *ngIf='selectedTab === viewOptions.charts' [ngClass]="selectedTab === viewOptions.charts ? 'show' : 'hide'">
      <app-charts [selectedTab] = 'selectedTab' [selectedSensor]='selectedSensor' (showPressure)='getPresOrTemp($event)' [mappedColor]='mappedColor' [loaderForNoWellData] = 'tempLoader'>
      </app-charts>
    </div>
    <div  *ngIf='selectedTab === viewOptions.alertRules' [ngClass]="selectedTab === viewOptions.alertRules ? 'show' : 'hide'">
    <app-alert-page (openAddAlertDialog)='openAddAlertDialog()' [loaderForNoWellData] = 'tempLoader'>
    </app-alert-page>
  </div>

  </div>
  <div class="container-fluid alert-panel">
    <app-alert-panel [togglePanel]='displayAlertPanel' (activeAlerts)="setNotificationStatus($event)" (acknowledgedAlert)="getAcknowledgedItems($event)">
    </app-alert-panel>
  </div>
  <app-global-filter #globalFilter [selectedTab]='selectedTab' [isOpen]='display' (enableMenus) = "enableSideMenu()" (loaderCharts) ="enableLoaderCharts()" (hideDialog)="hideDialog()">
  </app-global-filter>
  <app-date-range-select-dialog [isOpen]='displayDateRangeSelect' (dateRangeChangedEvent)='onDateRangeChanged($event)'
    (hideDialog)="hideDateRangeSelDialog($event)">
  </app-date-range-select-dialog>


  <app-date-range-select-dialog-interactive [isOpen]='displayDateRangeSelectInteractive' (dateRangeChangedEventInteractive)='onDateRangeChangedInteractive($event)'
  (hideDialog)="hideDateRangeSelDialogInteractive($event)">
</app-date-range-select-dialog-interactive>

  <app-unit-converter *ngIf='converterData && displayConverter' [unitData]='converterData' [openDialog]='displayConverter'
    (closeModal)='hideModal()'>
  </app-unit-converter>
  <app-alert-details *ngIf='openAlertDetails' [alert]='openedAlert' [openDialog]='openAlertDetails' (hideDialog)='hideAlertDetails($event)'>
  </app-alert-details>
</div>
