import { IMeasurementClass, IUnit } from 'src/app/models/measurement';
import { EngineeringValue } from 'src/app/services/uom/EngineeringValue';
import { BilinearTransform } from './BilinearTransform';

export class UnitExtensions {
  static To<TMeasurement extends IMeasurementClass>(
    from: IUnit<TMeasurement>,
    to: IUnit<TMeasurement>
  ): BilinearTransform {
    if (from === to) {
      return BilinearTransform.Identity;
    } else {
      const inverseTransformFromBase = from.TransformFromBaseUnit.Inverse;
      const transformToBase = to.TransformFromBaseUnit;

      return inverseTransformFromBase.ComposeWith(transformToBase);
    }
  }

  static With<TMeasurement extends IMeasurementClass>(
    sourceValue: EngineeringValue<TMeasurement>,
    value: number
  ): EngineeringValue<TMeasurement> {
    return sourceValue.With(value);
  }

  static ConvertTo<TMeasurement extends IMeasurementClass>(
    sourceValue: EngineeringValue<TMeasurement>,
    targetUnits: IUnit<TMeasurement>
  ): EngineeringValue<TMeasurement> {
    if (sourceValue.Unit === targetUnits) {
      return sourceValue;
    } else {
      //(ST^-1)^-1 = TS^-1
      const convertedValue = targetUnits.TransformFromBaseUnit.ComposeWith(
        sourceValue.Unit.TransformFromBaseUnit.Inverse
      ).Evaluate(sourceValue.Value);
      return new EngineeringValue(convertedValue, targetUnits);
    }
  }
}
