<div class="loading-view" *ngIf="(loaderForNoWellData === undefined || !loaderForNoWellData || !alertsLoadCompleted)">
  <div class="loading-view">
    <div class='loading-overlay'>
      <div class='spinner-container'>
        <div class='spinner'></div>
      </div>
      <p class= 'message'>Please wait while we load your data</p>
    </div>
  </div>
</div>
<div class="row">
	<div class='col-md-8'>
		<div class="chart-section sm-height">
			<div class="alert-rules-container">
				<div class="alert-rules-title">Alert Rules</div>
				<div class="add-alert-rule">
					<app-add-alert-rule (click)="addNewAlert()"></app-add-alert-rule>
				</div>
				<div class="text-right clearfix">
					<button class="ui-button delete-alert-btn btn-secondary" label="clear all"
					[disabled]="!(alerts && alerts.length > 0)"
					type="button" (click)="deleteAllAlertWarning()">Delete All</button>
				</div>
				<div class="alert-rules-header">
					<div>Sensor</div>
					<div>Thresholds</div>
				</div>
				<div class='no-reading-text' *ngIf="showNoAlertMessage">No Alert Rules Available</div>
				<div class="loading-view" *ngIf="showWaitIndicator">
					<div class='loading-overlay'>
						<div class='spinner-container'>
							<div class='spinner'></div>
						</div>
						<p class= 'message'> Please wait while we load your data </p>
					</div>
				</div>

				<!--Battery Alerts panel Ends-->
				<div *ngFor="let alert of alerts; let i = index" class="alert-rule">
          <app-alert-rule
          [alertsList]='alerts'
          (duplicateWarning)='duplicateWarning($event)'
					[alert]="alert" (startRuleSaving)='alertSaving(i)'
					(startRuleDelete)='ruleDelete(i)'
					(alertEditModeOn)="activateEditAlertMode($event, i)">
					</app-alert-rule>
				</div>
			</div>

			<div *ngIf='isAddAlertDialogOpened'>
				<app-alert-rule-modal [alertsList]='alerts' (duplicateWarning)='duplicateWarning($event)' [openDialog]='isAddAlertDialogOpened' (hideDialog)='isAddAlertDialogOpened = $event'
				(creationInProgress)='creationInProgress = $event'>
				</app-alert-rule-modal>
			</div>
		</div>
	</div>

	<div class="col-md-4">
		<app-live-readings [selectedTile] = "'alerts'" ></app-live-readings>
	</div>
</div>
<!--Warning popup to alert user on delete all alerts button click-->
<p-toast key="deleteAllAlert" position="center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <p>{{message.detail}}</p>
    </div>
    <div class="multiple-btns">
      <button DefaultFocus class="btn-secondary btn-no" (click)="cancelWarning()">No</button>
      <button class="btn-secondary btn-yes" (click)="clearAllAlerts()">Yes</button>
    </div>
  </ng-template>
</p-toast>

<p-toast key="duplicateAlarm" position="center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <p>{{message.detail}}</p>
    </div>
    <div class="multiple-btns">
      <button class="btn-secondary btn-yes" (click)="cancelAlertWarning()">Ok</button>
    </div>
  </ng-template>
</p-toast>
