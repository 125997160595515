// tslint:disable:max-classes-per-file
/**
 *  NgRx
 */
import { Action } from '@ngrx/store';
import { Well } from '../reducers/global-filter.reducer';

export enum GlobalFilterActionTypes {
  LoadWells = '[Wells] Load Wells',
  LoadNickNames = '[Wells] Load Nicknames',
  LoadWellsSuccess = '[Wells] Load Success',
  LoadWellsFail = '[Wells] Load Fail',
  SelectWell = '[Filter] Select Well And Show Sensors',
  LoadSensors = '[Sensors] Load Sensors',
  LoadSensorsSuccess = '[Sensors] Load Success',
  UpdateNickName = '[Sensors] Nickname Success',
  LoadSensorsFail = '[Sensors] Load Fail',
  UpdateSensorName = '[Wells] Update Sensor Name',
  UpdateFilterSelection = '[Filter] Update Filter Selection',
  UpdatedSensors = "UpdatedSensors",
  NickNamesSuccess = "[Wells] Get Nicknames"
}

// Action Creators
export class LoadWells implements Action {
    readonly type = GlobalFilterActionTypes.LoadWells;
    constructor(public payload: any) { }
}

export class LoadNickNames implements Action {
  readonly type = GlobalFilterActionTypes.LoadNickNames;
  constructor(public payload: any) { }
}

export class LoadWellsSuccess implements Action {
  readonly type = GlobalFilterActionTypes.LoadWellsSuccess;
  constructor(public payload: Well[]) { }
}

export class LoadWellsFail implements Action {
  readonly type = GlobalFilterActionTypes.LoadWellsFail;
  constructor(public payload: string) { }
}

export class SelectWell implements Action {
  readonly type = GlobalFilterActionTypes.SelectWell;
  constructor(public payload: string[]) { }
}

export class LoadSensors implements Action {
    readonly type = GlobalFilterActionTypes.LoadSensors;
    constructor(public payload: any) { }
}

export class LoadSensorsSuccess implements Action {
  readonly type = GlobalFilterActionTypes.LoadSensorsSuccess;
  constructor(public payload: Well[]) { }
}

export class UpdateNickName implements Action {
  readonly type = GlobalFilterActionTypes.UpdateNickName;

  constructor(public payload: any) { }
}

export class LoadSensorsFail implements Action {
  readonly type = GlobalFilterActionTypes.LoadSensorsFail;
  constructor(public payload: string) { }
}

export class UpdateSensorName implements Action {
  readonly type = GlobalFilterActionTypes.UpdateSensorName;
  constructor(public payload: Well[]) { }
}

export class UpdateFilterSelection implements Action {
  readonly type = GlobalFilterActionTypes.UpdateFilterSelection;
  constructor(public payload: Well[]) { }
}

export class UpdatedSensors implements Action {
  readonly type = GlobalFilterActionTypes.UpdatedSensors;
  constructor(public payload: Well) { }
}

export class NickNamesSuccess implements Action {
  readonly type = GlobalFilterActionTypes.NickNamesSuccess;
  constructor(public payload: any) { }
}


// Union the valid types
export type GlobalFilterActions = LoadWells
  | LoadWellsSuccess
  | LoadWellsFail
  | LoadSensors
  | SelectWell
  | LoadSensorsSuccess
  | UpdateNickName
  | LoadSensorsFail
  | UpdateSensorName
  | UpdatedSensors
  | NickNamesSuccess
  | UpdateFilterSelection;
