/**
 * Ngrx reducer for authorization
 */
import { AuthorizationDetails } from '../../models/authorization';
import { CoreAction } from '../actions/actions';
import { ADD_AUTHORIZATION } from '../actions/authorizationActions';
import { IAuthorizationTokenState } from '../container-states/authorization.state';

export const initialAuthorization = {
    authorizationToken: {
        authorizationTokenFromService: '',
    },
};

/**
 * Reducer for Location authorization
 * @param {IAuthorizationTokenState} state - The authorization state
 * @param {CoreAction<AuthorizationDetails>} action - The location navigation action
 * @return {IAuthorizationTokenState} - The new location navigation state
 */
export function AuthorizationReducer(state: IAuthorizationTokenState  = initialAuthorization, action: CoreAction<AuthorizationDetails>)
 {
  switch (action.type) {
    case ADD_AUTHORIZATION : return {
      ...state,
      authorizationToken: action.payload,
    } as IAuthorizationTokenState;

    default: return state;
  }
}
