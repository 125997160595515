/**
 * Service to capture display toggle
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PanelToggleService {
  private toggleSource = new BehaviorSubject<any>('');
  public currentSelection = this.toggleSource.asObservable();

  public changeSelection(selection: any) {
    this.toggleSource.next(selection);
  }
}
