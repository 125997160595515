import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sortByKeyFunc } from '../../shared/utilities/util';
import { State } from '../../state/container-states/app.state';

@Component({
  selector: 'well-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit, OnDestroy {
  @Input()
  template: string;
  @Input() JobName: FormControl;
  @Input() UidWell: FormControl;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() wellSelected: EventEmitter<any> = new EventEmitter();
  wellList: any[];
  unsubscribe$ = new Subject();
  selectedUid: string;
  isApiInprogress = true;

  constructor(
    private store: Store<State>
  ) { }

  ngOnInit() {
    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        this.wellList = sortByKeyFunc(wellState.wells, 'wellName');
        this.isApiInprogress = wellState.apiInprogress;
      });
    if (this.UidWell.valid) {
      this.selectedUid = this.UidWell.value;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onWellSelect(uid: string) {
   this.selectedUid = uid;
  }

  onCancel() {
    this.close.emit();
  }

  saveSelection() {
    const {
      wellName,
      sensors,
      jobName,
      uid,
      startDateTimeIndex,
      endDateTimeIndex
    } = this.wellList.find((well) => well.uidWell === this.selectedUid);
    this.UidWell.setValue(this.selectedUid);
    this.JobName.setValue(jobName);
    this.wellSelected.emit({ wellName, sensors, uid, startDateTimeIndex, endDateTimeIndex });
  }
}
