import { IMeasurementClass } from '../../../models/measurement';
import { IUnit } from '../../../models/measurement';
import { BilinearTransform } from '../BilinearTransform';
import { Feet } from './Feet';

export class Meter implements IUnit<IMeasurementClass> {
  static readonly Instance: Meter = new Meter();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Feet.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(0.3048,0,0,1);
  }
}
