<div *ngIf='dataInput' class='measurement-section'>
<div class="measurement-header-container">
  <div class='measurement-header'> {{ dataInput.measurementLabel }} </div>
</div>
<div class="no-reading-text" *ngIf='noWellSelected && measurementSensor.length <= 0'>
  <span>No well selected.</span>
</div>

<div class="no-reading-text" *ngIf='(measurementSensor.length <= 0 && schematicSelectedWell) || (selectedLiveReadingsSchematics.length <= 0 && schematicSelectedWell)'>
	<span class="no-reading-text">No sensors selected for well</span>
</div>

<div class="no-reading-text" *ngIf='(selectedTile == tileList.charts) && noSensorSelected'>
	<span class="no-reading-text">No sensors selected for well</span>
</div>
<div *ngFor="let sen of pres">
	<div class="no-reading-text" *ngIf='(selectedTile == tileList.charts) && (selectedSensor === sen.sensorType) && (sen.sensorLists.length === 0) && !(selectedLiveReadings.length <= 0) && (measurementSensor.length > 0)'>
		<span>No {{sen.displayName}} sensors selected.</span>
	</div>
</div>


<div class="no-reading-text" *ngIf='(selectedTile == tileList.alerts) && noSensorSelected'>
	<span>No sensors selected for well</span>
</div>

<div class="scroll-bar"
  *ngIf=' selectedTile == tileList.schematics && selectedLiveReadingsSchematics.length > 0 && measurementSensor.length > 0 '>
  <div *ngFor='let selectedReading of selectedLiveReadingsSchematics'>
    <app-sensor-display [measurementData]='dataInput' [displayData]='selectedReading' [isSchematic]='true'
      [showColor]='visible' [isAlert]='false'>
    </app-sensor-display>
  </div>
</div>
<div class="scroll-bar" *ngIf=' selectedTile == tileList.charts && measurementSensor.length > 0'>
  <div *ngFor='let selectedReading of selectedLiveReadings'>
    <app-sensor-display [measurementData]='dataInput' [displayData]='selectedReading' [isSchematic]='false'
      [isAlert]='false' [showColor]='visible' [color]='mappedColorCharts[selectedReading.combinedMnemonics]'>
    </app-sensor-display>
  </div>
</div>
<div class="scroll-bar" *ngIf='selectedTile == tileList.alerts && measurementSensor.length > 0'>
  <div *ngFor='let selectedReading of selectedLiveReadingsAlerts'>
    <app-sensor-display [measurementData]='dataInput' [displayData]='selectedReading' [isSchematic]='false'
      [isAlert]='true' [showColor]='hidden'>
    </app-sensor-display>
  </div>
</div>
</div>
