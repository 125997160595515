import { Injectable } from '@angular/core';
/* NgRx */
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { WellService } from 'src/app/services/well.service';
import { GlobalFilterDbService } from '../../services/global-filter-db.service';
import { SensorNameEditService } from '../../services/sensor-name-edit.service';
import * as globalFilterActions from '../actions/global-filter.actions';
import * as userActions from '../actions/userActions';
import { State } from '../container-states/app.state';
import * as fromFilter from '../container-states/global-filter.state';


@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private filterDBService: GlobalFilterDbService,
    private filterState: Store<fromFilter.GlobalFilterState>,
    private sensorNameService: SensorNameEditService,
    private wellService: WellService,
    private store: Store<State>
  ) {}

  @Effect()
  loadUserFilters$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoadUserFilters),
    map((action: userActions.LoadUserFilters) => action.payload),
    mergeMap((user) => {
      return this.filterDBService.getUserFilters(user).pipe(
        map((res) => {
            const jobsTobeFiltered = _.cloneDeep(user.IWJob);
            const noDataWellClone = _.cloneDeep(user.IWJob);
            const filteredJobsForRequest = jobsTobeFiltered.filter((jobs) => { // remove iwjobs with well uid null
              return jobs.wellUid != null;
            });
            const noDataWell = noDataWellClone.filter((jobs) => { // filter out wells with no data
              return jobs.wellUid == null;
            });
            //To clear the existing wellswithNodata
            this.wellService.setNoDataWells([], []);
            if (res && res.length > 0) {
            // Get Wells and Sensors
            this.filterState.dispatch(new globalFilterActions.LoadWells({
              jobs: filteredJobsForRequest,
              localFilters: res[0],
              noDataWells: noDataWell
            }));
            return new userActions.LoadUserFiltersSuccess(res[0]);
          } else {
            this.filterState.dispatch(new globalFilterActions.LoadWells({
              jobs: filteredJobsForRequest,
              localFilters: null,
              noDataWells: noDataWell
            }));
            return new userActions.LoadUserFiltersSuccess(null);
          }
      }));
    })
  );

  @Effect()
  addUserPreferences$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.AddUserPreferences),
    map((action: userActions.AddUserPreferences) => action.payload),
    mergeMap((user) => {

      return this.sensorNameService.getuserSensors(user).pipe(
        map((res) => {
          return new userActions.AddUserPreferencesSuccess(res);
        })
      );
    })
  );

  @Effect()
  editUserSensorName$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.EditUserSensorName),
    map((action: userActions.EditUserSensorName) => action.payload),
    mergeMap((payload) => {
      const currentWell = payload['currentWell'];
      return this.sensorNameService
      .updateSensorName(payload['newLabel'], payload['oldLabel'], currentWell['wellName'])
      .pipe(
        map((res) => {
          const well = payload['currentWell'];

            well.sensors.map((sensor) => {
              if (sensor.sensorOriginalName === payload['oldLabel']) {
                sensor.displayName = payload['newLabel'];
              }
            });
          this.store.dispatch(new globalFilterActions.UpdateSensorName(payload['wells']));
          const user = JSON.parse(localStorage['currentUser']);
          return new userActions.AddUserPreferences(user);
        })
      );
    })
  );
}
