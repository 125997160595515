import { IMeasurementClass } from '../../../models/measurement';
import { IUnit } from '../../../models/measurement';
import { BilinearTransform } from '../BilinearTransform';
import { Pascals } from './Pascals';


export class Bar implements IUnit<IMeasurementClass> {
  static readonly Instance: Bar = new Bar();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Pascals.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(1,0,0,100000);
  }
}
