import { Component } from '@angular/core';

@Component({
  selector: 'app-add-alert-rule',
  templateUrl: './add-alert-rule.component.html',
  styleUrls: ['./add-alert-rule.component.scss'],
})
export class AddAlertRuleComponent {
  constructor() { }
}
