import { IMeasurementClass } from '../../../models/measurement';
import { IUnit } from '../../../models/measurement';
import { BilinearTransform } from '../BilinearTransform';
import { Pascals } from './Pascals';


export class PoundsPerSqInchGauge implements IUnit<IMeasurementClass> {
  static readonly Instance: PoundsPerSqInchGauge = new PoundsPerSqInchGauge();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Pascals.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(1, -101352.932207, 0, 6894.75729318);
  }
}
