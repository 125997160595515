import { IMeasurementClass, IUnit } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";
import { CubicFeetPerSec } from "./CubicFeetPerSec";

export class BarrelsPerDay implements IUnit<IMeasurementClass> {
  static readonly Instance: BarrelsPerDay = new BarrelsPerDay();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return CubicFeetPerSec.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(15388.4972170686,0,0,1);
  }
}
