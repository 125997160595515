import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-history-view',
    templateUrl: 'history-view.component.html',
    styleUrls: ['./history-view.component.scss']
})
export class HistoryViewComponent implements OnInit {

    constructor() { }
    @Output()
    showDateRangeSelectDialog = new EventEmitter<boolean>();
    ngOnInit() { 

    }

    showDateRangeSelectEvent() {
        this.showDateRangeSelectDialog.next(true);
    }
}