<div class='alert-details-modal'>
<p-dialog [(visible)]="showSupportDialog" [draggable]="false" [showHeader]="false" [blockScroll]="true" [modal]="true"
  [breakpoint]="500" [style]="{'min-width': '200px'}" [responsive]="true" [contentStyle]="{'overflow':'auto'}" appendTo="body" [baseZIndex]="10001">
  <div class="error-dialog">
    <div class="col-md-12">
      <p class="header-text">Export is available in the desktop application.</p>
    </div>
    <div class="col-md-12 button-div">
      <button pButton type="button" class="confirmation-button" (click)="closeSupportDialog()" label="OK">OK</button>
    </div>
  </div>
</p-dialog>

 <p-dialog [(visible)]='openDialog' [modal]='true' [responsive]='true' (onHide)='closeDialog()' [style]="{'min-width': '750px'}" [minY]='70'
    [maximizable]='false' [draggable]='false'>
    <p-header>
      <div class='alert-details-modal-header ui-g'>
        <div class='header-logo ui-g-12'>
          <div class='ui-g-5'>
            <img class='header-logo' src="../../../assets/master-page/white.png">
          </div>
          <div class='ui-g-5'>
            <span class='brand-name'>Clariti&trade;</span>
          </div>
        </div>
        <div class='header-icons ui-g-2'>
          <div>
            <span *ngIf='isDesktopDevice' (click)="exportConfirmWarning()" title="Export Data" class='fa fa-external-link'></span>
            <span *ngIf='!isDesktopDevice' class='fa fa-external-link disabled'  (click)="showSupportMessage()" ></span>
            <span class='fa fa-close' (click)='closeDialog()'></span>
          </div>
        </div>
      </div>
    </p-header>
    <div class='alert-details-modal-content container-fluid'>
      <div class='row justify-content-center'>
        <div class='alert-name col-sm-12 col-md-12 col-lg-12'>
            <span>{{ alert.AlarmDetail.displayName }} <span class="wellName">({{alert.WellName}})</span> Alert</span>
        </div>
        <div class='alert col-md-4 col-lg-4 col-sm-12'>
          <app-alert-card *ngIf='tempPressureResponse' [alertData]="alert" [wellInformation] = "wellInformation" [showOverlay]="false"></app-alert-card>
        </div>
        <div class="loading-view" *ngIf="chartLoading.chartLoading.alertModal && !tempPressureResponse">
          <app-loading-spinner></app-loading-spinner>
        </div>
        <app-alert-chart *ngIf='tempPressureResponse' class='col-md-8 col-lg-8 col-sm-12 mb-1em'
        (dataSourceForAlert) = 'setDataSourceForAlert($event)'
        [graphData] ='tempPressureResponse'
        [converterData]='converterData'></app-alert-chart>
      </div>
    </div>
  </p-dialog>
    <!--Warning popup to alert user on export button click-->
    <p-toast key="alertExportConfirm" position="center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [modal]="true" [baseZIndex]="5000">
      <ng-template let-message pTemplate="message">
        <div style="text-align: center">
          <p>{{message.detail}}</p>
        </div>
        <div class="multiple-btns">
          <button DefaultFocus class="btn-secondary btn-no" (click)="cancelErrorWarnPopup()">No</button>
          <button class="btn-secondary btn-yes" (click)="export()">Yes</button>
        </div>
      </ng-template>
    </p-toast>
</div>
