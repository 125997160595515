/**
 * File for constants.
 * TO DO: Replace with actual data
 */

export const MeasurementConstants = {
  MEASUREMENT_DATA: [],
  MEASUREMENT_LABEL: 'Live Readings',
  PRESSURE_SENSOR_LABEL: 'Pressure Sensor',
  PRESSURE_UNIT: 'kPa',
  BATTERY_LABEL: 'percent',
  BATTERY_UNIT: '%',
  TEMPERATURE_SENSOR_LABEL: 'Temperature Sensor',
  TEMPERATURE_UNIT: 'C',
  PITCHANGERATE_UNIT: 'BBL/min',
  COLOR_SCHEMES: ['#b5ddf7', '#3cb44b', '#e3c91a', '#4363d8', '#f58231', '#63fa79', '#6c6a76', '#42D4F4',
  '#bfef45', '#fabebe', '#469990', '#e6beff', '#9A6324', '#f0e14b', '#b39db2', '#aaffc3', '#5f8beb',
  '#ffd8b1', '#0ff075', '#a9a9a9', '#0000ff', '#b71d1d', '#eb7819', '#0d8b7a', '#0a8fa1', '#8a43e2',
  '#d0021b', '#ffab02', '#00bfa5', '#01b8d4', '#aa00ff', '#e4143c', '#ffc401', '#1de9b6', '#00e5ff',
  '#c31ae0', '#ff5252', '#eec119', '#63ffdb', '#17ffff', '#ff75ba', '#ff897f', '#dfcc82', '#c5fff2',
  '#9fffff', '#ef8eff', '#ffc4bf', '#ddd7c0', '#cfe1dd', '#afd6d6', '#f7c9ff'],
  PRESSURE_AXIS_COLOR: ['#556B2F','#3CB371','#FF6347','#DC143C','#000000', '#ce566a', '#ec9b3b', '#0770a9', '#70416d', '#F50057', '#807a77', '#a0c334', '#107a2b', '#ff6347', '#04bdc0',
  '#000000', '#ce566a', '#ec9b3b', '#0770a9', '#70416d', '#F50057', '#807a77', '#a0c334', '#107a2b', '#ff6347', '#04bdc0'],
  TEMPERATURE_AXIS_COLOR: [ '#000296', '#ed0cef', '#1089ff', '#6600a3','#808000 ', '#48571d', '#ff4893', '#7b68ee', '#00BFA5', '#800000', '#b81260',
  '#000296', '#6600a3', '#48571d', '#ed0cef', '#1089ff', '#808000 ', '#ff4893', '#7b68ee', '#00BFA5', '#800000' , '#b81260',
  '#000296', '#6600a3', '#48571d', '#ed0cef', '#1089ff', '#808000 ', '#ff4893', '#7b68ee', '#00BFA5', '#800000', '#b81260'],
  FLOWRATE_AXIS_COLOR: ['#4169E1','#8A2BE2','#00BFFF','#8B0000','#4B0082','#FF00FF','#483D8B','#00008B','#008080','#6B8E23','#006400','#BDB76B','#FF4500','#cd5c5c','#c0c0c0',
  '#ff0000','#800080', '#ffff00','#808080','#00ff00','#ff8000','#ff0080','#3B8471','#00EE76'],
  GASRATE_AXIS_COLOR: ['#3E2723','#512DA8','#212121','#4CAF50','#9C27B0','#455A64','#D32F2F','#673AB7','#1B5E20','#795548','#1976D2','#607D8B','#C62828','#4E342E','#673AB7','#D84315','#263238','#00695C','#AFB42B','#8E24AA'],
  LIQUIDRATE_AXIS_COLOR: ['#1E1E1E','#2C3E50','#34495E','#2E4053','#212F3C','#303030','#2B2B2B','#262626','#4A4A4A','#1C1C1C','#333333','#424242','#2D2D2D','#282828','#1A1A1A','#151515','#2A2A2A','#1F1F1F','#383838','#1D1D1D']
};

export const ViewOptions = {
  map: 'Map View',
  schematic: 'Well Schematic',
  presTemp: 'Pressure and Temperature',
  charts: 'New Charts',
  alertRules: 'Alert Rules',
  export: 'Export',
  oneMonth: 'One Month',
  newSchematic: 'New Well Schematic',
};

export const fileStatus = {
  inProgress: 'In Progress',
  ready: 'Ready',
  failed: 'Failed',
  done: 'Done'
};

export const OWAI_APP_NAME: string = "Clariti View";
export const MS_APIS: string[] = ["getEntitlements", "keepalive", "Alarm","Export", "export"];
export const PRIVACY_POLICY_URL = "https://www.halliburton.com/en/about-us/corporate-governance/privacy-policy";
export const ADMIN_ROLES: string[] = ["Clariti.Admin", "clariti.admin"];

export const NicknameConstants = {
  RESTORE_SENSOR_NAME : "Restoring name to default...",
  RESTORE_SUCCESS : "Restored successfully",
  RESTORE_FAILED : "Restore failed",
  NICKNAME_EXISTS : "Already exists!",
  UPDATING_NICKNAME : "Updating nickname...",
  UPDATE_SUCCESS : "Updated successfully",
  NICKNAME_UPDATE_FAILED : "Update failed"
};