export class BilinearTransform {
  constructor(
    public A: number,
    public B: number,
    public C: number,
    public D: number
  ) {}

  static Identity: BilinearTransform = new BilinearTransform(1, 0, 0, 1);

  get Inverse(): BilinearTransform {
    return new BilinearTransform(this.D, -this.B, -this.C, this.A);
  }

  Evaluate(value: number): number {
    return (this.A * value + this.B) / (this.C * value + this.D);
  }

  ComposeWith(next: BilinearTransform): BilinearTransform {
    return new BilinearTransform(
      this.A * next.A + this.B * next.C,
      this.A * next.B + this.B * next.D,
      this.C * next.A + this.D * next.C,
      this.C * next.B + this.D * next.D
    );
  }

  static Multiply(
    first: BilinearTransform,
    second: BilinearTransform
  ): BilinearTransform {
    return first.ComposeWith(second);
  }
}
