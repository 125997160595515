export const DecimalsOptions = [
    { label: 'None', value: 0 },
    { label: '.0', value: 1},
    { label: '.00', value: 2},
    { label: '.000', value: 3},
    { label: '.0000', value: 4},
    { label: '.00000', value: 5},
    { label: '.000000', value: 6},
];

export const DelimiterOptions = [
    { label: 'Tab', value: "	" },
    { label: 'Space', value: " " },
    { label: 'Semicolon', value: ';' },
    { label: 'Comma', value: ',' }
];

export const DateTimeFormatOptions = [
    { label: 'MM/DD/YY HH:mm:SS', value: 'MM/dd/yy HH:mm:ss' },
    { label: 'Seconds', value: 's' },
    { label: 'HH:mm:SS DD-MMM-YY', value: 'HH:mm:ss dd-MMM-yy' },
    { label: 'HH:mm:SS MM/DD/YY', value: 'HH:mm:ss MM/dd/yy' },
    { label: 'HH:mm:SS DD/MM/YY', value: 'HH:mm:ss dd/MM/yy' },
    { label: 'HH:mm:SS DDMMMYY', value: 'HH:mm:ss ddMMMyy' },
    { label: 'DD-MM-YY HH:mm:SS', value: 'dd-MM-yy HH:mm:ss' },
    { label: 'DDMMMYY HH:mm:SS', value: 'ddMMMyy HH:mm:ss' },
    { label: 'DD/MM/YY HH:mm:SS', value: 'dd/MM/yy HH:mm:ss' },
];

export const DateTimeFormatOptionsLocal = [
  { label: 'MM/DD/YY HH:mm:SS', value: 'MM/dd/yy HH:mm:ss' },
  { label: 'Seconds', value: 's' },
  { label: 'HH:mm:SS DD-MMM-YY', value: 'HH:mm:ss dd-MMM-yy' },
  { label: 'HH:mm:SS MM/DD/YY', value: 'HH:mm:ss MM/dd/yy' },
  { label: 'HH:mm:SS DD/MM/YY', value: 'HH:mm:ss dd/MM/yy' },
  { label: 'HH:mm:SS DDMMMYY', value: 'HH:mm:ss ddMMMyy' },
  { label: 'DD-MM-YY HH:mm:SS', value: 'dd-MM-yy HH:mm:ss' },
  { label: 'DDMMMYY HH:mm:SS', value: 'ddMMMyy HH:mm:ss' },
  { label: 'DD/MM/YY HH:mm:SS', value: 'dd/MM/yy HH:mm:ss' },
];

export const warrantyMessage: string =  `\r\nWARRANTY: Halliburton will use its best efforts to furnish customers with accurate information and interpretations\r\n that are part of, and incident to, the services provided. However, Halliburton cannot and does\r\n not warrant the accuracy or correctness of such information and interpretations. Under no circumstances should\r\n any such information or interpretation be relied upon as the sole basis for any drilling,\r\n completion, production, or financial decision or any procedure involving any risk to the safety of\r\n any drilling venture, drilling rig or its crew or any other third party. The Customer\r\n has full responsibility for all drilling, completion and production operation. Halliburton makes no representations or\r\n warranties, either expressed or implied, including, but not limited to, the implied warranties of merchantability\r\n or fitness for a particular purpose, with respect to the services rendered. In no event\r\n will Halliburton be liable for failure to obtain any particular results or for any damages,\r\n including, but not limited to, indirect, special or consequential damages, resulting from the use of\r\n any information or interpretation provided by Halliburton.\r\n\r\n`

export const dateAsString = (date) => {
  return `${date.getFullYear()}` + `${date.getMonth()}` + `${date.getDate()}`;
};

export const unitsLookupByUnitProfile = {
  'English|00000000-0000-0000-0000-000000000001': {
    Pressure: 'psia',
    Temperature: 'degF'
  },
  'Metric|00000000-0000-0000-0000-000000000002': {
    Pressure: 'kPaa',
    Temperature: 'degC'
  }
}

export const defaultExportFileName: string = "Export.zip";
export const fileHeaders = {
  created: 'Export file not created yet',
  cancelled: 'Export was cancelled',
  failed: 'Export failed',
}