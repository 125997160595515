import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { UserFilters, UserGlobal } from '../models/user';
import { GlobalErrorService } from '../shared/services/global-error.service';
import { BaseService } from '../shared/services/indexdb/base.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalFilterDbService {
  constructor(
    private dbService: BaseService,
    private globalErrorService: GlobalErrorService
  ) {}

  addFilterToDB(selectedFilter: UserFilters) {
    let user;

    if (localStorage['currentUser']) {
      user = JSON.parse(localStorage['currentUser']);
      if (user) {
        try {
        this.getUserFilters(user.email).subscribe((result) => {
          if (result && result.length > 0) {
              this.dbService.connection
                .update({
                  in: 'GlobalFilter',
                  set: {
                    filters: selectedFilter.filters
                  },
                  where: {
                    userId: selectedFilter.userId
                  }
                })
                .then(() => {})
                .catch((err) => console.log(err));
            } else {
              this.dbService.connection
                .insert({
                  into: 'GlobalFilter',
                  return: true,
                  values: [selectedFilter]
                })
                .then((result) => {
                  if (result) {
                  }
                })
                .catch((err) => console.log(err));
            }
          })
        }catch(err) {
          console.log(err)
        };
      
    } else {
      const err = {
        endpointUrl: 'Keepalive API'
      };
      this.globalErrorService.set401Error(err);
    }
  }}

  getUserFilters(user: UserGlobal): Observable<any> {
    return from(
      this.dbService.connection
            .select({
              from: 'GlobalFilter',
              where: {
                userId: user.email
              }
            })
            .then((result) => result || [])
           ).pipe(
            catchError(() => of([]))
           );
  }
}
