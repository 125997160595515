export const Units = {
  BBL_S: 'bblsec',
  BBL_MIN: 'bblmin',
  BBL_D: 'bblday',
  GAL_S: 'galsec',
  GAL_MIN: 'galmin',
  GAL_D: 'galday',
  CMPD: 'cmpd',
  THOUSAND_CFPD: 'mcfpd',
  MILLION_CFPD: 'mmcfpd',
  MILLION_CMPD: 'e6m3pd'
};

