import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { ADMIN_ROLES } from './../../app/shared/constants/master-page.constants';

@Directive({
  selector: '[enableIfAdmin]'
})
export class EnableIfAdminDirective implements OnInit {
  @Input('enableIfAdmin') userRoles: string[];
  isAllowed = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const allowedRoles = ADMIN_ROLES;
    
    for (let i = 0; i < this.userRoles.length; i++) {
      const item = this.userRoles[i];
      if (allowedRoles.indexOf(item)) {
        this.isAllowed = true;
        break; 
      }
    }

    //disable if the role is not an admin
    if (!this.isAllowed) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }
}
