import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumber } from './only-number.directive';
import { DefaultFocus } from './default-focus-directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    OnlyNumber,
    DefaultFocus
  ],
  exports: [
    OnlyNumber,
    DefaultFocus
  ]
})
export class DirectivesModule { }
