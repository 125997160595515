import { IMeasurementClass, IUnit } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";
import { CubicFeetPerSec } from "./CubicFeetPerSec";

export class ThousandCubicFeetPerDay implements IUnit<IMeasurementClass> {
  static readonly Instance: ThousandCubicFeetPerDay = new ThousandCubicFeetPerDay();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return CubicFeetPerSec.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(86.4,0,0,1);
  }
}
