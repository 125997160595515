import moment from 'moment-timezone';

export const generateTimeZoneOffsetString = (date: Date, timeZone: string): string =>  {
  const timeZoneOffset = moment(date).tz(timeZone).utcOffset() / 60;
  const timeZoneOffsetHours = truncateDecimals(timeZoneOffset);
  const timeZoneOffsetMinutes = (timeZoneOffset - timeZoneOffsetHours) * 60;

  const absTimeZoneOffsetHours = Math.abs(timeZoneOffsetHours);
  
  let hours = absTimeZoneOffsetHours.toString();

  if (absTimeZoneOffsetHours < 10) {
    hours = `0${absTimeZoneOffsetHours}`;
  }

  const absTimeZoneOffsetMinutes = Math.abs(timeZoneOffsetMinutes);

  let minutes = absTimeZoneOffsetMinutes.toString();

  if (absTimeZoneOffsetMinutes < 10) {
    minutes = `0${absTimeZoneOffsetMinutes}`;
  }

  if (timeZoneOffsetHours < 0) {
    return `-${hours}:${minutes}`;
  } else {
    return `+${hours}:${minutes}`;
  }
};

export const addHoursByTimeZone = (date: Date, timeZone: string): Date => {
    const timeZoneOffset = moment(date).tz(timeZone).utcOffset() / 60;
    const browserOffset = new Date(date).getTimezoneOffset() / 60;
    const timeZoneOffsetHours = truncateDecimals(timeZoneOffset);
    const browserOffsetHours = truncateDecimals(browserOffset);
    const timeZoneOffsetMinutes = (timeZoneOffset - timeZoneOffsetHours) * 60;
    const browserOffsetMinutes = (browserOffset - browserOffsetHours) * 60;

    const timestamp = date.setHours(
      date.getHours() + timeZoneOffsetHours + browserOffsetHours,
      date.getMinutes() + timeZoneOffsetMinutes + browserOffsetMinutes
    );
    return new Date(timestamp);
}

export const subtractHoursByTimeZone = (date: Date, timeZone: string): Date => {
    const timeZoneOffset = moment(date).tz(timeZone).utcOffset() / 60;
    const browserOffset = date.getTimezoneOffset() / 60;
    const timeZoneOffsetHours = truncateDecimals(timeZoneOffset);
    const browserOffsetHours = truncateDecimals(browserOffset);
    const timeZoneOffsetMinutes = (timeZoneOffset - timeZoneOffsetHours) * 60;
    const browserOffsetMinutes = (browserOffset - browserOffsetHours) * 60;
    const timestamp = date.setHours(
      date.getHours() - timeZoneOffsetHours - browserOffsetHours,
      date.getMinutes() - timeZoneOffsetMinutes - browserOffsetMinutes
    );
    return new Date(timestamp);
}
//function to get correct time based on selected timezone starts here
export const subtractHoursByTimeZoneForHistorical = (date: Date, timeZone: string): Date => {
  const timeZoneOffset = moment(date).tz(timeZone).utcOffset() / 60;
  const browserOffset = new Date().getTimezoneOffset() / 60;
  var tempDate = moment(date).subtract(browserOffset, 'hours').format();
  tempDate = moment(tempDate).subtract(timeZoneOffset, 'hours').format();
  return new Date(tempDate);
}
//function to get correct time based on selected timezone ends here

const truncateDecimals = (number) => {
  return Math[number < 0 ? 'ceil' : 'floor'](number);
};

export const convertToNewTimeZone = 
  (date: Date, oldTz: string, newTz: string): Date => {
    const previousDate = new Date(date.valueOf());
    const revertedDate = subtractHoursByTimeZone(previousDate, oldTz);
    return addHoursByTimeZone(revertedDate, newTz);
};
