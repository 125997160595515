import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ViewOptions } from '../shared/constants/master-page.constants';

@Injectable()
export class ChartLoadingService {
  public chartLoading: { [key: string]: BehaviorSubject<boolean>} = {
    [ViewOptions.oneMonth]: new BehaviorSubject<boolean>(false),
    [ViewOptions.charts]: new BehaviorSubject<boolean>(false),
    [ViewOptions.presTemp]: new BehaviorSubject<boolean>(false),
    alertModal: new BehaviorSubject<boolean>(false)
  };
  public filterApplied = false;
  public timeWindowChanged = false;
  public xAxisTimeRange = 3600000;
  public sliderValue: BehaviorSubject<number> = new BehaviorSubject<number>(Date.now());
  public requestInitiated = false;
  public cancelChartLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showSpinner(chart: string) {
    this.chartLoading[chart].next(true);
  }
  hideSpinner(chart: string) {
    this.chartLoading[chart].next(false);
  }
  stopChartLoading() {
    this.cancelChartLoading.next(true);
  }
}
