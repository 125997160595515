import { DATA_TYPE, ITable } from 'jsstore';

export const userPrefTable: ITable = {
  name: 'UserPreferences',
  version: 11,
  columns: {
    uid:{
    primaryKey: true,
    autoIncrement: true,
   },
   userId:{
     dataType: DATA_TYPE.String,
   },
   originalSensorLabel:{
    notNull: true,
    dataType: DATA_TYPE.String,
   },
   renamedSensorLabel:{
    dataType: DATA_TYPE.String,
   },
   sensorWellName:{
    dataType: DATA_TYPE.String,
    notNull: true,
   }
  }
};

export const globalFilterTable: ITable = {
  name: 'GlobalFilter',
  columns: {
    uid:{
    primaryKey: true,
    autoIncrement: true,
   },
   userId:{
     dataType: DATA_TYPE.String,
   },
   filters:{
    dataType: DATA_TYPE.Array,
   }
  }
};

export const unitConversionTable: ITable = {
  name: 'UnitConversions',
  columns: {
    uid:{
    primaryKey: true,
    autoIncrement: true,
   },
   userId:{
     dataType: DATA_TYPE.String,
   },
   temperatureUnit: {
     dataType: DATA_TYPE.String,
   },
   pressureUnit:{
    dataType: DATA_TYPE.String,
   },
   pitchangerateUnit:{
    dataType: DATA_TYPE.String,
   },
   gasrateUnit:{
    dataType: DATA_TYPE.String,
   },
   liquidrateUnit:{
    dataType: DATA_TYPE.String,
   },
   depthUnit:{
    dataType: DATA_TYPE.String,
   },
   timeUnit:{
    dataType: DATA_TYPE.String,
   },
   timeLabel:{
    dataType: DATA_TYPE.String,
   }
  }
};

export const chartSettingsTable: ITable = {
  name: 'ChartSettings',
  columns: {
    id:{
    primaryKey: true,
    autoIncrement: true,
  },
  userId:{
    dataType: DATA_TYPE.String,
  },
  chartSettings:{
    dataType: DATA_TYPE.Array,
  }
}
};
