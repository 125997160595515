
/**
 * @method validateObjectChain
 * @description : This method validates the object is defined or not in an Object chain.
 * It only check array's first index for responses of AEM content
 * @param: {Object} data
 * @param: {String} propertyName: properties in that object to be checked
 * @return: {Boolean} returns the whether the object is exist or not
 */
// export function validateObjectChain<T>(obj: T, ...keys: string[]): boolean {
//   let result = keys.reduce((a, b) => {
//     if (Array.isArray((a || {})[b]) && (a || {})[b].length > 0) {
//       return (a || {})[b][0];
//     }

//     result = (a || {})[b];
//     return result;
//   }, obj);
//   return result !== undefined && result !== null;
// }

/**
 * @method isValidObject
 * @description This function validates an Object
 * @param item: Object to checked
 * @return {Boolean}: object valid or not
 */
export function isValidObject<T>(item: T): boolean {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * @method sortByKeyFunc
 * @description This function sort the array of an Object based on the key
 * @param sourceArr:{T} Array to sort
 * @param key: {String} Key to sort
 * @param rev: {boolean} True to reverse the sort
 * @return {T}: Sorted Array valid or not
 */
export function sortByKeyFunc<T, K extends keyof T>(sourceArr: T[], key: K, rev: boolean = false, keySecondary?: string): T[] {
  let sortedArr = [];
  if (keySecondary) {
    sortedArr = sourceArr
      .sort((a, b) => {
        return ((a[key][keySecondary] < b[key][keySecondary]) ? -1 : ((a[key][keySecondary] > b[key][keySecondary]) ? 1 : 0)) * (rev ? -1 : 1);
      });

  } else {

    sortedArr = sourceArr
      .sort((a, b) => {
        return ((a[key] < b[key]) ? -1 : ((a[key] > b[key]) ? 1 : 0)) * (rev ? -1 : 1);
      });

  }

  return sortedArr;
}

/**
 * @method getUserNameFromEmail
 * @description This function returns the first name as display name from the logged in user email address
 * @param email: Logged in user email
 * @return {string}: Display name for the logged in user \\ First name is considered.
 */
export function getDisplayNameFromEmail<T>(email: string): string {
  let displayName = "";
  if(email){
    const atTheRateIndex = email.indexOf('@');
    let userName = atTheRateIndex != -1 ? email.substr(0, atTheRateIndex): email;
    const dotIndex = userName.indexOf('.');
    displayName = dotIndex === -1 ? userName : userName.substr(0, dotIndex);
  }
  return displayName.toLowerCase();
}

export function clearLocalStorage() {
  const tempList = localStorage.getItem('allsensors');
  localStorage.clear();
  localStorage.setItem('allsensors', tempList);
}

export function downloadBlob(blob: Blob, fileName: string) {
  var link = document.createElement('a');
  link.type = 'text/plain';
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

export function getFileNameFromUrl(fileUrl: string, defaultName: string) : string {
  try {
    var fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

    return fileName;
  }
  catch {
    return defaultName;
  }
}