
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit {
  title = 'Clariti&trade;';
  ngOnInit() {
    // code to set flag for browser refresh
    window.onbeforeunload = (event) => {
      localStorage.setItem('refreshFlag', '1');
    };
  }
}
