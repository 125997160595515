/**
 * Combine all reducers into a single reducer
 */

import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, combineReducers, StoreModule } from '@ngrx/store';
import { IAppState } from '../../app.state';
import { ChartReducer } from './chart.reducer';
import { AuthorizationReducer } from './authorizationReducer';
import { ConversionReducer } from './conversionReducers';
import { UserReducer } from './userReducer';

/**
 * Object of all the reducers
 */
export const reducers = {
    AuthorizationReducer,
    ConversionReducer,
    UserReducer,
    ChartReducer
};

export const reducerToken: InjectionToken<ActionReducerMap<IAppState>> =
  new InjectionToken<ActionReducerMap<IAppState>>('Registered Reducers');

export const reducerProvider = { provide: reducerToken, useFactory: getRootReducer };

export function getRootReducer() {
  return reducers;
}

@NgModule({
  imports: [
    StoreModule.forFeature('conversion', ConversionReducer),
    StoreModule.forFeature('user', UserReducer),
    StoreModule.forFeature('chart', ChartReducer)
  ],
  providers: [
    reducerProvider,
  ],
})
export class ReducerModule {  }
