<p-menubar>
  <div class='app-header ui-g'>
    <div [ngClass]="loggedIn ? 'padding-left-20' : ''" class='ui-g-5 ui-md-7 ui-lg-7 app-header-left'>
      <div  *ngIf="!loggedIn" class='image-container ui-g-8'>
        <img class='header-logo' src="../../../assets/master-page/white.png">
        <span class="vertical-rule"></span>
        <div class="name-content">
          <span>Clariti&trade;</span>
        </div>
      </div>
      <div *ngIf="loggedIn" class='image-container width-padding ui-g-4'>
        <img class='header-logo width-height' src="../../../assets/master-page/white.png">
        <span class="vertical-rule hidden"></span>
        <div class="name-content hidden">
          <span>Clariti&trade;</span>
        </div>
      </div>
      <div *ngIf="loggedIn && selectedTab !== viewOptions.export" class='filter-button ui-g-4'  (keyup.enter)="showDialog()">
        <span tabindex="1" class="border-radius-8">
          <button DefaultFocus class="btn-secondary edit-rule-btn" (click)="showDialog()">Select wells/Sensors</button>
          </span>
      </div>
    </div>

    <div *ngIf="loggedIn" class='ui-g-7 ui-md-5 ui-lg-5 app-header-right '>
      <slot></slot>
      <div class="ui-g-2"></div>
      <div class='ui-g-10 float-right user-actions '>
        <div class='tab-menu ' [ngClass]="{'activeMen': !selectedMenu, 'ui-g-12 tab-menu-lss' : isLocal, 'ui-g-9':!isLocal}" (keyup.enter)="showControlsClick($event)">
          <span class='tab-button tab-name' tabindex="2" (click)='showControlsClick($event) '> {{displayName}} </span>
          <span class='tab-icon' (click)='showControlsClick($event)'>
            <i class='fa' [ngClass]='showControls ? "fa-caret-up" : "fa-caret-down"'></i>
          </span>
        </div>
        <div *ngIf="!isLocal" class='ui-g-3 tab-notifications ' [ngClass]="highlightAlert ? 'highlightAlertActive' : ''" (click)="toggleAlertPanel()" (keyup.enter)="toggleAlertPanel()">
          <span class='fa fa-bell-o' tabindex="5">
            <i [ngClass]="notificationStatus ? 'tab-notifications-active' : ''" >{{notificationLength}}</i>
          </span>
        </div>

        <div *ngIf='showControls ' class='ui-g-6 ui-md-3 ui-lg-3 configurations '>
          <div class='settings ' (click)='openSettings()' tabindex="3" (keyup.enter)="openSettings()">Settings</div>
          <div class='help ' (click)='openHelpDocument()' tabindex="4">Help</div>
          <div class='log-out ' (click)='logoutUser()' tabindex="5" (keyup.enter)="logoutUser()">Sign out</div>
        </div>
      </div>
    </div>
  </div>
</p-menubar>
<div *ngIf='showLoading' class="ui-g page-background">
  <app-loading-spinner [SigningOut]='true'></app-loading-spinner>
</div>

