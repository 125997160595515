import { Component, OnInit, OnDestroy, Input, Inject, EventEmitter, Output } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { IAlert } from '../../models/alert';
import { WellService } from '../../services/well.service';
import { select, Store } from '@ngrx/store'; 
import { Observable, Subscription, combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ViewOptions } from '../../shared/constants/master-page.constants';
import * as fromAlert from '../../state/container-states/alert.state';
import { SignalRService } from 'src/app/services/signal-r.service';
import { State } from 'src/app/state/container-states/app.state';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-azure-map',
  templateUrl: './azure-map.component.html',
  styleUrls: ['./azure-map.component.scss']
})
export class AzureMapComponent implements OnInit, OnDestroy {
  viewOptions = ViewOptions;
  currentAlerts:any;
  globalWells: any;
  selectedWell: any;
  isApiInProgress = true;
  currentPopup = null;
  showDialog = false;
  textColor = '#000';
  azureMapsKey:string;
  public alerts: IAlert[] = [];
  @Input() menu: any;
  @Input() globalFilter: any;
  @Output() activeAlerts = new EventEmitter<{ alertTriggered: boolean, alertLength: number, alertList: any }>();
  map!: atlas.Map;
  unsubscribe$ = new Subject<void>();
  markersMap = new Map<string, atlas.HtmlMarker>();
  contrastColors: { [key: string]: string } = {
    road: '#000',
    grayscale_dark: '#FFF',
    night: '#FFF',
    road_shaded_relief: '#000',
    satellite: '#FFF',
    satellite_road_labels: '#FFF'
  };
  

  constructor(
    @Inject('ENV_CONFIG') private envConfig: any,
    private formStore: Store<fromAlert.AlertPageState>,
    private wellService: WellService,
    private store: Store<State>,
    private signalRService: SignalRService,
    private userService : UserService
  ) {}

  ngOnInit(): void {
    this.signalRService.stopConnection();
    localStorage.setItem('refreshFlag', '0');
    localStorage.setItem('component', 'map');
    this.globalWells = [];

    this.userService.getEnvs().subscribe((data: any) => {
      this.azureMapsKey = data;
   

    //to understand if the nicknames api is completed
    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {

        this.isApiInProgress = wellState.apiInprogress;
      })
    // Loading the map
    const mapReady$ = this.initializeMap();
    // Loading the data
    const dataReady$ = this.loadWellData();
    // Wait until both the map and data are ready
    const combined$ = combineLatest([mapReady$, dataReady$]);

    combined$.pipe(takeUntil(this.unsubscribe$)).subscribe(([_, wells]) => {
      //adjust to the container
      this.map.resize();
      this.addWellsToMap(wells);
    });

    this.formStore
      .pipe(select(fromAlert.getAlertRules), takeUntil(this.unsubscribe$))
      .subscribe((alerts: IAlert[]) => {
        if (alerts) {
          this.currentAlerts = alerts;
          this.addWellAlert(alerts);
          if(this.currentAlerts.length > 0 && this.globalWells.length>0){
            this.updateMarkerColors(this.currentAlerts);
        }
        }
      });

    }),
    ((err)=>{
      console.log(`error in Azure Maps: ${err}`);
    });
  }

   /**
   * @name: addCustomControl
   * @description: Creates a custom control class
   * @param: NA
   */
  addCustomControl() {
    // Create a custom control class for search
    class CustomControl implements atlas.Control {
      onAdd(map: atlas.Map, options?: any): HTMLElement {
        // Create a container div for the custom control
        const container = document.createElement('div');
        container.className = 'azure-maps-search-control';
        container.style.display = 'flex';  
        container.style.zIndex = '600 !important';
        container.style.flexFlow= 'nowrap';
        container.style.position= 'absolute';
        container.style.alignItems= 'center';
        container.style.justifyContent= 'flex-start';
        container.style.bottom= '1px';
        container.style.paddingTop= '4px';
        container.style.paddingBottom= '4px';
        container.style.paddingLeft = '4px';
        //container.style.backgroundBlendMode= 'normal, color, luminosity';

        // Create input element for search bar
        const searchInput = document.createElement('input');
        searchInput.type = 'text';
        searchInput.placeholder = 'Search for wells';
        searchInput.style.width = '200px';
        searchInput.style.padding = '8px';
        searchInput.style.border = '1px solid #ccc';
        searchInput.style.borderRadius = '4px';
        searchInput.style.marginRight = '5px';

        // Create search icon button
        const searchButton = document.createElement('button');
        searchButton.innerHTML = `<i class="fa fa-search"></i>`;
        searchButton.style.padding = '8px';
        searchButton.style.borderRadius = '4px';
        searchButton.style.border = '1px solid #ccc';
        searchButton.style.backgroundColor = '#fff';
        searchButton.style.cursor = 'pointer';

        // Add the search input and button to the container div
        container.appendChild(searchInput);
        container.appendChild(searchButton);

        // Add a click event listener to the search button
        searchButton.addEventListener('click', () => {
          alert('well search');
          const query = searchInput.value.trim();
          //TODO search the well and zoom in
        });

        // Return the container div to be used as a control on the map
        return container;
      }

      onRemove(): void {
        // Cleanup if needed when the control is removed
      }
    }

    // Add the custom control to the map
    const customControl = new CustomControl();
    this.map.controls.add(customControl, {
      position: atlas.ControlPosition.BottomLeft
    });
}


   /**
   * @name: initializeMap
   * @description: Configure and initialize Azure maps with controls
   * @param: NA
   */
  initializeMap(): Observable<void> {
    return new Observable<void>((observer) => {
      this.map = new atlas.Map('myMap', {
        center: [0, 0],
        zoom: 1,
        style: 'satellite_road_labels',
        view: 'auto',
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: this.azureMapsKey,
        },
        showLogo: false,
        showFeedbackLink: false
      });

      this.map.resize();
      
      this.map.events.add('ready', () => {
        this.map.controls.add(
          [
            new atlas.control.ZoomControl(),
            new atlas.control.StyleControl({
              mapStyles: ['road', 'grayscale_dark', 'night', 'road_shaded_relief', 'satellite', 'satellite_road_labels']
            }),
            new atlas.control.CompassControl(),
            new atlas.control.PitchControl(),
            
          ],
          {
            position: atlas.ControlPosition.TopRight
          }
        );

        //add the Search custom control
        //this.addCustomControl();

        window.addEventListener('resize', () => {
          if (this.map) {
              this.map.resize();  
          }
        });

        // Notify that the map is ready
        observer.next();
        observer.complete();
      });

      //when a style is changed
      this.map.events.add('styledata', (event) => this.onStyleChange(event));
    });
    
  }

  /**
   * @name: onStyleChange
   * @description: updates color of the loading spinner text based on the mapstyle background
   * @param: click event
   */
  onStyleChange(event: any) {
    if(event.type === 'styledata'){
      let style = this.map.getStyle().style;
      this.textColor = this.contrastColors[style] || this.textColor;
    }
  }

  /**
   * @name: loadWellData
   * @description: Calls well service to load the list of wells
   * @param: List of wells
   */
  loadWellData(): Observable<any> {
    return this.wellService.getWells().pipe(
      filter(result => !!result)
    );
  }

  /**
   * @name: zoomToFitMarkers
   * @description: Finds the right bounds considering all well lucations and zoom to fit
   * @param: NA
   */
  zoomToFitMarkers() {
    const positions = [];
    for (const [key, marker] of this.markersMap) {
      positions.push(marker.getOptions().position);
    }
  
    if (positions.length > 0) {
      const bounds = atlas.data.BoundingBox.fromPositions(positions);

      this.map.setCamera({
        bounds: bounds,
        padding: 100
      });
    }
  }

  /**
   * @name: addWellsToMap
   * @description: Adds the wells with html markers
   * @param: NA
   */
  addWellsToMap(wells: any[]): void {
    
    wells['response'].well.forEach((well: any) => {
      this.globalWells.push({
        nameWell: well.name,
        uidWell: well.uid,
        field: well.field,
        licTime: well.dTimLicense,
        operator: well.operator,
        latitude: well.wellLocation && well.wellLocation[0].latitude ? well.wellLocation[0].latitude.value : null,
        longitude: well.wellLocation && well.wellLocation[0].longitude ? well.wellLocation[0].longitude.value : null,
        latUom: well.wellLocation && well.wellLocation[0].latitude ? well.wellLocation[0].latitude.uom : null,
        longUom: well.wellLocation && well.wellLocation[0].longitude ? well.wellLocation[0].longitude.uom : null,
      });
      this.addWellMarker(well);
      
    });
    
  }

  /**
   * @name: addWellAlert
   * @description: Adds the Alerts to the wells
   * @param: NA
   */
  addWellAlert(alerts){
    _.map(alerts, (alert) => {
    const alertWell = this.globalWells.find(item => item['uidWell'] === alert.UidWell);
    if(alertWell){
      if (alert.AlarmDetail.NotificationStatus || alert.AlarmDetail.AlarmNotificationStatus) {
        alertWell.color = 'red';
        this.activeAlerts.emit({ alertTriggered: true, alertLength: alerts.length, alertList: alerts });
      } else { 
        alertWell.color = 'green';
      }
    } 
    });
 }
  /**
   * Toggles tab on redirecting to schematic or charts page
   * @param viewOptions
   */
  toggleTab(viewOptions) {
    this.menu.toggleTab(viewOptions);
  }

  /**
   * @name: addWellMarker
   * @description: Adds the html markers to each wells
   * @param: well
   */
  addWellMarker(well: any): void {

    let lat =
      well.wellLocation && well.wellLocation[0].latitude
        ? well.wellLocation[0].latitude.value
        : null; 
    let lng =
      well.wellLocation && well.wellLocation[0].longitude
        ? well.wellLocation[0].longitude.value
        : null;

    var marker = new atlas.HtmlMarker({
      position: [lng, lat],
      color: well.color ? well.color : 'black',
      text:
        well.name && well.name.length > 2
          ? well.name.substring(0, 2)
          : well.name.charAt(0),
        secondaryColor: well.color ? well.color : 'yellow',
    });
    
    // Create a popup to show the metadata of the well
    const popup = new atlas.Popup({
      content: this.createPopupContent(well),
      position: [lng, lat],
      pixelOffset: [0, -20],
      closeButton: true
    });

    this.applyPopupStyle(popup, this.map.getStyle().style);

    this.map.events.add('styledata', () => {
      this.applyPopupStyle(popup, this.map.getStyle().style);
    });

    // Add events
    this.map.events.add('click', marker, () => {

      if (this.currentPopup) {
        this.currentPopup.close();
      }
      popup.close();
      popup.open(this.map);
      this.currentPopup = popup;
        document.getElementById('schematicsBtn').addEventListener('click', () => {
          if (!this.globalFilter.isvalidWellData(well)) {
            this.showDialog = true;
            return;
           }
           this.updateGlobalSelection(well);
           this.toggleTab(this.viewOptions.newSchematic);
           this.showPopup('schematics');
        });
        document.getElementById('chartsBtn').addEventListener('click', () => {
          if (!this.globalFilter.isvalidWellData(well)) {
            this.showDialog = true;
            return;
           }
           this.updateGlobalSelection(well);
           this.toggleTab(this.viewOptions.charts);
           this.showPopup('charts');
        });
    });

    this.map.events.add('move', function () {
      var position = popup.getOptions().position;
      if (position) {
        // Reopen the popup at the new position
        popup.setOptions({ position: position });
      }
    });

    this.map.events.add('zoom', function () {
      var position = popup.getOptions().position;
      if (position) {
        // Reopen the popup at the same position after zooming
        popup.setOptions({ position: position });
      }
    });

    // Add the marker to the map
    this.map.markers.add(marker);
    this.markersMap.set(well.uid.toString(), marker);

    // Create a popup (tooltip)
    var tooltip = new atlas.Popup({
      pixelOffset: [0, -30], 
      closeButton: false, 
      autoClose: true 
    });

    this.map.events.add('mouseover', marker, () => {
      // Set the content and position for the tooltip
      tooltip.setOptions({
        content: this.createTooltipContent({
          title: well.name,
          backgroundColor: 'red',
          fontColor: 'black'
        }),
        fillColor: 'red',
        
        position: marker.getOptions().position 
      });

      tooltip.open(this.map);
    });

    this.map.events.add('mouseout', marker, () => {
      tooltip.close(); 
    });
    this.zoomToFitMarkers();
  }

    /**
   * Updates the global selection of well
   * @param selectedWell
   */
    updateGlobalSelection(selectedWell) {
      this.globalFilter.onWellSelectionFromMap(selectedWell);
    }
  /**
   * @name: updateMarkerColors
   * @description: Update marker colors based on the alert Notifications.
   * @param: Alerts List
   */
  updateMarkerColors(currentAlerts) {
    
    currentAlerts.forEach((alert) => {
        const retrievedMarker = this.markersMap.get(alert.UidWell);
        if (retrievedMarker instanceof atlas.HtmlMarker) {
           retrievedMarker.setOptions({
              position: retrievedMarker.getOptions().position,
              color: (alert.AlarmDetail.NotificationStatus || alert.AlarmDetail.AlarmNotificationStatus) ? 'red' : 'green',
              text: retrievedMarker.getOptions().text,
              secondaryColor: retrievedMarker.getOptions().secondaryColor,
          });
        }
     });
}


  showPopup(tab) {
    if (tab == 'charts') {
      this.toggleTab(this.viewOptions.charts);
    }
    if (tab == 'schematics') {
      this.toggleTab(this.viewOptions.newSchematic);
    }
  }

  /**
   * @name: createTooltipContent
   * @description: Wel Name tool tip on hover over html marker.
   * @param: Well info
   */
  createTooltipContent(info) {
    return `<div style="color: #00ff00;padding:5px">
        <strong>${info.title}</strong><br>
    </div>`;
  }

  closeErrorDialog() {
    this.showDialog = false;
  }

  /**
   * @name: createPopupContent
   * @description: Create popup content.
   * @param: Well info
   */
  createPopupContent(wellInfo: any) {
    return `<div style="display: block;
    position: sticky;
    max-width: 480px;
    min-width:200px;
    background: rgba(38, 38, 38, .95);
    color: #edffff;
    border: 1px solid #444;
    border-right: none;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    box-shadow: 0 0 10px 1px #000;transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
    transition: opacity .2s ease-out, transform .2s ease-out;">
      
        <div>
          <h3 style="display: block;
          height: 20px;
          font-size:15px;
          padding: 5px 30px 5px 15px;
          background: rgba(84, 84, 84, 1);
          border-top-left-radius: 7px;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          box-sizing: content-box;">${wellInfo.name}</h3>
        </div>
      
        <div style="margin-bottom: 12px;padding-left:15px;font-size:12px;font-weight:bold;margin-right: 12px;">
        <p style="margin: 0;">Well Name: ${wellInfo.name}</p>
          <p style="margin: 0;">Field: ${wellInfo.field}</p>
          <p style="margin: 0;">Latitude: ${
            wellInfo.wellLocation &&
            wellInfo.wellLocation[0].latitude
              ? wellInfo.wellLocation[0].latitude.value
              : null
          }</p>
          <p style="margin: 0;">Longitude: ${
            wellInfo.wellLocation &&
            wellInfo.wellLocation[0].longitude
              ? wellInfo.wellLocation[0].longitude.value
              : null
          }</p>
        </div>
        
        <div style="display: flex; justify-content: flex-start;padding-left:15px;padding-bottom:15px">
          <button id="schematicsBtn" style="color: #ffffff;font-family: UniversLTStd, sans-serif;font-size: 14px;font-weight: bold;font-stretch: normal;
              font-style: normal;letter-spacing: normal;line-height: normal;text-align: center;padding: 8px 10px;font-size: 14px;
              border-radius: 8px;background-color: #cc0000;cursor: pointer;border: 1px solid #cc0000;">Schematics</button>
          <button id="chartsBtn" style="color: #ffffff;font-family: UniversLTStd, sans-serif;font-size: 14px;font-weight: bold;font-stretch: normal;
              font-style: normal;letter-spacing: normal;line-height: normal;text-align: center;padding: 8px 10px;font-size: 14px;
              border-radius: 8px;background-color: #cc0000;cursor: pointer;border: 1px solid #cc0000;margin-left:10px">Charts</button>
        </div>
</div>`;
  }

  /**
   * @name: applyPopupStyle
   * @description: Apply the styles to the popup.
   * @param: popup and mapStyle
   */
  applyPopupStyle(popup, mapStyle) {
    let backgroundColor, textColor;

    // Determine the popup background and text color based on the map style
    if (mapStyle === 'grayscale_light' || mapStyle === 'road') {
      backgroundColor = '#ffffff'; 
      textColor = '#000000'; 
    } else if (
      mapStyle === 'night' ||
      mapStyle === 'grayscale_dark' ||
      mapStyle === 'satellite_road_labels'
    ) {
      backgroundColor = '#333333'; 
      textColor = '#ffffff'; 
    }

    
    popup.setOptions({
      content: popup.getOptions().content, 
      position: popup.getOptions().position, 
      pixelOffset: [0, -40], 
      closeButton: true, 
      customStyle: `
            background-color: ${backgroundColor};
            color: ${textColor};
            padding: 40px;
            border-radius: 8px;
        `
    });
  }

  /**
   * @name: ngOnDestroy
   * @description: Unsubscribe the connections.
   * @param: NA
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.map) {
      this.map.dispose();
    }
  }
}
