import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
/* NgRx */
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
import { FilterListComponent } from '../../components/filter-list/filter-list.component';
import { GlobalFilterEffects } from '../../state/effects/global-filter.effects';
import { reducer } from '../../state/reducers/global-filter.reducer';
import { GlobalFilterComponent } from './global-filter.component';


@NgModule({
  imports: [
    DialogModule,
    ListboxModule,
    CommonModule,
    StoreModule.forFeature('globalFilters', reducer),
    EffectsModule.forFeature(
      [ GlobalFilterEffects ]
    ),
    FormsModule,
  ],
  declarations: [
    GlobalFilterComponent,
    FilterListComponent,
  ],
  exports: [
    GlobalFilterComponent,
    FilterListComponent,
  ],
})
export class GlobalFilterModule { }
