import { IMeasurementClass, IUnit } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";
import { Kelvin } from "./Kelvin";

export class Fahrenheit implements IUnit<IMeasurementClass> {
  static readonly Instance: Fahrenheit = new Fahrenheit();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Kelvin.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(1.8,-459.67,0,1);
  }
}
