export enum VolumetricFlowRateUnit {
    CubicFeetPerSec = 0,
    BarrelsPerDay,
    BarrelsPerMin,
    BarrelsPerSec,
    CubicMetersPerDay,
    GallonsPerDay,
    GallonsPerMin,
    GallonsPerSec,
    MillionCubicFeetPerDay,
    MillionCubicMetresPerDay,
    ThousandCubicFeetPerDay
}