import { UserActionTypes } from '../actions/userActions';

export function ClearStateReducer(reducer) {
  return (state, action) => {
    if (action.type === UserActionTypes.Logout) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
