export const NoDataChartOption = {
  title: {
      show: true,
      textStyle: {
        color: 'black',
        fontSize: 20
      },
      text: 'No Sensor Data',
      left: 'center',
      top: 'center'
    },
  xAxis: {
      show: false
  },
  yAxis: {
      show: false
  },
  grid: {
    top: '100%',
    left: '100%'
  },
  series: []
};
