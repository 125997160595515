import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { BaseService } from '../shared/services/indexdb/base.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChartsSettingsService {
  userObjectFromLocal: any;

  constructor(private http: HttpClient, public dbService: BaseService) {}

  public getAxisType(): Observable<any> {
    return this.http.get('../../assets/data/sensorslist.json');
  }

  public insertDBChartsTable(value: any): void {
    const localUser = localStorage.getItem('currentUser');
    if (!localUser) {
      return;
    }

    const user = JSON.parse(localUser);
    if (!user) {
      return;
    }

    try {
      this.getChartsSelectSettings(user.email).subscribe((result) => {
        if (result && result.length > 0) {
          this.dbService.connection.update({
              in: 'ChartSettings',
              set: {
                value
              },
              where: {
                userId: user.email
              }
            })
            .then((result) => {})
            .catch((err) => {
              console.log(err);
            });
        } else {
          (async () => {
            try {
              const result = this.dbService.connection
              .insert({
                into: 'ChartSettings',
                return: true,
                values: [
                  {
                    userId: user.email,
                    value
                  }
                ]
                })
                .then((result) => result || []);
              return from(result);
            } catch (error) {
              console.log("err" + error)
            }
          })()
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  public getChartsSelectSettings(email): Observable<any> {
    return from(
      this.dbService.connection
            .select({
              from: 'ChartSettings',
              where: {
                userId: email
              }
            })
            .then((result) => result || [])
           ).pipe(
            catchError(() => of([]))
           );
  }

}
