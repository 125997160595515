import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserGlobal } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private userDetails: UserGlobal) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // Session exceeds 10 min threshold or login for the first time
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
