<div class="calendar-input-container"
  [class.export-form]='exportForm'
  [class.historical-view]='historicalView'>
  <div class="display-flex">
      <span *ngIf='exportForm || historicalView' class='dropdown-label'>
          Start Date
      </span>
      
        <span class="start-container">
          <input matInput readonly
            placeholder="DD-MMM-YY" class="calendar-input"
            [(ngModel)]="startDate"
            [matDatepicker]="startPicker"
            (click)="startPicker.open()"
            (ngModelChange)="dateChecker()"
          >
          <i [ngClass]="!startPicker.opened ? 'pi pi-sort-down' : 'pi pi-sort-down upsidedown'" (click)="startPicker.open()"></i>
          <mat-datepicker
            #startPicker
            color="primary"
            [disabled]="disabled && !historicalView && !exportForm"
          >
          </mat-datepicker>
        </span>
  </div>
  

    <div *ngIf='exportForm || historicalView' class='right-arrow'>&#10230;</div>


  <div class="display-flex">
      <span *ngIf='exportForm || historicalView' class='dropdown-label'>
          End Date
      </span>
        <span class="end-container">
            <input matInput readonly
              placeholder="DD-MMM-YY" class="calendar-input"
              [matDatepicker]="endPicker"
              (click)="endPicker.open()"
              [ngModel]="endDate"
              (dateChange)="onEndDateChange($event)"
            >
            <i [ngClass]="!endPicker.opened ? 'pi pi-sort-down' : 'pi pi-sort-down upsidedown'" (click)="endPicker.open()" ></i>
            <mat-datepicker
              #endPicker
              color="primary"
              [disabled]="disabled && !historicalView && !exportForm"
            >
            </mat-datepicker>
          </span>
  </div>
</div>
