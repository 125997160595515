import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GraphUtilService } from 'src/app/services/graph-util.service';
import { ChartStoreService } from '../../services/chart.service';
import { PressureAndTemperatureService } from '../../services/pressure-temperature.service';
import * as ChartActions from '../actions/chart.actions';
import { IChartstate } from '../container-states/chart.state';

@Injectable()
//This is not used
export class ChartEffects {
    constructor(private actions$: Actions, private presTempService: PressureAndTemperatureService,
                private graphUtilService: GraphUtilService,
                private chartStore: Store<IChartstate>,
                private chartStoreService: ChartStoreService){}

    }
