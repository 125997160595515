import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import * as moment from 'moment-timezone';
import { ConnectionService } from 'ng-connection-service';
import { MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Conversion } from 'src/app/models/converterData';
import { IMeasurementData } from 'src/app/models/measurement';
import { Sensor, Well } from 'src/app/state/reducers/global-filter.reducer';
import configClariti from 'src/assets/data/config.json';
import { DateRange } from '../../models/DateRange';
import { IDataSensor, ILineChartData, IPositions } from '../../models/lineGraphData';
import { AlertService } from '../../services/alert.service';
import { ChartStoreService } from '../../services/chart.service';
import { GraphUtilService } from '../../services/graph-util.service';
import { PressureAndTemperatureService } from '../../services/pressure-temperature.service';
import { SignalRService } from '../../services/signal-r.service';
import { ERR_MESSAGES } from '../../shared/constants/error-messages.constants';
import { MeasurementConstants, ViewOptions } from '../../shared/constants/master-page.constants';
import { ConversionService } from '../../shared/services/conversion.service';
import { convertToNewTimeZone } from '../../shared/utilities/timeZoneConverter';
import { State } from '../../state/container-states/app.state';
import sensorInfo from './../../../assets/data/sensorTypeConfig.json';

@Component({
  selector: 'app-live-readings',
  templateUrl: './live-readings.component.html',
  styleUrls: ['./live-readings.component.scss']
})
export class LiveReadingsComponent implements OnInit, OnDestroy {
  @Input() schematicSelectedWell: any;
  @Input() isSchematic = false;
  @Input() isAlert = false;
  @Input() showColor = 'hidden';
  @Input() selectedTile: any;
  @Input() selectedSensor:any;
  @Input() mappedColorCharts: { [key: string]: string } = {};
  tempWellList: any[] = [];
  public tileList = {
    schematics: 'schematics',
    charts: 'charts',
    alerts: 'alerts'
  }
  selectedLiveReadings: any[] = [];
  selectedLiveReadingsSchematics: any[] = [];
  selectedLiveReadingsAlerts: any[] = [];
  public temperaturePressureResponse;

  pres: any[] = [];
  presAndTemp: any[] = [];
  signalRData: any[] = [];
  public measurementSensor: IDataSensor[] = [];
  public batterySensor: IDataSensor[] = [];
  public configDataForService: ILineChartData = {
    timeSelect: 7,
    sensorArray: []
  };
  public converterData: Conversion;
  public localData: any = {
    pressureUnit: '',
    temperatureUnit: '',
    pitchangerateUnit:'',
    depthUnit:'',
  };
  configClariti = configClariti;
  livePool$: Subscription;
  liveInterval: any;
  isPresent = false;
  sensorArray: any[];
  sensorInfo: any[];
  unsubscribe$ = new Subject();
  public wellList: Well[];
  selectedSensorName = '';
  wellNameFromMap = false;
  viewOptions = ViewOptions;
  noWellSelected: boolean;
  conversionsSubscription:any;
  filtersSubscription:any;
  noSensorSelected:boolean = true;
  isapiInprogress = true; //to wait for the nickname calls along with wells and sensors to complete

  public dataInput: IMeasurementData = {
    measurementData: MeasurementConstants.MEASUREMENT_DATA,
    measurementLabel: MeasurementConstants.MEASUREMENT_LABEL,
    pressureSensorLabel: MeasurementConstants.PRESSURE_SENSOR_LABEL,
    pressureUnit: MeasurementConstants.PRESSURE_UNIT,
    temperatureSensorLabel: MeasurementConstants.TEMPERATURE_SENSOR_LABEL,
    temperatureUnit: MeasurementConstants.TEMPERATURE_UNIT,
    pitchangerateUnit: MeasurementConstants.PITCHANGERATE_UNIT
  };
  constructor(
    private chartStoreService: ChartStoreService,
    private tempPressureService: PressureAndTemperatureService,
    private conversionService: ConversionService,
    private store: Store<State>,
    private signalRService: SignalRService,
    private graphUtilService: GraphUtilService,
    private alertService: AlertService,
    private connectionService: ConnectionService,
    private messageService: MessageService
  ) {
    // code added to catch online and offline event
    this.connectionService.monitor().subscribe(async (isConnected) => {
      if (isConnected) {
        // when reconnected
        await this.signalRService.startConnection();
        this.signalRService.stopConnection();
        this.signalRService.subscribeSignalR(this.measurementSensor, false);
        this.chartStoreService.setIsAlive(true);
        this.messageService.clear('ToastMessageKeyNetwork');
        // this.loadUserFilters();
      } else {
        // when application goes offline
        this.chartStoreService.setIsAlive(false);
        const error = new Error();
        error.message = ERR_MESSAGES.NO_CONNECTIVITY;
        this.messageService.clear('ToastMessageKeyNetwork');
      }
    });
    this.sensorInfo = sensorInfo.sensorData.filter((item:any) => {
      return item.settings === true || item.sensorType === MeasurementConstants.BATTERY_LABEL;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateSchematicLiveReading(this.schematicSelectedWell);
  }

  async ngOnInit() {
    this.chartStoreService.currentAxisList
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((payload: any) => {
        this.sensorArray = [];
        if (payload.axisList !== undefined && payload.axisList.length > 0) {
          payload.axisList.forEach((axis) => {
            if (axis.checked) {
              axis.sensorList.forEach((sensor) => {
                this.sensorArray.push(sensor);
              });
            }
          });
          this.getSelectedLiveReadings();
        }
      });

    this.alertService.setBatteryAlerts(true);
    await this.signalRService.startConnection();
    
    if (this.configClariti.livereadings.Method !== 'local') {
      if (this.signalRService.getRtProxy() === null) {
        return;
      }
      this.signalRService.getRtProxy().on('publish', (liveData, topic) => {
        this.processSignalrData(liveData, true);
      });
    }

    this.conversionsSubscription = this.store
      .select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState) => {
        if (conversionState) {
          this.converterData = conversionState.conversion;

          //code change for config file implementation
          for (let i = 0; i < this.selectedLiveReadingsSchematics.length; i++) {
            this.sensorInfo.forEach((sensorList) => {
              let sensorType = sensorList.sensorType;
              if (this.selectedLiveReadingsSchematics[i].sensorType.toLowerCase() === sensorType &&
                  this.selectedLiveReadingsSchematics[i][sensorType] !== 'Waiting...' &&
                  this.selectedLiveReadingsSchematics[i][sensorType+'Unit'] !== this.converterData[sensorType + 'Unit']
              ) {

                let convertingType = sensorType && sensorType.charAt(0).toUpperCase() + sensorType.slice(1);
                this.selectedLiveReadingsSchematics[i][sensorType] = this.conversionService['convertCurrent' + convertingType](
                  this.selectedLiveReadingsSchematics[i][sensorType+'Unit'],
                  this.selectedLiveReadingsSchematics[i][sensorType],
                  this.converterData[sensorType + 'Unit']
                )
                this.selectedLiveReadingsSchematics[i][sensorType+'Unit'] = this.converterData[sensorType + 'Unit'];
                this.dataInput[sensorType + 'Unit'] = this.converterData[sensorType + 'Unit'];
                this.localData[sensorType + 'Unit'] = this.converterData[sensorType + 'Unit'];

              }
            });
          }
        }
      });

    this.filtersSubscription = this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        this.noWellSelected = true;
        this.configDataForService.sensorArray = [];
        this.measurementSensor = [];
        this.dataInput.measurementData = [];
        //if there is a change in the global selections, reset the connection with new selections
        this.signalRService.stopConnection();

        this.isapiInprogress = wellState.apiInprogress;
        if (wellState && wellState.wells && wellState.wells.length > 0 && !this.isapiInprogress) {
          for (const item of wellState.wells) {
            if (item.checked) {
              this.noWellSelected = false;
            }
            if (item.sensors && item.sensors.length > 0 && item.checked) {
              //code to check if atleast a sensor is selected for any well
              for (const sensor of item.sensors) {
                if (sensor.checked) {
                  this.noSensorSelected = false;
                  break;
                }
              }
              this.createSensor(item.sensors, item.uidWell, item.uid, item.batSensors);
            }
            if (item.checked === true && this.measurementSensor.length === 0) {
              this.selectedSensorName = item.wellName;
              this.wellNameFromMap = true;
            }
          }
          this.wellList = wellState.wells;
          this.tempWellList = wellState.wells;
          //Moved the code to Masterpage to initiate the alarm notification immediately on login
          // if (this.configClariti.livereadings.Method !== 'local') {
          //   if (wellState && wellState.wells && wellState.wells.length > 0) {
          //     this.alertService.stopAlertTimer();
          //     this.alertService.startAlertTimer(wellState.wells);
          //   } else {
          //     this.alertService.stopAlertTimer();
          //   }
          // }

          this.signalRService.subscribeSignalR(this.measurementSensor, false);
          this.signalRData = [];
          for (let i = 0; i < this.measurementSensor.length; i++) {
            const sensor = this.measurementSensor[i];
            const liveReadingObj = {
              logid: sensor.uid,
              uid: sensor.uid,
              wellName: sensor.wellName,
              combinedName: sensor.wellName + '-' + sensor.sensorName,
              mnemonic: sensor.sensorName,
              mnemonic_alias: sensor.sensorOriginalName,
              nickName: sensor.nickName,
              md: sensor.md,
              depthUnit:sensor.depthUnit,
              unit_type: sensor.unitType,
              multiLogId: sensor.multiLogId,
              combinedMnemonics: sensor.combinedMnemonics,
              batterySensorName: sensor.batterySensorName,
              renamedLabel: sensor.displayName,
              uidWellBore: sensor.uidWellBore
            };
            const liveReadingObjLocal = {
              logid: sensor.uid,
              uid: sensor.uid,
              wellName: sensor.wellName,
              combinedName: sensor.wellName + '-' + sensor.sensorName,
              sensorName: sensor.sensorName,
              nickName: sensor.nickName,
              sensorOriginalName: sensor.displayName,
              sensorLabel: sensor.displayName,
              typeService: sensor.unitType,
              multiLogId: sensor.multiLogId,
              renamedLabel: sensor.displayName
            };
            this.processSignalrData(liveReadingObj, false, sensor);
          }
          this.loadVoltage(this.tempWellList);
        }
      });
  }

  /**
   * @name: createSensor
   * @description: Method to form sensors
   * @param: sensorArray {Sensor[]}: Array holding the sensor data
   * @param: uidWellSensor {string} well uid
   * @param: uidWellLog {string} log id for associated well
   */
  createSensor(
    sensorArray: Sensor[],
    uidWellSensor: string,
    uidWellLog: string,
    batSensors: Sensor[],
  ): void {
    let isSensorChecked = false;
    for (const sensorItem of sensorArray) {
      if (sensorItem.checked) {
        const dataSensor: IDataSensor = {
          uid: uidWellLog,
          uidWell: uidWellSensor,
          sensorName: sensorItem.sensorName,
          unitType: sensorItem.unitType.split(' ').join('').toLowerCase(),
          displayName: sensorItem.displayName,
          nickName: sensorItem.nickName,
          wellName: sensorItem.wellName,
          multiLogId: sensorItem.multiLogId,
          md:sensorItem.md,
          depthUnit:sensorItem.depthUnit,
          sensorOriginalName: sensorItem.sensorOriginalName,
          combinedMnemonics: sensorItem.wellName + '-' + sensorItem.sensorOriginalName,
          batterySensorName: sensorItem.batterySensorName,
          uidWellBore: sensorItem.uidWellBore
        };
        isSensorChecked = true;
        const isDataExist =
          this.configDataForService.sensorArray.filter((filteredData) => {
            return (
              filteredData.sensorName === dataSensor.sensorName &&
              filteredData.wellName === dataSensor.wellName
            );
          }).length > 0;
        if (!isDataExist) {
          this.configDataForService.sensorArray.push(dataSensor);
          this.measurementSensor.push(dataSensor);
        }
      }
    }

    for (const sensorItem of batSensors) {

        const dataSensor: IDataSensor = {
          uid: uidWellLog,
          uidWell: uidWellSensor,
          sensorName: sensorItem.sensorName,
          unitType: sensorItem.unitType,
          displayName: sensorItem.displayName,
          wellName: sensorItem.wellName,
          multiLogId: sensorItem.multiLogId,
          md:sensorItem.md,
          depthUnit:sensorItem.depthUnit,
          sensorOriginalName: sensorItem.sensorOriginalName,
          batterySensorName: sensorItem.batterySensorName,
          uidWellBore: sensorItem.uidWellBore
        }
        this.measurementSensor.push(dataSensor);
      }
    if (isSensorChecked) {
      if (this.configClariti.livereadings.Method !== 'local') {
        this.signalRService.subscribeSignalR(this.measurementSensor, false);
      }
      this.signalRData = _.filter(
        this.signalRData,
        (sen) =>
          _.findIndex(this.measurementSensor, (data) => {
            if(data['sensorName'] === sen['sensorName']){
            sen['renamedLabel'] = data['displayName']
            }
            return data['sensorName'] === sen['sensorName'];
          }) > -1
      );
      if (this.configClariti.livereadings.Method === 'local') {
        //this.getLocalLiveReadingsData();
      }
    }
  }

  // getLocalLiveReadingsData() {
  //   if (localStorage.getItem('currentUser') !== null) {
  //     if (this.measurementSensor.length <= 0) {
  //       return;
  //     }
  //     // List of all selected sensors
  //     const requestSensorList = this.measurementSensor;

  //     const requestList = []; // List to query at once with date range
  //     for (const item of requestSensorList) {
  //       const dateRange = this.getDateRangeForLocal(item);
  //       requestList.push({ dtRange: dateRange, sensor: item });
  //     }

  //     this.livePool$ = this.tempPressureService
  //       .joinDataForAllSensorsLive(
  //         requestList,
  //         null
  //         // tslint:disable-next-line:cyclomatic-complexity
  //       )
  //       .subscribe((response): void => {
  //         const logs = this.getLogs(response);
  //         if (logs && logs.length > 0) {
  //           const combinedResponse = this.chartStoreService.combineResponseData(
  //             logs
  //           );
  //           const finalDataSource = this.graphUtilService.buildSensorDataforCharts(
  //             combinedResponse,
  //             this.converterData
  //           );

  //           for (const item of finalDataSource) {
  //             item.latestData = this.getLastElement(item.logData.data);
  //             this.processLocalLiveData(item, true);
  //           }

  //           this.liveInterval = setTimeout(() => {
  //             this.getLocalLiveReadingsData();
  //           }, 2000);
  //         }
  //       });
  //   }
  // }

  getLogs(responseArray) {
    let responseData = [];
    responseData = _.map(responseArray, (response) => {
      if (response['response'] && response['response']['log']) {
        return response['response']['log'];
      }
    });
    return responseData;
  }

  getLastElement(data) {
    return _.last(data);
  }

  /*
   *Returns date range from last data point to current date
   */
  getDateRangeForLocal(item) {
    let dateRange: DateRange;

    const lastData = _.find(
      this.signalRData,
      (sens) =>
        sens['sensorName'] === item['sensorName'] &&
        sens['logid'] === item['uid']
    );

    if (lastData && lastData.time && lastData.fullTime) {
      dateRange = {
        start: convertToNewTimeZone(
          new Date(lastData.fullTime),
          this.converterData.timeUnit,
          moment.tz.guess()
        ),
        end: new Date()
      };
    } else {
      dateRange = {
        start: new Date(Date.now() - 300000), // Takes for last 5 min by default
        end: new Date()
      };
    }

    return dateRange;
  }
  processSignalrData(response, flag:boolean, sensor?) {
    this.isPresent = false;
    let tempObj = {};
    let multiId;
    if (sensor === undefined) {
      multiId = [];
    } else {
      multiId = sensor['multiLogId'];
    }
    //check if the signalRresponse is one of the listed unittypes
    let typeService = this.getTypeService(response);
    if(!typeService) return false;
    tempObj = {
      typeService: typeService,
      time: this.conversionService.convertTimeForLive(
        this.converterData.timeUnit,
        response['time_index']
      ),
      [typeService + 'Unit'] : this.converterData[typeService + 'Unit'],
      sensorName: response['mnemonic'],
      sensorLabel: response['mnemonic_alias'],
      sensorOriginalName: response['mnemonic_alias'],
      sensorType: typeService,
      uid: response['uid'],
      logid: response['logid'],
      combinedName: response['combinedName'],
      md:this.conversionService.convertDepth(this.converterData.depthUnit, response['md']),
      depthUnit: this.converterData.depthUnit,
      wellName: response['wellName'],
      multiLogId: multiId,
      renamedLabel: response['renamedLabel']
    };
    this.sensorInfo.forEach((sensorList) => {
      if (  sensorList.sensorType &&
        response['unit_type'].split(' ').join('').toLowerCase() ===
        sensorList.sensorType
      ) {
        this.dataInput[sensorList.sensorType + 'Unit'] = this.converterData[
          sensorList.sensorType + 'Unit'
        ];
        tempObj[sensorList.sensorType] =
          typeService === sensorList.sensorType
            ? response['mnemonic_value']
            : '';
        if (flag) {
          if (tempObj['typeService'] === sensorList.sensorType && tempObj['typeService'] != MeasurementConstants.BATTERY_LABEL) {
            tempObj[sensorList.sensorType] = this.conversionService[
              'convert' + sensorList.sensorType
            ](
              this.converterData[sensorList.sensorType + 'Unit'],
              tempObj[sensorList.sensorType]
            );
          }
        } else {
          const tempArr = _.filter(
            this.selectedLiveReadingsAlerts,
            (sensor) => {
              return sensor['combinedName'] === response['combinedName'];
            }
          );
          if (tempArr.length > 0) {
            tempObj = tempArr[0];
          } else {
            tempObj = {
              time: '',
              typeService: typeService,
              [sensorList.sensorType.toLowerCase()]: '',
              sensorName: response['mnemonic'],
              sensorOriginalName: response['mnemonic_alias'],
              sensorType: typeService,
              [typeService + 'Unit'] : this.converterData[typeService + 'Unit'],
              sensorLabel: response['mnemonic_alias'],
              uid: response['uid'],
              logid: response['logid'],
              nickName: response['nickName'],
              combinedName: response['combinedName'],
              md: this.conversionService.convertDepth(this.converterData.depthUnit, response['md']),
              depthUnit: this.converterData.depthUnit,
              wellName: response['wellName'],
              multiLogId: multiId,
              [sensorList.sensorType]: '',
              combinedMnemonics: response['combinedMnemonics'],
              batterySensorName: sensor.batterySensorName,
              renamedLabel: response['renamedLabel']

            };
            if (tempObj['typeService'] === sensorList.sensorType) {
              tempObj[sensorList.sensorType] = 'Waiting...';
            } else {
              tempObj[sensorList.sensorType] = '';
            }
          }
        }
      }
    });
    for (let i = 0; i < this.signalRData.length; i++) {
      if (
        ((this.signalRData[i]['sensorName'] === tempObj['sensorName'] ) ||
         (this.signalRData[i]['batterySensorName'] === tempObj['sensorName']) ) &&
          this.signalRData[i]['multiLogId'] === tempObj['logid']
      ) {
        this.isPresent = true;
        this.sensorInfo.forEach((sensorList) => {
          if (sensorList.sensorType === tempObj['sensorType']) {
            //do not include the live data if the timestamp is smaller than the latest
            if (tempObj[sensorList.sensorType] !== 'Waiting...' && this.signalRData[i].time <= tempObj['time']) {
              this.signalRData[i].time = tempObj['time'];
              let val = tempObj[sensorList.sensorType];
              tempObj['sensorType'] == MeasurementConstants.BATTERY_LABEL ? this.signalRData[i].battery = val:
              this.signalRData[i][sensorList.sensorType] = val ;
            }
          }
        });
      }
    }
    if (!this.isPresent) {
      this.signalRData.push(tempObj);
    }
    //if (this.signalRData.length >= this.measurementSensor.length) {
                this.buildLiveReadingsSignalR();
     //}
    }

  // processLocalLiveData(item, flag) {
  //   this.dataInput.pressureUnit = this.converterData.pressureUnit;
  //   this.dataInput.temperatureUnit = this.converterData.temperatureUnit;
  //   this.isPresent = false;
  //   let tempObj = {};
  //   if (flag) {
  //     tempObj = {
  //       typeService: item.typeService.toLowerCase(),
  //       time: moment(item.latestData[0]).format('DD-MMM-YY h:mm:ss a'),
  //       fullTime: item.latestData[0],
  //       sensorLabel: item.sensorName,
  //       pressure:
  //         item.typeService.toLowerCase() === 'pressure'
  //           ? item.latestData[1]
  //           : '',
  //       temperature:
  //         item.typeService.toLowerCase() === 'temperature'
  //           ? item.latestData[1]
  //           : '',
  //           sensorName: item.sensorName,
  //       sensorOriginalName: item.displayName,
  //       sensorType: item.typeService.toLowerCase(),
  //       combinedName: item.nameWell + '_' + item.sensorName,
  //       logid: item['logid']
  //     };
  //   } else {
  //     tempObj = {
  //       time: '',
  //       fullTime: '',
  //       typeService: item.typeService.toLowerCase(),
  //       pressure: '',
  //       temperature: '',
  //       sensorName: item.sensorName,
  //       sensorOriginalName: item.sensorOriginalName,
  //       sensorType: item.typeService.toLowerCase(),
  //       sensorLabel: item.sensorLabel,
  //       logid: item['logid'],
  //       combinedName: item.combinedName
  //     };
  //     if (tempObj['typeService'] === 'temperature') {
  //       tempObj['temperature'] = 'Waiting...';
  //       tempObj['pressure'] = '';
  //     } else {
  //       tempObj['pressure'] = 'Waiting...';
  //       tempObj['temperature'] = '';
  //     }
  //   }

  //   this.signalRData.some(function (element, index, array) {
  //     if (element['sensorName'] === tempObj['sensorName']) {
  //       this.isPresent = true;

  //       if (
  //         tempObj['pressure'] != 'Waiting...' ||
  //         tempObj['temperature'] !== 'Waiting...'
  //       ) {
  //         element.time = tempObj['time'];
  //         element.fullTime = tempObj['fullTime'];
  //         element.pressure = tempObj['pressure'];
  //         element.temperature = tempObj['temperature'];
  //         return true;
  //       }
  //     }
  //   }, this);

  //   if (!this.isPresent) {
  //     this.signalRData.push(tempObj);
  //   }
  //   this.buildLiveReadingsSignalR();
  // }

  buildLiveReadingsSignalR() {
    this.pres = [];
    this.sensorInfo.forEach((sensorList) => {
      if (sensorList.settings === true) {
        this.pres.push({
          sensorLists: [],
          sensorType: sensorList.sensorType,
          displayName: sensorList.axisName
        });
      }
    });
    this.presAndTemp = [];
    this.signalRData.map((sensor) => {
      this.pres.forEach((sensorList) => {
        let sensorTypeTemp =  sensor.sensorType && sensor.sensorType.includes(' ') ?  sensor.sensorType.split(' ').join('').toLowerCase() : sensor.sensorType;
        if ( sensorTypeTemp === sensorList.sensorType) {
          sensorList.sensorLists.push(sensor);
          this.presAndTemp.push(sensor);
        }
      });
    });
    this.selectedLiveReadingsAlerts = this.sortWells(this.presAndTemp);
    this.selectedLiveReadingsSchematics = this.getReadingSchematic(
      this.presAndTemp
    );
    this.getSelectedLiveReadings();
  }

  sortWells(unsorted) {
    return unsorted.sort((a, b) => {
      if (a.wellName === b.wellName) {
        return a.md - b.md; // Sort by Measured Depth in ascending order if Well Name is the same
      } else if (a.wellName && b.wellName) {
        return a.wellName.localeCompare(b.wellName, undefined, { numeric: true }); // Sort by Well Name in ascending order
      } else if (!a.wellName && b.wellName) {
        return -1;
      } else if (a.wellName && !b.wellName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  getReadingSchematic(readings) {
    if (this.schematicSelectedWell) {
      return this.sortWells(
        _.filter(readings, { logid: this.schematicSelectedWell.uid })
      );
    }
    return this.sortWells(readings);
  }
  updateSchematicLiveReading(schematicWell) {
    this.schematicSelectedWell = schematicWell;
    this.selectedLiveReadingsSchematics = this.getReadingSchematic(
      this.presAndTemp
    );
  }

  getTypeService(response) {
    let type: any[];
    let unitType = response['unit_type'];
    if (unitType && typeof unitType == "string") {
      type = this.sensorInfo.filter((sensorList) => {
        if(sensorList.sensorType){
          let responseUnitType = unitType.includes(' ') ? unitType.split(' ').join('').toLowerCase() : unitType.toLowerCase();
          return (responseUnitType === sensorList.sensorType);
        }
      });
    }else {
      return undefined;
    }
    return type[0] && type[0].sensorType;
  }

  /**
   * @name: convertData
   * @description: Method to convert data to different units
   * @param: temperaturePressureResponse response from service
   * @param: positions {IPositions}: Position for temp/ pressure
   */
  convertData(temperaturePressureResponse, positions: IPositions) {
    const unitLists = [
      this.converterData.temperatureUnit,
      this.converterData.pressureUnit,
      this.converterData.pitchangerateUnit
    ];
    temperaturePressureResponse.units = unitLists;
    const tempResponse = [];
    if (this.converterData) {
      for (const item of temperaturePressureResponse['data']) {
        item[positions.dateTime] = this.conversionService.convertTime(
          this.converterData.timeUnit,
          item[positions.dateTime]
        );
        this.sensorInfo.forEach((sensorList) => {
          if (
            sensorList.sensorType.toLowerCase() ===
            temperaturePressureResponse['typeService']
          ) {
            item[
              positions[sensorList.sensorType.toLowerCase()]
            ] = this.conversionService['convert' + sensorList.sensorType](
              this.converterData[sensorList.sensorType + 'Unit'],
              item[1]
            );
          }
          tempResponse.push(item);
        });

        temperaturePressureResponse['data'] = [...tempResponse];
      }
    }
    return temperaturePressureResponse;
  }
  //Function to filter live readings based on settings selection starts here
  getSelectedLiveReadings() {
    this.selectedLiveReadings = this.sortWells(this.presAndTemp);
    const checkedArray = [];
    if (this.sensorArray && this.selectedLiveReadings) {
      this.sensorArray.forEach((sensor) => {
        const combinedName =
          sensor.value.wellName + '-' + sensor.value.sensorName;
        const checkedSensors = _.filter(
          this.selectedLiveReadings,
          (checkedSensors: { combinedName: string }) => {
            return checkedSensors.combinedName === combinedName;
          }
        );
        if (checkedSensors.length > 0) {
          checkedArray.push(checkedSensors[0]);
        }
      });
    }
    if (checkedArray.length > 0) {
      this.selectedLiveReadings = this.sortWells(checkedArray);
    }
  }

  public loadVoltage(wellList): void {
    if (wellList.length > 0) {
      this.alertService.stopBatteryTimer();
      this.alertService.startBatteryTimer(wellList);
    }
  }
  // unsubscribe after the component destroy
  ngOnDestroy(){
    this.conversionsSubscription && this.conversionsSubscription.unsubscribe();
    this.filtersSubscription && this.filtersSubscription.unsubscribe();
  }
}
