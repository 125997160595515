import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { OktaAuthService } from '@okta/okta-angular';
import { Observable, Subject } from 'rxjs';
import { ExportProgressMessage } from '../models/exportProgressMessage';
import { ExportFilter } from '../models/query-params/exportFilter';
import { ExportJob } from '../models/export/exportJob';
import { getHttpParamsFromObject } from '../shared/utilities/httpParams.util';
import { ExportDownloadInfo } from '../models/export/exportDownloadInfo';
import { SignalRService } from './signal-r.service';

@Injectable()

export class ExportService {
    private signalRConnection: HubConnection;

    downloadStatus: EventEmitter<{ downloading: boolean }> = new EventEmitter();
    transmitStatus: EventEmitter<{[key: string]: string}[]> = new EventEmitter();
    clearStatusProgress: EventEmitter<any> = new EventEmitter();
    public reloadExports: EventEmitter<any> = new EventEmitter();
    public resetDates: Subject<boolean> = new Subject();
    public exportProgressUpdateEvent: Subject<ExportProgressMessage> = new Subject<ExportProgressMessage>();
    
    constructor(
        @Inject('ENV_CONFIG') private envConfig: any,
        private http: HttpClient,
        private authTokenService: SignalRService,
        private oktaAuth: OktaAuthService) {
            //this.initializeConnection();
        
    }

    private async initializeConnection(){
        const tokens = await this.authTokenService.getValidTokens().toPromise();
            this.signalRConnection = new HubConnectionBuilder()
                .withUrl(
                    this.envConfig.api.mmw_export_progress,
                    { 
                        accessTokenFactory: () => tokens.accessToken.accessToken,
                        withCredentials: false
                    })
                .withAutomaticReconnect()
                .build();
            this.signalRConnection.on("progressUpdate", (exportProgressMessage: ExportProgressMessage) => { 
                this.exportProgressUpdateEvent.next(exportProgressMessage);
            });
            this.signalRConnection.onclose(this.onClose);
            this.signalRConnection.onreconnected(async connectionId => await this.subscribeForUpdates());
    }

    prepareHeaders(): { [key: string]: any } {
        const { email } = JSON.parse(localStorage['currentUser']);
        return {
            headers: new HttpHeaders({
                'X-Email': email,
                'Content-Type': 'application/json'
            })
        };
    }

    public getStartEndTime(params: any) {
        let RtsComplexLogQueryList = [];
        params.logId.forEach((logId) => {
            RtsComplexLogQueryList.push({"Uid" : logId.multiLogId}); // passing multiple log id to get the date range
        });
        const body = {
            UidWell: params.uidWell,
            IndexType: 0,
            RtsComplexLogQueryList: RtsComplexLogQueryList
        };
        return this.http.post(this.envConfig.api.getMinMax, body);
    }

    public submitExportRequest(formPayload: any): Observable<ExportJob> {
        return this.http.post<ExportJob>(
          this.envConfig.api.mmw_exports,
          formPayload,
          this.prepareHeaders()
      );
    }

    public cancelExport(exportJobId: string): Observable<any> {
        var options = this.prepareHeaders();
        options.observe = 'response';

        return this.http.post(
            this.envConfig.api.mmw_exports + `/${exportJobId}/cancel`,
            { Cancel: true },
            options
        );
    }

    public getExportFile(exportJobId: string): Observable<any> {
        return this.http.get(
            this.envConfig.api.mmw_exports + `/${exportJobId}/download`,
            { responseType: 'blob' });
    }

    //NOTE: export download implementation for downloads with SAS tokens
    // public getExportDownloadInfo(exportJobId: string): Observable<ExportDownloadInfo> {
    //     return this.http.get<ExportDownloadInfo>(
    //         this.envConfig.api.mmw_exports + `/${exportJobId}/download`,
    //         this.prepareHeaders());
    // } 

    public getExports(filter: ExportFilter): Observable<ExportJob[]> {
        let options = this.prepareHeaders();
        options.params = getHttpParamsFromObject(filter);

        return this.http.get<ExportJob[]>(
            this.envConfig.api.mmw_exports,
            options)
    }

    public deleteExport(exportJobId: string) : Observable<any> {
        return this.http.delete(
            this.envConfig.api.mmw_exports + `/${exportJobId}`,
            this.prepareHeaders());
    }

    public async startExportUpdatesListening() {
	try {
          if(!this.signalRConnection){
               await this.initializeConnection();
          }
        if (this.signalRConnection && this.signalRConnection.state != HubConnectionState.Connected) {
            await this.signalRConnection.start()
                .then(async() => {
                    console.log("Export signalR connected")
                    await this.subscribeForUpdates();
            });
        }
        } catch (error) {
            console.error('Error starting SignalR connection:', error);
        }
    }
    public async stopExportUpdatesListening() {
        var user = JSON.parse(localStorage['currentUser']);
        if (this.signalRConnection && this.signalRConnection.state === HubConnectionState.Connected) {
            await this.signalRConnection.invoke("RemoveFromGroupAsync", user.email);
            console.log("Export signalR unsubscribed")

            this.signalRConnection.off("progressUpdate");
            await this.signalRConnection.stop();
            console.log("Export signalR disconneted")
          } 
    }

    private async subscribeForUpdates() {
        var user = JSON.parse(localStorage['currentUser']);
        await this.signalRConnection.send("AddToGroupAsync", user.email);

        console.log("Export signalR subscribed")
    }

    private onClose(error: Error) {
        console.log(error);
    }
}