import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserGlobal } from '../../../models/user';
import { BaseService } from '../indexdb/base.service';
import sensorInfo from './../../../../assets/data/sensorTypeConfig.json';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionDBService {

  sensorInfo: any[];
  constructor(
    private dbService: BaseService
  ) {
    this.sensorInfo = sensorInfo.sensorData.filter((item) => {
      return item.settings === true;
    });
  }

  getUnitConversion(user: UserGlobal): Observable<any> {
    const response =  from(this.dbService.connection.select({
      from: 'UnitConversions',
      where: {
        userId: user.email,
      },
    }));
    return response;
  }

  updateUnitConversion(payload): Observable<any> {
    let setValue = {};
    return this.getUnitConversion(payload['user']).pipe(map((res) => {
      if (res && res.length > 0) {
        this.sensorInfo.forEach(sensorList => {
          setValue[sensorList.sensorType + 'Unit'] = payload['unitConversion'][sensorList.sensorType + 'Unit'];
        });
        setValue['timeUnit'] = payload['unitConversion']['timeUnit'];
        setValue['timeLabel'] = payload['unitConversion']['timeLabel'];
        return from(this.dbService.connection.update({
          in: 'UnitConversions',
          set: setValue,
          where: {
            userId: payload['unitConversion']['userId']
          }
        }));
      } else {
        return from(this.dbService.connection.insert({
          into: 'UnitConversions',
          return: true,
          values: [payload['unitConversion']],
        }));
      }
    }));
  }
}
