<div class='backdrop'>
  <div class='col-12 modal-body'>
    <div class='header'>
        <div class='title'>Halliburton Terms and Conditions</div>
    </div>
    <div>
        The customer’s access to the cloud storage network shall be limited to 10 named users. The names of such users must be provided to Halliburton by the Customer and the Customer may replace a user by notifying Halliburton.  Customer may request access for additional users, but additional charges may apply.
    </div><br>
    <div>
        After 12 months, Halliburton shall be entitled to delete or remove from the Cloud any stored data. Should Customer become delinquent in the payment of any sum due Halliburton pursuant to this Agreement, Halliburton may immediately (a) suspend performance under this agreement; (b) delete the data collected; and (c) apply additional charges to restore the services if the account has been suspended.
    </div><br>
    <div>
        To the extent Halliburton does not disclose the Customer’s identity or information which would directly identify the Customer or Customer’s operations, Customer grants Halliburton the right to use the stored data for review, modification, calibration, and improvement of Halliburton’s services, products, materials, equipment, processes, and procedures.
    </div><br>
    <div>
        Halliburton shall have no responsibility or liability for any security vulnerabilities, or the consequences of such vulnerabilities, of information or data stored in the Cloud.  Further, Halliburton is not responsible for and does not guarantee the accuracy of data transmitted by electronic means.  Halliburton shall not be responsible or liable for accidental or intentional interception by others of information or data stored in the Cloud.  Company may request the removal of its information or data from the Cloud and/or delivery of its information or data from computers under Halliburton’s custody or control. Halliburton shall not be liable for the corruption, damage, loss or mis-transmission of information or data stored or accessed on the Cloud, or transmitted via cellular phone or text messaging or any other communications facilities. Notwithstanding the foregoing, in such circumstances, Halliburton shall use commercially reasonable efforts to assist Company in the recovery of its information or data from the Halliburton last back-up preceding the loss.
    </div><br>
    <div class='footer'>
      <!-- <button class='cancel' (click)='onCancel()'>Cancel</button> -->
      <button class='done' (click)='saveSelection()' value="Ok"> Ok </button>
    </div>
  </div>
</div>
