<div [ngClass]=" !isChartExport ? 'status-bar' : 'status-bar chartExportStyle'">
    <p class='status-text'>{{
        failed ? barText.failed
        : cancelled ? barText.cancelled
        : discard ? barText.discard
        : fileReady ? isChartExport ? barText.complete("Chart") : barText.complete(selectedWell)
        : initiated ? barText.initiated
        : barText.checking
        }}</p>
    <div class='button-group'>
        <button *ngIf='!fileReady && !cancelled && !failed && !initiated' class='empty button' (click)='handleCancel(isChartExport)'>
            Cancel Export
        </button>
        <button *ngIf='cancelled || failed || discard' class='empty button' (click)='close(isChartExport)'>
            OK
        </button>
        <button *ngIf='fileReady && !discard' class='button empty discard' (click)='handleCancel(isChartExport,true)'>
            Discard
        </button>
        <button *ngIf='fileReady && !discard' class='button filled' (click)='handleDownload(isChartExport)'>
            Download
        </button>
    </div>
</div>
<div *ngIf = '!isChartExport' class="exportOverlay">

</div>
