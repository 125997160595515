import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() termsNCondition: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  onCancel() {
    this.termsNCondition.emit(false);
    this.close.emit();
  }
  saveSelection() {
    this.termsNCondition.emit(true);
    this.close.emit();
  }
}
