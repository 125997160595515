/**
 * Label Edit Component code
 */

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-label-edit',
    templateUrl: './label-edit.component.html',
    styleUrls: [ './label-edit.component.scss' ],
})

export class LabelEditComponent implements OnInit {
    // Input value for label to be shown
    @Input() public editValue: string;
    // Output emitter on button click
    @Output() buttonEmitter = new EventEmitter();
    // Input element
    @ViewChild('inputForText', { static: false }) inputValue;

    // Data for cancel button
    public cancelButton: string;

    // Data for save button
    public saveButton: string;

    /**
     * Angular life cycle hook
     */
    public ngOnInit(): void {
        this.cancelButton = 'Cancel';
        this.saveButton = 'Save';
    }

    /**
     * @name: sendDataToMeasurement
     * @description: Method to send data to component.
     * Data is taken from the input field
     */
    public sendDataToMeasurement(flag: boolean): void {
        if (flag) {
          this.buttonEmitter.emit(this.inputValue.nativeElement.value);
        } else {
            this.buttonEmitter.emit(false);
        }
        
    }
}
