/**
 * Service method to get pressure and temperature
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class DatabaseConfigService {
  public path = '../../assets/conversion.json';
  constructor(private http: HttpClient) {}

  /**
   * @description: service method to get pressure and temperature data
   */
  public getInitialConfig( ): Observable<{}> {
    return this.http.get(this.path);
  }
}
