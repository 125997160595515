/**
 * Represents ngrx Actions for the authorization state
 */

// Import core actions
import { CoreAction } from './actions';

// Import model
import { AuthorizationDetails } from '../../models/authorization';

export const ADD_AUTHORIZATION = 'ADD_AUTHORIZATION';

export class AuthorizationActions implements CoreAction<AuthorizationDetails> {
    constructor(
        public type: string,
        public payload: AuthorizationDetails
    ) {}
}
export const AddAuthorizationDetails: (authorizationNumber: AuthorizationDetails) => AuthorizationActions =
(authorizationNumber: AuthorizationDetails) => new AuthorizationActions(
  ADD_AUTHORIZATION, authorizationNumber
);
