// Alert Page Modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/* NgRx */
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { AddAlertRuleComponent } from '../../components/add-alert-rule/add-alert-rule.component';
import { AlertCardComponent } from '../../components/alert-card/alert-card.component';
import { AlertPanelComponent } from '../../components/alert-panel/alert-panel.component';
import { AlertRuleModalComponent } from '../../components/alert-rule-modal/alert-rule-modal.component';
import { AlertRuleComponent } from '../../components/alert-rule/alert-rule.component';
import { EditAlertRuleComponent } from '../../components/edit-alert-rule/edit-alert-rule.component';
import { AlertPageEffects } from '../../state/effects/alert-page.effects';
import { alertReducer } from '../../state/reducers/alert-page.reducer';
import { LiveReadingsModule } from '../live-readings/live-readings.module';
import { AlertPageComponent } from './alert-page.component';


@NgModule({
  imports: [
    CommonModule,
    ToastModule,
    DialogModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    CommonModule,
    SidebarModule,
    LiveReadingsModule,
    StoreModule.forFeature('alertRules', alertReducer),
    EffectsModule.forFeature(
      [ AlertPageEffects ]
    ),
  ],
  declarations: [
    AlertPageComponent,
    AddAlertRuleComponent,
    AlertRuleComponent,
    AlertRuleModalComponent,
    EditAlertRuleComponent,
    AlertPanelComponent,
    AlertCardComponent,
  ],
  exports: [
    AlertPageComponent,
    AddAlertRuleComponent,
    AlertRuleComponent,
    AlertRuleModalComponent,
    AlertPanelComponent,
    AlertCardComponent,
  ]
})
export class AlertPageModule { }
