import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { IAlert } from '../../models/alert';
import { Conversion } from '../../models/converterData';
import { AlertService } from '../../services/alert.service';
import { ConversionService } from '../../shared/services/conversion.service';
import * as alertActions from '../../state/actions/alert-page.actions';
import * as fromAlert from '../../state/container-states/alert.state';
import { State } from '../../state/container-states/app.state';
import { Well } from '../../state/reducers/global-filter.reducer';

@Component({
  selector: 'app-alert-panel',
  templateUrl: './alert-panel.component.html',
  styleUrls: ['./alert-panel.component.scss']
})
export class AlertPanelComponent implements OnInit, OnDestroy {
  public alerts: IAlert[] = [];
  unsubscribe$ = new Subject();
  alertsToAcknowledge;
  converterData: Conversion;
  sensorUnit: string;
  upperLimit;
  displayName;
  lowerLimit;
  wells: Well[];
  wellInformation = {};
  @Input() alert: IAlert;
  @Input() togglePanel = false;
  @Output() closePanelEvent = new EventEmitter<boolean>();
  @Output() openPanelEvent = new EventEmitter<boolean>();
  @Output() activeAlerts = new EventEmitter<{ alertTriggered: boolean, alertLength: number, alertList: any }>();

  constructor(private store: Store<fromAlert.AlertPageState>, private alertService: AlertService, private conversionService: ConversionService) {}

  ngOnInit(): void {
    this.store
      .pipe(select(fromAlert.getAlertRules), takeUntil(this.unsubscribe$))
      .subscribe((alerts: IAlert[]) => {
        if (alerts) {
          this.alerts = alerts.filter((alert) => {
            return alert.AlarmDetail.NotificationStatus && alert.AlarmDetail.NotificationMessage.trim() !== '';
          });
          this.reCreateMessage(this.alerts);
          const hasAlerts = this.alerts.length ? true : false;
          this.activeAlerts.emit({alertTriggered: hasAlerts, alertLength: this.alerts.length, alertList: this.alerts});
        }
      });

    this.store
    .select((state: State) => state.globalFilters)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((wellState) => {
      this.wells = wellState.wells;
      this.wells.map((well) => {
        this.wellInformation[well.uidWell] = well.wellName; //mapping wellname to well uid(change made to implement multiple log id)
      });
    });

    this.store.select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState) => {
        this.converterData = conversionState.conversion;
        if (this.alerts) {
          this.reCreateMessage(this.alerts.filter((alert) => alert.AlarmDetail.NotificationStatus));
        }
    });
  }

  reCreateMessage(validAlerts) {
    for (let alert = 0; alert < validAlerts.length; alert++) {
      const currentAlarm = validAlerts[alert].AlarmDetail;

      if (currentAlarm.NotificationMessage.trim() !== '') {
        const actualMessage = currentAlarm.NotificationMessage.replace('_UTC', '').split(currentAlarm.MnemonicAlias + ': ');
        const tempString = (actualMessage.length === 1) ? actualMessage[0].split(' ') : actualMessage[1].split(' ');

        this.displayName = currentAlarm.displayName;
        // this.loadSensorEditedNames(currentAlarm.MnemonicName, validAlerts[alert].WellName);
        if(tempString.length > 3){
          tempString[4] = tempString[4] + '_UTC ';
        }

        currentAlarm['displayName'] = this.displayName;
        currentAlarm.NotificationMessage = tempString.join(' ');
      }
    }
  }

  loadSensorEditedNames(sensorOriginalName: string, sensorWellName: string) {
    this.store
      .select((state: State) => state.user)
      .pipe(first())
      .subscribe((user) => {
        if (
          user &&
          user.preferences &&
          user.preferences.length > 0
        ) {
          const userSensors = user.preferences;
          userSensors.map((userSensor) => {
            if (userSensor.originalSensorLabel === sensorOriginalName && userSensor['sensorWellName'] === sensorWellName) {
              this.displayName = userSensor.renamedSensorLabel;
            }
          });
        }
      });
  }

  getSensorUnitType(alertSensorUnit: string, converterData: Conversion): string {
    return (alertSensorUnit === 'pressure')
    ? converterData.pressureUnit
    : (alertSensorUnit === 'temperature')
    ? converterData.temperatureUnit
    : '';
  }

  findNewAlertAndInsert(alerts: IAlert[]) {
    const currentAlerts = this.alerts.map((alert) => alert.AlarmID);
    const newAlerts = alerts.filter((alert) => {
      return !currentAlerts.includes(alert.AlarmID);
    });
    this.alerts = newAlerts.concat(this.alerts);
  }

  closePanel(): void {
    this.togglePanel = false;
    this.closePanelEvent.emit(this.togglePanel);
  }

  removeAcknowledgedAlert(i) {
    this.alerts.splice(i, 1);
    if (!this.alerts.length) {
      this.activeAlerts.emit({alertTriggered: false, alertLength: 0, alertList: []});
    } else {
      this.activeAlerts.emit({alertTriggered: true, alertLength: this.alerts.length, alertList: this.alerts});
    }
  }

  // Clariti: Build Verify
  acknowledgeAlert(alertData) {

  }

  acknowledgeAllAlerts() {
    this.alertsToAcknowledge = this.alerts.reduce((ackAlerts, alert, index) => ({...ackAlerts, [index]: alert}), {});
    this.alerts = [];
    this.activeAlerts.emit({alertTriggered: false, alertLength: 0, alertList: []});
    Object.keys(this.alertsToAcknowledge).forEach((key) => {
      this.store.dispatch(new alertActions.AcknowledgeAllAlerts(this.alertsToAcknowledge[key]));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
