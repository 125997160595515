/**
 * Service to capture coercion settings
 */

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CoercionSetting } from '../models/CoercionSettings';

@Injectable({
  providedIn: 'root'
})
export class CoercionSettingsService {
  private coercionSettings = new BehaviorSubject<boolean>(false);
  public currentSetting = this.coercionSettings.asObservable();
  private coercionConfigBase: CoercionSetting = {
    EvenIntervalCoercionEnable: true,
    TimeIndexSampleInterval: '00:03:20',
    TimeIndexCoercionTypeForAllVars: 'RTS_CT_CTI',
    TimeIndexCoercionParameterForAllVars: '00:00:02',
    TimeIndexGapDistanceForAllVars: '00:00:02',
    LogCurvesCoercionInfo: [
      {
        LogCurveMnemonicAlias: '',
        TimeIndexCoercionType: 'RTS_CT_CTI',
        TimeIndexCoercionParameter: '00:06:40',
        TimeIndexGapTimeDistance: '00:10:00'
      }
    ]
  };
  constructor(@Inject('ENV_CONFIG') private envConfig: any){}
  private readonly coercionTimeRangeConfig = {
    ZERO_TO_ONE: null, // No Coercion settings in case range is less then 1
    TWO_TO_FOURTEEN: {
      TimeIndexSampleInterval: '00:10:00',
      TimeIndexGapDistanceForAllVars: '00:03:00'
    },
    FIFTEEN_TO_THIRTYONE: {
      TimeIndexSampleInterval: '00:30:00',
      TimeIndexGapDistanceForAllVars: '00:08:00'
    },
    ONEDAY:{
      TimeIndexSampleInterval: this.envConfig.timeIndexSampleInterval,
      TimeIndexGapDistanceForAllVars: this.envConfig.timeIndexGapDistanceForAllVars
    }
  };

  public changeSelection(selection) {
    this.coercionSettings.next(selection);
  }

  /**
   * @name: getCoercionSettings
   * @description This method returns Coercion settings with updated TimeIndex and TimeIndexGap for a no of days range.
   * @param  {Number} range
   * @returns CoercionSetting
   */
  getCoercionSettings(range: number): CoercionSetting {
    const timeIndex = this.getIntervalForDaysRange(range);
    if (timeIndex) {
      return {
        ...this.coercionConfigBase,
        ...timeIndex
      };
    }
    return null;
  }

  /**
   * @name: getIntervalForDaysRange
   * @description This method return TimeIndexSampleInterval & TimeIndexGapDistanceAllVars
   * @param  {Number} range
   * @returns CoercionTimeRangeConfig: {}
   */
  private getIntervalForDaysRange(range: number): {} {
    if (range <= 31 && range >= 15) {
      return this.coercionTimeRangeConfig.FIFTEEN_TO_THIRTYONE;
    } else if (range <= 14 && range >= 2) {
      return this.coercionTimeRangeConfig.TWO_TO_FOURTEEN;
    } else if( range == 1){
      return this.coercionTimeRangeConfig.ONEDAY;
    }
    // DEFAULT: Don't apply coercion setting for range < 1
    return this.coercionTimeRangeConfig.ZERO_TO_ONE;
  }
}
