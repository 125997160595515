<div class="settings-icon">
  <button class='icon-button-settings' (click)="showDialog()">
    <img class="img" src="../../../../assets/images/Settings.png" alt="Settings">
  </button>
</div>

<div class="charts-settings-modal">
  <p-dialog [(visible)]='display' [modal]='true' [responsive]='true' [style]="{'min-width': '750px'}" [minY]='70' [maximizable]='false'
    [draggable]='false' [resizable]='false' [baseZIndex] ="500">

    <p-header>
      <div class='alert-details-modal-header ui-g'>
        <div class='header-logo ui-g-12 ui-md-12 ui-sm-12'>
          <div class='header-title'>
            <span>Chart Settings</span>
            <span class='fa fa-close header-icon-close' (click)='hideDialog()'></span>
          </div>
        </div>
      </div>
    </p-header>

    <div class='charts-settings-modal-content container-fluid'>
      <div class="ui-g">
        <div class="ui-g-2 ui-md-2 ui-sm-12 side-nav">
          <button class="btn" (click)="displaySection(1)">Axes</button>
        </div>

        <!-- first image -->
        <div *ngIf="showone" class="display-section ui-g-10 ui-md-10 ui-sm-12">
          <div class="header add-axis ui-g-12">
            <div class='ui-g-6 add-axis-text'>
            Y axes
            </div>
            <div class="ui-g-6">
                <button class="btn header-button float-right btn-secondary" tabindex="0" (click)='displaySection(2)' [disabled]="axisList.length === 0">ADD AXIS</button>
            </div>

          </div>
          <div class="modal-content">
          <span class="sensor-error-label" *ngIf="this.axisList.length === 0">Please Select at least one sensors</span>
            <div *ngFor="let item of axisList" class="ui-md-12">
            <p-checkbox class="label-adjustment" name="axisGroup" #checkBoxValId [value]="item" [label]="item.label" [(ngModel)]="item.checked" (click)="checkAxis(checkBoxValId)" binary="item.checked"></p-checkbox>
              <span class="measurement-data-image pi pi-times" (click)="removeAxies(item)"></span>
              <a class='measurement-data-image' (click)="displaySection(4);bindDataForEdit(item);">
                <img src='assets/master-page/ic-edit-small.png' />
              </a>
            </div>
          </div>
          <div class="footer container">
            <p-footer class="ui-dialog-footer">
              <div class="col-12 float-right custom-pt-2">
                  <button class="btn-secondary btn-cancel" (click)="cancelDialog()">Cancel</button>
                  <button class="primary btn-apply" (click)="sendSelectedAxes();" [disabled]="(axisList.length === 0) || (enableApply)">Apply</button>
              </div>
            </p-footer>
          </div>

        </div>
        <!-- second image -->
        <div *ngIf="showtwo" class="display-section ui-g-10 ui-md-10 ui-sm-12">
          <div class="header">
            <h3>Add Axis</h3>
          </div>
          <div class="modal-content dropdown-content">
            Select type of axis to add
            <p-dropdown [options]="axistype" placeholder="Select an axis" [(ngModel)]="selectedAxis"
              [style]="{'outline':'auto'}"></p-dropdown>
          </div>
          <div class="footer container">
            <p-footer class="ui-dialog-footer">
              <div class="col-12 float-right custom-pt-2">
                  <button class="btn-secondary btn-cancel" (click)="displaySection(1)">Cancel</button>
                  <button class="primary btn-apply" (click)="displaySection(3); loadSensorList(selectedAxis.name)">Next</button>
              </div>
            </p-footer>
          </div>
        </div>

        <!-- third image -->
        <div *ngIf="showthree" class="display-section ui-g-10 ui-md-10 ui-sm-12">
          <div class="header">
            <h3 *ngIf="isEdit">Edit Axis</h3>
            <h3 *ngIf="!isEdit">Add Axis</h3>
          </div>
          <div class="modal-content">
            <h2>Select the sensor for this Axis</h2>
            <div *ngFor="let item of sensorCheckBoxList" class="ui-md-12">
              <p-checkbox class="display-flex" name="sensorList" #sensorCheckBoxId [value]="item" [label]="item.label" [disabled]="item.disable" [(ngModel)]="item.checked" (click)="checkSensor(sensorCheckBoxId)" binary="item.checked"></p-checkbox>
              </div>
            <p *ngIf="sensorCheckBoxList.length == 0" class='sensor-error-label'>
              Please select at least one {{selectedAxis.displayType}} sensor to view data
            </p>
          </div>
          <div class="footer container">
            <p-footer class="ui-dialog-footer">
              <div class="col-12 float-right custom-pt-2">
                  <button class="btn-secondary btn-cancel" (click)="displaySection(1)">Cancel</button>
                  <button class="btn-secondary btn-apply" (click)="displayEditOrAdd()">Back</button>
                  <button class="primary btn-apply" [disabled]= "enableApplyForSensorList" (click)="displaySection(1); addAxis()">Apply</button>
              </div>
            </p-footer>
          </div>
        </div>

        <!-- fourth image -->
        <div *ngIf="showfour" class="display-section ui-g-10 ui-md-10 ui-sm-12">
          <div class="header">
            <h3>Edit Axis</h3>
          </div>
          <div class="modal-content">
            <table class="table">
              <tbody>
                <tr>
                  <th scope="col">Name </th>
                  <th scope="col"><b>{{itemForEdit?.label}}</b></th>
                </tr>
                <tr>
                  <th scope="row">Auto</th>
                  <td>
                    <mat-slide-toggle [(ngModel)]="isAuto" (change)= "toggleEnableDisable(isAuto)"></mat-slide-toggle>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Min</th>
                  <td><input type="text" pInputText (input)='minMaxChanged($event, true)' placeholder="Enter a minimum value" [(ngModel)]="axisMin" [disabled]="isAuto" maxlength="14"> </td>
                </tr>
                <tr>
                  <th scope="row">Max</th>
                  <td><input type="text" pInputText (input)='minMaxChanged($event, false)' placeholder="Enter a maximum value" [(ngModel)]="axisMax" [disabled]="isAuto" maxlength="14"> </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="footer container">
            <p-footer class="ui-dialog-footer">
              <div class="col-12 float-right custom-pt-2 display-sm-flex">
                  <button class="btn-secondary btn-cancel" (click)="displaySection(1)">Cancel</button>
                  <button class="btn-secondary btn-apply width-auto" *ngIf="!disableSelectSensor" (click)="displaySection(3); loadSensorList(unitSelectedForEdit)"><span class="change-txt-position">Select Sensors</span></button>
                  <button class="primary btn-apply" [disabled]="invalidMinMaxValue" (click)="displaySection(1);addRange()">Apply</button>
              </div>
            </p-footer>
          </div>
        </div>

      </div>
    </div>

  </p-dialog>
  <p-dialog [(visible)]="showLimitDialog" [draggable]="false" [showHeader]="false" [blockScroll]="true" [modal]="true"
  [breakpoint]="500" [style]="{'min-width': '200px','width':'500px'}" [responsive]="true" [contentStyle]="{'overflow':'auto'}" appendTo="body" [baseZIndex]="10001">
  <div class="error-dialog">
    <div class="col-md-12">
      <p class="header-text">{{errorMessage}}</p>
    </div>
    <div class="col-md-12 button-div">
      <button pButton type="button" class="confirmation-button" (click)="closeLimitDialog()" label="OK">OK</button>
    </div>
  </div>
</p-dialog>
</div>
