<p-dialog [(visible)]="showSupportDialog" [draggable]="false" [showHeader]="false" [blockScroll]="true" [modal]="true"
  [breakpoint]="500" [style]="{'min-width': '200px'}" [responsive]="true" [contentStyle]="{'overflow':'auto'}" appendTo="body" [baseZIndex]="10001">
  <div class="error-dialog">
    <div class="col-md-12">
      <p class="header-text">Export is available in the desktop application.</p>
    </div>
    <div class="col-md-12 button-div">
      <button pButton type="button" class="confirmation-button" (click)="closeSupportDialog()" label="OK">OK</button>
    </div>
  </div>
</p-dialog>

<div class="nav-side-menu">
  <div class="brand">
      &nbsp;
  </div>
  <i class="fa fa-bars fa-2x toggle-btn" (click)="isCollapsed = !isCollapsed"
  [attr.aria-expanded]="!isCollapsed" aria-controls="menu-content"></i>

  <div id="menu-list" class="menu-list isLocked" #menulist>

    <ul id="menu-content" class="menu-content collapse out" [ngbCollapse]="isCollapsed">
      <li (click)='toggleTab(viewOptions.map)' class="display-flex" [ngClass]='{ "active": selectedTab === viewOptions.map}' *ngIf="configClariti.maps.show">
        <a data-toggle="tooltip" title="Map"  ng-href=""><i class="menu-img map"> </i></a>
        <label class="d-md-none label-style">Map</label>
      </li>
      <li data-toggle="collapse"  data-target="#products" class="collapsed display-flex" (click)='toggleTab(viewOptions.newSchematic)' [ngClass]='{ "active": selectedTab === viewOptions.newSchematic}'
      *ngIf="configClariti.schematics.show">
        <a  ng-href="" data-toggle="tooltip" title="Well Schematics">
          <i class="menu-img schematics"></i></a>
          <label class="d-md-none label-style">Well Schematics</label>
      </li>
      <li data-toggle="collapse" class="collapsed display-flex" (click)='toggleTab(viewOptions.charts)' [ngClass]='{ "active": selectedTab === viewOptions.charts}'
      *ngIf="configClariti.charts.show">
        <a  ng-href="" data-toggle="tooltip" title="Pressure and Temperature" ><i class="menu-img pressure-temperature"></i></a>
        <label class="d-md-none label-style">Charts</label>
        <div class="export-div" *ngIf='!chartExportState.completed && chartExportState.showStatus' >
          <img class='export-icon in-progress' src="../../../assets/icons/icon-export-in-progess.svg">
        </div>
        <div class="export-div" *ngIf='chartExportState.completed && chartExportState.showStatus' >
          <img class='export-icon' src="../../../assets/icons/icon-export-complete.svg">
        </div>
      </li>

      <li data-toggle="collapse" class="collapsed display-flex" (click)='toggleTab(viewOptions.alertRules)' [ngClass]='{ "active": selectedTab === viewOptions.alertRules}'
      *ngIf="configClariti.alerts.show">
          <a ng-href="" data-toggle="tooltip" title="Alert Rules" ><i class="menu-img alerts"></i></a>
          <label class="d-md-none label-style">Alert Rules</label>
        </li>
        <li data-toggle="collapse" class="collapsed display-flex disabled" *ngIf='!isDesktopDevice && configClariti.exports.show' (click)='showSupportMessage()' >
          <a  ng-href="" data-toggle="tooltip" title="Export"><i class="menu-img exports"></i></a>
          <label class="d-md-none label-style">Export</label>
        </li>
        <li data-toggle="collapse" class="collapsed display-flex" *ngIf='isDesktopDevice' (click)='toggleTab(viewOptions.export)' [ngClass]='{ "active": selectedTab === viewOptions.export}'>
          <a  ng-href="" data-toggle="tooltip" title="Export"><i class="menu-img exports"></i></a>
          <label class="d-md-none label-style">Export</label>
          <div class="export-div" *ngIf='exportState.started && !exportState.ready && !exportState.cancelled' >
            <img class='export-icon in-progress' src="../../../assets/icons/icon-export-in-progess.svg">
          </div>
          <div class="export-div" *ngIf='exportState.started && exportState.ready' >
            <img  class='export-icon' src="../../../assets/icons/icon-export-complete.svg">
          </div>
          <div class="export-div" *ngIf='isDownloading'>
            <img class='export-icon downloading' src="../../../assets/icons/icon-downloading.gif">
          </div>
        </li>
    </ul>
  </div>
</div>

<app-export-status *ngIf='selectedTab === viewOptions.export && (exportState.initiated || exportState.started)'
(cancelExport)='handleCancel()' [isChartExport] = 'false' [fileUrl]='fileUrl' [fileReady]='exportState.ready' [failed]='exportState.failed' [initiated]='exportState.initiated'
(closeBar)='closeBar()' [jobName]='jobName'></app-export-status>

<app-export-status *ngIf='selectedTab === viewOptions.charts && chartExportState.inProgress'
(cancelExport)='cancelChartExport()' [discard] = chartExportState.discard [cancelled] = chartExportState.cancelled  [fileReady]='chartExportState.ready' [isChartExport] = 'true' (closeBar)='closeBar()' [jobName] = '"Chart"' >
</app-export-status>
