import { IMeasurementClass } from '../../models/measurement';
import { IUnit } from '../../models/measurement';
import { Celsius } from './Temperature/Celsius';
import { Fahrenheit } from './Temperature/Fahrenheit';
import { Kelvin } from './Temperature/Kelvin';

export class TemperatureMeasurement implements IMeasurementClass {
  static readonly Instance: TemperatureMeasurement = new TemperatureMeasurement();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Fahrenheit.Instance;
  }

  static GetTemperatureUnitInstance(unitText:string){
    switch (unitText.toLowerCase()) {
      case 'c':
        return Celsius.Instance;
      case 'k':
        return Kelvin.Instance;
      default:
        return Fahrenheit.Instance;
    }
  }
}
