import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGlobalFilters from '../reducers/global-filter.reducer';
import * as fromRoot from './app.state';

export interface GlobalFilterState extends fromRoot.State {
    filters: fromGlobalFilters.GlobalFilterState;
}

// Selector functions
const getGlobalFilterFeatureState = createFeatureSelector<fromGlobalFilters.GlobalFilterState>('globalFilters');

export const getWellsList = createSelector(
    getGlobalFilterFeatureState,
    (state) => {
        return state.wells;
    }
);

export const getWellsListError = createSelector(
    getGlobalFilterFeatureState,
    (state) => state.error
);

export const getSensorsListError = createSelector(
    getGlobalFilterFeatureState,
    (state) => state.error
);