<div class='edit-group'>
  <div class='input-group'>
    <div class='col-sm-12 col-md-12 col-lg-12 rule'>
      <span class='title'>Min: </span>
      <input
        [value]='lowerLimit'
        (input)='limitChanged($event.target.value, "lower")'
        [(ngModel)]='lowerLimit'
        (ngModelChange)='limitChangedCheck($event)'
        class='min-max-value'
        type="text"
        pInputText>
      <span class='units min'>
        {{sensorUnit.label}}
      </span>
    </div>
    <div class='col-sm-12 col-md-12 col-lg-12  custom-mt-2 rule'>
        <span class='title'>Max:</span>
        <input
          [value]='upperLimit'
          (input)='limitChanged($event.target.value, "upper")'
          [(ngModel)]='upperLimit'
          (ngModelChange)='limitChangedCheck($event)'
          class='min-max-value'
          type="text"
          pInputText>
        <span class='units max'>
          {{sensorUnit.label}}
        </span>
    </div>
  </div>
  <div class='ui-g edit-min-max'>
    <div class='gray-label1'>
      Only one field is required
    </div>
    <div *ngIf='isDuplicateAlarm' class='warning-text'>This alert already exists.</div>
    <div class='button-group c-display-block'>
      <button
        class='ui-button cancel btn-secondary'
        label='Cancel'
        type='button'
        (click)='cancel.emit()'>
        Cancel
      </button>
      <button
        class='ui-button save-alert-btn primary-button'
        [disabled]='!valid || limit'
        label='Save'
        type='button'
        (click)='saveAlert()'>
        Save
      </button>
    </div>
  </div>
</div>
