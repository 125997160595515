import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    errorMapping = {
        'ADFS failed to issue token (invalid id/password; invalid audience; waiDB connection broken?).': 'Invalid Username or password',
        'Cannot connect to OAG Server.[java.net.SocketException: Connection reset]': 'Error while trying to authenticate. Please retry login.',
        'Cannot connect to WAI SQL Server.' : 'Error while trying to authenticate. Please retry login.',
        'Could not parse OAG auth error response.': 'Error while trying to authenticate. Please retry login.',
        'Failed to connect to ADFS to obtain token.': 'Error while trying to authenticate. Please retry login.',
        'Unauthorization access or bad request.' : 'User not registered. Please check with RTO support',
        'Application is Offline. Please connect to network and try again': 'Application is Offline. Please connect to network and try again',
        'Error: User credentials do not have access to any wells.' : 'User does not have access to any wells. Please check with RTO support',
        'User not registered. Please check with RTO support' : 'User not registered. Please check with RTO support',
        'Cannot connect to OAG Server.[java.net.ConnectException: Connection refused: connect]' : 'Gateway timed out please retry'
    };
    
    getErrorMessage(errorCode, errorMessage) {
        if (this.errorMapping[errorCode]) {
            return this.errorMapping[errorCode];
        } else if (this.errorMapping[errorMessage]) {
            return this.errorMapping[errorMessage];
        }
        return errorMessage;
    }
}
