import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* NgRx */
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { OktaAuthModule, OktaAuthService } from '@okta/okta-angular';
// import { AngularCesiumModule } from 'angular-cesium';
import 'chartjs-plugin-annotation';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { UserGlobal } from './models/user';
//import { PathResolverService } from './services/path-resolver.service';
import { CommonServicesModule } from './services/services.module';
import { DirectivesModule } from './shared/directives/directives.module';
import { Interceptor } from './shared/interceptors';
import { Okta } from './shared/okta.service';
import { ChartEffects } from './state/effects/chart.effects';
import { UnitConversionEffects } from './state/effects/unit-conversion.effects';
import { UserEffects } from './state/effects/user.effects';
import { ClearStateReducer } from './state/reducers/clearStateReducer';
import { ReducerModule, reducers } from './state/reducers/reducers';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    routing,
    OktaAuthModule,
    BrowserModule,
    CommonModule,
    DirectivesModule,
    StoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 800000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      autoDismiss: false,
    }),
    ComponentsModule,
    CommonServicesModule,
    ReducerModule,
    StoreModule.forRoot(reducers, {metaReducers: [ClearStateReducer]}),
    EffectsModule.forRoot([
      UserEffects,
      UnitConversionEffects,
      ChartEffects
    ]),
    DeviceDetectorModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [
    OktaAuthService,
    Okta,
    Store,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    UserGlobal
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
