import { Injectable } from '@angular/core';
import { IDataBase } from 'jsstore';
import * as dbTable from '../../constants/db-tables.constants';
import { idbCon } from './db-connection.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  dbName = 'vof_db';
  isUpgraded = true;

  constructor() {
    this.connection.setLogStatus(false);
    this.initJsStore();
  }

  get connection() {
    return idbCon;
  }

  /**
  * Initializes the JsStore database by checking if it already exists.
  * If the database exists, handles it accordingly; otherwise, initializes a new database.
  */
  private async initJsStore() {
    try {
      // Check if the database exists
      const isDbExist = await this.connection.isDbExist(this.dbName);
      console.log("Is DB exist: ", isDbExist);
  
      if (isDbExist) {
        // Handle existing database scenario
        await this.handleExistingDatabase();
      } else {
        // Initialize a new database if it doesn't exist
        await this.initializeDatabase();
      }
    } catch (error) {
      console.error("Error initializing database:", error);
    }
  }
  
  /**
  * Handles the scenario where the database already exists.
  * Checks the version and either opens the existing database or clears and reinitializes it if needed.
  */
  private async handleExistingDatabase() {
    // Get the version of the existing database
    const version = await this.connection.getDbVersion(this.dbName);
    console.log("Version of the table: ", version);
  
    // Check if the version matches the expected version
    if (version === dbTable.userPrefTable.version) {
      this.isUpgraded = false;
    }
  
    // Open the database if it is not upgraded, otherwise clear and reinitialize
    if (!this.isUpgraded) {
      await this.openDatabase(this.dbName);
      console.log("version not upgraded db opened");
    } else {
      await this.clearAndReinitializeDatabase();
      console.log("version cleared");
    }
  }
  
  /**
  * Initializes a new database using the provided schema and opens it.
  */
  private async initializeDatabase() {
    // Get the database schema and initialize it
    const db = this.getDatabase();
    await this.connection.initDb(db);
    console.log("Database initialized successfully");
    // Open the newly initialized database
    await this.openDatabase("Database opened successfully");
  }
  
  /**
  * Clears the existing database tables and local storage, then reinitializes the database.
  */
  private async clearAndReinitializeDatabase() {
    // Get the database schema
    const db = this.getDatabase();
    // Clear each table in the existing database
    db.tables.forEach((table) => this.connection.clear(table.name));
    localStorage.clear();
    // Reinitialize the database
    await this.connection.initDb(db);
    console.log("Existing DB cleared and recreated");
    await this.openDatabase("New DB opened.");
  }
  
  /**
  * Opens the database and logs a provided message.
  * @param message - The message to log after opening the database.
  */
  private async openDatabase(message: string) {
    await this.connection.openDb(this.dbName);
    console.log(message);
  }

  /**
  * Returns the database object containing the schema and tables.
  */
  public getDatabase() {
    const dataBase: IDataBase = {
      name: this.dbName,
      tables: [
        dbTable.userPrefTable,
        dbTable.globalFilterTable,
        dbTable.unitConversionTable,
        dbTable.chartSettingsTable
      ],
    };
    return dataBase;
  }
}
