<div class='container export-history-container'>
    <div class='select-well'>
      <span class='select-well-text'>Exports</span>
    </div>
    <div *ngIf='exports.length !== 0' class='export-list-container'>
        <ul class='exports-list' >
            <li *ngFor='let export of exports'>
                <div class="export-file-name">
                    <span class='header-text'>{{getExportHeader(export)}}</span>
                </div>
                <div *ngIf='!canDownloadExport(export)' class='export-state-container' >
                    <div *ngIf='canCancelExport(export)' class='progress-bar'>
                        <div class='progress-bar-fill' [ngStyle]="{'width.%': export.progress.initializationProgress}"></div>
                    </div>
                    <span *ngIf='isExportInCancellation(export)' class='subtext export-status'>{{export.progress.state}}</span>
                    <span *ngIf='isExportFailed(export)' data-toggle='tooltip' title='{{export.progress.error}}' class='subtext export-status-failed'>Error</span>
                </div>
                <div class='export-action-buttons-container'>
                    <i *ngIf='canCancelExport(export)' class='fa fa-ban export-action-button' aria-hidden='true' (click)='cancelExport(export)' data-toggle='tooltip' title='Cancel'></i>
                    <i *ngIf='canDeleteExport(export)' class='fa fa-trash export-action-button' aria-hidden='true' (click)='deleteExport(export)' data-toggle='tooltip' title='Delete'></i>
                    <i *ngIf='canDownloadExport(export)' class='fa fa-download export-action-button' aria-hidden='true' (click)='downloadExport(export)' data-toggle='tooltip' title='Download'></i>
                </div>
            </li>
        </ul>
    </div>
    <div *ngIf='exports.length === 0' class='empty-exports-view'>
        <label class='label'>You don't have any exports yet</label>
    </div>
</div>