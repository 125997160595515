/**
 * Service method to get pressure and temperature
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { concatMap, expand, map, takeUntil, timeout } from 'rxjs/operators';
import { CoercionSetting } from '../models/CoercionSettings';
import { DateRange } from '../models/DateRange';
import { IDataSensor } from '../models/lineGraphData';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';


@Injectable()
export class PressureAndTemperatureService {
  constructor(
    @Inject('ENV_CONFIG') private envConfig: any,
    private http: HttpClient) { }
  unsubscribe$ = new Subject();
  unsubscribeLive$ = new Subject();
  cancelChartExport$: Subscription;
  private exportStatus = new BehaviorSubject(false);
  currentExportStatus = this.exportStatus.asObservable();
  exportFileName;
  chartDataForExport;

  // For live pooling
  public createPayload(daterange: any, requestlist: any, converterData, axesList, selectedStep, coercion?: any) {
    const payload = {
      daterange,
      converterData,
      requestlist,
      axesList,
      selectedStep,
      coercion
    };
    return payload;
  }
  public joinDataForAllSensorsLive(
    sensorsWithDateRange: any,
    coercionSettings?: any
  ) {
    return forkJoin(
      sensorsWithDateRange.map((sensor) => {
        return this.getPressureAndTemperatureData(
          sensor.dtRange,
          sensor.sensor,
          coercionSettings
        );
      })
    );
  }


  changeExportStatus(expStatus) {
    this.exportStatus.next(expStatus);
  }
  /**
   * @description: service method to get pressure and temperature data
   */
  public getPressureAndTemperatureData(
    dateRange: DateRange,
    sensor: IDataSensor,
    coercionSettings?: any
  ): Observable<{}> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = {
      uidWell: sensor.uidWell,
      uid: sensor.multiLogId,
      logdata: [sensor.sensorName],
      size: 'l',
      maxreturnnodes: 30000,
      startDateTimeIndex: dateRange.start,
      endDateTimeIndex: dateRange.end,
      Uom: localStorage.userProfile,
      coercionSettings: { ...coercionSettings }
    };
    return this.http.post(this.envConfig.api.globalFilter, body);
  }

  /**
   * @description: service method to get pressure and temperature data for list
   */
  public getPressureAndTemperatureDataForList(
    dateRange: DateRange,
    well: any
  ): Observable<{}> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = {
      uidWell: well.uidWell,
      uid: well.uid,
      logdata: well.sensorNames,
      size: 'l',
      maxreturnnodes: 30000,
      startDateTimeIndex: dateRange.start,
      endDateTimeIndex: dateRange.end,
      Uom: localStorage.userProfile
    };
    return this.http.post(this.envConfig.api.globalFilter, body);
  }

  /**
   * @description: service method to initiate the export in middleware
   */
  initiateExport(excelConfig, fileName) {
    this.exportFileName = fileName
    this.changeExportStatus('started');
    const body = {
      excelConfig,
      uom: localStorage.userProfile
    };
    this.cancelChartExport$ = this.http.post(this.envConfig.api.downloadChartData, body, { responseType: 'blob' }).pipe().
      subscribe((response: any) => {
        this.chartDataForExport = response;
        this.changeExportStatus('created');
      })
  }

  /**
   * @description: service method to download the file
   */
  downloadFile() {
    const blob = new Blob([this.chartDataForExport], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, this.exportFileName);
    this.changeExportStatus('close');
  }

  public getRawChartData(sensorList: any, converterData: any, axesList: any, intervalInMillis:any, coercionSettings?: any) {
    const body = {
      sensorList,
      axesList,
      coercion: { ...coercionSettings },
      uom: localStorage.userProfile,
      userUnits: converterData,
      intervalInMillis //datetime range in milliseconds is sent to middleware to get xAxis min max values for chart
    };
    return this.http.post(this.envConfig.api.getChartDelta, body).pipe(
      timeout(300000),
      takeUntil(this.unsubscribeLive$),
      map((response: any) => {
        return response;
      })
    );
  }

  public getInitialData(sensorList: any, dateRange: DateRange, converterData: any, axesList: any, coercionSettings?: any) {
    const body = {
      sensorList,
      dateRange,
      axesList,
      coercion: { ...coercionSettings },
      uom: localStorage.userProfile,
      userUnits: converterData
    };
    return forkJoin(
      sensorList.map((sensor) => {
        const reqBody = _.cloneDeep(body) //To Do : find another way to pass the body (normal passing isnt working)
        reqBody['sensorList'] = sensor;
        return this.http.post(this.envConfig.api.getChartData, reqBody).pipe(
          timeout(300000),
          takeUntil(this.unsubscribeLive$),
          map((response: any) => {
            return response;
          })
        );
      })

    );

  }


  public joinDataForAllWells(
    wells,
    dateRange: DateRange,
    coercionSettings: CoercionSetting
  ) {
    this.unsubscribe$ = new Subject();
    return forkJoin(
      wells.map((well) => {
        return this.getPressureAndTemperatureDataRecursively(
          well,
          dateRange,
          coercionSettings
        );
      })
    );
  }
  public getPressureAndTemperatureDataRecursively(
    sensor: any,
    dateRange: DateRange,
    coercionSettings: CoercionSetting
  ) {
    let logDataTemp = [];
    const body = {
      uidWell: sensor.uidWell,
      uid: sensor.multiLogId,
      logdata: [sensor.sensorName],
      size: 'l',
      maxreturnnodes: 30000,
      startDateTimeIndex: dateRange.start,
      endDateTimeIndex: dateRange.end,
      Uom: localStorage.userProfile,
      CoercionSetting: { ...coercionSettings }
    };
    const request$ = this.getPressureAndTemperatureDataSingle(body);
    return request$.pipe(
      expand((log: any) => {
        body.startDateTimeIndex = new Date(log.endDateTimeIndex + 'Z');
        return log['hasMoreData']
          ? this.getPressureAndTemperatureDataSingle(body)
          : EMPTY;
      }),
      concatMap((logData: any) => {
        logDataTemp = [...logDataTemp, ...logData];
        return of(logDataTemp);
      })
    );
  }
  getPressureAndTemperatureDataSingle(body): Observable<{}> {
    return this.http.post(this.envConfig.api.globalFilter, body).pipe(
      takeUntil(this.unsubscribe$),
      map((response: any) => {
        return response.response != null ? response.response.log[0] : EMPTY;
      })
    );
  }
  cancellAPICalls() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  discardExport() {
    this.chartDataForExport = [];
  }

  cancelExport() {
    this.changeExportStatus('cancelled');
    this.cancelChartExport$.unsubscribe();
  }

  stopPendingLiveRequests() {
    this.unsubscribeLive$.next();
    this.unsubscribeLive$.complete();
  }
}
