import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { DateRange } from 'src/app/models/DateRange';
import { convertToNewTimeZone } from 'src/app/shared/utilities/timeZoneConverter';
import * as XLSX from 'xlsx';
import sensorInfo from '../../assets/data/sensorTypeConfig.json';
import { GetLivePool, GetLiveRequest, GetWaitIndicator } from '../state/actions/chart.actions';
import { IChartstate } from '../state/container-states/chart.state';
import { GraphUtilService } from './graph-util.service';
import { PressureAndTemperatureService } from './pressure-temperature.service';
import { SensorNameEditService } from './sensor-name-edit.service';

@Injectable()
export class ChartStoreService {
  wb: XLSX.WorkBook;
  ws: XLSX.WorkSheet;
  pollInterval: any;
  pollIntervalLocal: any;
  waitIndicator: boolean;
  isOnline = true;
  echartInstance = null;
  rawData = [];
  // used to map axis color with live reading sensor
  private colorMapSource = new BehaviorSubject([]);
  currentColorMap = this.colorMapSource.asObservable();
  // used to map axis color with live reading sensor
  private checkedSensorList = new BehaviorSubject([]);
  currentSensorList = this.checkedSensorList.asObservable();
  // used to update live reading sensor list based on axis selection
  private axesListForLive = new BehaviorSubject([]);
  currentAxisList = this.axesListForLive.asObservable();
  // used to update live reading sensor list based on axis selection
  private previousShownType = new BehaviorSubject([]);
  previousType = this.previousShownType.asObservable();
  private globalChange = new BehaviorSubject([]);
  changedGlobal = this.globalChange.asObservable();
  private renameToggle = new BehaviorSubject(false);
  isRenamed = this.renameToggle.asObservable();
  unsubscribe$ = new Subject();
  sensorInfo: any;
  constructor(
    private chartStore: Store<IChartstate>,
    private tempPressureService: PressureAndTemperatureService,
    private graphUtilService: GraphUtilService,
    private userPrefService: SensorNameEditService
  ) {
    this.sensorInfo = sensorInfo.sensorData.filter((item:any) => { // getting sensorinfo from config file
      return item.settings === true;
    }); // getting the sensor info from config file
   }

  /**
   * Method to share color Mapping between chart and measurment section
   * @param colorMapping
   */
  changeColorMapping(colorMapping) {
    this.colorMapSource.next(colorMapping);
  }
  changeGlobal(global) {
    this.globalChange.next(global);
  }
  changeRenameStatus(renamed) {
    this.renameToggle.next(renamed);
  }

  changeAxisList(axesList) {
    this.axesListForLive.next(axesList);
  }

  changePreviousType(shownType) {
    this.previousShownType.next(shownType);
  }

  // Method to share sensor list between chart and chart setting  starts here
  updateSelectedSensor(selectedSensors) {
    this.checkedSensorList.next(selectedSensors);
  }

  // Method to share sensor list between chart and chart setting  ends here
  getSorted(unsorted) {
    return unsorted.sort((a, b) => a.wellName.localeCompare(b.wellName, undefined, { numeric: true }));
  }

  //function to get the final coercedData starts here
  getCoercedData(logs, liveDurationMilliSec, forZoom, converterData) {
    const logArray = [];
    logs.forEach((log) => {
      logArray.push(log.logData.data);
    });
    //convert to data is required as the date in the repsonse is String
    for (let j = 0; j < logArray.length; j++) {
      for (let i = 0; i < logArray[j].length; i++) {
        logArray[j][i][0] = new Date(logArray[j][i][0]);
      }
    }
    const coercedData = this.coerceForDisplay(logArray, forZoom, liveDurationMilliSec, converterData);
    const finalData = this.mapDataToSeries(logs, coercedData);
    return finalData;
  }
  // function to get the final coercedData ends here

  // function to map the coerced data to corresponding series starts here
  mapDataToSeries(series, coercedData) {
    if (coercedData === undefined) {
      series.forEach((ser) => {
        ser.logData.data = [];
      });
    } else {
      let i = 0;
      series.forEach((ser) => {
        ser.logData.data = coercedData[i];
        i++;
      });
    }
    return series;
  }
//function to convert the timestamp of the incoming data starts here

  convertTimeStampForLive(seriesData,mappedColor) {
      seriesData.forEach((sensorSeries) => {
        const color = mappedColor ? mappedColor[`${sensorSeries.wellName}-${sensorSeries.sensorOriginalName}`] : 'red';
        if(sensorSeries){
        sensorSeries['itemStyle'] = {color}
        sensorSeries.data.forEach((data) => {
          data[0] = new Date(data[0])
        });
      }
      });

    return seriesData
  }
  // function to map the coerced data to corresponding series ends here

  filterEmptyObject(logs){
    return logs.filter((log) =>{
      return ! _.isEmpty(log);
    });
  }

  //function to pop and concatinate the live pool to the initial data starts here
  popAndappendData(originalData, polledData, xAxisTimeStamp, converterData) {
    for (let i = 0; i < originalData.length; i++) {
      if (originalData[i] && Array.isArray(originalData[i].logData.data) && originalData[i].logData.data.length > 0) {
        // removing data points which are out of range
        while (originalData[i].logData.data.length > 0 && this.graphUtilService.getConvertedTime(originalData[i].logData.data[0][0], converterData) <= xAxisTimeStamp) {
          originalData[i].logData.data.shift();
        }
      }
      // concatinating new set of live data to the existing raw data
      originalData[i].logData.data = _.concat(originalData[i].logData.data, polledData[i][0].logData.data);
    }
    return originalData;
  }
  //function to pop and concatinate the live pool to the initial data ends here

  // function to find min and max value of the series starts here
  filterSeriesWithData(originalData) { // Todo : still logic to find the min and max to be writtened
    const finalDataArray = [];
    for (let i = 0; i < originalData.length; i++) {
      if (originalData[i].length !== 0) {
        finalDataArray.push(originalData[i]);
      }
    }
    return finalDataArray;
  }
  findMin(filteredData){
    const minArray = [];
    for (let i = 0 ; i < filteredData.length; i++) { // find the fist value of all the series
      minArray.push(filteredData[i][0][0]);
    }
    return _.min(minArray); // return the min of the series
  }

  findMax(filteredData){
    const maxArray = [];
    for (let i = 0 ; i < filteredData.length; i++) { // find the last value of all the series
      maxArray.push(filteredData[i][filteredData[i].length - 1][0]);
    }
    return _.max(maxArray); // return the max of the series
  }
  // function to find min and max value of the series ends here

  // tslint:disable-next-line: cyclomatic-complexity
  // function for the coercion logic starts here
  coerceForDisplay(orginalData, forZoom, liveDurationMilliSec, converterData) {
    const originalDataSeries = _.cloneDeep(orginalData);
    const dataToBeCoerced = this.filterSeriesWithData(orginalData);
    let nValue;
    if (dataToBeCoerced.length === 0) {
      return; // todo : return empty array and check for length in calling funciton
    }
    const coercedData = [];
    const chartOptions = this.echartInstance.getOption();
    let left = 0;
    let right = 0;
    let minValue = this.findMin(dataToBeCoerced);
    let maxValue = this.findMax(dataToBeCoerced);
    let minZoom = Date.parse(new Date(Date.now() - liveDurationMilliSec) + '');
    let maxZoom = Date.parse(new Date() + ' ');
    if (forZoom) {
      const minValueArray = [];
      const maxValueArray = [];
      minZoom =  Date.parse(convertToNewTimeZone(new Date(chartOptions.dataZoom[1].startValue), converterData.timeUnit, moment.tz.guess()) + '');
      maxZoom =  Date.parse(convertToNewTimeZone(new Date(chartOptions.dataZoom[1].endValue), converterData.timeUnit, moment.tz.guess()) + '');
      //if (chartOptions.xAxis[0].min != undefined && chartOptions.xAxis[0].max != undefined) {
        //if (chartOptions.xAxis[0].min != 'dataMin' && chartOptions.xAxis[0].max != 'dataMax') {
      for (let k = 0; k < dataToBeCoerced.length; k++) {
          let n = 0; // we could potentially do a binary search to find time faster
          while (Date.parse(dataToBeCoerced[k][n][0]) < minZoom) {
            n++;
          }
          minValueArray.push(dataToBeCoerced[k][n][0]);
          while (n < (dataToBeCoerced[k].length - 1) && Date.parse(dataToBeCoerced[k][n][0]) < maxZoom) {
            n++;
          }
          maxValueArray.push(dataToBeCoerced[k][n][0]);
        }
      minValue = _.min(minValueArray);
      maxValue = _.max(maxValueArray);
    }
    right = this.echartInstance._coordSysMgr._coordinateSystems[0]._axesList[0]._extent[1];
    left = this.echartInstance._coordSysMgr._coordinateSystems[0]._axesList[0]._extent[0];

    const widthPixel = right - left + 1;

    const widthData = (maxValue) - (minValue) + 1;
    // nStep is the amount of time each pixel represents.
    const nStep = widthData / widthPixel;
    let nCount = 0;
    let nAxisValue = 0;
    const nIndex = [];
    // This creates an empty Output array and sets the start index for each series
    for (let j = 0; j < originalDataSeries.length; j++) {
      let n = 0;
      // keep increasing starting index if your time index is < the dataZoom's time range.
      while (n < originalDataSeries[j].length && originalDataSeries[j][n][0] < minValue) {
        n++;
      }
      nIndex.push(n);
      coercedData.push([]);
    }

    // Just an extra check, make sure we have proper min/max set.  Accounts for empty data better.
    if ((maxValue) > (minValue)) {
      for (let i = 0; i < widthPixel; i++) {
        // This is setting the X axis time value for the pixel we are about to draw.
        // Note there should be one X value per pixel.
        nAxisValue = (i + 1) * nStep + (Date.parse(minValue));
        for (let j = 0; j < originalDataSeries.length; j++) {
          nCount = 0;
          nValue = 0;
          // Traverse the series adding up every point represented by the X axis value
          // Example if one pixel = 5 seconds, this would add up all values in that 5 seconds.
          while (nIndex[j] < originalDataSeries[j].length && Date.parse(originalDataSeries[j][nIndex[j]][0]) < nAxisValue && Date.parse(originalDataSeries[j][nIndex[j]][0]) <= Date.parse(maxValue)) {
            if (originalDataSeries[j][nIndex[j]][1] != null) {
              nCount++;
              if (nCount == 1) {
                nValue = originalDataSeries[j][nIndex[j]][1];
              }
              else {
                if (originalDataSeries[j][nIndex[j]][1] > nValue) {
                  nValue = originalDataSeries[j][nIndex[j]][1];
                }
              }
            }
            nIndex[j]++;
          }
          if (nCount > 0) {
            if (nCount == 1) {
              // If this is only one point, push the exact x and y values.
              coercedData[j].push([originalDataSeries[j][nIndex[j] - 1][0], nValue]);
            }
            else {
              // If this is multiple points, round down in seconds (so not 4.3323423 seconds) and add the average of the points represented.
              coercedData[j].push([new Date(Math.floor(nAxisValue)), nValue]);
            }
          }
          else {
            // we don't want to push a null here, just leave it alone.
            //dsOut[j].push([nAxisValue - nStep, null]);
          }
        }
      }
    }
    //return orginalData;
    return coercedData;
  }
   // function for the coercion logic ends here

  getValue(series, shownSensorType, converterData, sensorList) {
    const unitTypeSource = _.filter(sensorList, (sensor) => {
      return series.seriesName === sensor.wellName + ' - ' + sensor.sensorOriginalName;
    });
    let unitType = unitTypeSource[0] ? unitTypeSource[0].unitType: "";
    let unit;
    this.sensorInfo.forEach((sensorList) => { // getting the unit type for charts tooltip
        sensorList.sensorUnits.forEach((sensorUnit) => {
          if (sensorUnit.value === (converterData[unitType.toLowerCase() + 'Unit'])) {
            unit = sensorUnit.label;
          }
        });
    });
    return series.value[1].toFixed(2) + ' ' + unit;

  }
  fillDisplayName(sensorList) {
    sensorList.forEach((sensor) => {
      this.userPrefService
        .getSensorUpdatedName(sensor.displayName, sensor.wellName)
        .pipe(first())
        .subscribe((res: any) => {
          if (res && res.length > 0) {
            sensor.displayName = res[0]['renamedSensorLabel'];
          }
        });
    });
    return sensorList;
  }

  // function to combine multiple responses data starts here
  combineResponseData(response) { //if the data points are more than 30,000 we need to combine the responses
    const combinedResponse = [];
    response.forEach((res) => {
      for (let i = 0 ; i < res.length - 1 ; i++){
        res[0].logData.data = _.concat(res[0].logData.data, res[i + 1].logData.data);
      }
      combinedResponse.push(res[0]);
    });
    return combinedResponse;
  }
  // function to combine multiple responses data ends here

  /**
   * This function starts live pooling
   */

  startDispatchForLiveData(payloadReq, pollInterval, converterData, stopFlag,liveDurationMilliSec, coercionSettings?: any) {
    if (this.pollIntervalLocal) {
      clearInterval(this.pollIntervalLocal);
    }
    if (stopFlag === false) {
      payloadReq['converterData'] = converterData;
    this.chartStore.dispatch(new GetLiveRequest(payloadReq));
    this.chartStore.select((state: IChartstate) => state['chart']['LiveRequest'])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((logs: any) => {
      if(logs.length >0){
        if (this.pollIntervalLocal) {
          clearInterval(this.pollIntervalLocal);
        }
        this.pollIntervalLocal = setInterval(() => {
          const xAxisMinTimeStamp = this.graphUtilService.getConvertedTime(
            Date.now() - liveDurationMilliSec,
            converterData
          );
          let requestList = [];
          const dateArray = [];
          if (
            this.isOnline &&
            this.echartInstance !== null &&
            this.echartInstance !== undefined
          ) {
            for (const item of payloadReq['requestlist']) {
              const dateRange = this.getDateRange(
                item,
                this.echartInstance,
                converterData
              );
              dateArray.push({ dtRange: dateRange, sensor: item });
            }
            // Todo : make a single function to create the payload
            requestList['sensorsWithDateRange'] = dateArray;
            requestList['xAxisStamp'] = xAxisMinTimeStamp;
            requestList['liveDuration'] = payloadReq['liveDuration'];
            requestList['converterData'] = converterData;
            requestList['coercion'] = coercionSettings;
            this.chartStore.dispatch(new GetLivePool(requestList));
          }
        }, pollInterval);
  }
  });

  }
}
// this method is called when network change is detected
  setIsAlive(connection) {
    this.isOnline = connection;
  }

  // this method is called when echart instance is created
  setEchartInstance(echartInstance) {
    this.echartInstance = echartInstance;
  }

  stopLivePool(){
    if (this.pollIntervalLocal) {
      clearInterval(this.pollIntervalLocal);
    }
  }


    //   this.chartStore.dispatch(new GetWaitIndicator(false));


  // Function to get sensors for export based on chart settings starts here
  getSensorForExport(chartData, sensorList) {
    const sensorForExport = [];
    // const sensorList = [];
    // axesList.forEach((axis) => {
    //   if (axis.checked) {
    //     axis.sensorList.forEach((sensor) => {
    //       sensorList.push(sensor);
    //     });
    //   }
    // });
    chartData.forEach((chartSeries) => {
      const sensorName = chartSeries.sensorOriginalName;
      const wellName = chartSeries.wellName;
      const filteredSensor = _.filter(sensorList, (sensorData) => {
        return sensorData.sensorOriginalName === sensorName && sensorData.wellName === wellName
      });
      if (filteredSensor.length > 0) {
        sensorForExport.push(filteredSensor[0]);
      }
    });
    return sensorForExport;
  }
   // Function to get sensors for export based on chart settings ends here

   // Function to format the response data to chart datasource starts here
  buildChartDataSource(response , sensorList) {
    const chartDataSource = [];
    let displayType;
    // tslint:disable-next-line:forin
    for (const item of response) {
      this.sensorInfo.forEach((sensor) => {
        if (sensor.sensorType.toLowerCase() === item.typeService.split(' ').join('').toLowerCase()){
        displayType = sensor.axisName;
        }
      });
      const displayName = this.getDisplayName(item.sensorName, item.nameWell, sensorList);
      const obj = {
        name: item.sensorName,
        displayName,
        series: item.logData.data,
        type: item.typeService,
        value: item.sensorName,
        wellName: item.nameWell,
        unitType: item.typeService,
        displayType
      };
      chartDataSource.push(obj);

    }
    return chartDataSource;
  }

  getLocalDisplayName(sensorName, wellName, sensorList) {
    for (let i = 0; i < sensorList.length; i++) {
      const sensor = sensorList[i];
      if (sensor.sensorName === sensorName && sensor.wellName === wellName) {
        return sensor.displayName;
      }
    }
  }

  getIndexBasedOnSelection(sensor, axesList){
    for (let i = 0; i < axesList.length; i++){
      for (let j = 0; j < axesList[i].sensorList.length; j++) {
        if (axesList[i].sensorList[j].sensorName === sensor.name &&
          axesList[i].sensorList[j].wellName === sensor.wellName){
          return i;
        }
      }
    }
    return -1;
  }

  sortedDataSource(chartDataSource) {
    return chartDataSource.sort((a, b) => a.name.localeCompare(b.name));
  }

  getSortedSeries(seriesData){
    seriesData.forEach((series) => {
      const uniqSet = new Set(series.data);
      const uniqArray = Array.from(uniqSet);
      series.data = uniqArray.sort((a, b) => {
        return a[0] - b[0];
      });
    });
    return seriesData;
  }

  getLogs(responseArray) {
    let responseData = [];
    responseData = _.map(responseArray, (response) => {
      if (response['response']['log']) {
        return response['response']['log'];
      }
    });
    return responseData;
  }
  getDisplayName(sensorName, wellName, sensorList) {
    for (let i = 0; i < sensorList.length; i++) {
      const sensor = sensorList[i];
      if (sensor.sensorOriginalName === sensorName && sensor.wellName === wellName) {
        return sensor.displayName;
      }
    }
  }
  getSeriesIndexForResponse(item, seriesData, sensorList) {
    // if (item && this.echartsInstance && this.echartsInstance._model && this.echartsInstance._model.option && this.echartsInstance._model.option.series
    //   && this.echartsInstance._model.option.series.length > 0) {
    //   const series = this.echartsInstance._model.option.series;
    //   return _.findIndex(series, { name: item.nameWell + ' - ' + this.getDisplayName(item.sensorName, item.nameWell) });
    // }
    if (
      item &&
      seriesData &&
      Array.isArray(seriesData) &&
      seriesData.length > 0
    ) {
      return _.findIndex(seriesData, {
        name: item.nameWell + ' - ' + item.sensorName
      });
    }
    return -1;
  }

  getMappedColor(wellSensorName, mappedSensorColor) {
    const sensorColor = _.filter(mappedSensorColor, (item) => {
      return item.wellSensorName === wellSensorName;
    });
    if (sensorColor[0]) {
      return sensorColor[0]['mappedColor'];
    } else {
      return 'black';
    }
  }

  getSeriesIndex(item, echartsInstance) {
    if (
      echartsInstance &&
      echartsInstance._model &&
      echartsInstance._model.option &&
      echartsInstance._model.option.series &&
      echartsInstance._model.option.series.length > 0
    ) {
      const series = echartsInstance._model.option.series;
      return _.findIndex(series, {
        name: item.wellName + ' - ' + item.sensorName
      });
    }
  }

  getSeriesIndexInChartDataSource(item, chartDataSource) {
    if (
      item &&
      chartDataSource &&
      Array.isArray(chartDataSource) &&
      chartDataSource.length > 0
    ) {
      return _.findIndex(chartDataSource, (source) => {
        return source.wellName + ' - ' + source.value === item.name;
      });
    }
    return -1;
  }

  /**
   * Returns date range from last data point to current date
   */
  getDateRange(item, echartsInstance, converterData) {
    const index = this.getSeriesIndex(item, echartsInstance); // -1 if the series is already not present in the charts
    let dateRange: DateRange;
    let data = [];
    if (index >= 0) {
      data = echartsInstance._model.option.series[index].data;
    } else {
      data = []; // To handle the sensors which can have data for new request
    }

    if (data.length > 0) {
      const lastDataTime = _.last(data)[0];
      const startTime = convertToNewTimeZone(new Date(lastDataTime), converterData.timeUnit, moment.tz.guess());
      dateRange = {
        start: new Date (startTime.setSeconds(startTime.getSeconds() + 1)),
        end: new Date()
      };
    }
    else { // If series is not already present in the chart, but can have data for next pool
      dateRange = {
        start: new Date(Date.now() - 10000), // Takes for last 10 sec by default
        end: new Date()
      };
    }
    return dateRange;
  }

  createChartYAxies(axesList) {
    const tempList = [];
    //To create multiple axes if the number of axes selected in settings is not empty
    if (axesList.length > 0) {
      for (let i = 0; i < axesList.length; i++) {
        let dataMin = 'dataMin';
        let dataMax = 'dataMax';
        if (!axesList[i].autoRange) {
          dataMin = axesList[i].min;
          dataMax = axesList[i].max;
        }
        tempList.push({
          type: 'value',
          scale: true,
          min: dataMin,
          max: dataMax,
          position: 'left',
          offset: i * 70,
          axisLine: {
            lineStyle: {
              color: axesList[i].axisColor
            },
            axisLine: {onZero: false}
          },
          axisLabel: {
            formatter: ((value) => {
              if (value || value === 0) {
                return Math.round(value * 100) / 100;
              }
            })
          },
          splitLine: {
            show: true
          },
        });
      }
      return tempList;
    }
    else {
      tempList.push({
        type: 'value',
        boundaryGap: [0, '100%'],
        scale: true,
        min: 'dataMin',
        max: 'dataMax',
        offset: 0,
        axisLabel: {
          formatter: ((value) => {
            if (value || value === 0) {
              return  Math.round(value * 100) / 100;
            }
          })
        },
        splitArea: {
          show: false
        },
        splitLine: {
          show: true
        }
      });
      return tempList;
    }
  }

  // code to add the axis number starts here
  getAxisCount(axisList, selectedAxis) {
    // Logic to get the deleted axis number
    let tempList = [];
    let count = 2;
    tempList = _.filter(axisList, (axis) => {
      return axis.value.toLowerCase().includes(selectedAxis['name'].toLowerCase());
    });
    tempList = _.sortBy(tempList, ['count']);
    for (let i = 1; i <= tempList.length; i++) {
      if (i !== tempList[i - 1].count) {
        count = i;
        break;
      } else {
        count = tempList.length + 1;
      }
    }
    return count;
  }
  // code to add the axis number ends here
  getSensorByUnitType(sensorType, sensorList) {
    const tempSensorList = [];
    sensorList.forEach((sensor) => {
      if (sensor.unitType === sensorType) {
        tempSensorList.push({ label: sensor['sensorName'] + ' (' + sensor['wellName'] + ')', id: sensor['sensorName'] + ' - ' + sensor['wellName'], value: sensor, checked: false});
      }

    });
    return tempSensorList;
  }

}
