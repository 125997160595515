import { IMeasurementClass, IUnit } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";
import { CubicFeetPerSec } from "./CubicFeetPerSec";

export class CubicMetersPerDay implements IUnit<IMeasurementClass> {
  static readonly Instance: CubicMetersPerDay = new CubicMetersPerDay();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return CubicFeetPerSec.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(2446.575545664, 0.0, 0.0, 1);
  }
}
