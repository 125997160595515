<p-dialog [(visible)]="showNoDataDailog" [draggable]="false" [showHeader]="false" [blockScroll]="true" [modal]="true"
  [baseZIndex]='10000' [breakpoint]="500" [style]="{'min-width': '200px'}" [responsive]="true" [contentStyle]="{'overflow':'auto'}"
  appendTo="body">
  <div class="error-dialog">
    <div class="col-md-12">
      <p class="header-text">Data not available for below Sensors</p>
      <div>
        <p *ngFor="let sens of noDataSensors" class="normal-text">{{sens.wellName}}-{{sens.displayName}} </p>
      </div>
    </div>
    <div class="col-md-12 button-div">
      <button pButton type="button" class="confirmation-button" (click)="closeErrorDialog()" label="OK">OK</button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="showSupportDialog" [draggable]="false" [showHeader]="false" [blockScroll]="true" [modal]="true"
  [breakpoint]="500" [style]="{'min-width': '200px'}" [responsive]="true" [contentStyle]="{'overflow':'auto'}" appendTo="body"
  [baseZIndex]="10001">
  <div class="error-dialog">
    <div class="col-md-12">
      <p class="header-text">Export is available in the desktop application.</p>
    </div>
    <div class="col-md-12 button-div">
      <button pButton type="button" class="confirmation-button" (click)="closeSupportDialog()" label="OK">OK</button>
    </div>
  </div>
</p-dialog>

<div class="loading-view" *ngIf="(loaderForNoWellData === undefined || !loaderForNoWellData)">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="row">
  <div class='col-md-8'>
    <div class="chart-section sm-height">
      <div class='no-sensor-selected-display' *ngIf='selectedTab === viewOptions.oneMonth'>
        No well selected.
      </div>
      <div class='history-section' *ngIf=' selectedTab === viewOptions.oneMonth'>
        <app-history-view (showDateRangeSelectDialog)=showDateRangeSelectHandler($event)></app-history-view>
      </div>
      <div class='no-sensor-selected-display' *ngIf=' selectedTab === viewOptions.presTemp'>
        No well selected.
      </div>

      <div class="chart-container" [ngClass]="{'no-header':noDataSensors.length<=0,'header': noDataSensors.length>0}">
        <!-- presTempSelectorItems is used to hide the chart actions when there is no sensors selected for view-->
        <div *ngIf="presTempSelectorItems.length > 0"  class="row chart-header">

          <div class='col-xs-12 col-md-12 col-lg-12 mb-2' *ngIf='isNoDataSensors()'>

            <a class='no-data-sensor-link' (click)='openNoDataDailog()'>
              Sensors with data unavailable
            </a>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">

            <app-display-selector (sendSelectedValue)='togglePresTemp($event)' [items]="presTempSelectorItems"
              [disabled]="showWaitIndicator || turningOffLiveData || showExportWaitIndicator || showWaitIndicatorForAxis">
            </app-display-selector>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 button-group">
            <div *ngIf='isDesktopDevice' class='display-selector'>
              <button [ngClass]='{active: isLive}' (click)='onClickLive()'
                [disabled]='turningOffLiveData || showWaitIndicator || showExportWaitIndicator || showWaitIndicatorForAxis'
                class='option-button'>
                Live
              </button>
              <button [ngClass]='{active: !isLive}' (click)='onClickHistorical()'
                [disabled]='turningOffLiveData || showWaitIndicator || showExportWaitIndicator || showWaitIndicatorForAxis'
                class='option-button'>
                Historical
              </button>
            </div>
            <div *ngIf='!isDesktopDevice' class='display-selector'>
              <button [ngClass]='{active: isLive}' (click)='onClickLive()'
                [disabled]='turningOffLiveData || showWaitIndicator || showExportWaitIndicator || showWaitIndicatorForAxis'
                class='option-button-no-hover'>
                Live
              </button>
              <button [ngClass]='{active: !isLive}' (click)='onClickHistorical()'
                [disabled]='turningOffLiveData || showWaitIndicator || showExportWaitIndicator || showWaitIndicatorForAxis'
                class='option-button-no-hover'>
                Historical
              </button>
            </div>
            <div *ngIf='!isDesktopDevice' (click)='showSupportMessage()'>
              <button class='icon-button-export' title="Export Data" [disabled]=true>
                <img src="assets/ic-export.svg" alt="Export CSV">
              </button>
            </div>
            <div *ngIf='isDesktopDevice'>
              <button class='icon-button-export' *ngIf="isLive" title="Export Data" (click)="exportConfirmWarning()"
                [disabled]='exportExcel || showWaitIndicator || !isChartExport'>
                <img src="assets/ic-export.svg" alt="Export CSV">
              </button>
            </div>
            <div [ngClass]="showWaitIndicatorForAxis ? 'disble-chart-setting':'enable-chart-setting'">
              <app-charts-settings (axesSelected)="onAxesSelected($event)" [sensorList]='sensorList'>
              </app-charts-settings>
            </div>
          </div>
        </div>

        <div class="line-chart">
          <div *ngIf="presTempSelectorItems.length > 0" class="selection-size-button-container row">
            <div>
              <div *ngIf="isLive">
                <span class="display">Display by&nbsp;&nbsp;</span>
                <span *ngIf='isDesktopDevice' class="display-flex">
                  <button class="selection-size-button padding-5" [ngClass]="{active: step === selectedStep}"
                    *ngFor="let step of steps"
                    [disabled]='turningOffLiveData || showWaitIndicator || showExportWaitIndicator || showWaitIndicatorForAxis'
                    (click)="selectStepSize(step)">
                    {{step}}
                  </button>
                </span>
                <span *ngIf='!isDesktopDevice' class="display-flex">
                  <button class="selection-size-button-no-hover padding-5" [ngClass]="{active: step === selectedStep}"
                    *ngFor="let step of steps"
                    [disabled]='turningOffLiveData || showWaitIndicator || showExportWaitIndicator || showWaitIndicatorForAxis'
                    (click)="selectStepSize(step)">
                    {{step}}
                  </button>
                </span>
              </div>
              <div *ngIf='!isLive' class='row one-month-date-range'>
                <div>
                  <button class="btn-duration btn-secondary" (click)='openDateRangeDialog()'
                    [disabled]='showWaitIndicator || showExportWaitIndicator || showWaitIndicatorForAxis'>Select
                    Duration</button>
                </div>

                <div class='current-range' *ngIf="!isLive">
                  <span class="display">Current Selection:</span>
                  <span class='display'>{{ displayDateRange?.start | date: 'dd-MMM-yy'}}</span>
                  <span class="display">to</span>
                  <span class='display'>{{ displayDateRange?.end | date: 'dd-MMM-yy'}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="canvas-wrapper">
            <!-- <div class='sub-select-message' *ngIf="((requestSensorList && requestSensorList.length === 0 ))">
              Please select at least one {{ displayType.toLowerCase() }} sensor to view data</div> -->
            <div class='sub-select-message'
              *ngIf="presTempSelectorItems.length <= 0 || (shownSensorType === 'Multiple' && allAxisList && allAxisList.length === 0)">
              Please select at least one sensor to view data</div>
            <div class="loading-view" *ngIf="(showWaitIndicator || showWaitIndicatorForAxis) && presTempSelectorItems.length > 0 ">
              <app-loading-spinner></app-loading-spinner>
            </div>
            <div class="loading-view-export" *ngIf="showExportWaitIndicator">
              <app-loading-spinner [exportData]="true"></app-loading-spinner>
            </div>

            <div class="chart-loading-view" *ngIf="turningOffLiveData">
              <span class="spinner-container live-data-off">Turning Off Live Chart Updates...</span>
            </div>

            <div class="data-chart" echarts [options]="chartOption" [detectEventChanges]="false"
              (chartDataZoom)="onChartZoom($event)" [autoResize]="true" id="lineChart"
              (chartInit)="onChartInit($event)"></div>
          </div>
        </div>

        <div *ngIf="isLive && presTempSelectorItems.length > 0" class="view-history-message display">
          <span>Switch to Historical Mode to View Historical Data.</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf='loadingView && showLoading' class='loading-view-dashbord'>
    <app-loading-spinner [dashbordLoading]='true'></app-loading-spinner>
  </div>
  <div class="col-md-4">
    <app-live-readings [selectedTile]="'charts'" [selectedSensor]='selectedSensor' [mappedColorCharts]='mappedColor'>
    </app-live-readings>
  </div>
</div>

<app-date-range-select-dialog-interactive [isOpen]='showDateRangeDialog'
  (dateRangeChangedEventInteractive)='onDateRangeChangedHistoric($event)' (hideDialog)="hideDateRangeDailog($event)">
</app-date-range-select-dialog-interactive>
<!-- Global Error Section: Start -->
<!-- Shows 201 data retrieval errors -->
<p-toast position="center" key="ToastMessageKeyCharts" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
  [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
      <h3>{{message.summary}}</h3>
      <p>{{message.detail}}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 login-bck-btn" style="text-align: center">
        <button type="button" pButton (click)="cancelErrorWarnPopup()" label="Close"
          class="ui-button-success custom-button-danger">
          Ok
        </button>
      </div>
    </div>
  </ng-template>
</p-toast>
<!--Warning popup to alert user on export button click-->
<p-toast key="exportConfirm" position="center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
  [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div>
      <p>{{message.detail}}</p>
    </div>
    <span>
      <input id="float-input" type="text" size="28" pInputText [(ngModel)]="exportFileName">
    </span>
    <div class="multiple-btns chart-export">
      <button DefaultFocus class="btn-secondary btn-no" (click)="cancelErrorWarnPopup()">Cancel</button>
      <button class="btn-secondary btn-yes" [disabled]="disableExportBtn()" (click)="getExcelFile()">Export</button>
    </div>
  </ng-template>
</p-toast>
