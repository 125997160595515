import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-alert-rule',
  templateUrl: './edit-alert-rule.component.html',
  styleUrls: ['./edit-alert-rule.component.scss']
})
export class EditAlertRuleComponent implements OnInit {
  @Input() degreeRequired: boolean;
  @Input() upperLimit;
  @Input() lowerLimit;
  @Input() sensorUnit: any;
  @Input() isDuplicateAlarm: boolean = false;
  @Output() saveChanges: EventEmitter<{ [key: string]: any }> = new EventEmitter();
  @Output() isDuplicateAlarmEdit: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  lower: number;
  upper: number;
  valid: boolean;
  limit: boolean;
  constructor() { }

  ngOnInit() {
  }
  limitChangedCheck(value) {
    this.isDuplicateAlarm = false;
    this.isDuplicateAlarmEdit.emit();
    this.limit = this.checkLimit(value);
 }
  limitChanged(value: string, type: string) {
    const changedValue = !value.length ? null : Number(value);

    if (isNaN(changedValue) && changedValue !== null) {
      return this.valid = false;
    }
    this[type] = changedValue;
    this.valid = this.isValid(type)
  }

  checkLimit(value){
    // changed from 14 to 13 to avoid convertion from float to decimal for pressure values
    if ((String(value).length > 13) || (String(value).length > 13)){
      return true;
    }
    return false;
  }

  saveAlert() {
    this.saveChanges.emit({
      lower: this.lower || this.lower === null || this.lower === 0 ? this.lower : this.lowerLimit,
      upper: this.upper || this.upper === null || this.upper === 0 ? this.upper : this.upperLimit
    });
  }

  isValid(type: string): boolean {
    let valid = false;
    switch (type) {
      case 'upper':
        if (this.upper === null) {
          valid = (this.lower || this.lowerLimit) && this.lower !== null
            ? true : false;
        } else if (this.upper || this.upper === 0) {
          if (!isNaN(this.lower) && this.lower !== null) {
            valid = this.upper !== this.upperLimit
            && this.upper > this.lower || (this.lower !== this.lowerLimit && this.upper > this.lower);
          } else if (this.lower === undefined && this.lowerLimit !== null) {
            valid = this.upper !== this.upperLimit
            && this.upper > this.lowerLimit;
          } else valid = this.upper !== this.upperLimit;
        }
        break;
      case 'lower':
        if (this.lower === null) {
          valid = (this.upper || this.upperLimit) && this.upper !== null
            ? true : false;
        } else if (this.lower || this.lower === 0) {
          if (this.upper !== null && !isNaN(this.upper)) {
            valid = (this.lower !== this.lowerLimit
            && this.lower < this.upper) || (this.upper !== this.upperLimit && this.lower < this.upper);
          } else if (this.upper === undefined && this.upperLimit !== null) {
            valid = this.lower !== this.lowerLimit
            && this.lower < this.upperLimit;
          } else valid = this.lower !== this.lowerLimit;
        }
        break;
      default:
        break;
    }
    return valid
  }

}
