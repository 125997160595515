<div *ngIf='alert && alert.AlarmDetail ' class='alert-rule ui-g'>
  <div class='ui-g col-sm-6 col-md-6 col-lg-6 sensor-label black-label'>
    <div>{{ displayName }}</div>
    <div class="alertWellName">({{ alert.WellName }})</div>

    <div class='ui-g-12 delet-alert' *ngIf='editAlertMode'>
      <button
        tabindex="0"
        class='ui-button delete-alert-btn primary-button'
        label='Delete'
        type='button'
        (click)='deleteAlert(alert)'>Delete
      </button>
    </div>
  </div>
  <div class='ui-g col-sm-6 col-md-6 col-lg-6 min-max-label black-label'>
    <div class='default-min-max' *ngIf='!editAlertMode'>
      <div class="alertMinMax">
        <span>Min: </span>
        <div class="alertMinMaxVal" *ngIf='!editAlertMode && alert.AlarmDetail'>
          {{ lowerLimit === null ? "N/A" : lowerLimit }}
          <span class="alertMinMaxSensor" *ngIf='lowerLimit !== null'>
            {{sensorUnit.label}}
          </span>
        </div>
      </div>
      <div class="alertMinMax">
        <span>Max: </span>
        <div class="alertMinMaxVal" *ngIf='!editAlertMode && alert.AlarmDetail'>
          {{ upperLimit === null ? "N/A" : upperLimit }}
          <span class="alertMinMaxSensor" *ngIf='upperLimit !== null'>
            {{sensorUnit.label}}
          </span>
        </div>
      </div>
      <div class='col-sm-12 col-md-12 col-lg-6 rule'>
      </div>
      <div *ngIf='!saving'>
        <a (click)='editAlert()'
          class='edit-rule'>
          <img src='assets/master-page/ic-edit-small.png' />
        </a>
      </div>
      <div *ngIf='saving || deleting'>
        <div *ngIf='deleting' class='message'>Deleting</div>
        <div *ngIf='saving' class='message'>Saving</div>
        <img src="../../../../assets/icons/icon-export-in-progess.svg" class='spinner'>
      </div>
    </div>
    <div *ngIf='editAlertMode' class='edit-form'>
      <app-edit-alert-rule [degreeRequired]=''
        [upperLimit]='upperLimit' [lowerLimit]='lowerLimit' [isDuplicateAlarm]='isDuplicateAlarm' (isDuplicateAlarmEdit)='alertModified()'  [sensorUnit]='sensorUnit'
        (cancel)='cancelEditAlert()' (saveChanges)='saveAlert($event)'>
      </app-edit-alert-rule>
    </div>
  </div>
</div>
