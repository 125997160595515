<p-dialog [(visible)]="showNoDataDailog" [draggable]="false" [showHeader]="false" [blockScroll]="true" [modal]="true" [baseZIndex]='10000' [breakpoint]="500" [style]="{'min-width': '200px'}" [responsive]="true" [contentStyle]="{'overflow':'auto'}" appendTo="body">
  <div class="error-dialog">
    <div class="col-md-12">
      <p class="header-text">Data not available for below Wells</p>
      <div>
          <p *ngFor="let well of noDataWels" class="normal-text">{{well.wellName}}</p>
      </div>
    </div>
    <div class="col-md-12 button-div">
      <button pButton type="button" class="confirmation-button" (click)="closeErrorDialog()" label="OK">OK</button>
    </div>
  </div>
</p-dialog>

<p-dialog
[transitionOptions]="'0ms'"
[(visible)]="isOpen"
[modal]="true"
[responsive]="true"
[focusOnShow]="false"
(onHide)="cancel()"
[breakpoint]="767"
[draggable]="false"
[style]="{'min-width': sensors.length > 0 ? '600px':'350px'}"
[positionLeft]="sensors.length > 0 ? '100':'200'"
[maximizable]="false"
[baseZIndex]="10000"
[resizable]="false"
[blockScroll]="true"
[contentStyle]="{'max-height':'calc(80vh - 235px)','min-height':'150px','width':'auto'}"
[className]="sensors.length > 0 ? 'move-class min-max-width-margin' : ''">
<p-header>
  Filters
</p-header>
<div class="ui-g" *ngIf='wells.length==0 && !(noDataWels.length > 0) || isapiInprogress'>
  <div class='global-spinner-container'>
  <div class='global-spinner'></div>
  <div class ='message-loading'>Retrieving wells from server</div>
  </div>
</div>
  <div class="ui-g" *ngIf="!isapiInprogress">
    <div [ngClass]="sensors.length > 0 ? 'ui-md-6' : 'ui-g-12'" *ngIf="wells && wells.length > 0 && isOpen">
      <app-filter-list
        (itemChecked)="toggleWells($event)"
        [filterList]="wells"
        [filterCategory]="'Well'"
        [selectedItems] = "selectedWells">
      </app-filter-list>
    </div>
    <div [ngClass]="sensors.length > 0 ? 'ui-md-6' : 'ui-g-12'" *ngIf="sensors && sensors.length > 0">
      <app-filter-list class="display-inline-flex"
        [filterList]="sensors"
        (itemChecked)="toggleSensors($event)"
        [filterCategory]="'Sensor'"
        [selectedItems] = "selectedSensors">
      </app-filter-list>
    </div>
  </div>
<p-footer>
  <div class='no-data' *ngIf="noDataWels.length > 0 && !isapiInprogress">
    <a class='no-data-well-link' (click)='openNoWellDataDailog()'>
        Wells with data unavailable
    </a>
  </div>
  <button class="btn-secondary btn-cancel" (click)="cancel()">Cancel</button>
  <button [disabled]='!(selectedSensors.length > 0) || isapiInprogress' class="primary btn-apply" (click)="applyFilter()">Apply</button>
</p-footer>
</p-dialog>
