// Service for graph functions
import { Injectable } from '@angular/core';
import sensorInfo from '../../assets/data/sensorTypeConfig.json';
import {
  DataSource,
  DataSourceDataSet,
  IDataSensor,
  IPositions
} from '../models/lineGraphData';
import { ConversionService } from '../shared/services/conversion.service';
const TIME_DATE = 'Time & Date';
const TEMPERATURE = 'Temperature';
@Injectable({
  providedIn: 'root'
})
export class GraphUtilService {
  sensorInfo: any;

  constructor(public conversionService: ConversionService) {
    this.sensorInfo = sensorInfo.sensorData.filter((item:any) => { // getting sensorinfo from config file
      return item.settings === true;
    });
  }

  getPositions(curveInfo): IPositions {
    const positionData: IPositions = {
      temperature: '',
      pressure: '',
      dateTime: ''
    };
    for (const curveItem in curveInfo) {
      this.sensorInfo.forEach(element => { // assigning position values dynamically
        if (curveInfo[curveItem]['unitType'] === TIME_DATE) {
          positionData.dateTime = curveItem;
        } else if (curveInfo[curveItem]['unitType'].split(" ").join("").toLowerCase() === element.sensorType) {
          positionData[element.sensorType] = curveItem;
        }
        else{
          positionData[element.sensorType] = '';
        }
      });
    }
    return positionData;
  }

  setUpResponseObject(response, positions: IPositions, item) {
    const responseFromService = response['logData'];
    responseFromService['sensorName'] = item['displayName'];
    responseFromService['uidWell'] = item['uidWell'];
    responseFromService['nameWell'] = response['nameWell'];
    responseFromService['uid'] = item['uid'];
    responseFromService['sensorOriginalName'] = item['sensorName'];
    if (!responseFromService['sensorName']) {
      responseFromService['sensorName'] = item['sensorName'];
    }
    let typeService;
    let displayType;
    this.sensorInfo.forEach((sensor) => { //assigning the unit type for export
      if(positions[sensor.sensorType] !== ''){
        typeService = sensor.sensorType
        displayType = sensor.axisName;
      }

    });
    responseFromService['typeService'] = typeService;
    responseFromService['displayType'] = displayType;
    return responseFromService;
  }

  buildData(
    item,
    positions: IPositions,
    response,
    dataSource: DataSource[]
  ): DataSourceDataSet {
    // Make an initial object to store data
    const itemResponse: DataSource = {
      sensorName: '',
      wellName: '',
      typeService: '',
      responseFromService: '',
      responseFromServiceConverted: '',
      responseFromServiceToBeConverted: ''
    };

    const dataSourceData: DataSourceDataSet = {
      dataSource: [],
      index: 0
    };

    // Find if sensor object is already present in array
    const isDataAlreadyPresent =
      dataSource.filter((dataSet) => {
        return dataSet.sensorName === item['sensorName'] && dataSet.wellName === item['wellName'];
      }).length > 0;

    if (isDataAlreadyPresent) {
      // If already present, update the responseFromService
      // and find what needs to be converted
      /** <---UPDATE--> */
      const position: number = this.getPositionsFromData(
        dataSource,
        item['sensorName'],
        item['wellName']
      );
      const lengthDataPresent =
        dataSource[position].responseFromService['data'].length;
      const isGreater = response['logData']['data'].length > lengthDataPresent;
      if (isGreater) {
        dataSource[position].responseFromService['data'] =
          response['logData']['data'];
        dataSource[
          position
        ].responseFromServiceToBeConverted = this.getDataConverted(
          lengthDataPresent,
          dataSource[position].responseFromService,
          true
        );
      } else {
        dataSource[
          position
        ].responseFromServiceToBeConverted = this.getDataConverted(
          lengthDataPresent,
          dataSource[position].responseFromService,
          false
        );
      }
      dataSourceData.index = position;
    } else {
      /** <---ADD--> */
      // If not present update the responseFromService
      // Put the same in what needs to be converted
      itemResponse.sensorName = item['sensorName'];
      itemResponse.wellName = item['wellName'];
      response = this.setUpResponseObject(response, positions, item);
      itemResponse.typeService = response['typeService'];
      itemResponse.responseFromService = response;
      itemResponse.responseFromServiceToBeConverted = this.getDataConverted(
        0,
        response,
        true
      );
      dataSource.push(itemResponse);
      dataSourceData.index = dataSource.length - 1;
    }
    dataSourceData.dataSource = dataSource;
    return dataSourceData;
  }

  getPositionsFromData(dataSource: DataSource[], sensorName: string, wellName: string): number {
    let positionData = 0;
    for (const item in dataSource) {
      if (dataSource[item].sensorName === sensorName && dataSource[item].wellName === wellName) {
        positionData = parseFloat(item);
        break;
      }
    }
    return positionData;
  }

  getDataConverted(length: number, response, dataAdd: boolean) {
    const dataSetConvert = [];
    const responseSent = {
      data: [],
      mnemonicList: [],
      sensorName: '',
      wellName: '',
      sensorOriginalName: '',
      typeService: '',
      unitList: []
    };
    const responseUsed= JSON.parse(JSON.stringify(response));
    if (dataAdd) {
      for (const itemData in responseUsed['data']) {
        if (parseFloat(itemData.toString()) >= length) {
          dataSetConvert.push(responseUsed['data'][itemData]);
        }
      }
      responseSent.data = dataSetConvert;
    }
    responseSent.mnemonicList = response['mnemonicList'];
    responseSent.sensorName = response['sensorName'];
    responseSent.wellName = response['nameWell'];
    responseSent.sensorOriginalName = response['sensorOriginalName'];
    responseSent.typeService = response['typeService'];
    responseSent.unitList = response['unitList'];
    return responseSent;
  }

  /**
   * @name: buildSensorDataforChart
   * @description This method builds sensor log data in an array format.
   * Response mimics the one that is recieved when requested for one chart
   * @param  {Number} range
   * @returns Array of sensors with agregated time log data.
   */
  buildSensorDataforChart(logs: any) {
    let logSensors = [];
    let finalDataSource;
    for (const log of logs) {
      for (const logItem of log) {
        const tempLogSensors = [];
        if (logItem.logCurveInfo && logItem.logCurveInfo.length > 0) {
          logItem.logCurveInfo.forEach((value, index) => {
            const logCurveInfoTemp = [ logItem.logCurveInfo[0] ];

            if (
              value.unitType === 'Temperature' ||
              value.unitType === 'Pressure'
            ) {
              logCurveInfoTemp.push(logItem.logCurveInfo[index]);
              tempLogSensors.push({
                index: index,
                sensorName: value.mnemonic.value,
                logData: { data: [] },
                mnemonicList: [],
                typeService: value.unitType,
                nameWell: logItem.nameWell,
                decimalPlace: logItem.decimalPlace,
                logCurveInfo: [
                  ...logCurveInfoTemp
                ]
              });
            }
          });
        }
        logItem.logData.data.forEach((dataValues) => {
          tempLogSensors.forEach((sensor) => {
            if (dataValues[sensor.index] != null && dataValues[sensor.index] !== '') {
              const dataPoint = [dataValues[0], dataValues[sensor.index]];
              sensor.mnemonicList = [
                logItem.logData.mnemonicList[0],
                logItem.logData.mnemonicList[sensor.index]
              ];
              sensor.unitList = [
                logItem.logData.unitList[0],
                logItem.logData.unitList[sensor.index]
              ];

              sensor.logData.data.push(dataPoint);
            }
          });
        });
        logSensors = [...logSensors, ...tempLogSensors];
      }
    }
    finalDataSource = this.buildDataSourceRemoveDup(logSensors);
    return finalDataSource;
  }

  /**
   * @name: buildSensorDataforChart
   * @description This method builds sensor log data in an array format.
   * Response mimics the one that is recieved when requested for one chart
   * @param  {Number} range
   * @returns Array of sensors with agregated time log data plus converted data
   */
  // buildSensorDataforCharts(logs: any, conversion) {
  //   let logSensors = [];
  //   let finalDataSource;
  //   let unitType = 'Pressure';
  //   let convertedArray = [];
  //   for (const log of logs) {
  //     for (const logItem of log) {
  //       const tempLogSensors = [];
  //       if (logItem.logCurveInfo && logItem.logCurveInfo.length > 0) {
  //         logItem.logCurveInfo.forEach((value, index) => {
  //           unitType = value.unitType;
  //           const logCurveInfoTemp = [ logItem.logCurveInfo[0] ];
  //           this.sensorInfo.forEach(element => {

  //           if(unitType.split(" ").join("").toLowerCase() === element.sensorType){

  //               convertedArray = this.conversionService.newService(logItem.logData.data, conversion[unitType.split(" ").join("").toLowerCase()+'Unit'], conversion.timeUnit);
  //             logCurveInfoTemp.push(logItem.logCurveInfo[index]);
  //             tempLogSensors.push({
  //               index: index,
  //               sensorName: value.mnemonic.value,
  //               logData: { data: convertedArray },
  //               logid: logItem.uid,
  //               mnemonicList: [],
  //               typeService: value.unitType,
  //               nameWell: logItem.nameWell,
  //               logCurveInfo: [
  //                 ...logCurveInfoTemp
  //               ]
  //             });
  //           }
  //           });
  //         });
  //       }
  //       if(logItem.logData && logItem.logData.data.length > 0) {
  //         logItem.logData.data.forEach((dataValues) => {
  //           tempLogSensors.forEach((sensor) => {
  //             if (dataValues[sensor.index] != null && dataValues[sensor.index] !== '') {
  //               sensor.mnemonicList = [
  //                 logItem.logData.mnemonicList[0],
  //                 logItem.logData.mnemonicList[sensor.index]
  //               ];
  //               sensor.unitList = [
  //                 logItem.logData.unitList[0],
  //                 logItem.logData.unitList[sensor.index]
  //               ];

  //             }
  //           });
  //         });
  //       }
  //       logSensors = [...logSensors, ...tempLogSensors];
  //     }
  //   }

  //   finalDataSource = this.buildDataSourceRemoveDup(logSensors);
  //   return finalDataSource;
  // }

  getConvertedTime(time, conversion) {
    if (conversion) {
    return new Date(this.conversionService.convertTime(conversion.timeUnit, time));
    }
    return time;
  }

  /**
   * @name: buildDataSourceRemoveDup
   * @description This method agregates the sensor data maintaining single entry per sensor.
   * @param  {Number} range
   * @returns Array of sensors with agregated time log data.
   */
  public  buildDataSourceRemoveDup(source: any[]): any[] {
    const finalDataSource = [];
    source.forEach((sensor) => {
      if (
        finalDataSource.filter((item) => item.sensorName === sensor.sensorName && item.nameWell === sensor.nameWell)
          .length === 0
      ) {
        // If not found add the sensor
        finalDataSource.push(sensor);
      } else {
        // If found append the data points
        finalDataSource.forEach((item) => {
          if (item.sensorName === sensor.sensorName && item.nameWell === sensor.nameWell) {
            item.logData.data = [...item.logData.data, ...sensor.logData.data];
          }
        });
      }
    });
    return finalDataSource;
  }
  /**
   * @name: groupSensorsByWell
   * @description Create a group of Well Ids which maitains a list of sensors.
   * @param  {Number} range
   * @returns Array of Well Ids with agregated sensor.
   */
  public groupSensorsByWell(sensors: IDataSensor[]): any[] {
    const groupedSensors = sensors.reduce((m, v) => {
      const uid = v.uid;
      const entry = m[uid];
      if (typeof entry === 'undefined') {
        m[uid] = {};
        m[uid]['sensors'] = [v.sensorName];
        m[uid]['uidWell'] = v.uidWell;
      } else {
        entry['sensors'].push(v.sensorName);
      }
      return m;
    }, {});
    const res = Object.keys(groupedSensors).map((d) => {
      return {
        uid: d,
        sensors: groupedSensors[d]['sensors'],
        uidWell: groupedSensors[d]['uidWell']
      };
    });
    return res;
  }

  public getUnitLabelSensorType = (sensorType:string) => {
      const sensorData = sensorInfo.sensorData.find(data => data.sensorType === sensorType);
      return sensorData? sensorData.axisName : sensorType;
  }
}
