import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import sensorInfo from '../../../../assets/data/sensorTypeConfig.json';
import { ChartStoreService } from '../../../services/chart.service';
import { ChartsSettingsService } from '../../../services/charts-settings.service';
import { PanelToggleService } from '../../../services/panel-toggle.service';
import { MeasurementConstants } from '../../../shared/constants/master-page.constants';
import { BaseService } from '../../../shared/services/indexdb/base.service';
import { IChartstate } from '../../../state/container-states/chart.state';
import { GlobalFilterState } from '../../../state/container-states/global-filter.state';
@Component({
  selector: 'app-charts-settings',
  templateUrl: './charts-settings.component.html',
  styleUrls: ['./charts-settings.component.scss']
})
export class ChartsSettingsComponent implements OnInit, OnChanges, OnDestroy{
  showone = false;
  showtwo = false;
  showthree = false;
  showfour = false;
  showfive = false;
  display = false;
  items: SelectItem[];
  data: any;
  sensorCheckBoxList: any[];
  pickedSensors: any[];
  selectedAxisList: any;
  axistype = [];
  pitchangeratesensorlist: any;
  rpmsensorlist: any;
  temperaturesensorlist: any;
  axisList = [];
  cloneAxisList = [];
  selectedAxis: any;
  itemForEdit:  any;
  isEdit = false;
  unitSelectedForEdit: any;
  isAuto: boolean;
  axisMin: string;
  axisMax: string;
  mappedSensorColor: any = [];
  dialogForMultiple: any;
  disableSelectSensor = false;
  sensorInfo: any[];
  isRenamed: boolean;

  @Input()
  sensorList: any;

  @Output() axesSelected: EventEmitter<any> = new EventEmitter<any>();
  selectedAxisData: any;
  userObjectFromLocal;
  unsubscribe$ = new Subject();
  newSensorList = [];
  oldSensorList = [];
  newSensor = [];
  errorMessage: string;
  showLimitDialog: boolean;
  errorMessageForAxisList: string;
  showLimitDialogForAxisList: boolean;
  shownType = '';
  reverseDispatch: any;
  invalidMinMaxValue = true;
  enableApply: any;
  enableApplyForSensorList = false;
  previousShownType: any;
  allSensors: any = [];
  newTab = true;
  callUpdateAxis: boolean;
  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.sensorList.length !== 0) {
      this.updateSensorList(this.sensorList); // save sensor list based on user
    }
  }
  // code added to fix the multi tab issue
  @HostListener('window:storage', ['$event'])
  onLocalStorageEvent(e) {
    if (e.key === 'openpages'){
      const temp = Date.now();
      localStorage.setItem('page_available', temp.toString());
    }
    if (e.key === 'page_available') {
      this.newTab = false;
    }
  }
  constructor(private chartsSettingsService: ChartsSettingsService, public dbService: BaseService , private chartStoreService: ChartStoreService , private store: Store<GlobalFilterState> , private chartStore: Store<IChartstate> , private toggleService: PanelToggleService) {
    this.sensorInfo = sensorInfo.sensorData.filter((item:any) => { //creating axis based on config file
      return item.settings === true && item.useData === true;
    }).sort((firstItem: any, secondItem: any) => firstItem.dataIndex - secondItem.dataIndex);

    this.chartStoreService.isRenamed.subscribe((status) => {
      this.isRenamed = status;
    });
    let i = 1;
    this.sensorInfo.forEach((sensorList) => {
      this.newSensor.push({
        sensorType: sensorList.sensorType,
        sensorsList: [],
        axisName: sensorList.axisName
      });
    });
    this.sensorInfo.forEach((sensor) => {
      this.axistype.push({
        label: sensor.axisName,
        value: {
          id: i++,
          name: sensor.sensorType,
          displayType: sensor.axisName
        }
      });

    });

    const temp = Date.now();
    localStorage.setItem('openpages', temp.toString());

    if (JSON.parse(localStorage.getItem('allsensors')) == null) {
      localStorage.setItem('allsensors', JSON.stringify([]));
    }
    this.getAxisList(true);
  }

  ngOnInit() {
    this.showone = true;
    this.selectedAxis = {name: 'pressure', displayType: 'Pressure', id: 1};
    // Initial loading based on global filter selection
  }

  ngOnChanges(changes: any) {
    if (!this.isRenamed){
    this.chartStoreService.changeGlobal(changes.sensorList.currentValue);
    }
    this.isRenamed = false;
    this.newSensorList = changes.sensorList.currentValue;
    if (this.newSensorList && this.newSensorList.length !== 0 && changes.sensorList.previousValue && changes.sensorList.previousValue.length === 0) {
      const localSiteFlag = localStorage.getItem('localSiteFlag');
      if (localSiteFlag !== '1'){
      this.updateSensorList(changes.sensorList.currentValue);
          }
    }
    const refreshFlag = localStorage.getItem('refreshFlag');
    if (changes.sensorList.previousValue !== undefined) {
      // save previous list to localstorage on click of signout from charts
      if (refreshFlag === null && changes.sensorList.currentValue.length === 0) {
        this.updateSensorList(changes.sensorList.previousValue);
      }
      if (refreshFlag === '1') {
        localStorage.setItem('refreshFlag', '0');
      } else {
        if (changes.sensorList.currentValue.length === 0 ) {
          return;
        }
        this.sensorList = changes.sensorList.previousValue;
        this.newSensorList = changes.sensorList.currentValue;
        let differenceArray = this.differenceBy(this.newSensorList, this.sensorList); // find the differance based on both well and sensor Name
        let globallyAbsent = this.differenceBy(this.sensorList, this.newSensorList); // find the differance based on both well and sensor Name
        if (differenceArray.length > 0 || globallyAbsent.length > 0) {
          const localSiteFlag = localStorage.getItem('localSiteFlag');
          if (localSiteFlag === '1'){
            localStorage.setItem('localSiteFlag', '0');
            differenceArray = [];
            globallyAbsent = [];
            this.callUpdateAxis = true;
          }
          if (this.callUpdateAxis){
            this.updateAxisList();
            this.callUpdateAxis = false;
          }
          this.addToAvailableAxis(differenceArray, globallyAbsent);
        }
        this.sensorList = this.newSensorList;
        this.updateSensorList(this.sensorList);
      }
    }
    this.removeEmptyAxis();
  }

  updateAxisList() {
    this.removeUnselectedSensors();
    this.removeEmptyAxis();
    const storedSensorList = localStorage.getItem('allsensors');
    const temp = JSON.parse(storedSensorList).filter((element) => {
     return element.key === JSON.parse(localStorage.getItem('currentUser')).email;
    });
    if (temp.length <= 0) {
      localStorage.setItem('allsensors', JSON.stringify([]));
      return;
    }

    if (temp[0].sensors === null || temp[0].sensors === undefined || temp[0].sensors === 'undefined' || temp[0].sensors === 'null') {
      this.oldSensorList = [];
    } else {
      this.oldSensorList = temp[0].sensors;
    }
    const differenceArray = this.differenceBy(this.newSensorList, this.oldSensorList); // find the difference based on both well and sensor Name
    const globallyAbsent = this.differenceBy(this.oldSensorList, this.newSensorList); // find the difference based on both well and sensor Name
    if (differenceArray.length > 0 || globallyAbsent.length > 0) {
      this.addToAvailableAxis(differenceArray, globallyAbsent);
    }
    let currentSensorList = [];
    this.axisList.forEach((axis) => {
      axis.sensorList.forEach((sensor) => {
        currentSensorList.push(sensor.value);
      });
    });
    const differenceCurrentArray = this.differenceBy(this.newSensorList, currentSensorList); // find the differance based on both well and sensor Name
    const globallyCurrentAbsent = this.differenceBy(currentSensorList, this.newSensorList); // find the differance based on both well and sensor Name
    if (differenceCurrentArray.length > 0 || globallyCurrentAbsent.length > 0) {
      this.addToAvailableAxis(differenceCurrentArray, globallyCurrentAbsent);
    }
  }

  updateSensorList(sensors) {
    if (localStorage.getItem('currentUser') === null) {
      return;
    }
    const user = JSON.parse(localStorage.getItem('currentUser')).email;
    this.allSensors = JSON.parse(localStorage.getItem('allsensors'));
    const check = this.allSensors.filter((value) => {
      return value.key === user;
    });
    if (check.length !== 0) {
      this.allSensors = this.allSensors.map((value) => {
        if (value.key === user) {
          value.sensors = sensors;
        }
        return value;
      });
    } else {
      this.allSensors.push({
        //key: JSON.parse(localStorage.getItem('currentUser')).email,
        key: JSON.parse(localStorage.getItem('currentUser')).email,
        sensors
      });
    }
    localStorage.setItem('allsensors', JSON.stringify(this.allSensors));
  }

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.sensorList && this.sensorList.length !== 0) {
       this.updateSensorList(this.sensorList);
     }
  }

  /**
   * Adds sensor to the first available axis if axis exists
   * Creates an axis and adds sensors if axis not availble
   * @param sensor
   * @param globallyAbsent
   */
  addToAvailableAxis(sensor, globallyAbsent) {
    this.newSensor.forEach((sensor) => {
      sensor.sensorsList = [];
      });

    sensor.forEach((element) => {
      this.newSensor.forEach((sensorList) => {
        sensorList.isPresent = false;
        if (element.unitType.toLowerCase() === sensorList.sensorType) {
          sensorList.sensorsList.push({ label: element['displayName'] + ' (' + element['wellName'] + ')', id: element['sensorName'] + ' - ' + element['wellName'], value: element, checked: true });
        }
      });
    });

    this.axisList.forEach((axes) => {
      this.newSensor.forEach((sensorList) => {
        if (axes.value.toLowerCase().includes(sensorList.sensorType) && !sensorList.isPresent) {
          sensorList.axisColor = axes.axisColor;
          axes.sensorList = _.union(axes.sensorList, sensorList.sensorsList);
          sensorList.isPresent = true;
        }
      });
    });
    this.newSensor.forEach((sensorList) => {
      if (this.axisList.length > 0) {
        const a = this.axisList.filter((ax) => {
          return ax.value.toLowerCase().includes(sensorList.sensorType);
        });
        if (a.length > 0) {
          this.axisList.forEach((axes) => {
            if (!sensorList.isPresent && axes.value.toLowerCase().includes(sensorList.sensorType)) {
              axes.sensorList = _.union(axes.sensorList, sensorList.sensorsList);
            }
          });
        } else {
          this.axisList.push({
            label: sensorList.axisName + 1,
            value: sensorList.sensorType + 1,
            checked: true,
            count : 1,
            autoRange: true,
            min : '',
            max : '',
            sensorList: sensorList.sensorsList,
            axisColor: MeasurementConstants[sensorList.axisName.split(' ').join('').toUpperCase() + '_AXIS_COLOR'][0]
          });
        }
      } else {
        this.axisList.push({
          label: sensorList.axisName + 1,
          value: sensorList.sensorType + 1,
          checked: true,
          count : 1,
          autoRange: true,
          min : '',
          max : '',
          sensorList: sensorList.sensorsList,
          axisColor: MeasurementConstants[sensorList.axisName.split(' ').join('').toUpperCase() + '_AXIS_COLOR'][0]
        });
      }
      this.loadBasedOnAxis(false);
    });

    globallyAbsent.forEach((sensor) => {
      this.removeSensorFromAxis(sensor.sensorName, sensor.wellName);
    });
    // code to remove axis with empty sensor list starts here
    _.remove(this.axisList, (obj) => {
      return obj.sensorList.length === 0;
    });
    this.checkLastUnitAxis();
    this.emitAxisList(this.axisList , true, false);

  }

// code to auto select the last remaining axis of unit type
  checkLastUnitAxis() {
    const unitAxisCheckedCount = _.filter(this.axisList, (axis) => {
      return axis.value.toLowerCase().includes(this.shownType.toLowerCase()) && axis.checked;
    }).length;
    const unitAxisCount = _.filter(this.axisList, (axis) => {
      return axis.value.toLowerCase().includes(this.shownType.toLowerCase());
    }).length;

    if (unitAxisCount > 0 && unitAxisCheckedCount === 0) {
      for (let i = 0; i < this.axisList.length; i++){
        if (this.axisList[i].value.includes(this.shownType.toLowerCase())) {
          this.axisList[i].checked = true;
          break;
        }
      }
    }
  }

  loadBasedOnAxis(isEmit) {
    // uncheck all axis as  by default
    this.axisList.forEach((axis) => {
        axis.checked = false;
    });
    // check all axis if multiple
    if (this.shownType === 'Multiple') {
      this.axisList.forEach((axis) => {
        axis.checked = true;
      });
    } else {
      this.axisList.forEach((axis) => {
        if (axis.value.toLowerCase().includes(this.shownType.toLowerCase())) {
          axis.checked = true;
        }
      });
    }
    if (isEmit) {
      this.emitAxisList(this.axisList, true);
    }
  }

  /**
   * Axis list will be emitted after chart setting changes to store
   * @param axisList
   * @param flag
   * @param fromGlobal
   */
  emitAxisList(axisList, flag , fromSettings?) {
    this.mappedSensorColor = [];
    // code to remove axis with empty sensor list starts here
    _.remove(axisList, (obj) => {
      return obj.sensorList.length === 0;
    });
    // code to remove axis with empty sensor list ends here
    axisList.forEach((axis) => {
      const uniqueSensorList = _.uniqBy(axis.sensorList, 'id');
      axis.sensorList = uniqueSensorList;
      axis.sensorList.forEach((sensor) => {
          this.mappedSensorColor.push({
            wellSensorName : sensor['value'].multiLogId + ' - ' + sensor['value'].sensorName, // Debug on where it is missed while merging
            sensorAxisColor: axis.axisColor,
          });
      });
    });
    if (axisList.length === 0){
      this.chartStoreService.changeColorMapping([]);
    } else {
    this.chartStoreService.changeColorMapping(this.mappedSensorColor);
    }
    if (flag) {
      const payload = {
        axisList,
        fromSettings
      };
      this.chartStoreService.changeAxisList(payload);
    }
    this.axesSelected.emit(axisList);
    this.chartsSettingsService.insertDBChartsTable(axisList);
  }
  showDialog(){
    this.removeUnselectedSensors();
    this.removeEmptyAxis();
    this.cloneAxisList = _.cloneDeep(this.axisList);
    this.display = true;
    this.displaySection(1);
    this.enableApply = true;
  }
  hideDialog() {
  this.cancelDialog();
 }

  cancelDialog() {
    if (this.dialogForMultiple && this.shownType.toLowerCase().includes('multiple')) {
      this.chartStoreService.changePreviousType(this.previousShownType);
     }
    this.display = false;
    this.axisList = _.cloneDeep(this.cloneAxisList);
 }

  closeLimitDialog() {
    this.showLimitDialog = false;
  }

  //code to emmit the selected axes to the chart component starts here
  sendSelectedAxes(){
    this.emitAxisList(this.axisList, true, true);
    this.dialogForMultiple = false;
    this.display = false;
  }
  //code to emmit the selected axes to the chart component ends here

  closeLimitDialogForAxisList() {
    this.showLimitDialogForAxisList = false;
  }

  //Code to remove row from axes list starts here
  removeAxies(selectedItem) {
    let unitType = 'pressure';
    this.sensorInfo.forEach((sensorList) => {
      if (selectedItem.value.includes(sensorList.sensorType)) {
        unitType = sensorList.sensorType;
      }
    });
    const unitTypeAxisLength = _.filter(this.axisList, (axis) => {
      return axis.value.toLowerCase().includes(unitType);
    }).length;
    if (unitTypeAxisLength > 1) {
      const removedAxis = _.remove(this.axisList, (obj) => {
        return obj.value === selectedItem.value;
      });
      const firstUnitTypeAxis = _.filter(this.axisList, (axis) => {
        return axis.value.toLowerCase().includes(unitType);
      })[0];
      removedAxis[0].sensorList.forEach((sensor) => {
        firstUnitTypeAxis.sensorList.push(sensor);
      });
      this.enableApply = this.axisList.every((axis) => {
        return axis.checked !== true;
      });
    } else {
      let unitCategory;
      this.sensorInfo.forEach((sensorList) => {
        if (selectedItem.value.toLowerCase().includes(sensorList.sensorType)) {
          unitCategory = sensorList.axisName;
        }
      });
      this.errorMessage = 'Global sensor selection contains ' + unitCategory + ' sensors. Cannot delete axis.';
      this.showLimitDialog = true;
    }
  }
  //Code to remove row from axes list ends here

  // Code to bind the edit item to variable starts here
  bindDataForEdit(item){
    this.isEdit = true;
    this.itemForEdit = item;
    this.isAuto = this.itemForEdit['autoRange'];
    this.axisMin = this.itemForEdit['min'];
    this.axisMax = this.itemForEdit['max'];
    this.sensorInfo.forEach((sensorList) => {
      if (item.value.toLowerCase().includes(sensorList.sensorType)) {
        this.unitSelectedForEdit = sensorList.sensorType;
      }
    });
    const selectedAxisForEdit = _.filter(this.axisList, (axis) => {
      return axis.value === item.value;
    })[0];
    this.itemForEdit.sensorList = _.cloneDeep(selectedAxisForEdit.sensorList);
    this.enableOrDisableSelectSensor(this.unitSelectedForEdit);
    this.invalidMinMaxValue = true;
  }
  // Code to bind the edit item to variable ends here

  // Code to validate the select sensor button
  enableOrDisableSelectSensor(unitType) {
    this.disableSelectSensor = true;
    const unitAxisCount = _.filter(this.axisList, (axis) => {
      return axis.value.toLowerCase().includes(unitType.toLowerCase());
    }).length;
    if (unitAxisCount > 1) {
      this.disableSelectSensor = false;
    }
  }
  /**
   * Loads the sensors based on unit type selected
   * @param axisType
   */
// Code to load sensor list based on the selected axis starts here
  loadSensorList(axisType) {
    this.sensorCheckBoxList = [];
    this.sensorList.forEach((element) => {
      if (element['unitType'].toLowerCase() === axisType.toLowerCase()) {
        this.sensorCheckBoxList.push({ label: element['displayName'] + ' (' + element['wellName'] + ')', id: element['sensorName'] + ' - ' + element['wellName'],   value: element, checked: false });
        this.sensorCheckBoxList.sort((a, b) => (a.value['sensorName'] > b.value['sensorName']) ? 1 : ((b.value['sensorName'] > a.value['sensorName']) ? -1 : 0));
      }
    });
    if (this.isEdit){
      this.sensorCheckBoxList.forEach((sensorCheckBox) => {
        this.itemForEdit.sensorList.forEach((item) => {
          if (sensorCheckBox.id ===  item['value']['sensorName'] + ' - ' + item['value']['wellName']) {
            sensorCheckBox.checked = true;
            sensorCheckBox.disable = true;
          }
        });
      });
    }
  }
  // Code to load sensor list based on the selected axis ends here

  // Code to add min max range to the axis starts here
  addRange() {
    this.itemForEdit['autoRange'] = this.isAuto;
    this.itemForEdit['min'] = this.axisMin;
    this.itemForEdit['max'] = this.axisMax;
    // code to validate apply button after edit axis
    this.enableApply = false;
    this.enableApply = this.axisList.every((axis) => {
      return axis.checked !== true;
    });
  }
  // Code to add min max range to the axis ends here
  getInitialAxes(){
    this.axisList = [];
    const uniqSensorList = _.uniqBy(this.sensorList, 'unitType');
    uniqSensorList.forEach((element) => {
      const tempSensorList = this.chartStoreService.getSensorByUnitType(element['unitType'], this.sensorList);
      tempSensorList.forEach((sensor) => {
        sensor.checked = true;
      });
      let color;
      this.sensorInfo.forEach((sensor) => {
        if (sensor.sensorType.toLowerCase() === element.unitType.toLowerCase())
        color =  MeasurementConstants[sensor.axisName.split(' ').join('').toUpperCase() + '_AXIS_COLOR'][0];

      });
      if (tempSensorList.length > 0) {
        this.sensorInfo.forEach((sensorList) => {
          if (element['unitType'].toLowerCase() === sensorList.sensorType) {
            this.axisList.push({
              label: sensorList.axisName + 1 ,
              value: element['unitType'] + 1,
              count : 1,
              checked: true,
              autoRange: true,
              min : '',
              max : '',
              sensorList : tempSensorList,
              axisColor: color
            });
          }
        });
      }
    });
  }

  // Code to add and edit axis to the main list starts here
  addAxis() {
    this.pickedSensors = _.filter(this.sensorCheckBoxList, (sensorCheckBox) => {
      return sensorCheckBox.checked;
    });
    if (this.isEdit) {
      const index = _.findIndex(this.axisList, (ele) => {
        return ele['value'] === this.itemForEdit.value;
      });
      this.pickedSensors.forEach((element) => {
        this.removeSensorFromAxis(element['value']['sensorName'], element['value']['wellName']);
      });
      this.axisList[index]['sensorList'] = this.pickedSensors;
      this.addRange();

    } else {
      if (this.pickedSensors !== undefined && this.pickedSensors.length > 0) {
        this.pickedSensors.forEach((element) => {
          this.removeSensorFromAxis(element['value']['sensorName'], element['value']['wellName']);
        });
        const tempCount = this.chartStoreService.getAxisCount(this.axisList, this.selectedAxis);
        let color;
        this.sensorInfo.forEach((sensor) => {
          if (this.selectedAxis.name.toLowerCase().includes(sensor.sensorType.toLowerCase())){
            color =  MeasurementConstants[sensor.axisName.split(' ').join('').toUpperCase() + '_AXIS_COLOR'][tempCount];

          }
        });
        this.sensorInfo.forEach((sensorList) => {
          if (this.selectedAxis.name === sensorList.sensorType) {
            this.axisList.push({
              label: sensorList.axisName + tempCount,
              value: this.selectedAxis.name + tempCount,
              count: tempCount,
              checked: true,
              autoRange: true,
              min : '',
              max : '',
              sensorList: this.pickedSensors,
              axisColor: color });
          }
        });
      }
    }
    _.remove(this.axisList, (item) => {
      return item.sensorList.length === 0;
    });
    this.enableApply = false; // Send flag to valiate the axis lists
  }
  // Code to add and edit axis to the main list ends here

  /**
   * When application loads settings are taken from indexDB or creates one using global selection
   * @param flag
   */
  // Code to get the chart settings form Js.store starts here
  getAxisList(flag) {
    // Get the logged in user email from local storage
    this.userObjectFromLocal = JSON.parse(localStorage.getItem('currentUser'));
    if (this.userObjectFromLocal === null) {
      return;
    }
    this.chartsSettingsService.getChartsSelectSettings(this.userObjectFromLocal['email']).subscribe((result) => {
      if (!result || result.length === 0 || !result[0] || !result[0].value || result[0].value.length === 0) {
        this.getInitialAxes();
      } else {
        this.axisList = [];
        for (const res of result[0].value) {
          this.axisList.push(res);
        }
        this.SetSyncOnSensors();
        if (this.newSensorList.length > 0) {
          this.updateAxisList();
        }
      }

      this.toggleService.currentSelection.pipe(takeUntil(this.unsubscribe$)).subscribe((payload) => {
        if (payload.typeSelected === this.shownType) {
          return;
        }
        this.previousShownType = this.shownType;
        this.shownType = payload.typeSelected;
        if (this.shownType.toLowerCase().includes('multiple')) {
          this.dialogForMultiple = true;
          this.showDialog();
        } else {
          if (payload.clicked) {
            this.loadBasedOnAxis(true);
          }
        }
      });
    });
  }
  // Code to get the chart settings form Js.store ends here

  editSensorList() {
    this.sensorCheckBoxList = [];
    this.sensorInfo.forEach((sensorList) => {
      if (this.itemForEdit['id'].toLowerCase().includes(sensorList.sensorType)) {
        this.loadSensorList(sensorList.sensorType);
      }
    });
  }

  // Code to remove the sensor from axis list starts here
  removeSensorFromAxis(senName, wellname) {
    this.axisList.forEach((element) => {
      _.remove(element.sensorList, (sensor) => {
        return  sensor.id === senName + ' - ' + wellname;
      });
    });
  }

  // Code to remove unselected sensors when navigated from map
  removeUnselectedSensors() {
    const allSensors = [];
    this.axisList.forEach((axis) => {
      axis.sensorList.forEach((sensor) => {
        allSensors.push(sensor.value);
      });
    });

    allSensors.forEach((sensor) => {
      const checkForAvailableSensors = this.newSensorList.filter((sen) => {
        return sen.wellName + ' - ' + sen.sensorName === sensor.wellName + ' - ' + sensor.sensorName;
      });
      if (checkForAvailableSensors.length === 0) {
        this.removeSensorFromAxis(sensor.sensorName, sensor.wellName);
      }
    });
  }
  SetSyncOnSensors() {
    const allSensors = [];
    this.axisList.forEach((axis) => {
      axis.sensorList.forEach((sensor) => {
        allSensors.push(sensor.value);
      });
    });
    const storedSensorList = localStorage.getItem('allsensors');
    const temp = JSON.parse(storedSensorList).filter((element) => {
     return element.key === JSON.parse(localStorage.getItem('currentUser')).email;
    });
    if (temp.length <= 0) {
      localStorage.setItem('allsensors', JSON.stringify([]));
      return;
    }
    let selectedSensors =[]
    if (temp[0].sensors === null || temp[0].sensors === undefined || temp[0].sensors === 'undefined' || temp[0].sensors === 'null') {
      selectedSensors = [];
    } else {
      selectedSensors = temp[0].sensors;
    }
    allSensors.forEach((sensor) => {
      const checkForAvailableSensors = selectedSensors.filter((sen) => {
        return sen.wellName + ' - ' + sen.sensorName === sensor.wellName + ' - ' + sensor.sensorName;
      });
      if (checkForAvailableSensors.length === 0) {
        this.removeSensorFromAxis(sensor.sensorName, sensor.wellName);
      }
    });
  }

  removeEmptyAxis(){
    _.remove(this.axisList, (axis) => {
      return axis.sensorList.length === 0;
    });
  }
  // Code to add sensor checked list starts here
  checkAxis(checkBoxValId) {
    this.axisList.forEach((element) => {
        if (element['label'] === checkBoxValId.value.label) {
          element['checked'] = checkBoxValId.checked;
        }
    });
    // To valiate at least one axis item checked
    this.enableApply = this.axisList.every((axis) => {
      return axis.checked !== true;
    });
  }

  checkSensor(sensorCheckBoxId) {
    this.sensorCheckBoxList.forEach((element) => {
      if (element['id'] === sensorCheckBoxId.id) {
        element['checked'] = sensorCheckBoxId.checked;
      }
    });
    // To valiate the at least one sensor item checked
    this.enableApplyForSensorList = this.sensorCheckBoxList.every((sensor) => {
      return sensor.checked !== true;
    });

  }

  // code to display the section based on the events starts here
  displaySection(section) {
    this.showone = false;
    this.showtwo = false;
    this.showthree = false;
    this.showfour = false;
    this.showfive = false;

    switch (section) {
      case 1:
        this.showone = true;
        break;
      case 2:
        this.showtwo = true;
        this.isEdit = false;
        break;
      case 3:
        this.pickedSensors = [];
        this.showthree = true;
        this.enableApplyForSensorList = true;
        break;
      case 4:
        this.showfour = true;
        break;
      case 5:
        this.showfive = true;
        break;
      default:
        this.showone = true;
    }

  }
// code to display the section based on the events ends

// Code to find the differance of two array starts here
  differenceBy(sensorList1, sensorList2) {
    const tempList = [];
    sensorList1.forEach((sensor) => {
      const sensorWellName = sensor['sensorName'] + sensor['wellName'];
      const filterdList = _.filter(sensorList2, (sensor2) => {
        return sensor2['sensorName'] + sensor2['wellName'] === sensorWellName;
      });
      if (filterdList.length > 0) {
        // skip the sensor
      } else {
        tempList.push(sensor);
      }
    });
    return tempList;
  }
// Code to find the differance of two array ends here

// Code to display screen on back button starts here
  displayEditOrAdd() {
    if (this.isEdit) {
      this.displaySection(4);
    } else {
      this.displaySection(2);
    }
  }
  // Code to display screen on back button ends here

  /**
   * Validation for minimum and maximum for chart axis range
   * @param event
   * @param flag
   */
  //Code to validate min and max range starts here
  minMaxChanged(event, flag) {
    if (flag) {
      this.axisMin = event.target.value;
    } else {
      this.axisMax = event.target.value;
    }
    const pattern = /^[-]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    if ((this.axisMin === '' || this.axisMin === null)) {
      this.invalidMinMaxValue = true;
    } else if ((this.axisMax === '' || this.axisMax === null)) {
      this.invalidMinMaxValue = true;
    } else if (!this.axisMin.toString().match(pattern)) {
      this.invalidMinMaxValue = true;
    } else if (!(this.axisMax.toString().match(pattern))) {
      this.invalidMinMaxValue = true;
    } else if (this.axisMin === '-0' || this.axisMax === '-0') { // validation for negative Zero
      this.invalidMinMaxValue = true;
    } else if (this.axisMin.length > 12 || this.axisMax.length > 12) { // validation max length 12
      this.invalidMinMaxValue = true;
    } else if (parseFloat(this.axisMin) >= parseFloat(this.axisMax)) {
      this.invalidMinMaxValue = true;
    } else {
      this.invalidMinMaxValue = false;
    }
    this.onKeydown(event);
  }
  //Code to validate min and max range ends here
  // tslint:disable-next-line: cyclomatic-complexity
  onKeydown(e) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode === 189 || e.keyCode === 109) || // Negetive No
      (e.keyCode === 190 || e.keyCode === 110) || // Period or decimal
      (e.keyCode >= 35 && e.keyCode <= 45) // Home, End, Left, Right
    ) {
      return;  // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57 || e.keyCode === 8)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  toggleEnableDisable(value) {
    if (value) {
      this.axisMin = '';
      this.axisMax = '';
      this.invalidMinMaxValue = false;
    } else {
      this.invalidMinMaxValue = true;
    }
  }
}
