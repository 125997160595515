import { Injectable } from '@angular/core';
/* NgRx */
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GlobalFilterService } from '../../services/global-filter.service';
import { WellService } from '../../services/well.service';
import * as globalFilterActions from '../actions/global-filter.actions';
import { State } from '../container-states/app.state';
import * as fromFilter from '../container-states/global-filter.state';


@Injectable()
export class GlobalFilterEffects {
  constructor(
    private globalFilterService: GlobalFilterService,
    private actions$: Actions,
    private filterState: Store<fromFilter.GlobalFilterState>,
    private wellService: WellService
  ) { }

  @Effect()
  loadWells$: Observable<Action> = this.actions$.pipe(
    ofType(globalFilterActions.GlobalFilterActionTypes.LoadWells),
    map((action: globalFilterActions.LoadWells) => action.payload),
    mergeMap((payload) => {
      return this.globalFilterService.getWells(payload).pipe(
        map((allWellsData: any) => {
          this.wellService.setNoDataWells(allWellsData.wellsWithoutData, payload['noDataWells']);
          //const renamedWellSensorList = this.wellService.getRenamedWellSensors(allWellsData.wellsWithData); //Can be moved if the middleware has a dedicated database
          this.filterState.dispatch(new globalFilterActions.LoadNickNames({
            wellsWithSensors: allWellsData.wellsWithData
          }));
          return new globalFilterActions.LoadSensorsSuccess(allWellsData.wellsWithData);
        }),
        catchError((err) => of(new globalFilterActions.LoadWellsFail(err)))
      );
    })
  );

  @Effect()
  loadNickNames$: Observable<Action> = this.actions$.pipe(
    ofType(globalFilterActions.GlobalFilterActionTypes.LoadNickNames),
    map((action: globalFilterActions.LoadNickNames) => action.payload),
    mergeMap((payload) => {
      // TODO: User should be stored in stores. This hardcoding has be removed
      return this.wellService.getNickNames(payload.wellsWithSensors).pipe(
        map((result: any) => {
          return new globalFilterActions.NickNamesSuccess(result);
        }),
        catchError((err) => of(new globalFilterActions.LoadWellsFail(err)))
      );
    })
  );


}
