import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalErrorService } from 'src/app/shared/services/global-error.service';
import { IAlert } from '../../models/alert';
import { AlertService } from '../../services/alert.service';
import { ChartStoreService } from '../../services/chart.service';
import { ErrorService } from '../../services/error.service';
import { ERR_MESSAGES } from '../../shared/constants/error-messages.constants';
import * as util from '../../shared/utilities/util';
import * as alertActions from '../../state/actions/alert-page.actions';
import * as fromAlert from '../../state/container-states/alert.state';
import * as fromFilter from '../../state/container-states/global-filter.state';
import { Well } from '../../state/reducers/global-filter.reducer';

@Component({
    selector: 'app-alert-page',
    templateUrl: './alert-page.component.html',
    styleUrls: [ './alert-page.component.scss' ],
    providers: [ MessageService, ErrorService ]
})

export class AlertPageComponent implements OnInit, OnDestroy {
  @Output() openAddAlertDialog = new EventEmitter<boolean>();

  @Output()
  clearInterval = new EventEmitter<boolean>();
  @Output()
  startInterval = new EventEmitter<boolean>();
  @Input()
  loaderForNoWellData;

  ruleDeleting: boolean;
  alerts: IAlert[] = [];
  wellList: Well[];
  showWaitIndicator = false;
  showNoAlertMessage =  false;
  alertsLoadCompleted = false;
  unsubscribe$ = new Subject();
  currentEditIndex = -1;
  isAddAlertDialogOpened = false;
  indexToBeSaved: number[] = [];
  creationInProgress = false;
  message: any;
  public editValuePressure: string;
  batteryTextFieldName: number;
  editIcon: boolean;
  showInput = false;
  showMagicMargin = false;
  valid = true;
  @ViewChild('toggle', { static: false }) toggle: ElementRef;
  taggleKey: void;

  constructor(
    private store: Store<fromAlert.AlertPageState>,
    private filterState: Store<fromFilter.GlobalFilterState>,
    private globalErrorService: GlobalErrorService,
    private alertService: AlertService,
    private chartStoreService: ChartStoreService,
    private errorService: ErrorService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    localStorage.setItem('refreshFlag', '0');
    this.chartStoreService.changeColorMapping([]);
    this.alertService.refreshFlag.pipe(takeUntil(this.unsubscribe$)).subscribe((message) => { this.message = message; });
    this.batteryTextFieldName = 25;
    this.editIcon = false;
    localStorage.setItem('component', 'alertRules');
    this.store
      .pipe(select(fromAlert.getAlertState), takeUntil(this.unsubscribe$))
      .subscribe(({alerts,alertsLoadCompleted}) => {

        this.showNoAlertMessage = false;
        this.alertsLoadCompleted =alertsLoadCompleted;
        if (this.ruleDeleting) {
          this.ruleDeleting = false;
          if (this.alerts && Array.isArray(this.alerts) && this.alerts.length <= 0 && alertsLoadCompleted) {
            this.showNoAlertMessage = true;
          }
        } else if (alerts && !this.indexToBeSaved.length) {
          if (this.message === 'false') {
            this.alerts =  util.sortByKeyFunc(
              JSON.parse(JSON.stringify(alerts)),
              'AlarmDetail',
              false ,
              'MnemonicAlias'
            );
          }
          if (this.alerts && Array.isArray(this.alerts) && this.alerts.length <= 0 && alertsLoadCompleted) {
            this.showNoAlertMessage = true;
           }
          if (this.creationInProgress) {
            this.isAddAlertDialogOpened = false;
            this.creationInProgress = false;
          }
        } else if (alerts && this.indexToBeSaved.length) {
          this.handleAlertUpdate(this.indexToBeSaved.shift(), alerts);
        }
      });

    this.globalErrorService.get5xxError()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          if (this.isAddAlertDialogOpened) {
            this.isAddAlertDialogOpened = false;
          }
        });

    this.filterState
        .pipe(select(fromFilter.getWellsList), takeUntil(this.unsubscribe$))
        .subscribe((wells: Well[]) => {
          this.wellList = wells;
      });
  }

  clearAllAlerts() {
    // tslint:disable-next-line: prefer-for-of
    for (const curentAlert of this.alerts) {
      const alert: IAlert =  curentAlert;
      this.store.dispatch(new alertActions.DeleteAlertRule({
        alert,
        wells: this.wellList,
      }));
    }
    this.alerts = [];
    this.messageService.clear();
  }

  alertSaving(index: number) {
    this.indexToBeSaved.push(index);
  }

  ruleDelete(index: number) {
    this.alerts.splice(index, 1);
    this.ruleDeleting = true;
  }

  handleAlertUpdate(index: number, alerts) {
    const alertToUpdate = alerts.find((alert) => {
      return alert.UidWell === this.alerts[index].UidWell
        && alert.AlarmDetail.CurveName === this.alerts[index].AlarmDetail.CurveName;
    });
    this.alerts.splice(index, 1, alertToUpdate);
  }

  addNewAlert() {
    this.isAddAlertDialogOpened = true;
  }

  activateEditAlertMode(editAlertMode, i) {
    this.currentEditIndex = editAlertMode ? i : -1;
  }
  // To show text filed when user clicks on edit icon
  showTextField(event) {
    this.showInput = true;
    this.showMagicMargin = true;
  }
  // To save user input
  saveBatteryValue(textFieldName) {
    this.batteryTextFieldName = textFieldName;
    this.showInput = false;
    this.showMagicMargin = false;
    this.valid = true;
  }
  valueChanged(event) {
    if (event.srcElement.value !== this.batteryTextFieldName) {
      this.valid = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkBoxEnableOrDisable(event) {
    this.alertService.setBatteryAlerts(event.target.checked);
    localStorage.setItem('toggleValue', event.target.checked);
  }

  // Warning message to alert users who select delete all button if inadvertently
  deleteAllAlertWarning(){
    const warning = new Error(ERR_MESSAGES.WARN_DELETE_ALL_ALERTS);
    this.showWarning(warning, warning.message);
  }
  showWarning(warningCode, message?) {
    this.messageService.clear('deleteAllAlert');
    const warningMessage = this.errorService.getErrorMessage(message, warningCode);
    this.messageService.add({
      key: 'deleteAllAlert',
      severity: 'warning',
      summary: warningMessage,
      closable: false,
      detail: message,
      sticky: true
    });
  }

  cancelWarning(){
    this.messageService.clear();
  }


  // Warning message to alert users who select delete all button if inadvertently
  duplicateWarning(){
    const warning = new Error(ERR_MESSAGES.WARN_DUPLICATE_ALERT);
    this.showAlertWarning(warning, warning.message);
  }

  showAlertWarning(warningCode, message?) {
    this.messageService.clear('duplicateAlarm');
    const warningMessage = this.errorService.getErrorMessage(message, warningCode);
    this.messageService.add({
      key: 'duplicateAlarm',
      severity: 'warning',
      summary: warningMessage,
      closable: false,
      detail: message,
      sticky: true
    });
  }

  cancelAlertWarning(){
    this.messageService.clear();
  }
}
