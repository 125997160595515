/**
 * Represents ngrx Actions
 */
// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { UserFilters, UserGlobal, UserPreferences } from '../../models/user';

export enum UserActionTypes {
  AddUserPreferences = '[User] Add User Preferences',
  AddUserPreferencesSuccess = '[User] Add User Preferences Success',
  LoadUserFilters = '[User] Load User Filters',
  LoadUserFiltersSuccess = '[User] Load User Filters Success',
  EditUserSensorName = '[User] Edit User Sensor Name',
  EditUserSensorNameSuccess = '[User] Edit User Sensor Name Success',
  LoadUserRolesSuccess ='[User] Get User Roles Success',
  LoadUserSettingsSuccess = '[User] User settings update success',
  Logout = "[User] logout",
}

// Action Creators
export class AddUserPreferences implements Action {
  readonly type = UserActionTypes.AddUserPreferences;
  constructor(public payload: UserGlobal) { }
}

export class LoadUserFilters implements Action {
  readonly type = UserActionTypes.LoadUserFilters;
  constructor(public payload: any) { }
}

export class Logout implements Action {
  readonly type = UserActionTypes.Logout;
}

export class LoadUserFiltersSuccess implements Action {
  readonly type = UserActionTypes.LoadUserFiltersSuccess;

  constructor(public payload: UserFilters) { }
}

export class AddUserPreferencesSuccess implements Action {
  readonly type = UserActionTypes.AddUserPreferencesSuccess;

  constructor(public payload: UserPreferences) { }
}

export class EditUserSensorName implements Action {
  readonly type = UserActionTypes.EditUserSensorName;

  constructor(public payload: any) { }
}

export class EditUserSensorNameSuccess implements Action {
  readonly type = UserActionTypes.EditUserSensorNameSuccess;

  constructor(public payload: UserPreferences) { }
}

export class LoadUserRolesSuccess implements Action {
  readonly type = UserActionTypes.LoadUserRolesSuccess;
  constructor(public payload: string[]) { }
}

export class LoadUserSettingsSuccess implements Action {
  readonly type = UserActionTypes.LoadUserSettingsSuccess;
  constructor(public payload: {}) { }
}


// Union the valid types
export type UserActions = AddUserPreferences
| AddUserPreferencesSuccess
| LoadUserFilters
| LoadUserFiltersSuccess
| EditUserSensorNameSuccess
| LoadUserRolesSuccess
| LoadUserSettingsSuccess ;
