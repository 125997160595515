import { IMeasurementClass, IUnit } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";

export class Feet implements IUnit<IMeasurementClass> {
  static readonly Instance: Feet = new Feet();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Feet.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(1,0,0,1);
  }
}
