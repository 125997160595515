import { IMeasurementClass, IUnit } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";
import { CubicFeetPerSec } from "./CubicFeetPerSec";

export class MillionCubicFeetPerDay implements IUnit<IMeasurementClass> {
  static readonly Instance: MillionCubicFeetPerDay = new MillionCubicFeetPerDay();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return CubicFeetPerSec.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(0.0864,0,0,1);
  }
}
