import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import moment from 'moment-timezone';
// import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, EMPTY, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IAlert } from '../models/alert';
import * as alertActions from '../state/actions/alert-page.actions';
import * as fromAlert from '../state/container-states/alert.state';
import { OWAI_APP_NAME } from '../shared/constants/master-page.constants';


@Injectable({ providedIn: 'root' })
export class AlertService {
  private alertTimer;
  private batteryTimer;
  private messageSource = new BehaviorSubject('false');
  private batteryAlert = new BehaviorSubject(null);
  refreshFlag = this.messageSource.asObservable();

  constructor(
    @Inject('ENV_CONFIG') private envConfig: any,
    private http: HttpClient,
    // private socket: Socket,
    private alertState: Store<fromAlert.AlertPageState>
  ) { }

  changeStatus(message: string) {
    this.messageSource.next(message);
  }

  startAlertTimer(tempWellList) {
    this.alertState.dispatch(new alertActions.LoadAlertRules(this.getLoadAlertsPayload(tempWellList)));
    this.alertTimer = setInterval(() => {
      if (navigator.onLine) {
        this.alertState.dispatch(new alertActions.LoadAlertRules(this.getLoadAlertsPayload(tempWellList)));
      }
    }, 20000);
  }

  stopAlertTimer() {
    clearInterval(this.alertTimer);
  }

  startBatteryTimer(wellList) {
    this.alertState.dispatch(new alertActions.ActionBatteryVoltage(this.getBatteryVoltagePayload(wellList)));
    this.batteryTimer = setInterval(() => {
      if (navigator.onLine) {
        this.alertState.dispatch(new alertActions.ActionBatteryVoltage(this.getBatteryVoltagePayload(wellList)));
      }
    }, 60000);
  }

  stopBatteryTimer() {
    clearInterval(this.batteryTimer);
  }

  getAlerts(jobs: string[]): Observable<any> {
    if (jobs.length > 0) {
      const apiUrl = encodeURI(`${this.envConfig.api.getAlerts}/${jobs[0]}/Alarms?appName=${OWAI_APP_NAME}`);
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        secureProtocol: "TLSv1_2_method"
      };

      return this.http.get(apiUrl, httpOptions).pipe(
        tap((response: any[]) => {
          return response;
        }),
        catchError(this.handleError)
      );
    }
    return EMPTY;
  }

  getVoltageValue(userJobs: any): Observable<any> {
    // TODO: Check if fork join can be moved to effects
    return forkJoin(
      userJobs.map((job) => {
        const body = job;
        return this.http
          .post(this.envConfig.api.globalFilter, body)
          .pipe(catchError(this.handleError));
      })
    );

  }

  acknowledgeAlert(alert): Observable<any> {
    const UID = alert.UnitProfileUid.split('|')[1];
    const apiUrl = `${this.envConfig.api.acknowledgeAlert}/${alert.AlarmID}/Job/${alert.JobName}/Uom/${UID}`;
    return this.http.put(apiUrl, {}).pipe(
      tap((response) => response),
      catchError(this.handleError)
    );
  }

  addAlert(alert: IAlert): Observable<any> {
    const apiUrl = `${this.envConfig.api.addAlert}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(apiUrl, alert, httpOptions)
      .pipe(tap((response) => response),
        catchError(this.handleError));
  }

  deleteAlert(alert: IAlert): Observable<any> {
    const apiUrl = `${this.envConfig.api.deleteAlert}/${alert['AlarmID']}/Job/${alert['JobName']}`;

    return this.http.delete(apiUrl).pipe(
      tap((response) => response),
      catchError(this.handleError)
    );
  }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (err.body) {
        errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
      } else if (err.error.errorMessage) {
        errorMessage = `An error occurred. ${err.status}: ${err.error.errorMessage}`;
      }else if (err.error) {
        errorMessage = `Backend returned code ${err.status}: ${err.error}`;
      }

    }
    return throwError(errorMessage);
  }

  getLoadAlertsPayload(wells): any {
    if (localStorage['currentUser']) {

      const userDetails = JSON.parse(localStorage['currentUser']);
      const jobs = [];
      userDetails.IWJob.map((job) => {
        jobs.push(job['jobName']);
      });

      const payload = {
        jobs,
        wells,
      };

      return payload;

    }

  }
  splitWellsForBattery(selectdWells) { // Function to split the well based on log id (Multile log id  )
    let result;
    const resultArray = [];
    selectdWells.forEach((well) => {
      if (well.checked) {
        result = _(well.sensors)
          .groupBy((x) => x.multiLogId)
          .map((value, key) => ({
            checked: true,
            multiLogId: key,
            sensors: value,
            jobName: well.jobName,
            startDateTimeIndex: well.startDateTimeIndex,
            endDateTimeIndex: well.endDateTimeIndex,
            nameWell: well.nameWell,
            uid: well.uid,
            uidWell: well.uidWell

          }))
          .value();
        result.forEach((element) => {
          resultArray.push(element);
        });
      }
    });
    return resultArray;
  }

  getBatteryVoltagePayload(wellList): any {
    let i = 0;
    const payloadList = [];
    const splitWell = this.splitWellsForBattery(wellList);
    splitWell.forEach((well) => {
      if (well.checked) {
        const sensorList = new Set();
        splitWell[i].sensors.forEach((sensor) => {
          if (sensor.checked && sensor.batterySensorName) {
            sensorList.add(sensor.batterySensorName);
          }
        });
        i++;
        const payload = {
          uidWell: well.uidWell,
          uid: well.multiLogId, //"60edd64c-38ea-492e-ac89-8d075d74418b",
          logData: Array.from(sensorList),
          size: 'l',
          maxreturnnodes: 30000,
          startDateTimeIndex: moment(new Date()).subtract(60, 'seconds').toISOString(),
          endDateTimeIndex: new Date().toISOString(),
          Uom: 'Clariti_English'
        };
        payloadList.push(payload);
      }
    });
    return payloadList;
  }

  getSensorNameForBattery(sensorName) {
    // const sensorNameLowerCase = sensorName.toLowerCase();

    // const sensorSplit = sensorNameLowerCase.split(new RegExp('[_.]', 'g'));
    // if (sensorSplit.length >= 6) {
      return sensorName.replace(/pressure|temperature|pres|temp/gi, 'BatP');
    // } else {
      // return sensorName.replace(/pressure|temperature|pres|temp/gi, 'BatteryVoltage');
    // }
  }

  setBatteryAlerts(value) {
    this.batteryAlert.next(value);
  }
  getBatteryalerts(): Observable<any> {
    return this.batteryAlert.asObservable();
  }
}
