// Services module
import { NgModule } from '@angular/core';
import { ConversionService } from '../shared/services/conversion.service';
// Import individual Services
import { AlertService } from './alert.service';
import { AuthorizationService } from './authorization.service';
import { ChartLoadingService } from './chart-loading.service';
import { ChartStoreService } from './chart.service';
import { DatabaseConfigService } from './database-config.service';
import { ExportService } from './export.service';
import { PressureAndTemperatureService } from './pressure-temperature.service';
import { SignalRService } from './signal-r.service';
import { WellService } from './well.service';


@NgModule({
    providers: [
        AuthorizationService,
        PressureAndTemperatureService,
        ConversionService,
        DatabaseConfigService,
        AlertService,
        ChartLoadingService,
        ExportService,
        WellService,
        SignalRService,
        ChartStoreService
    ]
})

export class CommonServicesModule {

}
