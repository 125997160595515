/**
 * Service method to get authorization token
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IAppState } from '../app.state';
import { AddAuthorizationDetails } from '../state/actions/authorizationActions';
import { OWAI_APP_NAME } from '../shared/constants/master-page.constants';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  token: string = null;
  constructor(@Inject('ENV_CONFIG') private envConfig: any, private http: HttpClient, private store: Store<IAppState>) {
    this.store.subscribe((state) => {
      if (state.authorization) {
        this.token =
          state.authorization.authorizationToken.authorizationTokenFromService;
      }
    });
  }

  //check if there is any update to user entitlements in OWAI
  syncEntitlements() {
    const apiUrl = encodeURI(`${this.envConfig.api.syncEntitlements}?appName=${OWAI_APP_NAME}`);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      secureProtocol: "TLSv1_2_method"
    };
  
    return this.http.get(apiUrl, httpOptions).pipe(
      tap((data) => {
        return data;
      })
    )  
  }



  /**
   * @description: method to get authorization token
   * @param: data passed from main component
   */
  public getAuthToken(data?): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:
          'Basic YW51LmNoaW50YWxhcGFsbHlAaGFsbGlidXJ0b24uY29tOmhAbHBAc3M0NTY='
      })
    };

    if (this.token) {
      return of(this.token);
    }
    return this.http.get('assets/OAuth.json', httpOptions).pipe(
      tap((res) => {
        if (res['access-token']) {
          const authObject = {
            authorizationToken: {
              authorizationTokenFromService: res['access-token']
            }
          };
          this.store.dispatch(
            AddAuthorizationDetails(authObject.authorizationToken)
          );
        }
      }),
      map((res) => res['access-token'])
    );
  }
}
