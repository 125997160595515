/**
 * Ngrx reducer for authorization
 */
import { UserPreferences } from '../../models/user';
import { CoreAction } from '../actions/actions';
import { UserActionTypes, UserActions } from '../actions/userActions';
import { UserState } from '../container-states/user.state';

export const initialUser: UserState = {
  preferences: [],
  filters: {
    userId: '',
    filters: []
  },
  roles:[],
  settings:{}
};

export function UserReducer(state = initialUser, action: UserActions) {
    switch (action.type) {
      case UserActionTypes.AddUserPreferencesSuccess:
        return {
          ...state,
          preferences: action.payload,
        };

      case UserActionTypes.EditUserSensorNameSuccess:
        return {
          ...state,
          preferences: [...state.preferences, action.payload]
        };

      case UserActionTypes.LoadUserRolesSuccess:
        let tempRoles = [...state.roles];
        if(tempRoles == action.payload){
          return state;
        }else{
        return {
           ...state,
            roles: action.payload
         };
        }

      case UserActionTypes.LoadUserFiltersSuccess:
        return {
          ...state,
          filters: action.payload,
        };
      case UserActionTypes.LoadUserSettingsSuccess:
        return {
          ...state,
          settings: action.payload,
        };
      default: return state;
  }
}
