import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as UserActions from '../state/actions/userActions';
import * as fromUser from '../state/container-states/user.state';
import {  Store } from '@ngrx/store';
import { State } from '../state/container-states/app.state';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  
  constructor(@Inject('ENV_CONFIG') private envConfig: any,private http: HttpClient,public store: Store<State>,private userState: Store<fromUser.UserState>,
    ) {}

  // Return list of roles of the user
  getRoles():Observable<any> {
    const apiUrl = encodeURI(`${this.envConfig.api.mmw_user_roles}`);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'responseType': 'text',
      }),

      secureProtocol: "TLSv1_2_method"
    };

    return this.http.get(apiUrl,  {
      ...httpOptions,
      responseType: 'text'
    }).pipe(
      tap((data) => {
        this.userState.dispatch(new UserActions.LoadUserRolesSuccess(JSON.parse(data)));
        return data;
      }),
      catchError(this.handleError)
    );
  }

    // Return the environment variable value
    getEnvs():Observable<any> {
      const apiUrl = encodeURI(`${this.envConfig.api.getEnvs}`);
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'responseType': 'text',
        }),
        secureProtocol: "TLSv1_2_method"
      };

      const params = new HttpParams().set('key', 'AZURE_MAP_KEY');
  
      return this.http.get(apiUrl,  {
        ...httpOptions,
        params: params,
        responseType: 'text'
      }).pipe(
        map((data) => {
          let jsonData = JSON.parse(data);
          return jsonData.value;
        }),
        catchError(this.handleError)
      );
    }

    public handleError(err: { error: { message: any; errorMessage: any; }; body: { error: any; }; status: any; }) {
      // in a real world app, we may send the server to some remote logging infrastructure
      // instead of just logging it to the console
      let errorMessage: string;
      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        if(err){
            if (err.body) {
              errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
            }  else if (err.error && err.error.errorMessage) {
              errorMessage = `An error occurred. ${err.status}: ${err.error.errorMessage}`;
            } else if (err.error) {
              errorMessage = `Backend returned code ${err.status}: ${err.error}`;
            }
        }
      }
      return throwError(errorMessage);
    }

}
