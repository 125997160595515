import { ChartActions, EChartActions } from '../actions/chart.actions';
import { IChartstate, initialChartState } from '../container-states/chart.state';
export function ChartReducer(
  state = initialChartState,
  action: ChartActions
): IChartstate {
  switch (action.type) {
    case EChartActions.GetLiveRequestSuccess: {
      return {
        ...state,
        LiveRequest: action.payload
      };
    }
    case EChartActions.GetHistoricalDataSuccess: {
      return {
        ...state,
        HistoricalData: action.payload
      };
    }
    case EChartActions.GetLivePoolSuccess: {
      return {
        ...state,
        LivePool: action.payload
      };
    }

    case EChartActions.GetResetState: {
      return {
        ...state,
        LiveRequest: [],
        LivePool: [],
        HistoricalData: []
      };
    }

    case EChartActions.GetWaitIndicator: {
      return {
        ...state,
        waitIndicator: action.payload
      };
    }

    case EChartActions.GetAxisList: {
      return {
        ...state,
        axesList: action.payload
      };
    }
    case EChartActions.GetLivePoolFail: {
      return {
        ...state,
        LivePool: []
      };
    }
    case EChartActions.GetLiveRequestFail: {
      return {
        ...state,
        LiveRequest: []
      };
    }
    case EChartActions.GetOriginalData: {
      return {
        ...state,
        originalData: action.payload
      };
    }
    case EChartActions.GetHistoricalRaw: {
      return {
        ...state,
        rawDataHistoric: action.payload
      };
    }
   
    case EChartActions.GetHistoricalFail: {
      return {
        ...state,
        HistoricalData: []
      };
    }
    default:
      return state;
  }
}