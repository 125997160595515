import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EChartOption } from 'echarts';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  IGraphData,
  IGraphObject
} from '../../models/lineGraphData';

import sensorInfo from './../../../assets/data/sensorTypeConfig.json';

const DATE_INDEX = 0;
@Component({
  selector: 'app-alert-chart',
  templateUrl: './alert-chart.component.html',
  styleUrls: ['./alert-chart.component.scss']
})
export class AlertChartComponent implements OnInit {

  @Input() graphData: any;
  @Input() converterData: any;
  @Output()
  dataSourceForAlert = new EventEmitter();
  staticGraphData: any;
  liveChartDataSource = [];
  chartOption: EChartOption;
  startDataZoom = 0;
  endDataZoom = 100;
  multi: IGraphObject[] = [];
  typeService: any;
  sensorInfo: any;

  constructor(private http: HttpClient) { 
    // getting sensorinfo from config file
    this.sensorInfo = sensorInfo.sensorData.filter((item: any) => { 
      return item.settings === true;
    });
  }

  ngOnInit() {
    this.staticGraphData = this.graphData;
    this.typeService = this.staticGraphData['typeService'];
    this.createChartConfig();
    this.transformDataGraph(
      this.staticGraphData,
      this.staticGraphData['typeService']
    );
  }

  getStaticData(): Observable<any> {
    // get users from api
    return this.http.get('assets/data/data.json').pipe(
      tap((data) => {
        return data.json; })
    );
  }

  getValue(series) {
    let unit;
    // getting the unit type for charts tooltip
    this.sensorInfo.forEach((sensorList) => { 
      sensorList.sensorUnits.forEach((sensorUnit) => {
        if (sensorUnit.value === (this.converterData[this.typeService + 'Unit'])) {
          unit = sensorUnit.label;
        }
      });         
  });
    return series.value[1] + unit; 
  }

  public transformDataGraph(dataInput, typeService) {
    this.multi = [];
    this.multi.push(this.fillDataInView(1, typeService, dataInput));
    this.dataSourceForAlert.emit(this.multi);
  }

  fillDataInView(index: number, identifier: string, dataInput): IGraphObject {
    const pressureObject: IGraphObject = {
      name: dataInput['sensorName'],
      value: dataInput['sensorOriginalName'],
      type: dataInput['typeService'],
      series: []
    };
    const unitIndex = identifier.toLowerCase() === 'temperature' ? 0 : 1;

    for (const item of dataInput['data']) {
      const series: IGraphData = {
        name: new Date(item[DATE_INDEX]),
        value: item[index]
      };
      pressureObject.series.push(series);
    }
    return pressureObject;
  }

  buildChartSeries() {
    if (this.staticGraphData !== undefined) {
        return {
          name: this.staticGraphData.nameWell + ' - ' + this.staticGraphData.displayName,
          data: this.staticGraphData.data,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          itemStyle: {
            color: 'red'
          },
        };
    }
  }

  createChartConfig() {
    let seriesData;
    seriesData = this.buildChartSeries();
    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params) => {
          params = _.uniqBy(params, 'seriesIndex');
          let str = moment(params[0]['axisValue']).format('DD-MMM-YY h:mm:ss a') + '</br>';
          for (let i = 0; i < params['length']; i++) {
            const series = params[i];
            str = str + series.marker + series.seriesName + ' : ' + this.getValue(series) + '</br>';
          }
          return str;
        }
      },
      title: {
        left: 'center',
        text: ''
      },
      grid: {
        left: '15%',
        right: '15%',
        top: '2%',
        bottom: '15%'
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          formatter: ((value) => {
            return  moment(value).format('DD-MMM-YY') + '\n' +  moment(value).format('h:mm:ss a');
          })
        },
        boundaryGap: false,
        splitArea: {
          show: true
        }
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        scale: true,
        min: 'dataMin',
        max: 'dataMax',
        splitArea: {
          show: false
        },
        splitLine: {
          show: true
        }
      },
      dataZoom: [{
        type: 'inside',
        show: true,
        xAxisIndex: [0],
        realtime: false,
        start: this.startDataZoom,
        end: this.endDataZoom,
      }, {
        start: 0,
        show: true,
        end: 100,
        top: '93%',
        dataBackground: {
          lineStyle: {
            color: 'rgba(0,0,153,1)',
          },
          areaStyle: {
            color: '#cc0000',
            opacity: 1

          }
        },
        labelFormatter: (value, valueStr) => {
          return moment(valueStr).format('DD-MMM-YY') + '\n' + moment(valueStr).format('h:mm:ss a');         
      },
        borderColor: '#fff',
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: 30,
        handleStyle: {
          color: '#fff',
          shadowBlur: 3,
          shadowColor: 'rgba(0, 0, 0, 0.6)',
          shadowOffsetX: 2,
          shadowOffsetY: 2
        }
      }],
      series: seriesData
    };
  }
}
