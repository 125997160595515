import { IMeasurementClass } from '../../../models/measurement';
import { IUnit } from '../../../models/measurement';
import { BilinearTransform } from '../BilinearTransform';
import { Kelvin } from './Kelvin';

export class Celsius implements IUnit<IMeasurementClass> {
  static readonly Instance: Celsius = new Celsius();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Kelvin.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(1,-273.15,0,1);
  }
}
