import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sensor-select',
  templateUrl: './sensor-select.component.html',
  styleUrls: ['./sensor-select.component.scss', '../well-selection/selection.component.scss']
})
export class SensorSelectComponent implements OnInit {
  @Input() sensors: any[];
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() saveSelected: EventEmitter<any[]> = new EventEmitter();
  @Input() selectedSensors: any[] = [];
  reducedSensors;
  selectedUids: string[] = [];
  selectAll = false;
  sensorObject: { [key: string]: any } = {};

  constructor() { }

  ngOnInit() {
    this.sensors.sort((a, b) => a.sensorOriginalName.localeCompare(b.sensorOriginalName, undefined, {numeric: true}));
    this.reducedSensors = this.sensors
      .reduce(
        (allSensors, sensor) => ({ ...allSensors, [sensor.uid]: sensor }), {}
      );
    this.selectedUids = this.selectedSensors.map((sensor) => {
      return sensor.uid;
    });
    if (this.selectedUids.length === this.sensors.length) this.selectAll = true;
  }

  onCancel() {
    this.close.emit();
  }

  onSelectAll() {
    if (!this.selectAll) {
      this.selectAll = true;
      this.selectedUids = Object.keys(this.reducedSensors);
    } else {
      this.selectAll = false;
      this.selectedUids = [];
    }
  }

  onSensorSelect(uid: string) {
    if (this.selectedUids.includes(uid)) {
      if (this.selectAll) this.selectAll = false;
      const nextList = this.selectedUids.filter((item) => item !== uid);
      this.selectedUids = nextList ? nextList : [];
    } else {
      this.selectedUids.push(uid);
      if (this.selectedUids.length === this.sensors.length) {
        this.selectAll = true;
      }
    }
  }

  populateSelectedSensors() {
    return this.selectedUids.map((uid) => {
      return this.reducedSensors[uid];
    });
  }

  saveSelection() {
    if (this.selectAll) {
      this.saveSelected.emit(this.sensors);
    } else {
      this.saveSelected.emit(this.populateSelectedSensors());
    }
  }

}
