/* NgRx */
import { GlobalFilterActions, GlobalFilterActionTypes } from '../actions/global-filter.actions';

// State for this feature (Global Filters)
// TODO: Move below interfaces to a dedicated Model file
// tslint:disable:interface-name
export interface GlobalFilterState {
  wells: Well[];
  uniqueSelectedTypes: string[];
  error: string;
  apiInprogress: boolean;
}

export interface Sensor {
  [x: string]: any;
  name?: string;
  checked: boolean;
  wellName: string;
  sensorName: string;
  multiLogId?: any;
  unit: string;
  unitType: string;
  minDateTimeIndex: string;
  maxDateTimeIndex: string;
  displayName?: string;
  combinedName?: string;
  sensorOriginalName?: string
}

export interface Well {
  name?: string;
  checked?: boolean;
  sensors: Sensor[];
  batSensors: Sensor[];
  jobName: string;
  wellName: string;
  nameWellbore?: string;
  objectGrowing?: boolean;
  indexType?: string;
  uidWell: string;
  uidWellbore?: string;
  startDateTimeIndex?: string;
  endDateTimeIndex?: string;
  uid: string;
}

const initialState: GlobalFilterState = {
  wells: [],
  uniqueSelectedTypes: [],
  error: '',
  apiInprogress: true
};
export function reducer(
  state = initialState,
  action: GlobalFilterActions
): GlobalFilterState {
  switch (action.type) {
    case GlobalFilterActionTypes.LoadWellsSuccess:
      let uniqueTypesArray = getUniqueUnitTypes(action.payload);
      return {
        ...state,
        wells: action.payload,
        uniqueSelectedTypes: uniqueTypesArray,
        error: ''
      };

    case GlobalFilterActionTypes.LoadWellsFail:
      return {
        ...state,
        error: action.payload
      };
    case GlobalFilterActionTypes.LoadSensorsSuccess:
    let uniqueSelectedUnitTypes = getUniqueUnitTypes(action.payload);
      return {
        ...state,
        // TODO append the sensors list to existing payload rather then replacing.
        wells: action.payload,
        uniqueSelectedTypes: uniqueSelectedUnitTypes,
        error: ''
      };
    case GlobalFilterActionTypes.LoadSensorsFail:
      return {
        ...state,
        error: action.payload
      };
    case GlobalFilterActionTypes.UpdateSensorName:
      return {
        ...state,
        wells: action.payload,
        error: ''
      };
    case GlobalFilterActionTypes.UpdateNickName:
      let wellItoEdit = action.payload.uidWell;
      let sensorNameToEdit = action.payload.sensorName;
      let nickNameUpdated = action.payload.nickName;

      const updatedWells = state.wells.map((well) => {
        if (well.uidWell === wellItoEdit) {
          const updatedSensors = well.sensors.map((sensor) => {
            if (sensor.sensorName === sensorNameToEdit) {
              return { ...sensor, nickName: nickNameUpdated };
            }
            return sensor;
          });

          // Update the Sensors property with the modified array
          return { ...well, sensors: updatedSensors };
        }
        return well;
      });

      return {
        ...state,
        wells: updatedWells,
        error: ''
      };
      // update the nicknames to the global filter object
      case GlobalFilterActionTypes.UpdatedSensors:
        let wellToUpdate = action.payload.uidWell;
        
        const updatedWells1 = state.wells.map((well) => {
          if (well.uidWell === wellToUpdate) {
            return { ...well, sensors:  action.payload.sensors };
          }
          return well;
        });
  
        return{
          ...state,
          wells: updatedWells1,
          error: ''
        };

    case GlobalFilterActionTypes.UpdateFilterSelection:
      return {
        ...state,
        wells: action.payload,
        error: ''
      };
    case GlobalFilterActionTypes.NickNamesSuccess:
      return {
        ...state,
        apiInprogress: false,
        error: ''
      };
    default:
      return state;
  }
}

//Get unique unit types selected in Global filter
function getUniqueUnitTypes(data: Well[]): string[] {
  const uniqueUnitTypes = new Set<string>();

  data.forEach(item => {
    item.sensors.forEach((sensorItem: any) => {
      if (sensorItem.checked) {
        uniqueUnitTypes.add(sensorItem.unitType.toLowerCase());
      }
    });
  });

  return Array.from(uniqueUnitTypes);
}
