<div *ngIf='filterList && filterList.length > 0' class='filter'>
  <span class='filter-title'>
    {{filterCategory}}
  </span>

  <p-listbox
    *ngIf='!filterList[0].sensorName'
    [options]='filterList'
    multiple='multiple'
    (onChange)='itemSelected($event)'
    checkbox='checkbox'
    optionLabel='wellName'
    [(ngModel)]='selectedItems'
    [showToggleAll]='false'>
  </p-listbox>
  <p-listbox
    *ngIf='filterList[0].sensorName'
    [options]='filterList'
    multiple='multiple'
    (onChange)='itemSelectedSensor($event)'
    checkbox='checkbox'
    optionLabel='combinedName'
    [showToggleAll]='false'
    [(ngModel)]='selectedItems'>
  </p-listbox>
</div>

<p-dialog [(visible)]="showLimitDialog" [draggable]="false" [showHeader]="false" [blockScroll]="true" [modal]="true"
  [breakpoint]="500" [style]="{'min-width': '200px','width':'500px'}" [responsive]="true" [contentStyle]="{'overflow':'auto'}" appendTo="body" [baseZIndex]="10001">
  <div class="error-dialog">
    <div class="col-md-12">
      <p class="header-text">{{errorMessage}}</p>
    </div>
    <div class="col-md-12 button-div">
      <button pButton type="button" class="confirmation-button" (click)="closeLimitDialog()" label="OK">OK</button>
    </div>
  </div>
</p-dialog>
