import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { differenceInCalendarDays } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateRange } from 'src/app/models/DateRange';
import { addHoursByTimeZone } from '../../shared/utilities/timeZoneConverter';
import { State } from '../../state/container-states/app.state';

@Component({
    selector: 'app-date-range-select-dialog',
    templateUrl: 'date-range-select-dialog.component.html',
    styleUrls: ['./date-range-select-dialog.component.scss']
})
export class DateRangeSelectDialogComponent implements OnInit, OnDestroy {

    constructor(private store: Store<State>) { }
    @Input()
    isOpen = false;
    @Output()
    dateRangeChangedEvent = new EventEmitter<any>();
    @Output()
    hideDialog = new EventEmitter<boolean>();

    isDateRangeValid = false;
    rangeDiff = null;
    dateRange: DateRange;
    unsubscribe$ = new Subject();
    selectedTimeZone: { [key: string]: string } = {};

    ngOnInit() {
      this.store
        .select((state: State) => state.conversion)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((conversionState: any) => {
          if (conversionState) {
            this.selectedTimeZone.timeUnit = conversionState.conversion.timeUnit;
            this.selectedTimeZone.timeLabel = conversionState.conversion.timeLabel;
          }
        });
    }

    ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }

    onDateSelectionChanged($event) {
        this.dateRange = {
          start : $event.start,
          end: $event.end
        };
        this.validateDateRange(this.dateRange);
    }

    applyDateFilter() {
        this.dateRangeChangedEvent.next(this.dateRange);
        this.closeDialog();
    }

    validateDateRange(dateRange: DateRange) {
      if (!this.dateRange.start || !this.dateRange.end) return;
      this.rangeDiff = differenceInCalendarDays(
        addHoursByTimeZone(new Date(dateRange.end.valueOf()), this.selectedTimeZone.timeUnit),
        addHoursByTimeZone(new Date(dateRange.start.valueOf()), this.selectedTimeZone.timeUnit)
      );
      this.isDateRangeValid = this.rangeDiff >= 2 && this.rangeDiff <= 31 && !isNaN(this.rangeDiff);
    }

    closeDialog() {
      this.isOpen = false;
      this.hideDialog.emit(this.isOpen);
    }

    cancel(){
       this.closeDialog();
    }

}
