import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartLoadingService } from '../../services/chart-loading.service';
import { ViewOptions } from '../../shared/constants/master-page.constants';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: 'loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
    @Input() historicalView: boolean;
    @Input() exportData: boolean;
    @Input() dashbordLoading: boolean;
    @Input() textColor: string;
    @Input() SigningOut: boolean;
    @Input() SigningIn: boolean;
    @Output() hideError: EventEmitter<boolean> = new EventEmitter();

    constructor(private chartLoadingService: ChartLoadingService) { }
    ngOnInit() {
      this.hideError.emit(false);
    }
    onCancel(){
      this.chartLoadingService.stopChartLoading();
      this.chartLoadingService.hideSpinner(ViewOptions.oneMonth);
    }

}
