import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAlert } from '../../models/alert';
import { AlertDetailsService } from '../../services/alert-details.service';
import * as alertActions from '../../state/actions/alert-page.actions';
import * as fromAlert from '../../state/container-states/alert.state';
@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss']
})
export class AlertCardComponent implements OnInit{
  @Input() alertData: IAlert;
  @Input() wellInformation;
  @Input() showOverlay;
  @Output() acknowledgedAlert = new EventEmitter();
  alertTime: string;

  constructor(
    private alertDetailsService: AlertDetailsService,
    private store: Store<fromAlert.AlertPageState>
  ) { }

  ngOnInit() {
    if (this.alertData && this.alertData.AlarmDetail) {
      this.alertTime = this.getTimeFromString(this.alertData.AlarmDetail.NotificationMessage);
    }
  }

  openAlertDetails(): void {
    this.alertDetailsService.openAlertDetailsModal.emit(this.alertData);
  }

  acknowledgeAlert(alert: IAlert): void {
    this.store.dispatch(new alertActions.AcknowledgeAlert(alert));
    this.acknowledgedAlert.emit();
  }

  getTimeFromString(notification: string): string {
    let time = '';
    time = notification.split('@').length > 1 ?
      notification.split('@')[1].substring(1, 21) : '';
    return time;
  }

}
