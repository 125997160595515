import { IMeasurementClass } from '../../models/measurement';
import { IUnit } from '../../models/measurement';


export class EngineeringValue<TMeasurement extends IMeasurementClass> {
  readonly Value: number;
  readonly Unit: IUnit<TMeasurement>;

  constructor(value: number, unit: IUnit<TMeasurement>) {
      this.Value = value;
      this.Unit = unit;
  }

  With(value: number): EngineeringValue<TMeasurement>{
    return new EngineeringValue<TMeasurement>(value, this.Unit);
  }
}
