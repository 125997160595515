
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit, OnChanges {
  @Input() dayRangeData: any;
  displayVal: string;

  ngOnChanges(changes: SimpleChanges) {
    this.move(changes.dayRangeData.currentValue);
    this.displayVal = this.dayRangeData;
  }

  constructor() { }
  ngOnInit(){
    this.displayVal = this.dayRangeData;
    this.move(this.dayRangeData)
  }

  move(currentValue) {
    var elem = document.getElementById("myBar");
    var val = currentValue;
    if(val == 0){
      elem.style.width = 1 + '%';
     elem.innerHTML = '';
    } else {
      elem.style.width = val + '%';
      elem.innerHTML = val + '%';
    }
  }

}
