<div class='backdrop'>
  <div class='modal-body'>
    <div class='header'>
        <div class='title'>Select Sensors</div>
        <div class='select-all {{ selectAll ? "selected" : "" }}'>
            <span class='checkbox' (click)='onSelectAll()'></span><div class='subtitle'>Sensor</div>
        </div>
    </div>
    <br>
    <div class='selection-block'>
      <div *ngFor='let sensor of sensors; let odd = odd' class='selection-row'>
        <div class='select-block {{ selectedUids.includes(sensor.uid) ? "selected" : "" }}'>
          <span class='checkbox' (click)='onSensorSelect(sensor.uid)'></span></div>
        <div class='sensor-name' [ngClass]='{ odd: odd }'><p class='name original-name'>{{ sensor.sensorOriginalName }}</p>
        <p *ngIf='sensor.sensorOriginalName !== sensor.displayName' class='name display-name'>({{ sensor.displayName }})</p>
        </div>
      </div>
    </div>
    <div class='footer'>
      <button class='cancel' (click)='onCancel()'>Cancel</button>
      <button class='done' (click)='saveSelection()' [disabled]='!selectedUids.length'>Done</button>
    </div>
  </div>
</div>
