<div
  *ngIf='(showPressure && displayValue.value !=="" || ((isSchematic || isAlert || showMultiple) && displayValue.value !==""))'
  [ngStyle]="{'border-left': '4px solid ' + axisColor, 'border-top': '5px solid ' + color, 'margin-bottom': '5px', 'border-bottom': (isAlert || isSchematic ? '1px solid #d4d8df' : '')}">

  <div (mouseover)="showEditPress=true;updateSchematics(displayData);"
    (mouseleave)="showEditPress=false;updateSchematics(null);">
    <div class="parent first-row">
      <div class="first-child child-alarm">
        <div *ngIf='getAlertReadings(displayData, liveReadingAlerts)' class="fa fa-bell alert-icon-style"
          [style.color]='getAlertReadings(displayData, liveReadingAlerts)'></div>
      </div>

      <div class="reading-val">
        {{ displayValue.value }}&nbsp;
        <span *ngIf='displayValue.value !== "Waiting..."' class='child-unit'>{{ displayValue.type == 'temperature' ?
          displayValue.unit : displayValue.unit.toLowerCase() }}</span>
      </div>
      <div class="depth-val">{{displayValue.md}} <span class="child-unit">{{displayData.depthUnit}} (MD)</span></div>
    </div>

    <div class="parent">
      <div class="first-child child-alarm">
        <!--Battery level indicator starts-->
        <span class="tooltip-battery" *ngIf="showBatteryColor" attr.tooltip-title="{{batteryPercentage}}">
          <img *ngIf="fourGreens" src='assets/master-page/battery-images/Four-Greenbar.png'
            class="battery-placement-style" />
          <img *ngIf="threeGreens" src='assets/master-page/battery-images/Three-Greenbar.png'
            class="battery-placement-style" />
          <img *ngIf="twoGreens" src='assets/master-page/battery-images/Two-Greenbar.png'
            class="battery-placement-style" />
          <img *ngIf="red" src='assets/master-page/battery-images/Red.png' class="battery-placement-style" />
        </span>
        <!--Battery level indicator Ends-->
      </div>
      <div class="well-name">{{wellInformation[displayData.logid]}}</div>
    </div>

    <div class="parent">
      <div class="first-child"></div>
      <div *ngIf='displayValue.value!==""' class="time">{{ displayValue.value !== "Waiting..." ?
        displayData.time.toLocaleUpperCase() : '&#x22EF;' }}</div>
    </div>

    <div class="parent">
      <div class="first-child">
        <div [enableIfAdmin]="roles" *ngIf='(displayData.sensorOriginalName != sensorEditedTruncated) && !hideSensor'>
          <a (click)='deleteNickname()' class='measurement-data-image'>
            <img src='assets/master-page/ic-delete-small.png' />
          </a>
        </div>
      </div>
      <div [enableIfAdmin]="roles" class="edit-icon">
        <a (click)='editData(displayData.sensorType)' class='measurement-data-image'>
          <img src='assets/master-page/ic-edit-small.png' />
        </a>
      </div>
      <div class="display-name">
        <span *ngIf='!editValue || editValue==""'>{{ sensorEditedTruncated }}</span>
        <app-label-edit *ngIf='displayValue.value!==""' [editValue]='editValue'
          (buttonEmitter)='updateValue($event, "pressureSensorLabel")'>
        </app-label-edit>
      </div>
      
    </div>
    <div class="error" *ngIf="showSensorNameAlert"><span>{{labelError}}</span></div>
  </div>
</div>