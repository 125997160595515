import { Units } from 'src/app/shared/constants/units.constants';
import { IMeasurementClass } from '../../models/measurement';
import { IUnit } from '../../models/measurement';
import { BarrelsPerDay } from './Flow/BarrelsPerDay';
import { BarrelsPerMin } from './Flow/BarrelsPerMin';
import { BarrelsPerSec } from './Flow/BarrelsPerSec';
import { CubicMetersPerDay } from './Flow/CubicMetersPerDay';
import { GallonsPerDay } from './Flow/GallonsPerDay';
import { GallonsPerMin } from './Flow/GallonsPerMin';
import { GallonsPerSec } from './Flow/GallonsPerSec';
import { MillionCubicFeetPerDay } from './Flow/MillionCubicFeetPerDay';
import { MillionCubicMetresPerDay } from './Flow/MillionCubicMetresPerDay';
import { ThousandCubicFeetPerDay } from './Flow/ThousandCubicFeetPerDay';


export class FlowMeasurement implements IMeasurementClass {
  static readonly Instance: FlowMeasurement = new FlowMeasurement();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return BarrelsPerMin.Instance;
  }

  static GetFlowUnitInstance(unitText:string){

    switch (unitText.toLowerCase()) {
        case Units.BBL_S:
          return BarrelsPerSec.Instance;
        case Units.BBL_MIN:
          return BarrelsPerMin.Instance;
        case Units.BBL_D:
          return BarrelsPerDay.Instance;
        case Units.GAL_S:
          return GallonsPerSec.Instance;
        case Units.GAL_MIN:
          return GallonsPerMin.Instance;
        case Units.GAL_D:
          return GallonsPerDay.Instance;
        case Units.CMPD: //to m3/day
          return CubicMetersPerDay.Instance;
        case Units.THOUSAND_CFPD: //Thousand Cubic Feet Per Day
        return ThousandCubicFeetPerDay.Instance;
        case Units.MILLION_CFPD: // Million Cubic Feet Per Day
          return MillionCubicFeetPerDay.Instance;
        case Units.MILLION_CMPD: //Million Cubic Meters per Day
          return MillionCubicMetresPerDay.Instance;
        default:
          return BarrelsPerMin.Instance;
    }
  }
}
