export interface IChartstate{
    LiveRequest: any;
    HistoricalData: any;
    LivePool: any;
    waitIndicator: boolean;
    axesList: any;
    originalData: any;
    rawDataHistoric: any;
}

export const initialChartState: IChartstate = {
    LiveRequest : [],
    HistoricalData : [],
    LivePool : [],
    waitIndicator : true,
    axesList : [],
    originalData: [],
    rawDataHistoric: []

};
