/**
 * Service method to get authorization token
 */
import { Injectable } from '@angular/core';
import { formatToTimeZone } from 'date-fns-timezone';
import moment from 'moment-timezone';
import { EngineeringValue } from 'src/app/services/uom/EngineeringValue';
import { CubicFeetPerSec } from 'src/app/services/uom/Flow/CubicFeetPerSec';
import { FlowMeasurement } from 'src/app/services/uom/FlowMeasurement';
import { PoundsPerSqFootGauge } from 'src/app/services/uom/Pressure/PoundsPerSqFootGauge';
import { PoundsPerSqInchGauge } from 'src/app/services/uom/Pressure/PoundsPerSqInchGauge';
import { PressureMeasurement } from 'src/app/services/uom/PressureMeasurement';
import { DepthMeasurement } from 'src/app/services/uom/DepthMeasurement';
import { Fahrenheit } from 'src/app/services/uom/Temperature/Fahrenheit';
import { TemperatureMeasurement } from 'src/app/services/uom/TemperatureMeasurement';
import { UnitExtensions } from 'src/app/services/uom/UnitExtensions';
import { Feet } from 'src/app/services/uom/Depth/Feet';
import { BarrelsPerMin } from 'src/app/services/uom/Flow/BarrelsPerMin';

@Injectable()
export class ConversionService {

  // Function converts depth from Feet to user selected units
  public convertDepth(
    depthUnit: string,
    depth: number
  ): number {
    if (depth === null) return;
    depth = UnitExtensions.ConvertTo(
      new EngineeringValue(depth, Feet.Instance),
      DepthMeasurement.GetDepthUnitInstance(depthUnit)
    ).Value;
    return parseFloat((depth * 1).toFixed(2));
  }

  //Method to convert Live reading temperature when user changes the temperature Unit
  public convertCurrentTemperature(
    temperatureFromUnit: string,
    temperature: number,
    temperatureToUnit: string
  ): number {

    temperature = UnitExtensions.ConvertTo(
      new EngineeringValue(temperature, TemperatureMeasurement.GetTemperatureUnitInstance(temperatureFromUnit)),
      TemperatureMeasurement.GetTemperatureUnitInstance(temperatureToUnit)
    ).Value;
    return parseFloat((temperature * 1).toFixed(2));
  }

  public convertTime(timeUnit: string, time: string): string {
    if (time && time !== '' && timeUnit !== '') {
      return moment(time).tz(timeUnit).format('M/D/YYYY h:mm:ss a');
    } else {
      return time;
    }
  }

  public convertTimeForLive(timeUnit: string, time: string): string {
    if (time && time !== '') {
      return moment(time).tz(timeUnit).format('DD-MMM-YY h:mm:ss a');
    } else {
      return time;
    }
  }

  // Function converts pressure from Pounds per Square foot Gauge to user selected units
  public alertConvertPressure(pressureUnit: string, pressure: number): number {

    if (pressure === null) return;

    pressure = UnitExtensions.ConvertTo(
      new EngineeringValue(pressure, PoundsPerSqFootGauge.Instance),
      PressureMeasurement.GetPressureUnitInstance(pressureUnit)
    ).Value;
    return parseFloat((pressure * 1).toFixed(2));
  }

  // Function converts temperature from Fahrenheit to user selected units
  public alertConvertTemperature(
    temperatureUnit: string,
    temperature: number
  ): number {
    if (temperature === null) return;
    temperature = UnitExtensions.ConvertTo(
      new EngineeringValue(temperature, Fahrenheit.Instance),
      TemperatureMeasurement.GetTemperatureUnitInstance(temperatureUnit)
    ).Value;
    return parseFloat((temperature * 1).toFixed(2));
  }

  //convert flow from Cubic Feet Per Second(DSRT saved flow Units) to user selected unit
  public alertConvertPitchangerate(pitchangerateUnit:string, pitchangerate:number): number {
    if (pitchangerate === null) return;
    pitchangerate = UnitExtensions.ConvertTo(
      new EngineeringValue(pitchangerate, CubicFeetPerSec.Instance),
      FlowMeasurement.GetFlowUnitInstance(pitchangerateUnit)
    ).Value;
    return parseFloat((pitchangerate * 1).toFixed(2));
  }

  //convert gas rate from Cubic Feet Per Second(DSRT saved flow Units) to user selected unit
  public alertConvertGasrate(gasrateUnit:string, gasrate:number): number {
    return this.alertConvertPitchangerate(gasrateUnit, gasrate);
  }

  //convert liquid rate from Cubic Feet Per Second(DSRT saved flow Units) to user selected unit
  public alertConvertLiquidrate(liquidrateUnit:string, liquidrate:number): number {
    return this.alertConvertPitchangerate(liquidrateUnit, liquidrate);
  }

  //convert from other pressure units to Pounds per Square Inch Gauge
  public convertExportPressure(fromUnit: string, pressure: number): number {
    if (pressure === null) return;

    pressure = UnitExtensions.ConvertTo(
      new EngineeringValue(pressure, PressureMeasurement.GetPressureUnitInstance(fromUnit)),
      PoundsPerSqInchGauge.Instance
    ).Value;
    return pressure;
  }

  //convert from other temperature unit to Fahrenheit
  public convertExportTemperature(
    fromUnit: string,
    temperature: number
  ): number {
    if (temperature === null) return;

    temperature = UnitExtensions.ConvertTo(
      new EngineeringValue(temperature, TemperatureMeasurement.GetTemperatureUnitInstance(fromUnit)),
      Fahrenheit.Instance
    ).Value;
    return temperature;
  }

  //Method to convert Live reading pressure when user changes the pressure Unit
  public convertCurrentPressure(
    fromUnit: string,
    pressure: number,
    toUnit: string
  ): number {
    if (pressure === null) return;

    pressure = UnitExtensions.ConvertTo(
      new EngineeringValue(pressure, PressureMeasurement.GetPressureUnitInstance(fromUnit)),
      PressureMeasurement.GetPressureUnitInstance(toUnit)
    ).Value;

    return parseFloat((pressure * 1).toFixed(2));
  }

  //Method to convert Live reading flow when user changes the pressure Unit
  public convertCurrentPitchangerate(
    fromUnit: string,
    pitchangerate: number,
    toUnit: string
  ) {
    let result = UnitExtensions.ConvertTo(
      new EngineeringValue(pitchangerate, FlowMeasurement.GetFlowUnitInstance(fromUnit)),
      FlowMeasurement.GetFlowUnitInstance(toUnit)
    ).Value;
    return parseFloat((result * 1).toFixed(2));
  }

  public convertAlertPressure(pressureUnit: string, pressure: number): number {
    // convert to psf from ui
    if (pressure === null) return;
    let pressure1;
    pressure = UnitExtensions.ConvertTo(
      new EngineeringValue(pressure, PressureMeasurement.GetPressureUnitInstance(pressureUnit)),
      PoundsPerSqFootGauge.Instance
    ).Value;
    return pressure;
  }

  //this function converts alert temperature values from any to Fahrenheit
  public convertAlertTemperature(
    temperatureUnit: string,
    temperature: number
  ): number {
    if (temperature === null) return;
    let temperature1: number;
    temperature = UnitExtensions.ConvertTo(
      new EngineeringValue(temperature, TemperatureMeasurement.GetTemperatureUnitInstance(temperatureUnit)),
      Fahrenheit.Instance
    ).Value;
    return temperature;
  }

  //convert current units to cfps (Clariti_English units for flow rate)
  public convertAlertPitchangerate(pitchangerateUnit, pitchangerate): number {
    if (pitchangerate === null) return;
    pitchangerate = UnitExtensions.ConvertTo(
      new EngineeringValue(pitchangerate, FlowMeasurement.GetFlowUnitInstance(pitchangerateUnit)),
      CubicFeetPerSec.Instance
    ).Value;
    return pitchangerate;
  }

  //convert current units to cfps (Clariti_English units for gas flow rate)
  public convertAlertGasrate(gasrateUnit, gasrate): number {
    return this.convertAlertPitchangerate(gasrateUnit, gasrate);
  }

  //convert current units to cfps (Clariti_English units for liquid flow rate)
  public convertAlertLiquidrate(liquidrateUnit, liquidrate): number {
    return this.convertAlertPitchangerate(liquidrateUnit, liquidrate);
  }

  public alertConvertForService(
    typeConversion: string,
    unit: string,
    value: number
  ): number {
    let convertedValue: number = this['convertAlert' + typeConversion](
      unit,
      value
    );
    if (typeof convertedValue === 'string') {
      convertedValue = parseFloat(convertedValue);
    }
    // return parseFloat(convertedValue.toFixed(2));
    return convertedValue;
  }

  //Method for flow meter conversion
  //This method assumes the flow rate value is coming in barrells per min from rts
  public convertpitchangerate(
    pitchangerateUnit: string,
    pitchangerate: number
  ): number {
    if (pitchangerate === null || pitchangerateUnit == undefined) return;

    pitchangerate = UnitExtensions.ConvertTo(
      new EngineeringValue(pitchangerate, BarrelsPerMin.Instance),
      FlowMeasurement.GetFlowUnitInstance(pitchangerateUnit)
    ).Value;
    return parseFloat((pitchangerate * 1).toFixed(2));
  }

  //Method for flow meter conversion
  //This method assumes the gas flow value is coming in cubic feet per sec from rts
  public convertgasrate(
    pitchangerateUnit: string,
    pitchangerate: number
  ): number {
    if (pitchangerate === null || pitchangerateUnit == undefined) return;

    pitchangerate = UnitExtensions.ConvertTo(
      new EngineeringValue(pitchangerate, CubicFeetPerSec.Instance),
      FlowMeasurement.GetFlowUnitInstance(pitchangerateUnit)
    ).Value;
    return parseFloat((pitchangerate * 1).toFixed(2));
  }

  //Method for flow meter conversion
  //This method assumes the liquid flow value is coming in cubic feet per sec from rts
  public convertliquidrate(
    pitchangerateUnit: string,
    pitchangerate: number
  ): number {
    if (pitchangerate === null || pitchangerateUnit == undefined) return;

    pitchangerate = UnitExtensions.ConvertTo(
      new EngineeringValue(pitchangerate, CubicFeetPerSec.Instance),
      FlowMeasurement.GetFlowUnitInstance(pitchangerateUnit)
    ).Value;
    return parseFloat((pitchangerate * 1).toFixed(2));
  }

  //Method for temperature conversion from Fahrenheit to Celsius and Kelvin
  public converttemperature(
    temperatureUnit: string,
    temperature: number
  ): number {
    if (temperature === null) return;
    temperature = UnitExtensions.ConvertTo(
      new EngineeringValue(temperature, Fahrenheit.Instance),
      TemperatureMeasurement.GetTemperatureUnitInstance(temperatureUnit)
    ).Value;
      return parseFloat((temperature * 1).toFixed(2));
  }

  //Method for pressure conversion from Pounds per square inch Absolute
  public convertpressure(pressureUnit: string, pressure: number): number {
    if (pressure === null) return;


    pressure = UnitExtensions.ConvertTo(
      new EngineeringValue(pressure, PoundsPerSqInchGauge.Instance),
      PressureMeasurement.GetPressureUnitInstance(pressureUnit)
    ).Value;
    return parseFloat((pressure * 1).toFixed(2));
  }
}
