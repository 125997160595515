import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class GlobalFilterService {
  constructor(@Inject('ENV_CONFIG') private envConfig: any,private http: HttpClient) {}

  getWells(payload: any): Observable<{}> {
    // TODO: Check if fork join can be moved to effects
    // return forkJoin(
    //   userJobs.map((job) => {
    //     const body = {
    //       uidWell: job.WellUid
    //     };
    const body = {jobs: payload['jobs'],
    localFilter: payload['localFilters']
  }
        return this.http
          .post(this.envConfig.api.wellsAndSensors, body)
          .pipe(catchError(this.handleError));
      // })
    // );
  }

  // TODO well should be strongly typed. Get rid of any type.
  // getSensors(wells: any[]): Observable<{}> {
  //   // TODO: Check if fork join can be moved to effects
  //   return forkJoin(
  //     wells.map((well) => {
  //       const body = {
  //         uidWell: well.uidWell,
  //         uid: well.uid
  //       };

  //       return this.http
  //         .post(this.envConfig.api.globalFilter, body)
  //         .pipe(catchError(this.handleError));
  //     })
  //   );
  // }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (err.body) {
        errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
      } else if (err.error.errorMessage) {
        errorMessage = `An error occurred. ${err.status}: ${err.error.errorMessage}`;
      }else if (err.error) {
        errorMessage = `Backend returned code ${err.status}: ${err.error}`;
      }
    }
    return throwError(errorMessage);
  }
}
