import { Injectable } from '@angular/core';
/* NgRx */
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { UserGlobal } from '../../models/user';
import { UnitConversionDBService } from '../../shared/services/unitconversion/unit-conversion-db.service';
import * as unitConversionAction from '../actions/conversionActions';
import { defaultUnitConversion } from '../reducers/conversionReducers';



@Injectable()
export class UnitConversionEffects {
  constructor(
    private actions$: Actions,
    private conversionDBService: UnitConversionDBService
  ) {}

  @Effect()
  loadUnitConversion$: Observable<Action> = this.actions$.pipe(
    ofType(unitConversionAction.UnitConversionActionTypes.LoadUnitConversion),
    map((action: unitConversionAction.LoadUnitConversion) => action.payload),
    mergeMap((user: UserGlobal) => {
      return this.conversionDBService.getUnitConversion(user).pipe(
        map((res) => {
          if (res && res.length > 0) {
            return new unitConversionAction.LoadUnitConversionSuccess(res[0]);
          } else {
            return new unitConversionAction.LoadUnitConversionSuccess(defaultUnitConversion.conversion);
          }
      }));
    })
  );

  @Effect()
  updateUnitConversion$: Observable<Action> = this.actions$.pipe(
    ofType(unitConversionAction.UnitConversionActionTypes.UpdateUnitConversion),
    map((action: unitConversionAction.UpdateUnitConversion) => action.payload),
    mergeMap((payload) => {
      return this.conversionDBService.updateUnitConversion(payload).pipe(
        map((res) => {
          if (res) {
            localStorage.setItem('refreshFlag', '1');
            setTimeout(() => {
               location.reload();
           }, 3000);
            return new unitConversionAction.LoadUnitConversionSuccess(payload['unitConversion']);
          } else {
            return new unitConversionAction.LoadUnitConversionSuccess(defaultUnitConversion.conversion);
          }
      }));
    })
  );
}
