import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import sensorInfo from '../../../assets/data/sensorTypeConfig.json';
import { IAlert } from './../../models/alert';
import { Conversion } from './../../models/converterData';
import { AlertService } from './../../services/alert.service';
import { ConversionService } from './../../shared/services/conversion.service';
import * as alertActions from './../../state/actions/alert-page.actions';
import * as fromAlert from './../../state/container-states/alert.state';
import { State } from './../../state/container-states/app.state';
import * as fromFilter from './../../state/container-states/global-filter.state';
import { Well } from './../../state/reducers/global-filter.reducer';


@Component({
  selector: 'app-alert-rule',
  templateUrl: './alert-rule.component.html',
  styleUrls: ['./alert-rule.component.scss'],
})
export class AlertRuleComponent implements OnInit, OnDestroy {
  editAlertMode: boolean;
  sensorUnit: {} = {
    label: '',
    value: ''
  };
  converterData: Conversion;
  alertData: IAlert;
  alertLimitChanged: boolean;
  wellList: Well[];
  unsubscribe$ = new Subject();
  upperLimit;
  displayName = '';
  lowerLimit;
  saving: boolean;
  deleting = false;
  @Input() isDuplicateAlarm = false;
  @Input() alert: IAlert;
  @Input() alertsList:  IAlert[] ;
  @Output() alertEditModeOn: EventEmitter<boolean> = new EventEmitter();
  @Output() startRuleSaving: EventEmitter<boolean> = new EventEmitter();
  @Output() startRuleDelete: EventEmitter<boolean> = new EventEmitter();
  sensorInfo: any[];

  constructor(
    public store: Store<State>,
    private alertState: Store<fromAlert.AlertPageState>,
    private alertService: AlertService,
    private filterState: Store<fromFilter.GlobalFilterState>,
    private conversionService: ConversionService
  ) {
    this.sensorInfo = sensorInfo.sensorData;
  }

  ngOnInit() {
    this.store.select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState) => {
        this.converterData = conversionState.conversion;
        if (this.alert && this.alert.AlarmDetail) {
          const { ConvertedUpperLimit, ConvertedLowerLimit } = this.alert.AlarmDetail;
          const alertSensorUnit = this.alert.AlarmDetail.UnitType.toLowerCase();
          this.sensorUnit = this.getSensorUnitType(alertSensorUnit, this.converterData);
          this.sensorInfo.forEach((sensorList) => {
            // converting the alert set uom to user set uom
            if (alertSensorUnit === sensorList.sensorType) {
              const type = sensorList.sensorType;
              let convertedType = type && type.charAt(0).toUpperCase() + type.substring(1, type.length);
              this.upperLimit = ConvertedUpperLimit || ConvertedUpperLimit === 0 ? this.conversionService['alertConvert' + convertedType](
                this.sensorUnit['value'], ConvertedUpperLimit
              ) : null;
              this.lowerLimit = ConvertedLowerLimit || ConvertedLowerLimit === 0 ? this.conversionService['alertConvert' + convertedType](
                this.sensorUnit['value'], ConvertedLowerLimit
              ) : null;
            }
          });
          this.displayName = this.alert.AlarmDetail.displayName;
          this.loadSensorEditedNames(this.alert.AlarmDetail.MnemonicAlias, this.alert['WellName']);
        }

    });

    this.filterState
      .pipe(select(fromFilter.getWellsList), takeUntil(this.unsubscribe$))
      .subscribe((wells: Well[]) => {
        this.wellList = wells;
    });
  }

  loadSensorEditedNames(sensorOriginalName: string, sensorWellName: string) {
    this.store
      .select((state: State) => state.user)
      .pipe(first())
      .subscribe((user) => {
        if (
          user &&
          user.preferences &&
          user.preferences.length > 0
        ) {
          const userSensors = user.preferences;
          userSensors.map((userSensor) => {
            if (userSensor.originalSensorLabel === sensorOriginalName && userSensor['sensorWellName'] === sensorWellName) {
              this.displayName = userSensor.renamedSensorLabel;
            }
          });
        }
      });
  }

  editAlert() {
    this.editAlertMode = true;
    this.isDuplicateAlarm = false;
    this.alertEditModeOn.emit(this.editAlertMode);
    this.alertService.changeStatus('true');
  }

  cancelEditAlert() {
    this.editAlertMode = false;
    this.alertEditModeOn.emit(this.editAlertMode);
    this.alertService.changeStatus('false');
  }

  alertModified(){
    this.isDuplicateAlarm = false;
  }

  saveAlert(data: { [key: string]: any }) {
    if(this.alertsList.length > 0){
     let existingExactAlert = this.alertsList.filter((alert) => {
       let alertDetail = alert.AlarmDetail;
       let newOrEditAlertDetail = this.alert.AlarmDetail;

       let convertedupperLimit: number;
       let convertedlowerLimit: number;
       if (alertDetail.MnemonicName === newOrEditAlertDetail.MnemonicName) {
         const { UpperLimit, LowerLimit } = alertDetail;
         const alertSensorUnit = alertDetail.UnitType.toLowerCase();
         this.sensorUnit = this.getSensorUnitType(alertSensorUnit, this.converterData);

         const convertedType = newOrEditAlertDetail.UnitType;
         convertedupperLimit = UpperLimit || UpperLimit === 0 ? this.conversionService['alertConvert' + convertedType](
           this.sensorUnit['value'], UpperLimit
         ) : null;
         convertedlowerLimit = LowerLimit || LowerLimit === 0 ? this.conversionService['alertConvert' + convertedType](
           this.sensorUnit['value'], LowerLimit
         ) : null;
       }

       return convertedlowerLimit == data.lower && convertedupperLimit == data.upper;
     });
     if(existingExactAlert.length > 0){
      this.isDuplicateAlarm = true;
       return false;
     }
    }

    this.saving = true;
    this.startRuleSaving.emit(true);
    const alertPayload: IAlert = this.alert;
    const type = alertPayload.AlarmDetail.UnitType.toLowerCase();
    const typeConversion = type && type.charAt(0).toUpperCase() + type.substring(1, type.length);
    alertPayload.AlarmDetail.UpperLimit = data.upper === null ? data.upper : this.conversionService.
        alertConvertForService(typeConversion,
          this.converterData[type + 'Unit'],
          data.upper
        );
    alertPayload.AlarmDetail.LowerLimit = data.lower === null ? data.lower : this.conversionService.
        alertConvertForService(typeConversion,
          this.converterData[type + 'Unit'],
          data.lower
        );

    // Setting email and notification status back to default, as we are adding new alert
    alertPayload.AlarmDetail.NotificationStatus = 0;
    alertPayload.AlarmDetail.EmailNotificationStatus = 1;
    this.alertState.dispatch(new alertActions.UpdateAlertRule({
      alarm: alertPayload,
      wells: this.wellList,
    }));

    this.lowerLimit = data.lower;
    this.upperLimit = data.upper;
    this.cancelEditAlert();
  }

  deleteAlert(alert: IAlert) {
    this.alertState.dispatch(new alertActions.DeleteAlertRule({
      alert,
      wells: this.wellList,
    }));

    this.cancelEditAlert();
    this.deleting = true;
    this.startRuleDelete.emit(this.deleting);
  }

  getSensorUnitType(alertSensorUnit: string, converterData: Conversion) {
    let result = {
      value: '',
      label: ''
    };
    this.sensorInfo.forEach((sensorList) => {
      if (alertSensorUnit === sensorList.sensorType) {
        sensorList.sensorUnits.forEach((sensorUnit) => {
          if (sensorUnit.value == converterData[sensorList.sensorType + 'Unit']) {
            result = {
              label: sensorUnit.label,
              value: sensorUnit.value
            };
          }
        });
      }
    });
    return result;
  }

  isDegreeRequired(): boolean {
    return this.sensorUnit === 'C' || this.sensorUnit === 'F';
  }

  ngOnDestroy(): void {
    this.cancelEditAlert();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
