import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]'
})
export class OnlyNumber {
  regex: RegExp;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) { }

  ngOnInit() {
    if (this.NumberType === 'whole') {
      this.regex = new RegExp('[1-9][0-9]*');
    }

    if (this.NumberType === 'decimal') {
      this.regex = new RegExp('^[+-]?[0-9]{0,900}(?:\\.[0-9]{0,900})?$');
    }

    if (this.NumberType === 'integer') {
      this.regex = new RegExp('^-?\\d*\\.{0,1}\\d+$');
    }
  }

  @Input() NumberType: string;

  @HostListener('keydown', ['$event']) onKeyDown(event) {

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
      }
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
        if (!(this.NumberType === 'integer' && current.length === 0 && event.key === '-')) {
          event.preventDefault();
        }
      }
   }
}
