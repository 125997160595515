<div *ngIf='alertData && alertData.AlarmDetail && alertData.AlarmDetail.NotificationMessage.trim() !== "" ' class='ui-g alert-card'>
  <div class='alert-card-header'>
    <div class='ui-g-12'>
      <div>{{ alertData.AlarmDetail.displayName }}</div>
      <div class="alertCardWellName">({{wellInformation[alertData.UidWell]}})</div>
    </div>
  </div>
  <div class='alert-card-content'>
    <div class="alert-data">
      <div class='alert-data-content ui-g-12'>
        <p>{{ alertData.AlarmDetail.NotificationMessage }}</p>
      </div>
    </div>
    <div class='ui-g-12 alert-overlay' *ngIf="showOverlay">
      <div class='dismiss-alert'>
        <button class="dismiss-alert-btn btn-secondary" (click)='acknowledgeAlert(alertData)'>Acknowledge</button>
      </div>
      <div class='view-graph' (click)='openAlertDetails()'>
        <span>VIEW GRAPH</span>
      </div>
    </div>
  </div>
</div>
