
const getWorkerPath = () => {
    return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js");
 
};

const workerPath = getWorkerPath().default;
// import * as workerPath from 'file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js';
import * as JsStore from 'jsstore';

// @Injectable({
//   providedIn: 'root',
// })
export const idbCon = new JsStore.Connection(new Worker(workerPath));
// export class DbConnectionService {
//  // this will make sure that we are using one connection
//  // otherwise multiple instance will be created and thus multiple
//  // worker and that may create some problems
//  static idbCon = new JsStore.Instance(new Worker(workerPath));
// }
