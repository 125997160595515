import { Action } from '@ngrx/store';
export enum EChartActions {
    GetLiveRequest = '[chart] get live request',
    GetLiveRequestSuccess = '[chart] get live request success',
    GetHistoricalData = '[chart] get historical data',
    GetHistoricalDataSuccess = '[chart] get historical data success',
    GetLivePool = '[chart] get live data',
    GetLivePoolSuccess = '[chart] get live data success',
    GetResetState = '[chart] get the reset state',
    GetWaitIndicator = '[chart] get wait indicator state ',
    GetAxisList = '[chart] get axis list',
    GetLivePoolFail = '[chart] get live pool fail',
    GetLiveRequestFail = '[chart] get live request fail',
    GetHistoricalFail = '[chart] get historical fail',
    GetOriginalData = '[chart] get original data success',
    GetHistoricalRaw = '[chart] get raw historical data'
}

export class GetLiveRequest implements Action
{
    public readonly type = EChartActions.GetLiveRequest;
    constructor(public payload: any){}
}
export class GetOriginalData implements Action
{
    public readonly type = EChartActions.GetOriginalData;
    constructor(public payload: any){}
}
export class GetLiveRequestSuccess implements Action
{
    public readonly type = EChartActions.GetLiveRequestSuccess;
    constructor(public payload: any){}
}
export class GetHistoricalData implements Action
{
    public readonly type = EChartActions.GetHistoricalData;
    constructor(public payload: any){}
}
export class GetHistoricalDataSuccess implements Action
{
    public readonly type = EChartActions.GetHistoricalDataSuccess;
    constructor(public payload: any){}
}

export class GetLivePool implements Action
{
    public readonly type = EChartActions.GetLivePool;
    constructor(public payload: any){}
}
export class GetLivePoolSuccess implements Action
{
    public readonly type = EChartActions.GetLivePoolSuccess;
    constructor(public payload: any){}
}
export class GetResetState implements Action
{
    public readonly type = EChartActions.GetResetState;
}
export class GetWaitIndicator implements Action
{
    public readonly type = EChartActions.GetWaitIndicator;
    constructor(public payload: any){}
}
export class GetAxisList implements Action
{
    public readonly type = EChartActions.GetAxisList;
    constructor(public payload: any){}
}
export class GetLivePoolFail implements Action {
  public readonly type = EChartActions.GetLivePoolFail;
  constructor(public payload: any) { }
}
export class GetLiveRequestFail implements Action {
  public readonly type = EChartActions.GetLiveRequestFail;
  constructor(public payload: any) { }
}
export class GetHistoricalFail implements Action {
  public readonly type = EChartActions.GetHistoricalFail;
  constructor(public payload: any) { }
}
export class GetHistoricalRaw implements Action {
    public readonly type = EChartActions.GetHistoricalRaw;
    constructor(public payload: any) { }
  }

export type ChartActions = GetLiveRequest
|GetLiveRequestSuccess
|GetHistoricalData
|GetHistoricalDataSuccess
|GetLivePool
|GetLivePoolSuccess
|GetResetState
|GetWaitIndicator
|GetAxisList
|GetLivePoolFail
|GetLiveRequestFail
|GetHistoricalFail
|GetOriginalData
|GetHistoricalRaw;
