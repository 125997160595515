import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAlerts from '../reducers/alert-page.reducer';
import * as fromRoot from './app.state';

// Extends the app state to include the Manage Alert Rules feature.
// This is required because alerts are lazy loaded.
// So the reference to AlertRulesState cannot be added to app.state.ts directly.
export interface AlertPageState extends fromRoot.State {
    alerts: fromAlerts.AlertRulesState;
}

// Selector functions
const getAlertRulesFeatureState = createFeatureSelector<fromAlerts.AlertRulesState>('alertRules');

export const getCurrentAlertId = createSelector(
    getAlertRulesFeatureState,
    (state) => state.currentAlertRule
);

export const getCurrentAlert = createSelector(
    getAlertRulesFeatureState,
    getCurrentAlertId,
    (state, currentAlertId) => {
        if (currentAlertId === '') {
            return {
                // Create dummy alert
            };
        } else {
            return currentAlertId ? state.alerts.find((p) => p.AlarmID === currentAlertId) : null;
        }
    }
);

export const getAlertRules = createSelector(
    getAlertRulesFeatureState,
    (state) => state ? state.alerts : ''
);
export const getAlertState = createSelector(
    getAlertRulesFeatureState,
    (state) => state
);

export const getError = createSelector(
    getAlertRulesFeatureState,
    (state) => state.error
);
