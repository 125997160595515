export const ERR_MESSAGES = {
    error401: 'Unauthorization access or bad request.',
    error500: 'Server error. Please try again or contact support.',
    error200: 'Something went wrong, Please try again later.',
    error400: 'Requested resource is not available, Please try again later.',
    MOBILE_API_CONFIG_762: 'Unable to add alarm, please check if you are not trying to add already added sensor.',
    MOBILE_API_PROCESSOR_661: 'Something went wrong, Please Refresh',
    USER_NO_DATA: 'User credentials do not have access to any wells.',
    USER_ALREADY_LOGGEDIN: 'There is already a logged in user please refresh the page',
    NO_CONNECTIVITY: 'Application is Offline. Please connect to network and try again',
    INVALID_USER_PASSWORD: 'Invalid Username or password',
    TOKEN_EXPIRED : 'Please contact an Administrator. Email: RTO@Halliburton.com. RTO Support: +1.713.839.4315. Toll Free:+1.877.263.6071',
    ERR_ALERT_DELETE_FAIL : 'Unable to delte alert, Please try again later.',
    ERR_ALERT_UPDATE_FAIL : 'Unable to Edit alert, Please try again later.',
    ERR_ALERT_ISVALID : 'Unable to parse invalid alert.',
    ERR_PANDT_ISVALID : 'Unable to parse p & T Invalid alert',
    ERR_GLOBAL_FILTER_ISVALID : 'Unable to parse Global Filter on Invalid alert.',
    ERR_PANDT_SERVICE_ISVALID : 'Unable to parse p & T data on Invalid alert.',
    WARN_DELETE_ALL_ALERTS : 'This will delete all alerts. Are you sure you want to continue ?',
    WARN_DUPLICATE_ALERT : 'This alert already exists',
    WARN_LIVE_CHART_EXPORT : 'If required, edit the file name in below field.',
    WARN_EXPORT : 'Data Export selected. Do you want to continue ?',
    API_SECURITY_703: 'Authentication failed: Security token could not be validated. Login again.',
    OKTA_SESSION_EXPIRED: 'Session expired. Login again.',
    ENTITLEMENTS_UPDATED_PROMPT: 'Logging out... User permissions have changed. Login again to update the session',
    SESSION_EXPIRED: 'Logging out... Session expired. Login again'
    // ADD MORE ERROR KEYS HERE
};
