<div class='add-alert-modal'>
  <p-dialog [(visible)]="openDialog"></p-dialog>
  <p-dialog
    [(visible)]="openDialog"
    [modal]="true"
    [responsive]="true"
    [focusOnShow]="false"
    (onHide)="closeDialog()"
    [breakpoint]="767"
    [maximizable]="false"
    [baseZIndex]="10000"
    [resizable]="false"
    (onShow)='loadWells()'
    [blockScroll]='true'
    [draggable]="false"
    [positionTop] = 10
    [positionLeft] = 100
    [minX] = 70
    [style]="{'max-height':'calc(80vh - 235px)','min-height':'150px', 'min-width':'350px'}"
    [className]="selectedWell ? 'sm-move-class min-max-width-margin':''">
  <p-header>
    <span *ngIf='!goToNextLevel' class='black-label2'>Select Sensor for New Rule</span>
    <span *ngIf='goToNextLevel' class='black-label2'>Set Min. and Max. Value</span>
  </p-header>
  <div *ngIf='!goToNextLevel && wellList.length == 0' class='ui-g add-alert-modal-content'>
    <div class='global-spinner-container'>
      <div class='global-spinner'></div>
    </div>
  </div>
  <div *ngIf='!goToNextLevel && wellList.length > 0' class='ui-g add-alert-modal-content'>
    <div [ngClass]="selectedWell ? 'ui-md-6' : 'ui-g-12'" class='width-100 ui-sm-12 modal-content-header'>
      <div [ngClass]="ui-g-12" class='filter-title'>
        <span>Well</span>
        <span> (Select One)</span>
      </div>
      <div *ngIf='wellList' [ngClass]="ui-g-12"
      class='alert-option-lists-container'>
      <div class='alert-option' *ngFor='let well of wellList'>
        <p-radioButton class="display-inline-flex"
          label='{{well.wellName}}'
          name='wellName'
          value='{{well.wellName}}'
          (onClick)='filterSensorsList(well)'>
        </p-radioButton>
      </div>
    </div>
    </div>
    <div [ngClass]="selectedWell ? 'ui-md-6' : 'ui-g-12'" class='ui-g-6 ui-sm-12 width-100 modal-content-header'>
      <div *ngIf='selectedWell' class='ui-g-12 filter-title'>
        <span>Sensor</span>
        <span> (Select One)</span>
      </div>
      <div *ngIf='selectedWell && sensorList' class='alert-option-lists-container ui-g-12' style="border-left: 1px solid #d4d8df !important;">
        <div class='alert-option' *ngFor='let sensor of sensorList'>
          <p-radioButton class="display-inline-flex"
            label='{{sensor.wellName}} - {{sensor.displayName}}'
            name='sensorName'
            value='{{sensor.displayName}}'
            (onClick)='selectSensor(sensor)'>
          </p-radioButton>
        </div>
      </div>
  </div>
  </div>
  <div
    *ngIf='goToNextLevel'
    class='ui-g min-max-container'>
    <div class='ui-g-12'>
      <div class='ui-g-2'>Min.</div>
      <div class='ui-g-10'>
        <input
          [(ngModel)]='alertLowerLimit'
          (ngModelChange)='limitChanged($event)'
          class='min-max-value'
          type="text"
          pInputText>
          <span>
            {{sensorUnit.label}}
          </span>
      </div>
    </div>
    <div class='ui-g-12'>
      <div class='ui-g-2'>Max.</div>
      <div class='ui-g-10'>
        <input
          [(ngModel)]='alertUpperLimit'
          (ngModelChange)='limitChanged($event)'
          class='min-max-value'
          type="text"
          pInputText>
          <span>
            {{sensorUnit.label}}
          </span>
      </div>
      <div *ngIf='isDuplicateAlarm' class='warning-text'>This alert already exists.</div>
    </div>
  </div>
  <p-footer>
    <button *ngIf='!creating' class='btn-secondary btn-cancel' (click)='closeDialog()'>Cancel</button>
    <button
    *ngIf='!creating'
     class='btn btn-apply'
     (click)='addAlert()'
     [disabled]="!selectedWell || !selectedSensor || invalidMinMaxLimit || limit"
      [ngClass]='{"active": selectedWell && selectedSensor && !invalidMinMaxLimit && !limit}'>
        <span *ngIf='!goToNextLevel'>Select</span>
        <span *ngIf='goToNextLevel'>Add Alert</span>
      </button>
    <img *ngIf='creating' class='spinner' src='../../../../assets/icons/icon-export-in-progess.svg'>
  </p-footer>
</p-dialog>
</div>
