import { IMeasurementClass } from '../../../models/measurement';
import { IUnit } from '../../../models/measurement';
import { BilinearTransform } from '../BilinearTransform';
import { Pascals } from './Pascals';

export class KilopascalsAbsolute implements IUnit<IMeasurementClass> {
  static readonly Instance: KilopascalsAbsolute = new KilopascalsAbsolute();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Pascals.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(0.001,0,0,1);
  }
}
