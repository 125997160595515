import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { ExportSortBy } from "src/app/models/enums/exportSortBy";
import { SortDirection } from "src/app/models/enums/sortDirection";
import { ExportJob } from "src/app/models/export/exportJob";
import { ExportJobState, ExportProgressMessage } from "src/app/models/exportProgressMessage";
import { ExportFilter } from "src/app/models/query-params/exportFilter";
import { ExportService } from "src/app/services/export.service";
import { HttpStatusCodes } from "src/app/shared/constants/constants";
import { defaultExportFileName, fileHeaders } from "src/app/shared/constants/export-constants";
import { downloadBlob, getFileNameFromUrl } from "src/app/shared/utilities/util";

@Component({
    selector: 'app-export-history',
    templateUrl: './export-history.component.html',
    styleUrls: ['./export-history.component.scss']
})

export class ExportHistoryComponent implements OnInit, OnDestroy {
    @Input() exports: ExportJob[];

    private unsubscribe$ = new Subject();
    private deleteExportSet: Set<string> = new Set<string>();
    private cancelExportSet: Set<string> = new Set<string>();

    constructor(
        private exportService: ExportService,
        private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.exportService.exportProgressUpdateEvent.pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => this.handleExportProgressUpdate(data));
        this.exportService.reloadExports.pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.loadExports());
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private loadExports() {
        var exportsFilter = new ExportFilter();
        exportsFilter.includeDeleted = false;
        exportsFilter.sorting = SortDirection.Descending;
        exportsFilter.sortBy = ExportSortBy.CreationDate;
        this.exportService.getExports(exportsFilter)
            .subscribe(result => this.exports = result);
    }

    private getExportHeader(exportJob: ExportJob) : string {
        var state = this.getExportStateNumber(exportJob.progress.state);
        if(state === ExportJobState.Failed)
            return fileHeaders.failed;

        if(exportJob.progress.createdFileUri)
            return getFileNameFromUrl(exportJob.progress.createdFileUri, defaultExportFileName);

        var isCancelled = state === ExportJobState.Cancelled;
        return isCancelled ? fileHeaders.cancelled : fileHeaders.created;
    }

    private handleExportProgressUpdate(progressMessage: ExportProgressMessage) {
        var exportJob = this.exports.find(x => x.exportJobId === progressMessage.jobId);

        if(!exportJob) {
            this.loadExports();
        } else {
            this.updateExportJob(exportJob, progressMessage);
        }
    }

    private updateExportJob(exportJob: ExportJob, progressMessage: ExportProgressMessage) {
        exportJob.progress.createdFileUri = progressMessage.fileUri;

        if(progressMessage.initializationProgress > exportJob.progress.initializationProgress) {
            exportJob.progress.retrievalProgress = progressMessage.retrievalProgress;
            exportJob.progress.initializationProgress = progressMessage.initializationProgress;
        }

        if(progressMessage.state > parseInt(ExportJobState[exportJob.progress.state]))
            exportJob.progress.state = ExportJobState[progressMessage.state.toString()];

        if(progressMessage.state === ExportJobState.Failed)
            exportJob.progress.error = progressMessage.error;

        //NOTE: export download implementation for downloads with SAS tokens
        // if(progressMessage.state == ExportJobState.Completed) {
        //     exportJob.progress.downloadUrl = progressMessage.sasUrl;
        //     exportJob.progress.downloadUrlExpiry = progressMessage.sasUrlExpiry;
        // }

        this.changeDetector.detectChanges();
    }

    private downloadExport(exportJob: ExportJob) {
        this.exportService.downloadStatus.emit({ downloading: true });

        this.exportService.getExportFile(exportJob.exportJobId)
            .subscribe((response) => {
                downloadBlob(response, getFileNameFromUrl(exportJob.progress.createdFileUri, defaultExportFileName));

                this.exportService.downloadStatus.emit({ downloading: false });
            },
            () => this.exportService.downloadStatus.emit({ downloading: false }));
    }

    private deleteExport(exportJob: ExportJob) {
        if(this.deleteExportSet.has(exportJob.exportJobId))
            return;

        this.deleteExportSet.add(exportJob.exportJobId);
        this.exportService.deleteExport(exportJob.exportJobId)
            .subscribe(
                result => {
                    var index = this.exports.findIndex(x => x.exportJobId === exportJob.exportJobId);
                    if(index !== -1) {
                        this.exports.splice(index, 1);
                    }

                    this.deleteExportSet.delete(exportJob.exportJobId);
                },
                () => this.deleteExportSet.delete(exportJob.exportJobId));
    }

    private cancelExport(exportJob: ExportJob) {
        if(this.cancelExportSet.has(exportJob.exportJobId))
            return;

        this.cancelExportSet.add(exportJob.exportJobId);
        this.exportService.cancelExport(exportJob.exportJobId)
            .subscribe(
                result => {
                    if(result.status !== HttpStatusCodes.Ignored) {
                        exportJob.progress.state = ExportJobState[ExportJobState.Cancelling.toString()];
                    }

                    this.cancelExportSet.delete(exportJob.exportJobId);
                },
                () => this.cancelExportSet.delete(exportJob.exportJobId));
    }

    private canCancelExport(exportJob: ExportJob) : boolean {
        var state = this.getExportStateNumber(exportJob.progress.state);
        var canCancel =  state < ExportJobState.Completed;

        return canCancel;
    }

    private canDeleteExport(exportJob: ExportJob) : boolean {
        var state = this.getExportStateNumber(exportJob.progress.state);
        var canDelete = state === ExportJobState.Completed || state === ExportJobState.Cancelled || state === ExportJobState.Failed;

        return canDelete;
    }

    private canDownloadExport(exportJob: ExportJob) : boolean {
        var state = this.getExportStateNumber(exportJob.progress.state);
        var canDownload = state === ExportJobState.Completed;

        return canDownload;
    }

    private isExportInCancellation(exportJob: ExportJob): boolean {
        var state = this.getExportStateNumber(exportJob.progress.state);
        var isCancellation = state === ExportJobState.Cancelling || state === ExportJobState.Cancelled;

        return isCancellation;
    }

    private isExportFailed(exportJob: ExportJob) : boolean {
        var state = this.getExportStateNumber(exportJob.progress.state);
        var isFailed = state === ExportJobState.Failed;

        return isFailed;
    }

    private getExportStateNumber(state: ExportJobState) : number {
        return ExportJobState[state.toString()];
    }

    //NOTE: export download implementation for downloads with SAS tokens
    // private downloadExport(exportJob: ExportJob) {
    //     var expiryDate = new Date(exportJob.progress.downloadUrlExpiry);
    //     if(expiryDate.toISOString() > new Date().toISOString() && exportJob.progress.downloadUrl) {
    //         this.openExportDownloadUrl(exportJob.progress.downloadUrl);
    //     } else {
    //         this.exportService.getExportDownloadInfo(exportJob.exportJobId)
    //             .subscribe((downloadInfo) => {
    //                 exportJob.progress.downloadUrl = downloadInfo.url;
    //                 exportJob.progress.downloadUrlExpiry = downloadInfo.expiry;

    //                 window.open(exportJob.progress.downloadUrl, '_blank');
    //             });
    //     }
    // }
}