import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
import { LabelEditComponent } from 'src/app/components/label-edit/label-edit.component';
import { MeasurementComponent } from 'src/app/components/measurements/measurement.component';
import { SensorDisplayComponent } from 'src/app/components/sensor-display/sensor-display.component';
import { LiveReadingsComponent } from './live-readings.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EnableIfAdminDirective } from './../../directives/role-based-enable.directive';


@NgModule({
  imports: [
    DialogModule,
    ListboxModule,
    CommonModule,
    FormsModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [
    EnableIfAdminDirective,
    MeasurementComponent,
    LiveReadingsComponent,
    LabelEditComponent,
    SensorDisplayComponent
  ],
  exports: [
    MeasurementComponent,
    LiveReadingsComponent,
    LabelEditComponent,
    MatButtonModule,
    MatIconModule
  ],

})
export class LiveReadingsModule { }
