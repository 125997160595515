import { Injectable } from '@angular/core';

@Injectable()
export class UserGlobal {
    waiToken: string;
    email: string;
    accessToken: string;
    IWJob: any[];
    ApiServer?: string;
    RtsServer?: string;
    IsIWSupportUser?: boolean;
    OAGVersion?: string;
    token?: string;
    roles?: string[];
}

// tslint:disable:interface-name
export interface UserPreferences {
  sensors: UserSensors[];
}

export interface UserSensors {
  uid?: number;
  userId: string;
  originalSensorLabel: string;
  renamedSensorLabel: string;
}

export interface UserFilters {
  uid?: number;
  userId: string;
  filters: FilterSelected[];
}

export interface FilterSelected {
  wellName: string;
  sensorName: string;
  sensorType:string;
}
