<div id="myMap" style="width: 100%; height: 90%; display: block;" ></div>

<div class="loading-view" *ngIf="isApiInProgress">
  <app-loading-spinner [textColor]="textColor" [dashbordLoading]='isApiInProgress'></app-loading-spinner>
</div>

<p-dialog [(visible)]="showDialog" [draggable]="false" [showHeader]="false" [blockScroll]="true" [modal]="true"
  [breakpoint]="500" [style]="{'min-width': '200px','width':'500px'}" class="centered-dialog" [responsive]="true" [contentStyle]="{'overflow':'auto'}" appendTo="body">
  <div class="error-dialog">
    <div class="col-md-12">
      <p *ngIf="showDialog && !isApiInProgress" class="header-text">No sensor data available for the selected well.</p>
      <p *ngIf="showDialog && isApiInProgress" class="header-text">Data for this well is still being loaded.</p>
    </div>
    <div class="col-md-12 button-div">
      <button pButton type="button" class="confirmation-button" (click)="closeErrorDialog()" label="OK">OK</button>
    </div>
  </div>
</p-dialog>