import { IMeasurementClass } from '../../models/measurement';
import { IUnit } from '../../models/measurement';
import { Meter } from './Depth/Meter';
import { Feet } from './Depth/Feet';

export class DepthMeasurement implements IMeasurementClass {
  static readonly Instance: DepthMeasurement = new DepthMeasurement();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Feet.Instance;
  }

  static GetDepthUnitInstance(unitText:string){
    if (!unitText) {
      return Feet.Instance;
    }
  
    switch (unitText.toLowerCase()) {
      case 'm':
        return Meter.Instance;
      case 'ft':
        return Feet.Instance;
      default:
        return Feet.Instance;
    }
  }
}
