import { IUnit, IMeasurementClass } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";
import { Pascals } from "./Pascals";



export class PoundsPerSqFootGauge implements IUnit<IMeasurementClass> {
  static readonly Instance: PoundsPerSqFootGauge = new PoundsPerSqFootGauge();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Pascals.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(0.02088543, -2116.8, 0, 1);
  }
}
