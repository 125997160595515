import { IAlert } from '../../models/alert';
import { AlertRulesActions, AlertRulesActionTypes } from '../actions/alert-page.actions';

// State for this feature (Alert Rules)
export interface AlertRulesState {
    alerts: IAlert[];
    currentAlertRule: string | null;
    error: string;
    sensorList: any;
    alertsLoadCompleted: boolean;
}

export class AlertRule {
    id: number;
    well: string;
    sensor: string;
    min: number | null;
    max: number | null;
}

const initialState: AlertRulesState = {
    alerts: [],
    currentAlertRule: null,
    error: '',
    sensorList: [],
    alertsLoadCompleted:false,
};

export function alertReducer(state = initialState, action: AlertRulesActions): AlertRulesState {

    switch (action.type) {

        case AlertRulesActionTypes.SetCurrentAlertRule:
            return {
                ...state,
                currentAlertRule: action.payload.AlarmID
            };

        case AlertRulesActionTypes.LoadAlertRulesSuccess:
            // Very Basic comparison to update state only if response object changes
            if (JSON.stringify(state.alerts) === JSON.stringify(action.payload)) {
                return {
                    ...state,
                    alertsLoadCompleted:true
                };
            }
            return {
                ...state,
                alerts: action.payload,
                alertsLoadCompleted: true,
                error: ''
            };

        case AlertRulesActionTypes.LoadAlertRulesFail:
            return {
                ...state,
                alerts: [],
                alertsLoadCompleted: true,
                error: action.payload
            };

        case AlertRulesActionTypes.UpdateAlertRuleSuccess:
            const updatedRule = state.alerts.map(
                (item) => action.payload.AlarmID === item.AlarmID ? action.payload : item);
            return {
                ...state,
                alerts: updatedRule,
                currentAlertRule: action.payload.AlarmID,
                error: ''
            };

        case AlertRulesActionTypes.UpdateAlertRuleFail:
            return {
                ...state,
                alerts: [...state.alerts],
                error: action.payload
            };

        case AlertRulesActionTypes.CreateAlertRuleSuccess:
            return {
                ...state,
                alerts: [...state.alerts, action.payload],
                currentAlertRule: action.payload.AlarmID,
                error: ''
            };

        case AlertRulesActionTypes.CreateAlertRuleFail:
            return {
                ...state,
                error: action.payload
            };

        // After a delete, the currentProduct is null.
        case AlertRulesActionTypes.DeleteAlertRuleSuccess:
            return {
                ...state,
                alerts: state.alerts.filter((rule) => rule.AlarmID !== action.payload),
                currentAlertRule: null,
                error: ''
            };

        case AlertRulesActionTypes.DeleteAlertRuleFail:
            return {
                ...state,
                error: action.payload
            };

        case AlertRulesActionTypes.AcknowledgeAlertSuccess:
            return {
                ...state,
                alerts: [...state.alerts]
            };

        case AlertRulesActionTypes.AcknowledgeAllAlerts:
            return {
                ...state,
                alerts: [...state.alerts]
            };
        case AlertRulesActionTypes.ActionBatteryVoltageSuccess:
            return {
                ...state,
                sensorList: action.payload,
            };
        case AlertRulesActionTypes.ActionBatteryVoltageFail:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}
