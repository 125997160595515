/**
 * display selector Component code
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartStoreService } from '../../services/chart.service';
import { IChartstate } from '../../state/container-states/chart.state';
import sensorInfo from './../../../assets/data/sensorTypeConfig.json';

@Component({
  selector: 'app-display-selector',
  templateUrl: './display-selector.component.html',
  styleUrls: ['./display-selector.component.scss']
})
export class DisplaySelectorComponent implements OnInit, OnDestroy {

  public selectedType: string;
  pressureCount: any;
  temperatureCount: any;
  @Output()
  sendSelectedValue = new EventEmitter();

  @Input()
  items: Array<{ text: string; value: any }>;
  @Input()
  disabled: boolean;
  unsubscribe$ = new Subject();
  shownType: string;
  sensorInfo: any[];
  canHover: boolean;
  isDesktopDevice: boolean;

  constructor(private chartStore: Store<IChartstate>,
              private deviceService: DeviceDetectorService,
              private chartStoreService: ChartStoreService) {
                this.sensorInfo = sensorInfo.sensorData.filter((item:any) => { // getting sensors from config file
                  return item.settings === true;
                });
              }

  public ngOnInit(): void {
    this.getDeviceInfo();
    this.chartStoreService.previousType.pipe(takeUntil(this.unsubscribe$)).subscribe((previousType: any) => {
      this.clickOnButton(previousType,false,false);
    });
    this.chartStoreService.currentAxisList.pipe(takeUntil(this.unsubscribe$)).subscribe((payload: any) => {
      if (payload.fromSettings) {
          this.getShownType(payload.axisList);
      }
    });
    this.selectedType = this.items[0] && this.items[0].value;
    const payload = {
      typeSelected: this.selectedType,
      clicked: this.selectedType ? true : false
    };
    this.sendSelectedValue.emit(payload);
    this.chartStoreService.changedGlobal.pipe(takeUntil(this.unsubscribe$)).subscribe((sensorList: any) => {
      this.toggleForGlobal(sensorList);
    });
}
  public toggleForGlobal(sensorList) {
    const selectedSensorList = [];
    if (sensorList.length === 0) {
      this.clickOnButton('pressure', true,false);
      return;
    }
    sensorList.forEach((sensor) => {
      let a;
      a = this.sensorInfo.filter((sensorData) => {
        return sensor.unitType.toLowerCase() === sensorData.sensorType.toLowerCase();
      });
      selectedSensorList.push(a[0]);
    });
    if ([...new Set(selectedSensorList)].length > 1) {
      this.clickOnButton(this.selectedType, true,false);
    } else if ([...new Set(selectedSensorList)].length === 1) {
      this.clickOnButton(selectedSensorList[0].sensorType, true,false);
    }
  }

  onSelectChange(selectedValue: string) {
    this.clickOnButton(selectedValue, true, true);
  }
  
  public getShownType(axesList) {
    const selectedSensorList = [];
    if (axesList.length > 0) {
      const selecteAxesList = _.filter(axesList, (axis) => {
        return axis.checked === true;
      });
      selecteAxesList.forEach((sensor) => {
        let a;
        a = this.sensorInfo.filter((sensorData) => {
          return sensor.value.toLowerCase().includes(sensorData.sensorType.toLowerCase());
        });
        selectedSensorList.push(a[0]);
      });
      if ([...new Set(selectedSensorList)].length > 1) {
        this.clickOnButton('Multiple', false,false);
      } else if ([...new Set(selectedSensorList)].length === 1) {
        this.clickOnButton(selectedSensorList[0].sensorType, false,false);
      }
    }
  }

  public clickOnButton(typeSelected: string, clicked: boolean,isUserInput): void {
    this.selectedType = typeSelected;
    const payload = {
      typeSelected,
      clicked,
      isUserInput
    };
    this.sendSelectedValue.emit(payload);
  }

  getDeviceInfo() {
    this.deviceService.isDesktop() ? this.isDesktopDevice = true : this.isDesktopDevice = false;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
