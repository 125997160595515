
import { IAlert } from '../../models/alert';

/* NgRx */
import { Action } from '@ngrx/store';

// tslint:disable:max-classes-per-file

export enum AlertRulesActionTypes {
  LoadAlertRules = '[Alert Rules] Load Alert Rules',
  LoadAlertRulesSuccess = '[Alert Rules] Load Alert Rules Success',
  LoadAlertRulesFail = '[Alert Rules] Load Alert Rules Fail',
  SetCurrentAlertRule = '[Alert Rules] Set Current Alert Rule',
  CreateAlertRule = '[Alert Rules] Create Alert Rule',
  CreateAlertRuleSuccess = '[Alert Rules] Create Alert Rule Success',
  CreateAlertRuleFail = '[Alert Rules] Create Alert Rule Fail',
  UpdateAlertRule = '[Alert Rules] Update Alert Rule',
  UpdateAlertRuleSuccess = '[Alert Rules] Update Product Success',
  UpdateAlertRuleFail = '[Alert Rules] Update Product Fail',
  DeleteAlertRule = '[Alert Rules] Delete Alert Rule',
  DeleteAlertRuleSuccess = '[Alert Rules] Delete Alert Rule Success',
  DeleteAlertRuleFail = '[Alert Rules] Delete Alert Rule Fail',
  AcknowledgeAlert = '[New Alert] Aknowledge Alerts',
  AcknowledgeAlertSuccess = '[New Alert] Create Alert Rule Success',
  AcknowledgeAlertFail = '[New Alert] Create Alert Rule Fail',
  AcknowledgeAllAlerts = '[New Alert] Acknowledge All Alerts',
  ActionBatteryVoltage = '[New Alert] Action Battery Voltage',
  ActionBatteryVoltageFail = '[New Alert] Action Battery Voltage Fail',
  ActionBatteryVoltageSuccess = '[New Alert] Action Battery Voltage Success',
  pollAlertRules = '[new Alert] Alert polling through socket'
}

// Action Creators
export class LoadAlertRules implements Action {
    readonly type = AlertRulesActionTypes.LoadAlertRules;
    constructor(public payload: any) { }
}

export class pollAlertRules implements Action {
  readonly type = AlertRulesActionTypes.pollAlertRules;
  constructor(public payload: any) { }
}

export class LoadAlertRulesSuccess implements Action {
  readonly type = AlertRulesActionTypes.LoadAlertRulesSuccess;
  constructor(public payload: IAlert[]) { }
}
export class LoadAlertRulesFail implements Action {
  readonly type = AlertRulesActionTypes.LoadAlertRulesFail;
  constructor(public payload: string) { }
}

export class SetCurrentAlertRule implements Action {
  readonly type = AlertRulesActionTypes.SetCurrentAlertRule;

  constructor(public payload: IAlert) { }
}

export class CreateAlertRule implements Action {
  readonly type = AlertRulesActionTypes.CreateAlertRule;
  constructor(public payload: any) { }
}

export class CreateAlertRuleSuccess implements Action {
  readonly type = AlertRulesActionTypes.CreateAlertRuleSuccess;
  constructor(public payload: IAlert) { }
}

export class CreateAlertRuleFail implements Action {
  readonly type = AlertRulesActionTypes.CreateAlertRuleFail;
  constructor(public payload: string) { }
}

export class UpdateAlertRule implements Action {
  readonly type = AlertRulesActionTypes.UpdateAlertRule;
  constructor(public payload: any) { }
}

export class UpdateAlertRuleSuccess implements Action {
  readonly type = AlertRulesActionTypes.UpdateAlertRuleSuccess;
  constructor(public payload: IAlert) { }
}

export class UpdateAlertRuleFail implements Action {
  readonly type = AlertRulesActionTypes.UpdateAlertRuleFail;
  constructor(public payload: string) { }
}

export class DeleteAlertRule implements Action {
  readonly type = AlertRulesActionTypes.DeleteAlertRule;
  constructor(public payload: any) { }
}

export class DeleteAlertRuleSuccess implements Action {
  readonly type = AlertRulesActionTypes.DeleteAlertRuleSuccess;
  constructor(public payload: string) { }
}

export class DeleteAlertRuleFail implements Action {
  readonly type = AlertRulesActionTypes.DeleteAlertRuleFail;
  constructor(public payload: string) { }
}

export class AcknowledgeAlert implements Action {
  readonly type = AlertRulesActionTypes.AcknowledgeAlert;

  constructor(public payload: any) { }
}

export class AcknowledgeAlertSuccess implements Action {
  readonly type = AlertRulesActionTypes.AcknowledgeAlertSuccess;
  constructor(public payload: any) { }
}

export class AcknowledgeAlertFail implements Action {
  readonly type = AlertRulesActionTypes.AcknowledgeAlertFail;
  constructor(public payload: {}) { }
}

export class AcknowledgeAllAlerts implements Action {
  readonly type = AlertRulesActionTypes.AcknowledgeAllAlerts;
  constructor(public payload: any) { }
}
export class ActionBatteryVoltage implements Action {
  readonly type = AlertRulesActionTypes.ActionBatteryVoltage;
  constructor(public payload: any) { }
}
export class ActionBatteryVoltageSuccess implements Action {
  readonly type = AlertRulesActionTypes.ActionBatteryVoltageSuccess;
  constructor(public payload: any) { }
}
export class ActionBatteryVoltageFail implements Action {
  readonly type = AlertRulesActionTypes.ActionBatteryVoltageFail;
  constructor(public payload: any) { }
}
export type AlertRulesActions = LoadAlertRules
  | LoadAlertRulesSuccess
  | LoadAlertRules
  | LoadAlertRulesSuccess
  | SetCurrentAlertRule
  | LoadAlertRulesFail
  | CreateAlertRule
  | CreateAlertRuleSuccess
  | CreateAlertRuleFail
  | UpdateAlertRule
  | UpdateAlertRuleSuccess
  | UpdateAlertRuleFail
  | DeleteAlertRule
  | DeleteAlertRuleSuccess
  | DeleteAlertRuleFail
  | AcknowledgeAlert
  | AcknowledgeAlertSuccess
  | AcknowledgeAlertFail
  | AcknowledgeAllAlerts
  | ActionBatteryVoltage
  | ActionBatteryVoltageFail
  | ActionBatteryVoltageSuccess
  | pollAlertRules
  ;
