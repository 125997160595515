<p-sidebar [(visible)]="togglePanel" styleClass="ui-sidebar-sm" position="right" [showCloseIcon]="false"
  [modal]="false">
  <div class='alert-container'>
    <div class='alert-day'>
      <h2>Today</h2>
      <div *ngIf='alerts'>
        <app-alert-card [alertData]="alert" [wellInformation]="wellInformation" [showOverlay]="true"
          *ngFor="let alert of alerts; let i = index" (acknowledgedAlert)="removeAcknowledgedAlert(i)"></app-alert-card>
      </div>

    </div>
    <div *ngIf="alerts.length && alerts.length > 0 && togglePanel" class='btn-acknowledge'>
      <button class="btn-secondary" (click)='acknowledgeAllAlerts()' >Acknowledge All</button>
    </div>
    <div *ngIf="alerts.length && alerts.length > 0 && !togglePanel" class='btn-acknowledge'>
      <button class="btn-secondary" (click)="acknowledgeAllAlerts()" tabindex="-1">Acknowledge All</button>
    </div>
  </div>
</p-sidebar>
