<p-dialog
  [(visible)]='isOpen'
  (onHide)='cancel()'
  [modal]='true'
  [responsive]='true'
  [focusOnShow]='false'
  [style]="{'min-width': '600px'}"
  [maximizable]='false'
  [baseZIndex]='10000'>

  <div class= 'range-container'>
    <div class='title'> Select Duration </div>
    <app-date-calendar-picker
      [historicalView]=true
      (newDateRange)='onDateSelectionChanged($event)'>
    </app-date-calendar-picker>
    <div *ngIf='!isDateRangeValid && (rangeDiff || rangeDiff === 0)'>
      <div class='message-error' >
        Please select a date range between 2 and 31 days.
      </div>
    </div>
    <p class='time-zone'>
      Time Zone: {{selectedTimeZone.timeLabel}}
    </p>
  </div>
  <p-footer class='modal-footer'>
    <div class='footer-button-container'>
      <button class='btn-secondary btn-cancel' (click)='cancel()'>Cancel</button>
      <button [disabled]="!isDateRangeValid" class='btn-apply primary' (click)='applyDateFilter()'>Apply</button>    
    </div>
  </p-footer>
</p-dialog>
