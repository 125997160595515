import { Directive , AfterViewInit ,ElementRef } from '@angular/core';

@Directive({
  selector: '[DefaultFocus]'
})
export class DefaultFocus implements AfterViewInit {

  constructor(private el: ElementRef) { }

  ngAfterViewInit(){
  }

}