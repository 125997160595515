<div class="history-view-container">
  <div>
    <i class='fa fa-calendar'></i>
    <p class='message'>
      Select duration to view data
    </p>
    <button class='btn-duration' (click)='showDateRangeSelectEvent()'>
          SELECT DURATION  
    </button>
  </div>
</div>
