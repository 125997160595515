import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Sensor, Well } from '../../state/reducers/global-filter.reducer';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterListComponent {
    @Input() filterCategory: string;
    @Input() filterList: any[];
    @Output() itemChecked = new EventEmitter<boolean>();

    @Input() selectedItems: Well[] | Sensor[];
    showLimitDialog = false;
    errorMessage = '';

    constructor(
      @Inject('ENV_CONFIG') private envConfig: any,
    ) {}

    itemSelected($event): void {
      this.itemChecked.emit($event.value);
    }

    itemSelectedSensor($event): void {
      // comment out the max sensor select check for presure and temperature sensors
      // const tempSensors = _.filter(this.selectedItems, (sen) => {
      //   if (sen.unitType) {
      //     return (sen.unitType).toLowerCase() === 'temperature';
      //   }
      // });

      // const preSensors = _.filter(this.selectedItems, (sen) => {
      //   if (sen.unitType) {
      //     return (sen.unitType).toLowerCase() === 'pressure';
      //   }
      // });

      // if (tempSensors.length > this.envConfig.sensorLimit || preSensors.length > this.envConfig.sensorLimit) {
      let noOfSelectedItems = this.selectedItems.length;
            
      if (noOfSelectedItems > this.envConfig.sensorLimit) {
        this.errorMessage = `Sensor selection is limited to ${this.envConfig.sensorLimit} sensors`;
        this.showLimitDialog = true;
        this.selectedItems.pop();
        return;
      }

      this.itemChecked.emit($event.value);
    }

    closeLimitDialog() {
      this.showLimitDialog = false;
    }
}
