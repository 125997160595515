import { IMeasurementClass, IUnit } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";

export class CubicFeetPerSec implements IUnit<IMeasurementClass> {
  static readonly Instance: CubicFeetPerSec = new CubicFeetPerSec();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return CubicFeetPerSec.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(1,0,0,1);
  }
}
