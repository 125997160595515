import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { sortByKeyFunc } from 'src/app/shared/utilities/util';
import sensorInfo from '../../../assets/data/sensorTypeConfig.json';
import { IAlert } from '../../models/alert';
import { Conversion } from '../../models/converterData';
import { UserGlobal } from '../../models/user';
import { AlertService } from '../../services/alert.service';
import { ConversionService } from '../../shared/services/conversion.service';
import * as alertActions from '../../state/actions/alert-page.actions';
import * as fromAlert from '../../state/container-states/alert.state';
import { State } from '../../state/container-states/app.state';
import * as fromFilter from '../../state/container-states/global-filter.state';
import { Sensor, Well } from '../../state/reducers/global-filter.reducer';

@Component({
  selector: 'app-alert-rule-modal',
  templateUrl: './alert-rule-modal.component.html',
  styleUrls: ['./alert-rule-modal.component.scss'],
})
export class AlertRuleModalComponent implements OnInit, OnDestroy {
  limit: boolean;
  wellList: Well[];
  sensorList: any[];
  selectedWell: Well;
  selectedSensor: Sensor;
  userDetails: UserGlobal;
  converterData: Conversion;
  goToNextLevel: boolean;
  alertUpperLimit: number;
  alertLowerLimit: number;
  invalidMinMaxLimit: boolean;
  isDuplicateAlarm: boolean = false;
  sensorUnit: any;
  unsubscribe$ = new Subject();
  creating = false;
  @Input() openDialog = false;
  @Input() alertsList:  IAlert[] ;
  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() duplicateWarning: EventEmitter<boolean> = new EventEmitter();
  @Output() creationInProgress = new EventEmitter<boolean>();
  sensorInfo: any[];

  constructor(
    private filterState: Store<fromFilter.GlobalFilterState>,
    private alertState: Store<fromAlert.AlertPageState>,
    private alertService: AlertService,
    private conversionService: ConversionService,
    private store: Store<State>
  ) {
    this.sensorInfo = sensorInfo.sensorData;
  }

  ngOnInit() {
    this.loadWells();
  }

  loadWells() {
    this.filterState
      .pipe(select(fromFilter.getWellsList), takeUntil(this.unsubscribe$))
      .subscribe((wells: Well[]) => {
        this.wellList = sortByKeyFunc(wells, 'wellName');
    });
  }

  filterSensorsList(well: Well) {
    this.selectedWell = well;
    this.sensorList = well.sensors.sort((a, b) => a.sensorOriginalName.localeCompare(b.sensorOriginalName, undefined, {numeric: true}));
  }

  selectSensor(sensor: Sensor) {
    this.selectedSensor = sensor;
  }

  createAlert() {
    if(this.alertsList.length > 0){
     let existingExactAlert = this.alertsList.filter((alarm) => {
       let alertDetail = alarm.AlarmDetail;

       let convertedupperLimit: number;
        let convertedlowerLimit: number;
       if(alertDetail.MnemonicName == this.selectedSensor.sensorName){
        const { UpperLimit, LowerLimit } = alertDetail;
        const alertSensorUnit = alertDetail.UnitType.toLowerCase();
        this.sensorUnit = this.getSensorUnitType(alertSensorUnit, this.converterData);

        const convertedType = this.selectedSensor.unitType;
             convertedupperLimit = UpperLimit || UpperLimit === 0 ? this.conversionService['alertConvert' + convertedType](
              this.sensorUnit['value'], UpperLimit
            ) : null;
             convertedlowerLimit = LowerLimit ||LowerLimit === 0 ? this.conversionService['alertConvert' + convertedType](
              this.sensorUnit['value'], LowerLimit
            ) : null;
           }

       return convertedlowerLimit ==  this.alertLowerLimit && convertedupperLimit ==  this.alertUpperLimit;
     });
     if(existingExactAlert.length > 0){
      this.isDuplicateAlarm = true;
       return false;
     }
    }


    this.alertService.changeStatus('false');
    this.creating = true;
    this.userDetails = JSON.parse(localStorage['currentUser']);
    const alarm: IAlert = {
      JobName: this.selectedWell.jobName,
      UidWell: this.selectedWell.uidWell,
      UidLog: this.selectedSensor.multiLogId,
      UnitProfileUid: 'English|00000000-0000-0000-0000-000000000001',
      AlarmDetail: {
        MnemonicName: this.selectedSensor.sensorOriginalName,
        MnemonicAlias: this.selectedSensor.sensorOriginalName,
        CurveName: this.selectedSensor.sensorOriginalName,
        UpperLimit: this.alertUpperLimit ?
        this.conversionService.alertConvertForService(this.selectedSensor.unitType, this.sensorUnit['value'], this.alertUpperLimit) : null,
        LowerLimit: this.alertLowerLimit ?
        this.conversionService.alertConvertForService(this.selectedSensor.unitType, this.sensorUnit['value'], this.alertLowerLimit) : null,
        AlarmType: 1,
        ConvertedLowerLimit: 1, //workaround for precision fix. To be removed once backend fixed
        ConvertedUpperLimit: 2, // workaround for precision fix. To be removed once backend fixed
        NotificationStatus: 0,
        EmailNotificationStatus: 1,
        UnitProfileUid: 'English|00000000-0000-0000-0000-000000000001',
        UnitType: this.selectedSensor.unitType,
        Unit: this.selectedSensor.unit,
        UnitName: this.selectedSensor.unit,
      },
      //UserEmailId: this.userDetails['email'],
      UserEmailId: this.userDetails['Email'],
    };
    this.alertState.dispatch(new alertActions.CreateAlertRule({
      alarm,
      wells: this.wellList,
    }));
    this.creationInProgress.emit(true);
  }

  addAlert() {
    if (!this.goToNextLevel) {
      this.goToNextLevel = true;
      this.alertLowerLimit = null;
      this.alertUpperLimit = null;
      this.invalidMinMaxLimit = true;
      this.getAlertUnitType();
      (document.querySelector('.ui-dialog') as HTMLElement).parentElement.className = '';
    } else {
      this.createAlert();
    }
  }
  onDragLeave(event) {
    const leftPos = event.clientX - 100;
    if (event.clientX >  window.innerWidth) {
      (document.querySelector('.ui-dialog') as HTMLElement).style.left = ((window.innerWidth / 2)) + 'px';
    } else {
      (document.querySelector('.ui-dialog') as HTMLElement).style.left = leftPos + 'px';
    }
  }
  getAlertUnitType() {
    this.store
      .select((state: State) => state.conversion)
      .pipe(first())
      .subscribe((conversionState) => {
        this.converterData = conversionState.conversion;
        if (this.selectedSensor) {
          this.sensorUnit = this.getSensorUnitType(this.selectedSensor.unitType.toLowerCase(), this.converterData);
        }
      });
  }

  limitChanged(value) {
    this.isDuplicateAlarm = false;
    const changedValue = Number(value);
    this.limit = this.checkLimit();
    const isValueEntered: boolean = (this.alertLowerLimit && this.alertUpperLimit) ? true : false;
    this.invalidMinMaxLimit = (!isValueEntered || isNaN(changedValue)) ? true : false;
    if ((this.alertLowerLimit && this.alertLowerLimit !== null) && (this.alertUpperLimit && this.alertUpperLimit !== null)) {
      this.invalidMinMaxLimit = !(Number(this.alertLowerLimit) < Number(this.alertUpperLimit));
    }
  }

  checkLimit(){
    // changed from 14 to 13 to avoid convertion from float to decimal for pressure values
    if ((String(this.alertUpperLimit).length > 13) || (String(this.alertLowerLimit).length > 13)){
      return true;
    }
    return false;
  }
  closeDialog(): void {
    this.resetAlertValues();
    this.hideDialog.emit(this.openDialog);
  }

  getUserDetails(): any[] {
    // TODO: store/fetch user data from NGRx state
    this.userDetails = JSON.parse(localStorage['currentUser']);
    const userJobs = [];
    this.userDetails.IWJob.map((job) => {
      //userJobs.push({JobName: job.jobName, WellUid: job.wellUid, WellName: job.wellName});
      userJobs.push({JobName: job.jobName, WellUid: job.wellUid, WellName: job.wellName});
    });
    return userJobs;
  }

  resetAlertValues() {
    this.alertLowerLimit = null;
    this.alertUpperLimit = null;
    this.selectedWell = null;
    this.selectedSensor = null;
    this.sensorList = [];
    this.goToNextLevel = false;
    this.openDialog = false;
    this.invalidMinMaxLimit = false;
  }

  getSensorUnitType(sensorUnit: string, converterData: Conversion) {
    let result = {
      value: '',
      label: ''
    };
    this.sensorInfo.forEach((sensorList) => { // get the unit type of sensor
      if (sensorUnit === sensorList.sensorType) {
        sensorList.sensorUnits.forEach((sensorUnitItem) => {
          if (sensorUnitItem.value == converterData[sensorList.sensorType + 'Unit']) {
            result = {
              label: sensorUnitItem.label,
              value: sensorUnitItem.value
            };
          }
        });
      }
    });
    return result;
  }

  isDegreeRequired(): boolean {
    return this.sensorUnit === 'C' || this.sensorUnit === 'F';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
