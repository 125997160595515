import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ERR_MESSAGES } from '../constants/error-messages.constants';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorService {

  private $4XX: Subject<any> = new Subject();
  private $401: Subject<any> = new Subject();
  private $5XX: Subject<any> = new Subject();
  private $200: Subject<any> = new Subject();

  set4xxError(res: any) {
    if (res) {
      this.$4XX.next({ endpointUrl: res.endpointUrl, message: ERR_MESSAGES.error400});
    }
  }

  set401Error(res: any) {
    if (res) {
      let errorMessage = ERR_MESSAGES.error401;
      if (res.error && res.error.Error && res.error.Error.Message) {
        errorMessage = res.error.Error.Message === '' ? ERR_MESSAGES.error401 : res.error.Error.Message;
      }else if(res.error && res.error.errorMessage){
        errorMessage = res.error.errorMessage === '' ? ERR_MESSAGES.error401 : res.error.errorMessage;
      }
      this.$401.next({ endpointUrl: res.endpointUrl, message: errorMessage });
    }
  }

  set200Error(res: any, error: string) {
    if (res) {
      this.$200.next({ endpointUrl: res.request.operation, message: ERR_MESSAGES[error] });
    }
  }

  set5xxError(res: any) {
    if (res && res.error) {
      if (typeof (res.error) === 'string') {
        const errorCode = res.error.split(':')[0];
        if (errorCode && errorCode !== '' && ERR_MESSAGES[errorCode]) {
             this.$5XX.next({ endpointUrl: res.endpointUrl, message: ERR_MESSAGES[errorCode] });
        }
        else {
          this.$5XX.next({ endpointUrl: res.endpointUrl, message: ERR_MESSAGES.error500 });
        }
      } else {
        let errorMessage = ERR_MESSAGES.error500;
        if(res.error && res.error.errorMessage){
          errorMessage = res.error.errorMessage === '' ? ERR_MESSAGES.error401 : res.error.errorMessage;
        }else{
          errorMessage = res.error.message;
        }
        this.$5XX.next({ endpointUrl: res.endpointUrl, message: errorMessage });
      }
    }else{
      this.$5XX.next({ endpointUrl: res.endpointUrl, message: ERR_MESSAGES.error500});
    }
  }

  get4xxError(): Observable<any> {
    return this.$4XX.asObservable().pipe(debounceTime(500));
  }

  get401Error(): Observable<any> {
    return this.$401.asObservable().pipe(debounceTime(500));
  }

  get5xxError(): Observable<any> {
    return this.$5XX.asObservable().pipe(debounceTime(500));
  }

  get200Error(): Observable<any> {
    return this.$200.asObservable().pipe(debounceTime(500));
  }
}
