import { IMeasurementClass, IUnit } from "src/app/models/measurement";
import { BilinearTransform } from "../BilinearTransform";

export class Kelvin implements IUnit<IMeasurementClass> {
  static readonly Instance: Kelvin = new Kelvin();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Kelvin.Instance;
  }

  get TransformFromBaseUnit(): BilinearTransform {
      return new BilinearTransform(1,0,0,1);
  }
}
