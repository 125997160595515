import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
// Import constants
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ChartLoadingService } from 'src/app/services/chart-loading.service';
import * as XLSX from 'xlsx';
import { IAlert } from '../../models/alert';
// Import model
import { Conversion } from '../../models/converterData';
import { DateRange } from '../../models/DateRange';
import { IDataSensor, IGraphObject, ILineChartData, IPositions } from '../../models/lineGraphData';
import { ErrorService } from '../../services/error.service';
import { GraphUtilService } from '../../services/graph-util.service';
import { PressureAndTemperatureService } from '../../services/pressure-temperature.service';
import { ERR_MESSAGES } from '../../shared/constants/error-messages.constants';
import { ConversionService } from '../../shared/services/conversion.service';
import { GlobalErrorService } from '../../shared/services/global-error.service';
import { State } from '../../state/container-states/app.state';
import sensorInfo from './../../../assets/data/sensorTypeConfig.json';





@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss'],
  providers: [MessageService, ErrorService],
})
export class AlertDetailsComponent implements OnInit, OnDestroy {
  @Input()
  openDialog = false;
  @Input()
  alert: IAlert;
  @Output()
  hideDialog = new EventEmitter<boolean>();
  wells;
  tempPressureResponse;
  wellData: IDataSensor[] = [];
  userDetails;
  public dateRange: DateRange = {
    start: new Date(),
    end: new Date(),
  };
  public dataSource: IGraphObject[];

  view: any[] = [];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  timeline = true;
  scheme = true;
  showGridLines = false;
  lineChartData: ILineChartData = {
    sensorArray: [],
  };
  // line, area
  autoScale = true;
  converterData: Conversion;
  multi: IGraphObject[];
  unsubscribe$ = new Subject();
  wellInformation = {};
  isDesktopDevice = false;
  showSupportDialog = false;
  sensorInfo: any[];
  isapiInprogress = true; //to wait for the nickname calls along with wells and sensors to complete

  constructor(
    private store: Store<State>,
    public graphService: GraphUtilService,
    private conversionService: ConversionService,
    private messageService: MessageService,
    private errorService: ErrorService,
    private temperatureService: PressureAndTemperatureService,
    private globalErrorService: GlobalErrorService,
    public chartLoading: ChartLoadingService,
    private deviceService: DeviceDetectorService
  ) {
    this.sensorInfo = sensorInfo.sensorData;
  }
  public ngOnInit(): void {
    this.getDeviceInfo();
    this.getUserDetails();

    this.store
      .select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState) => {
        if (conversionState) {
          this.converterData = conversionState.conversion;
        }
      });
    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        this.wells = wellState.wells;
        this.wells.map((well) => {
          this.wellInformation[well.uidWell] = well.wellName;
        });

        this.isapiInprogress = wellState.apiInprogress;
        if (wellState && wellState.wells && wellState.wells.length > 0 && !this.isapiInprogress) {

          // TODO: As of now no better way to extract date from string
          // SAMPLE STRING: '"Gauge3Temp: 321.13 degF @ 20-SEP-2018 16:07:18 exceeded upper limit 100.00"'
          this.alert.AlertTime = this.getTimeFromString(this.alert.AlarmDetail['NotificationMessage']);
          this.dateRange.start = new Date(this.getDateTime(this.alert.AlertTime, true));
          this.dateRange.end = new Date(this.getDateTime(this.alert.AlertTime, false));
          const sensorName = this.getDataForSensor(this.alert);
          this.lineChartData.sensorArray.push(sensorName);
          this.chartLoading.showSpinner('alertModal');
          this.temperatureService
            .getPressureAndTemperatureData(this.dateRange, sensorName)
            .pipe(first())
            .subscribe((response) => {
              const request = response['request'];
              if (request && request.hasOwnProperty('IsValid') && !JSON.parse(request.IsValid)) {
                this.globalErrorService.set200Error(response, 'ERR_ALERT_ISVALID');
              } else {
                const responseData = response['response']['log'][0];
                this.processData(responseData, sensorName);
              }
            });
        }
      });
  }

  getDeviceInfo() {
    this.deviceService.isDesktop() ? this.isDesktopDevice = true : this.isDesktopDevice = false;
  }

  showSupportMessage() {
    this.showSupportDialog = true;
  }
  closeSupportDialog() {
    this.showSupportDialog = false;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @name: processData
   * @description: Method to process data from service
   * @param: response: response from service
   * @param: item: sensor name
   * @param: measurement: boolean value to determine if data is for measurement
   */
  processData(response, item): void {
    // Method to identify if graph has temperature OR pressure
    // SEND object back to get temperature/pressure/time positions
    const positions: IPositions = this.graphService.getPositions(response['logCurveInfo']);
    this.transformDataForDisplay(response, item, positions);
  }

  /**
   * @name: transformDataForDisplay
   * @description: method to transform data for display
   * @param: response: response from service
   * @param: item: sensor name
   * @param: positions {IPositions} array indexes for temperature and pressure
   */
  transformDataForDisplay(
    response,
    item,
    positions: IPositions
  ) {
    const responseFromService = this.graphService.setUpResponseObject(response, positions, item);
    if (responseFromService['typeService'] !== '') {
      this.convertData(responseFromService, positions);
      this.tempPressureResponse = responseFromService;
      this.tempPressureResponse['displayName'] = this.alert.AlarmDetail['displayName'];
      this.chartLoading.hideSpinner('alertModal');
    }
  }

  convertData(data, positions: IPositions): void {
    const unitLists = [this.converterData.temperatureUnit, this.converterData.pressureUnit];
    data.units = unitLists;
    if (this.converterData) {
      for (const item of data['data']) {
        item[positions.dateTime] = this.conversionService.convertTime(
          this.converterData.timeUnit,
          item[positions.dateTime]
        );
        for (const i in this.sensorInfo) { // dynamic implementatiom of conversion
          const sensorType = this.sensorInfo[i].sensorType;
          if (sensorType === data['typeService']) {
             item[
               positions[sensorType]
             ] = this.conversionService['convert' + sensorType](
               this.converterData[sensorType + 'Unit'],
               item[positions[sensorType]]
             );
          }
        }
      }
    }
  }

  public getDataForSensor(alert: IAlert): IDataSensor {
    if (this.wells) {
      const wellSelected = this.wells.filter((well) => {
        return well.wellName === alert['WellName'];
      });
      const sensorName: IDataSensor = {
        sensorName: alert.AlarmDetail.MnemonicName,
        uid: wellSelected[0].uid,
        uidWell: wellSelected[0].uidWell,
        multiLogId: alert.UidLog
      };
      return sensorName;
    }
  }

  public getTimeFromString(notification: string): string {
    let time = '';
    time = notification.split('@').length > 1 ?
      notification.split('@')[1].substring(1, 21) : '';
    return time;
  }

  export() {
      const units = this.converterData;
      const selectedTimeZone = units.timeLabel;
      const indexes = Array.from(this.lineChartData.sensorArray.keys());
      const temp = _.cloneDeep(this.multi);
      const selectedLinesData = temp.map((line) => {
        return line.series;
      });
      let selectedUnit;
      this.sensorInfo.forEach((sensorList) => {
        if (this.tempPressureResponse['typeService'].toLowerCase() === sensorList.sensorType) {
          selectedUnit = units[sensorList.sensorType + 'Unit'];
        }
      });
      selectedLinesData.map((line) => {
        return line.map((point) => {
          Object.assign(point, {
            ['TIME (' + selectedTimeZone + ')']: point.name
          });
          Object.assign(point, {
            [this.tempPressureResponse['displayType'].toUpperCase() + ' (' + selectedUnit + ')']: point.value
          });
          delete point.name;
          delete point.value;
        });
      });
      const workBook = XLSX.utils.book_new();
      if (selectedLinesData !== undefined) {
        indexes.map((index) => {
          const workSheet = XLSX.utils.json_to_sheet(selectedLinesData[index], {
            header: [this.alert.WellName + '-' + this.alert.AlarmDetail.displayName],
            dateNF: 'YYYY-MM-DD HH:mm:ss'
          });
          XLSX.utils.book_append_sheet(
            workBook,
            workSheet
          );
        });
      }
      this.sensorInfo.forEach((sensorList) => {
        if (this.tempPressureResponse['typeService'].toLowerCase() === sensorList.sensorType) {
          XLSX.writeFile(workBook, sensorList.axisName + '.xlsx');
        }
      });
      this.messageService.clear();
  }

  public getDateTime(time: string, start: boolean): Date {
    const dateAlert = new Date(time);
    dateAlert.setHours(dateAlert.getHours() - dateAlert.getTimezoneOffset() / 60);
    const eighteenHrsMs = 64000000;
    let limitDate;
    if (start) {
      if (Date.now() - dateAlert.getTime() < eighteenHrsMs) {
        const twentyFourHrsAgo = new Date();
        twentyFourHrsAgo.setDate(twentyFourHrsAgo.getDate() - 1);
        limitDate = twentyFourHrsAgo;
      } else {
        limitDate = dateAlert.setHours(dateAlert.getHours() - 6);
      }
    } else {
      if (Date.now() - dateAlert.getTime() < eighteenHrsMs) {
        limitDate = new Date();
      } else {
        limitDate = dateAlert.setHours(dateAlert.getHours() + 18);
      }
    }
    return limitDate;
  }

  closeDialog(): void {
    this.openDialog = false;
    this.hideDialog.emit(this.openDialog);
  }

  /**
   * @name: getUserDetails
   * @description: Method to get User details
   */
  public getUserDetails(): void {
    // TODO: store/fetch user data from NGRx state
    if (localStorage['currentUser']) {
      this.userDetails = JSON.parse(localStorage['currentUser']);
    }
  }

  public setDataSourceForAlert(event: IGraphObject[]) {
    this.multi = event;
  }

   // Warning message to alert users who select export button if inadvertently
  exportConfirmWarning(){
    const warning = new Error(ERR_MESSAGES.WARN_EXPORT);
    this.showWarning(warning, warning.message);
  }

  showWarning(warningCode, message?) {
    this.messageService.clear('alertExportConfirm');
    const warningMessage = this.errorService.getErrorMessage(message, warningCode);
    this.messageService.add({
      key: 'alertExportConfirm',
      severity: 'warning',
      summary: warningMessage,
      closable: false,
      detail: message,
      sticky: true
    });
  }

  cancelErrorWarnPopup(){
    this.messageService.clear();
  }
}
