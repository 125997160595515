import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, tap, first } from 'rxjs/operators';
import { SensorNameEditService } from '../services/sensor-name-edit.service';
import { Store } from '@ngrx/store';
import { State } from '../state/container-states/app.state';
import * as fromFilter from '../state/container-states/global-filter.state';
import * as GlobalFilterActions from '../state/actions/global-filter.actions';
import { AppConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class WellService {

  responseArray = [];
  noDataWells = [];
  config:any;
  constructor(@Inject('ENV_CONFIG') private envConfig: any, private http: HttpClient,
    public store: Store<State>,
    private globalFilterState: Store<fromFilter.GlobalFilterState>,
    private renameService: SensorNameEditService) { 
    }

  // Return list of wells for showing up on Map
  getWells() {
    const apiUrl = `${this.envConfig.api.getWellsForMap}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(apiUrl, { size: 'l' }, httpOptions).pipe(
      tap((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getUserJobList() {
    // Return list of jobs for the user
    const apiUrl = `${this.envConfig.api.getEntitlements}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(apiUrl, httpOptions).pipe(
      tap((data) => {
        return data;
      }),
      catchError((err) =>{
        return this.handleError(err);
      })
    );


  }

  setNoDataWells(noDataWells: any[], nodatawellFromResponse: any[]) {

    this.noDataWells = noDataWells.concat(nodatawellFromResponse);
  }

  getNoDataWells() {
    return this.noDataWells;
  }

  getRenamedWellSensors(wellSensors: any[]) { // To get the renamed sensors from indexDB and update the names
    wellSensors.map((wells: { sensors: any[]; wellName: string; }) => {
      wells.sensors.map((sensors: { sensorOriginalName: string; displayName: string; combinedName: string; wellName: string; }) => {
        this.renameService
          .getSensorUpdatedName(sensors.sensorOriginalName, wells.wellName)
          .pipe(first())
          .subscribe((res: any) => {
            if (res && res.length > 0) {
              sensors.displayName = res[0]['renamedSensorLabel'];
              sensors.combinedName = sensors.wellName + '-' + sensors.displayName;
            }
          });
      });
    });
    return wellSensors;
  }

  //get the nicknames for all the sensors of a well
  getNickNames(wellsAndSensors: any): Observable<any> {
    const apiUrl = `${this.envConfig.api.getNickNames}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      secureProtocol: "TLSv1_2_method"
    };

    return forkJoin(wellsAndSensors.map((wellAndSensor: any) => {

      const wellAndSensorCopy = JSON.parse(JSON.stringify(wellAndSensor));
      wellAndSensorCopy.sensors = wellAndSensorCopy.sensors.map(sensor => ({ sensorName: sensor.sensorName }));

      const reqData = {
        wellSensors:wellAndSensorCopy 
      };

      return this.http.post(apiUrl, reqData, { ...httpOptions, responseType: 'text' }).pipe(
        tap((data) => {

          let nickNameSensors = JSON.parse(data);
          nickNameSensors.forEach(nickNameSensor => {
            const matchingSensor = wellAndSensor.sensors.find(wellSensor => wellSensor.sensorName === nickNameSensor.sensorName);
          
            if (matchingSensor) {
              matchingSensor.combinedName = nickNameSensor.combinedName ? nickNameSensor.combinedName : matchingSensor.combinedName || '';
              matchingSensor.nickName = nickNameSensor.nickName || '';
              matchingSensor.displayName = nickNameSensor.displayName ? nickNameSensor.displayName : matchingSensor.displayName || '';
           }
          });
          
          this.globalFilterState.dispatch(new GlobalFilterActions.UpdatedSensors(wellAndSensor));
          return data;
        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );
    }))

  }

  getWellBore(uid: string) {
    const apiUrl = `${this.envConfig.api.getWellBore}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      secureProtocol: "TLSv1_2_method"
    };

    const reqData = {
      uidWell: uid,
      size: 'l'
    };

    return this.http.post(apiUrl, reqData, httpOptions).pipe(
      tap((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getWellDataSetProperties(uidWell: string, run: string, record: string) {
    const apiUrl = `${this.envConfig.api.getDataSetProperties}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      secureProtocol: "TLSv1_2_method"
    };

    const reqData = {
      uidWell: uidWell,
      Run: run,
      Record: record
    };

    return this.http.post(apiUrl, reqData, httpOptions).pipe(
      tap((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getRawSensorData(sensorRequests: any[]): Observable<{}> {
    const apiUrl = `${this.envConfig.api.getRawSensorData}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      secureProtocol: "TLSv1_2_method"
    };

    // TODO: Check if fork join can be moved to effects
    return forkJoin(
      sensorRequests.map((senReq) => {
        return this.http.post(apiUrl, senReq, httpOptions)
          .pipe(catchError(this.handleError));
      })
    );
  }

  addNickName(wellId: string, sensorId: any, nickName: string): Observable<any> {
    const apiUrl = encodeURI(`${this.envConfig.api.mmw_wells}/${wellId}/sensors/${sensorId}/nickname`);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'responseType': 'text',
      }),

      secureProtocol: "TLSv1_2_method"
    };

    return this.http.put(apiUrl, JSON.stringify(nickName), {
      ...httpOptions,
      responseType: 'text'
    })
      .pipe(catchError(this.handleError));

  }

  //Delete sensor nickname
  deleteNickname(wellId: string, sensorId: any): Observable<any> {
    const apiUrl = encodeURI(`${this.envConfig.api.mmw_wells}/${wellId}/sensors/${sensorId}/nickname`);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'responseType': 'text',
      }),

      secureProtocol: "TLSv1_2_method"
    };

    return this.http.delete(apiUrl, {
      ...httpOptions,
      responseType: 'text'
    })
      .pipe(catchError(this.handleError));

  }

  public handleError(err: { error: { message: any; errorMessage: any; }; body: { error: any; }; status: any; }) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (err) {
        if (err.body) {
          errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        } else if (err.error && err.error.errorMessage) {
          errorMessage = `An error occurred. ${err.status}: ${err.error.errorMessage}`;
        } else if (err.error) {
          errorMessage = `Backend returned code ${err.status}: ${err.error}`;
        }
      }
    }
    return throwError(errorMessage);
  }
}
