import { IMeasurementClass } from '../../models/measurement';
import { IUnit } from '../../models/measurement';
import { Bar } from './Pressure/Bar';
import { KilopascalsAbsolute } from './Pressure/KilopascalsAbsolute';
import { Pascals } from './Pressure/Pascals';
import { PoundsPerSqInchAbsolute } from './Pressure/PoundsPerSqInchAbsolute';
import { PoundsPerSqInchGauge } from './Pressure/PoundsPerSqInchGauge';


export class PressureMeasurement implements IMeasurementClass {
  static readonly Instance: PressureMeasurement = new PressureMeasurement();

  get BaseUnit(): IUnit<IMeasurementClass> {
      return Pascals.Instance;
  }

  static GetPressureUnitInstance(unitText:string){

    switch (unitText.toLowerCase()) {
      case 'bar':
        return Bar.Instance;
      case 'kpa':
        return KilopascalsAbsolute.Instance;
      case 'psig':
        return PoundsPerSqInchGauge.Instance;
      case 'psia':
        return PoundsPerSqInchAbsolute.Instance;
      default:
        return PoundsPerSqInchGauge.Instance;
    }
  }
}
