/**
 * Represents ngrx Actions
 */
// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { Conversion } from '../../models/converterData';
import { UserGlobal } from '../../models/user';

export enum UnitConversionActionTypes {
  UpdateUnitConversion = '[Conversion] Update Unit Conversion',
  LoadUnitConversion = '[Conversion] Load Unit Conversion',
  LoadUnitConversionSuccess = '[Conversion] Load Unit Conversion Success',
}

// Action Creators
export class LoadUnitConversion implements Action {
  readonly type = UnitConversionActionTypes.LoadUnitConversion;
  constructor(public payload: UserGlobal) { }
}

export class LoadUnitConversionSuccess implements Action {
  readonly type = UnitConversionActionTypes.LoadUnitConversionSuccess;

  constructor(public payload: Conversion) { }
}

export class UpdateUnitConversion implements Action {
  readonly type = UnitConversionActionTypes.UpdateUnitConversion;

  constructor(public payload: any) { }
}

// Union the valid types
export type UnitConversionActions = LoadUnitConversion
| LoadUnitConversionSuccess
| UpdateUnitConversion;
