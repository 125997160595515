  import { Inject, Injectable } from '@angular/core';
import OktaSignIn from '@okta/okta-signin-widget';
import { HttpClient } from '@angular/common/http';
import { OktaAuthService } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root'
})
export class Okta {
  widget: any;

  constructor(@Inject('ENV_CONFIG') private envConfig: any,
  private http: HttpClient,public oktaAuth: OktaAuthService) {
    this.widget = new OktaSignIn({
      baseUrl: this.envConfig.oktaBaseUrl,
      clientId: this.envConfig.clientId,
      redirectUri: this.envConfig.redirectUri,
      el: '#okta-login-container',
      authParams: {
        display: 'page',
        issuer: this.envConfig.issuer,
        scopes: ["openid", "profile", "email"],
        responseMode: 'query',
        responseType: ['id_token', 'token'],
        pkce: true
      },
      i18n: {
        en: {
          'primaryauth.title': 'Sign In to your account',
          'errors.E0000006': `Internal users use your Halliburton machine to reset your password.`
         }
      },
      features: { registration: this.envConfig.oktaRegistration, showPasswordToggleOnSignInPage: true, idpDiscovery: true },
      idpDiscovery: { requestContext: window.location.href }
    });
  }

  getWidget() {
    return this.widget;
  }
}

