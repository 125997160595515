import { Injectable } from '@angular/core';
// import { AlertRule } from '../alert-rule.reducer.ts';
/* NgRx */
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IAlert } from '../../models/alert';
import { AlertService } from '../../services/alert.service';
import { GlobalErrorService } from '../../shared/services/global-error.service';
import * as alertRulesActions from '../actions/alert-page.actions';



@Injectable()
export class AlertPageEffects {
  userDetails;
  finalAlertArr = [];

  constructor(
    private alertService: AlertService,
    private actions$: Actions,
    private globalErrorService: GlobalErrorService
  ) { }
  getMin(description: any, lowerLimit: any, upperLimit: any) { // get the minimum alert value from description
    if (description) {
      const split = description.split(':')[1];
      const spaceSplit = split.split(' ');
      return upperLimit != undefined ? spaceSplit[13] * 1 : spaceSplit[8] * 1;
    } else {
      return lowerLimit;
    }
  }
  getMax(description: any, upperLimit: any) { // get the maximum alert value from description
    if (description) {
      const split = description.split(':')[1];
      const spaceSplit = split.split(' ');
      return upperLimit != undefined ? spaceSplit[8] * 1 : null;
    } else {
      return upperLimit;
    }
  }

  @Effect()
  loadAlertRules$: Observable<Action> = this.actions$.pipe(
    ofType(alertRulesActions.AlertRulesActionTypes.LoadAlertRules),
    map((action: alertRulesActions.LoadAlertRules) => action.payload),
    mergeMap((payload) => {
      try {
        return this.alertService.getAlerts(payload['jobs']).pipe(
          map((jobAlerts) => {
            if (localStorage['currentUser']) {
              this.userDetails = JSON.parse(localStorage['currentUser']);
            }
            let alertsArr = [];
            jobAlerts.map((jobAlert) => {
              if (Array.isArray(jobAlert.AlarmDetails)) {
                alertsArr = alertsArr.concat(jobAlert.AlarmDetails);
              }
            });
            this.finalAlertArr = [];

            payload['wells'].map((well) => {
              well['sensors'].map((sensor) => {
                alertsArr.map((alert) => {
                  if ((alert['JobName'] === well['jobName'] && sensor['sensorOriginalName'] === alert['MnemonicAlias']) &&
                    (!alert['IsInValid'])
                  ) {
                    //Since DSRT truncates the min max values sent from clariti in the Lower and UpperLimit properties,
                    const min = this.getMin(alert['Description'], alert['LowerLimit'], alert['UpperLimit']);// Done as a workoaround for precision fix.To be removed when the backend send the exact precision value
                    const max = this.getMax(alert['Description'], alert['UpperLimit']);// Done as a workoaround for precision fix.To be removed when the backend send the exact precision value
                    //const min = alert['LowerLimit'];
                    //const max = alert['UpperLimit'];

                    const foundAlert = {
                      AlarmID: alert['AlarmID'],
                      JobName: alert['JobName'],
                      UidWell: well['uidWell'],
                      UidLog: sensor['multiLogId'],
                      UnitProfileUid:
                        'English|00000000-0000-0000-0000-000000000001',
                      WellName: sensor['wellName'],
                      AlarmDetail: {
                        MnemonicName: sensor['sensorName'],
                        MnemonicAlias: sensor['sensorOriginalName'],
                        CurveName: sensor['sensorOriginalName'],
                        displayName: sensor['displayName'],
                        UpperLimit: alert['UpperLimit'],
                        LowerLimit: alert['LowerLimit'],
                        ConvertedUpperLimit: max,
                        ConvertedLowerLimit: min,
                        AlarmType: alert['AlarmType'],
                        NotificationStatus: alert['AlarmNotificationStatus'],
                        EmailNotificationStatus: alert['EmailNotificationStatus'],
                        UnitProfileUid:
                          'English|00000000-0000-0000-0000-000000000001',
                        UnitType: sensor['unitType'],
                        Unit: sensor['unit'],
                        UnitName: sensor['unit'],
                        NotificationMessage: alert['NotificationMessage']
                      },
                      UserEmailId: this.userDetails['email']
                    };
                    this.finalAlertArr.push(foundAlert);
                  }
                });
              });
            });
            return new alertRulesActions.LoadAlertRulesSuccess(this.finalAlertArr);
          }),
          catchError((err) => of(new alertRulesActions.LoadAlertRulesFail(err)))
        )
      } catch (err) {
        of(new alertRulesActions.LoadAlertRulesFail(err));
      }
    }
    )
  );

  @Effect()
  createAlertRule$: Observable<Action> = this.actions$.pipe(
    ofType(alertRulesActions.AlertRulesActionTypes.CreateAlertRule),
    map((action: alertRulesActions.CreateAlertRule) => action.payload),
    mergeMap((payload) => {
      try {
        return this.alertService.addAlert(payload['alarm']).pipe(
          map((response) => {
            if (response) {
              const alertPayload = this.alertService.getLoadAlertsPayload(payload['wells']);
              return new alertRulesActions.LoadAlertRules(alertPayload);
            }
          }),
          catchError((err) => of(new alertRulesActions.CreateAlertRuleFail(err)))
        )
      } catch (err) {
        of(new alertRulesActions.CreateAlertRuleFail(err));
      }
    }
    )
  );

  @Effect()
  deleteAlertRule$: Observable<Action> = this.actions$.pipe(
    ofType(alertRulesActions.AlertRulesActionTypes.DeleteAlertRule),
    map((action: alertRulesActions.DeleteAlertRule) => action.payload),
    mergeMap((payload: any) => {
      try {
        return this.alertService.deleteAlert(payload['alert']).pipe(
          map((res) => {
            if (res && res !== 'Fail') {
              const alertPayload = this.alertService.getLoadAlertsPayload(payload['wells']);
              return new alertRulesActions.LoadAlertRules(alertPayload);
            } else {
              const err = {
                endpointUrl: 'Alert Delete API',
                error: 'Delete Alert failed',
                request: {
                  operation: 'Delete Alert'
                }
              };
              this.globalErrorService.set200Error(err, 'ERR_ALERT_DELETE_FAIL');
              return new alertRulesActions.DeleteAlertRuleFail(err.error);
            }
          }),
          catchError((err) => of(new alertRulesActions.DeleteAlertRuleFail(err)))
        )
      } catch (err) {
        of(new alertRulesActions.DeleteAlertRuleFail(err));
      }
    }
    )
  );

  @Effect()
  updateAlertRule$: Observable<Action> = this.actions$.pipe(
    ofType(alertRulesActions.AlertRulesActionTypes.UpdateAlertRule),
    map((action: alertRulesActions.UpdateAlertRule) => action.payload),
    mergeMap((payload: any) => {
      try {
        // First Delete existing alert
        return this.alertService.deleteAlert(payload['alarm']).pipe(
          map((res) => {
            if (res && res !== 'Fail') {
              // Then create new alert
              return new alertRulesActions.CreateAlertRule(payload);
            } else {
              const err = {
                endpointUrl: 'Alert Delete API',
                error: 'Delete Alert failed',
                request: {
                  operation: 'Delete Alert'
                }
              };
              this.globalErrorService.set200Error(err, 'ERR_ALERT_UPDATE_FAIL');
              return new alertRulesActions.UpdateAlertRuleFail('Edit alert was failed: Service error');
            }
          }),
          catchError((err) => of(new alertRulesActions.DeleteAlertRuleFail(err)))
        )
      } catch (err) {
        of(new alertRulesActions.UpdateAlertRuleFail(err));
      }
    }
    )
  );

  @Effect()
  acknowledgeAlertRule$: Observable<Action> = this.actions$.pipe(
    ofType(alertRulesActions.AlertRulesActionTypes.AcknowledgeAlert),
    map((action: alertRulesActions.AcknowledgeAlert) => action.payload),
    mergeMap((alert: IAlert) => {
      try {
        return this.alertService.acknowledgeAlert(alert).pipe(
          map((result) => {
            if (result.toLowerCase() === 'success') {
              const find = this.finalAlertArr.findIndex((aletrtList) => aletrtList.AlarmID === alert.AlarmID);
              this.finalAlertArr[find].AlarmDetail.NotificationStatus = false;
            }
            return new alertRulesActions.AcknowledgeAlertSuccess(this.finalAlertArr);
          }),
          catchError((err) => of(new alertRulesActions.AcknowledgeAlertFail(err)))
        )
      } catch (err) {
        of(new alertRulesActions.AcknowledgeAlertFail(err));
      }
    }
    ));

  @Effect()
  acknowledgeAllAlertRule$: Observable<Action> = this.actions$.pipe(
    ofType(alertRulesActions.AlertRulesActionTypes.AcknowledgeAllAlerts),
    map((action: alertRulesActions.AcknowledgeAlert) => action.payload),
    mergeMap((alert: IAlert) => {
      try {
        return this.alertService.acknowledgeAlert(alert).pipe(
          map((result) => {
            if (result.toLowerCase() === 'success') {
              const find = this.finalAlertArr.findIndex((aletrtList) => aletrtList.AlarmID === alert.AlarmID);
              this.finalAlertArr[find].AlarmDetail.NotificationStatus = false;
            }
            return new alertRulesActions.AcknowledgeAlertSuccess(this.finalAlertArr);
          }),
          catchError((err) => of(new alertRulesActions.AcknowledgeAlertFail(err)))
        )
      } catch (err) {
        of(new alertRulesActions.DeleteAlertRuleFail(err));
      }
    }
    ));
  @Effect()
  ActionBatteryVoltageRule$: Observable<Action> = this.actions$.pipe(
    ofType(alertRulesActions.AlertRulesActionTypes.ActionBatteryVoltage),
    map((action: alertRulesActions.ActionBatteryVoltage) => action.payload),
    mergeMap((item: any) => {
      try {
        return this.alertService.getVoltageValue(item).pipe(
          map((result) => {
            const tempResponse = [];
            result.forEach((res) => {
              res.response.log.forEach((well) => {
                tempResponse.push(well);
              });
            });
            return new alertRulesActions.ActionBatteryVoltageSuccess(tempResponse);
          }),
          catchError((err) => of(new alertRulesActions.ActionBatteryVoltageFail(err)))
        )
      } catch (err) {
        of(new alertRulesActions.DeleteAlertRuleFail(err));
      }
    }
    ));
}
