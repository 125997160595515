<div class='backdrop'>
  <div class='modal-body'>
    <div class='header'>
      <div class='title'>Select Well</div>
    </div>
    <div class='content-body'>
      <div class="ui-g" *ngIf='wellList.length === 0 || isApiInprogress'>
        <div class='global-spinner-container'>
        <div class='global-spinner'></div>
        <div class='message-loading'>Retrieving wells from server</div>
        </div>
      </div>
      <div *ngIf='!isApiInprogress'>
      <div *ngFor='let well of wellList; let i = index; let odd = odd;' 
      [ngClass]='{ odd: odd }' class='list-item 
    {{ well.uidWell === selectedUid ? "selected" : "" }}'
     (click)='onWellSelect(well.uidWell)'>
        <span class='check-box'><span class='check-fill'></span></span>
        <div class='well-name'>{{ well.wellName }}</div>
      </div>
    </div>
    </div>
    <div class='footer'>
      <button class='cancel' (click)='onCancel()'>Cancel</button>
      <button type="button" class='done' (click)='saveSelection()' [disabled]='!selectedUid'>
        Done</button>
    </div>
  </div>
</div>