/**
 * Ngrx reducer for Unit Conversion
 */
import sensorData from '../../../assets/data/sensorTypeConfig.json';
import { Conversion } from '../../models/converterData';
import { CoreAction } from '../actions/actions';
import { UnitConversionActionTypes } from '../actions/conversionActions';
import { ConversionState } from '../container-states/conversion.state';

const conversion = sensorData.defaultUnitsForConversion;
export const defaultUnitConversion: ConversionState = {
  conversion
};

export function ConversionReducer(state = defaultUnitConversion, action: CoreAction<Conversion>) {
  switch (action.type) {
      case UnitConversionActionTypes.LoadUnitConversionSuccess:
        return {
          ...state,
          conversion: action.payload,
        };
      default: return state;
  }
}
