<div class="row">
  <div class="loading-view" *ngIf="showWaitIndicator">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="col-md-8">
      <div class="chart-section sm-height">
        <div class="schematic-wrapper">
          <div class="data-container">
            <div *ngIf="showSelection" class="row selection-header">
              <span class="header-text">Well Selection</span>
              <div>
                <mat-form-field appearance="fill">
                  <!-- <mat-label>{{ selectedWell.wellName }}</mat-label> -->
                  <mat-select [(ngModel)]="selectedWell" (selectionChange)="onWellChange()" [disabled]="showWaitIndicator">
                    <mat-option *ngFor="let well of globalSelectedWells" [value]="well">
                      {{ well.wellName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="!showSelection && !showMessage" class="row selection-header">
              <span class="header-text">Well name: {{selectedWell ? selectedWell.wellName: ''}}</span>
            </div>
            <div class="row col-md-12 col-sm-12">
              <div class="message-div" *ngIf="showMessage || showErrorMessage">
                  <span class="message-text">{{messageText}}</span>
              </div>
              <div  class='responsive-canvas'>
                <div class="canvas-container">
                  <canvas #myCanvas class="myCanvas"  width="1000px"  height="700px">
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="col-md-4">
    <app-live-readings [isSchematic]='true' [schematicSelectedWell]='selectedWell' [selectedTile]="'schematics'"></app-live-readings>
  </div>
</div>

<p-toast position="center" key="ToastMessageKeyCharts" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
      <h3>{{message.summary}}</h3>
      <p>{{message.detail}}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 login-bck-btn" style="text-align: center">
        <button type="button" pButton (click)="hideDataRetrievalError()" label="Close" class="ui-button-success custom-button-danger">
          Close
        </button>
      </div>
    </div>
  </ng-template>
</p-toast>
