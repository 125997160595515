<div [ngClass]="SigningIn ? 'loading-overlay-login' : 'loading-overlay'">
  <div class='spinner-container'>
    <div class='spinner'></div>
  </div>
  <p *ngIf="!exportData && !dashbordLoading && !SigningOut && !SigningIn" class= 'message'> Please wait while we load your data </p>
  <p *ngIf="exportData" class= 'message'> Please wait while we export your data </p>
  <p *ngIf="dashbordLoading" [ngStyle]="{'color': textColor ? textColor : 'black'}" class= 'message'> Please wait while we load your wells </p>
  <p *ngIf="SigningOut" class= 'message'> Please wait while we are signing you out </p>
  <p *ngIf="SigningIn" class= 'message'> Please wait while we fetch your data </p>
  <button
    *ngIf='historicalView'
    class='btn-secondary btn-cancel'
    (click)='onCancel()'> Cancel </button>
</div>
